import httpClient from "../../core/httpclient";
import incomingInvoiceTypes from "../type/incomingInvoice";
import ProjectConfig from '../../config/config'
import XMSErrorHandler from "../../core/xmsErrorHandler";
import PropTypes from 'prop-types';
import moment from "moment";
import { dateToEpoch } from "../../core/epochToDateConverter";
import Axios from "axios";

export const getIncomingInvoices = (periodStartMonth, periodEndMonth, periodYear, customerName, customerNumber,integrationFilterValue,eInvoiceFilterValue) => async dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/customerinvoice/getcustomerinvoices`;
    const customerInformation = {
        periodStart: periodStartMonth,
        periodEnd: periodEndMonth,
        periodYear: periodYear,
        customerName: customerName,
        customerNumber: customerNumber,
        IsEInvoice : eInvoiceFilterValue,
        IsIntegrated : integrationFilterValue,
    }
    dispatch({
        type: incomingInvoiceTypes.GETTING_INCOMING_INVOICES
    })
    httpClient.post(endPoint, customerInformation)
        .then((response) => {
            const IncomingInvoices = response.data.result
            dispatch({
                type: incomingInvoiceTypes.GET_INCOMING_INVOICES,
                IncomingInvoices: IncomingInvoices,
            })

            if (IncomingInvoices.length < 1) {
                dispatch({
                    type: incomingInvoiceTypes.NOT_FOUND
                })
            }
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: incomingInvoiceTypes.SET_NETWORK_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
}
getIncomingInvoices.propTypes = {
    periodStartMonth: PropTypes.number,
    periodEndMonth: PropTypes.number,
    periodYear: PropTypes.number,
    customerName: PropTypes.string,
    customerNumber: PropTypes.string,
    integrationFilterValue : PropTypes.bool,
    eInvoiceFilterValue : PropTypes.bool,
}
getIncomingInvoices.defaultProps = {
    periodStartMonth: null,
    periodEndMonth: null,
    periodYear: null,
    customerName: "",
    customerNumber: "",
    integrationFilterValue : null,
    eInvoiceFilterValue : null,
}

export const clearPage = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.CLEAR_PAGE,
    })
}

export const setError = (ErrorCode, ErrorDescription) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription
    })
}
export const clearError = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_ERROR,
        ErrorCode: -1,
        ErrorDescription: null
    })
}
export const setNetworkError = (ErrorCode, ErrorDescription) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_NETWORK_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription
    })
}
export const clearNetworkError = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_NETWORK_ERROR,
        ErrorCode: -1,
        ErrorDescription: ""
    })
}
export const setStartMonth = (StartMonth) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_STARTMONTH,
        StartMonth: StartMonth
    })
}
export const setEndMonth = (EndMonth) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_ENDMONTH,
        EndMonth: EndMonth
    })
}
export const setYear = (Year) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_YEAR,
        Year: Year
    })
}
export const setCustomerName = (CustomerName) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_CUSTOMERNAME,
        CustomerName: CustomerName
    })
}
export const setCustomerNumber = (CustomerNumber) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_CUSTOMERNUMBER,
        CustomerNumber: CustomerNumber
    })
}
export const setIntegrationInput = (IntegrationFilterValue) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_INTEGRATION_INPUT,
        IntegrationFilterValue: IntegrationFilterValue
    })
}
export const setEInvoiceInput = (EInvoiceFilterValue) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_EINVOICE_INPUT,
        EInvoiceFilterValue: EInvoiceFilterValue
    })
}
export const showNewInvoiceModal = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SHOW_NEW_INVOICE_MODAL
    })
}
export const hideNewInvoiceModal = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.HIDE_NEW_INVOICE_MODAL
    })
}
export const showEditInvoiceModal = (SelectedInvoice) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SHOW_EDIT_MODAL,
        SelectedInvoice: SelectedInvoice
    })
}
export const hideEditInvoiceModal = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.HIDE_EDIT_MODAL
    })
}

//New Invoice
const CancelToken = Axios.CancelToken;
let cancel;
export const getCustomers = (customerName, customerNumber) => dispatch => {
    cancel && cancel()
    const endPoint = `${ProjectConfig.apiUrl}/customer/getcustomers `;
    return new Promise(
        (resolve, reject) => {
            dispatch({
                type: incomingInvoiceTypes.GETTING_CUSTOMERS
            })
            const GetCustomersRequest = {
                customerName: customerName,
                customerNumber: customerNumber
            }
            httpClient.post(endPoint, JSON.stringify(GetCustomersRequest), {
                cancelToken: new CancelToken((c) => cancel = c)
            })
                .then((response) => {
                    const CustomerDTO = response.data.result
                    dispatch({
                        type: incomingInvoiceTypes.GET_CUSTOMERS,
                        CustomerDTO: CustomerDTO
                    })
                }).catch((error) => {
                    if (error.message === undefined) return //calceled
                    dispatch({
                        type: incomingInvoiceTypes.GET_CUSTOMER_ERROR
                    })
                })
                .finally(() => {
                    resolve("ok")
                })
        }
    )
}
export const resetIncomingInvoicePage = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.RESET_INCOMING_INVOICE_PAGE
    })
}
export const setSelectedCustomer = (SelectedCustomer) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_SELECTED_CUSTOMER,
        SelectedCustomer: SelectedCustomer
    })
}
export const setCustomerStartMonth = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_CUSTOMER_STARTMONTH,
        StartMonth: value
    })
}
export const setCustomerEndMonth = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_CUSTOMER_ENDMONTH,
        EndMonth: value
    })
}
export const setCustomerYear = (Year) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_CUSTOMER_YEAR,
        Year: Year
    })
}
export const setSelectTransaction = (Transaction) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SELECT_TRANSACTION,
        SelectedTransaction: Transaction,
    })
}

export const getInvoiceFromArchive = (InvoiceETTN) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/customerinvoice/getinvoicefromarchive`;
    dispatch({
        type: incomingInvoiceTypes.GETTING_CUSTOMER_ARCHIVE,
    })
    const GetInvoiceFromArchiveRequest = {
        InvoiceETTN: InvoiceETTN
    }
    httpClient.post(endPoint, JSON.stringify(GetInvoiceFromArchiveRequest))
        .then(
            (response) => {
                const CustomerArchive = response.data.result
                dispatch({
                    type: incomingInvoiceTypes.GET_CUSTOMER_ARCHIVE,
                    CustomerArchive: CustomerArchive,
                    invoiceAmount: CustomerArchive.invoiceAmount,
                    invoiceAmountAsTry: CustomerArchive.invoiceAmountAsTry,
                    invoiceCurrency: CustomerArchive.invoiceCurrency,
                    invoiceId: CustomerArchive.invoiceId,
                    invoiceDate: CustomerArchive.invoiceDate ? moment.unix(CustomerArchive.invoiceDate) : 0,
                    invoicePaymentDate: CustomerArchive.invoicePaymentDate ? moment.unix(CustomerArchive.invoicePaymentDate) : 0,
                    voucherDate: CustomerArchive.voucherDate ? moment.unix(CustomerArchive.voucherDate) : 0,
                    differenceAmountAsTry: CustomerArchive.differenceAmount,
                    exchangeRate: CustomerArchive.exchangeRate,
                    invoiceId: CustomerArchive.invoiceId,
                    ettn: CustomerArchive.ettn,

                })

            })
        .catch(
            (error) => {
                const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                dispatch({
                    type: incomingInvoiceTypes.GET_CUSTOMER_ARCHIVE_ERROR,
                    ErrorCode: ErrorCode,
                    ErrorDescription: ErrorDescription,
                })
            })
}

export const getCustomerTransactions = (periodStartMonth, periodEndMonth, periodYear, CustomerNumber, CustomerName) => dispatch => {

    const today = new Date()
    const todayMonth = ((today.getMonth() + 1) < 10) ? ("0" + (today.getMonth() + 1)) : (today.getMonth() + 1)
    const _pStartMonth = (periodStartMonth === undefined) ? todayMonth : (periodStartMonth < 10 ? ("0" + periodStartMonth) : periodStartMonth)
    const _pEndMonth = (periodStartMonth === undefined) ? todayMonth : (periodEndMonth < 10 ? ("0" + periodEndMonth) : periodEndMonth)

    var startDate = moment((periodYear === undefined ? today.getFullYear() : periodYear) + '-' + (_pStartMonth) + '-01')
    var endDate = moment((periodYear === undefined ? today.getFullYear() : periodYear) + '-' + (_pEndMonth) + '-01')

    endDate = moment(endDate).add(1, 'M').subtract(1, 'S');

    const startDateEpoch = dateToEpoch(startDate.toDate())
    const endDateEpoch = dateToEpoch(endDate.toDate())

    const endPoint = `${ProjectConfig.apiUrl}/transaction/gettransactions`;
    const rebateInformation = {
        periodStart: startDateEpoch,
        periodEnd: endDateEpoch,
        customerName: '',
        customerCode: CustomerNumber,
    }
    dispatch({
        type: incomingInvoiceTypes.GETTING_CUSTOMER_INVOICES,
    })
    httpClient.post(endPoint, JSON.stringify(rebateInformation))
        .then(
            (response) => {
                const customerTransactions = response.data.result
                dispatch({
                    type: incomingInvoiceTypes.GET_CUSTOMER_INVOICES,
                    CustomerInvoices: customerTransactions
                })
            })
        .catch(
            (error) => {
                const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                dispatch({
                    type: incomingInvoiceTypes.GET_CUSTOMER_INVOICES_ERROR,
                    ErrorCode: ErrorCode,
                    ErrorDescription: ErrorDescription,
                })
            })
}
export const getCustomerTransaction = (TransactionId) => dispatch => {

    const endPoint = `${ProjectConfig.apiUrl}/transaction/gettransaction?transactionId=${TransactionId}`;
    dispatch({
        type: incomingInvoiceTypes.GETTING_CUSTOMER_TRANSACTION,
    })
    httpClient.post(endPoint, null)
        .then(
            (response) => {
                const CustomerInvoices = response.data.result
                dispatch({
                    type: incomingInvoiceTypes.GET_CUSTOMER_TRANSACTION,
                    CustomerInvoices: CustomerInvoices
                })
            })
        .catch(
            (error) => {
                const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                dispatch({
                    type: incomingInvoiceTypes.GET_CUSTOMER_TRANSACTION_ERROR,
                    ErrorCode: ErrorCode,
                    ErrorDescription: ErrorDescription,
                })
            })
}

export const setInputInvoiceAmount = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_INVOICEAMOUNT,
        invoiceAmount: value,
    })
}
export const setInputInvoiceDescription = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_INVOICEDESCRIPTION,
        Description: value,
    })
}
export const setInputInvoiceAmountAsTry = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_AMOUNTASTRY,
        invoiceAmountAsTry: value,
    })
}
export const setInputInvoiceCurrency = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_INVOICECURRENCY,
        invoiceCurrency: value,
    })
}
export const setInputInvoiceId = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_INVOICEID,
        invoiceId: value,
    })
}
export const setInputInvoiceDate = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_INVOICEDATE,
        invoiceDate: value,
    })
}
export const setInputInvoicePaymentDate = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_INVOICEPAYMENTDATE,
        invoicePaymentDate: value,
    })
}
export const setInputExchangeRate = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_EXCHANGERATE,
        exchangeRate: value
    })
}
export const setInputEttn = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_ETTN,
        ettn: value,
    })
}
export const setInputWATAmount = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_WATAMOUNT,
        watAmount: value
    })
}
export const setInputWATAmountAsTry = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_WATAMOUNTASTRY,
        watAmountAsTry: value
    })
}
export const setInputTotalAmount = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_TOTALAMOUNT,
        TotalAmount: parseFloat(value)
    })
}
export const setInputTotalAmountAsTry = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_TOTALAMOUUNTASTRY,
        TotalAmountAsTry: parseFloat(value)
    })
}
export const setEInvoice = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_EINVOICE,
        EInvoice: value
    })
}
export const setInputVoucherDate = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_VOUCHERDATE,
        voucherDate: value
    })
}
export const saveNewInvoice = (Period, CustomerName, CustomerNumber, isEInvoice, VoucherDate, TransactionId, invoiceAmount,
    invoiceAmountAsTry, InvoiceCurrency, InvoiceId, InvoiceDate, InvoicePaymentDate, exchangeRate, Ettn, watAmount, watAmountAsTry, Description, differenceAmountAsTry) => dispatch => {
        dispatch({
            type: incomingInvoiceTypes.SAVING_NEW_INVOICE
        })
        const endPoint = `${ProjectConfig.apiUrl}/customerinvoice/savecustomerinvoice`;
        const epochVoucherDate = moment(VoucherDate).add(moment.unix(VoucherDate).utcOffset() * 60 * 1000).unix()
        const epochInvoiceDate = moment(InvoiceDate).add(moment.unix(InvoiceDate).utcOffset() * 60 * 1000).unix()
        const epochInvoicePaymentDate = moment(InvoicePaymentDate).add(moment.unix(InvoicePaymentDate).utcOffset() * 60 * 1000).unix()
        const InvoiceAmount = invoiceAmount ? parseFloat(invoiceAmount) : 0
        const InvoiceAmountAsTry = invoiceAmountAsTry ? parseFloat(invoiceAmountAsTry) : 0
        const ExchangeRate = exchangeRate ? parseFloat(exchangeRate) : 0
        const VatAmount = watAmount ? parseFloat(watAmount) : 0
        const VatAmountAsTry = watAmountAsTry ? parseFloat(watAmountAsTry) : 0
        const DifferenceAmountAsTry = differenceAmountAsTry ? parseFloat(differenceAmountAsTry) : 0
        const InvoiceTotalAmount = InvoiceAmount + VatAmount
        const InvoiceTotalAmountAsTry = InvoiceAmountAsTry + VatAmountAsTry + DifferenceAmountAsTry
        const CustomerInvoiceDto = {
            period: Period,
            customerName: CustomerName,
            customerNumber: CustomerNumber,
            isEInvoice: isEInvoice,
            voucherDate: epochVoucherDate,
            transactionId: TransactionId,
            invoiceAmount: InvoiceAmount,
            invoiceAmountAsTry: InvoiceAmountAsTry,
            invoiceTotalAmount: InvoiceTotalAmount,
            invoiceTotalAmountAsTry: InvoiceTotalAmountAsTry,
            invoiceCurrency: InvoiceCurrency,
            invoiceId: InvoiceId,
            invoiceDate: epochInvoiceDate,
            invoicePaymentDate: epochInvoicePaymentDate,
            exchangeRate: ExchangeRate,
            vatAmount: VatAmount,
            vatAmountAsTry: VatAmountAsTry,
            differenceAmountAsTry: DifferenceAmountAsTry,
            ettn: Ettn,
            description: Description,
        }
        httpClient.post(endPoint, JSON.stringify(CustomerInvoiceDto))
            .then(
                (response) => {
                    const CustomerInvoices = response.data.result
                    dispatch({
                        type: incomingInvoiceTypes.SAVE_NEW_INVOICE,
                        CustomerInvoices: CustomerInvoices
                    })
                })
            .catch(
                (error) => {
                    const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                    dispatch({
                        type: incomingInvoiceTypes.SAVE_NEW_INVOICE_ERROR,
                        ErrorCode: ErrorCode,
                        ErrorDescription: ErrorDescription,
                    })
                })
    }

export const showDeleteInvoiceModal = (SelectedInvoice) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SHOW_DELETE_INVOICE_MODAL,
        SelectedInvoice: SelectedInvoice
    })
}

export const hideDeleteInvoiceModal = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.HIDE_DELETE_INVOICE_MODAL,
    })
}

export const showIntegrateModal = (SelectedInvoice) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SHOW_INTEGRATE_MODAL,
        SelectedInvoice: SelectedInvoice
    })
}

export const hideIntegrateModal = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.HIDE_INTEGRATE_MODAL,
    })
}

export const showCheckIntegrateModal = (SelectedInvoice) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SHOW_CHECK_INTEGRATE_MODAL,
        SelectedInvoice: SelectedInvoice
    })
}

export const hideCheckIntegrateModal = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.HIDE_CHECK_INTEGRATE_MODAL,
    })
}



export const deleteInvoice = (sourceInvoiceId) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.DELETING_INVOICE
    })
    const endPoint = `${ProjectConfig.apiUrl}/customerinvoice/deletecustomerinvoice`;

    httpClient.post(endPoint, JSON.stringify(sourceInvoiceId))
        .then(
            (response) => {
                //const CustomerInvoices = response.data.result
                dispatch({
                    type: incomingInvoiceTypes.DELETE_INVOICE,
                    //CustomerInvoices: CustomerInvoices
                })
            })
        .catch(
            (error) => {
                const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                dispatch({
                    type: incomingInvoiceTypes.DELETE_INVOICE_ERROR,
                    ErrorCode: ErrorCode,
                    ErrorDescription: ErrorDescription,
                })
            })

}

export const integrate = (sourceInvoiceId) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INTEGRATING
    })
    const endPoint = `${ProjectConfig.apiUrl}/customerinvoice/integrate`;

    httpClient.post(endPoint, JSON.stringify(sourceInvoiceId))
        .then(
            (response) => {
                //const CustomerInvoices = response.data.result
                dispatch({
                    type: incomingInvoiceTypes.INTEGRATE,
                    //CustomerInvoices: CustomerInvoices
                })
            })
        .catch(
            (error) => {
                const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                dispatch({
                    type: incomingInvoiceTypes.INTEGRATE_ERROR,
                    ErrorCode: ErrorCode,
                    ErrorDescription: ErrorDescription,
                })
            })

}


export const checkIntegrate = (sourceInvoiceId) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.CHECKING_INTEGRATE
    })
    const endPoint = `${ProjectConfig.apiUrl}/customerinvoice/checkIntegrate`;

    httpClient.post(endPoint, JSON.stringify(sourceInvoiceId))
        .then(
            (response) => {
                //const CustomerInvoices = response.data.result
                dispatch({
                    type: incomingInvoiceTypes.CHECK_INTEGRATE,
                    //CustomerInvoices: CustomerInvoices
                })
            })
        .catch(
            (error) => {
                const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                dispatch({
                    type: incomingInvoiceTypes.CHECK_INTEGRATE_ERROR,
                    ErrorCode: ErrorCode,
                    ErrorDescription: ErrorDescription,
                })
            })

}
export const okModalClose = () => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.OK_MODAL_CLOSE,
    })
}

const setSelectedInvoice = (SelectedInvoice) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_SELECTED_INVOICE,
        SelectedInvoice: SelectedInvoice
    })
}
export const setValidationInvoiceNumber = (Validation) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_INVOICE_NUMBER_VALIDATION,
        Validation: Validation
    })
}

export const setInvoiceAmountValidation = (Validation) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_INVOICE_AMOUNT_VALIDATION,
        Validation: Validation
    })
}
export const setExchangeRateValidation = (Validation) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_EXCHANGERATE_VALIDATION,
        Validation: Validation
    })
}

export const setValidationTotalAmountValidation = (Validation) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_INVOICE_TOTAL_AMOUNT_VALIDATION,
        Validation: Validation
    })
}
export const setDateValidation = (Validation) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_INVOICE_DATE_VALIDATION,
        Validation: Validation
    })
}
export const setPaymentDateValidation = (Validation) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_INVOICE_PAYMENTDATE_VALIDATION,
        Validation: Validation
    })
}
export const setVoucherDateValidation = (Validation) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.SET_VOUCHERDATE_VALIDATION,
        Validation: Validation
    })
}
export const setInputDifferenceAmountAsTry = (value) => dispatch => {
    dispatch({
        type: incomingInvoiceTypes.INPUT_SET_DIFFERENCEAMOUNTASTRY,
        differenceAmountAsTry: value
    })
}

