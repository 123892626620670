export const getDataFromParent = (ParentIds, RowData, columnId, parentArrayPosition) => {
    if (ParentIds !== undefined) {
        if (ParentIds.length > 0) {
            let Data;
            ParentIds.map((pId) => {
                if (pId !== "customer") {
                    if (parentArrayPosition || parentArrayPosition === 0) {
                        const ParentData = Data ? Data[pId] && Data[pId][parentArrayPosition] : RowData[pId] ? RowData[pId][parentArrayPosition] : null
                        if (pId === "accountings") {
                            Data = ParentData
                        } else {
                            Data = Data ? Data[pId] : ParentData
                        }
                        return
                    }
                }
                Data = Data ? Data[pId] : RowData[pId]
            })
            return Data ? Data[columnId] : null
        }
    }
    return RowData[columnId]

}
export const setDataFromParent = (ParentIds, columnId, Row, Data) => {
    if (ParentIds && typeof ParentIds === "object") {
        if (ParentIds.length > 0) {
            let pData;
            ParentIds.map((pId) => {
                pData = pData ? pData[pId] : Row[pId]
            })
            pData[columnId] = Data
        } else {
            Row[columnId] = Data
        }
    } else {
        Row[columnId] = Data
    }
}
export const getDataListFromParent = (ParentIds, DataList, ColumnId) => {
    let childData;
    if (ParentIds !== undefined) {
        if (ParentIds.length > 0) {
            ParentIds.map((pId) => {
                let listOfValue = childData ? childData : DataList
                for (let index = 0; index < listOfValue.length; index++) {
                    if (listOfValue[index].ColumnId === pId) {
                        childData = listOfValue[index].CellHistoryList
                        break
                    }
                }
            })
            for (let index = 0; index < childData.length; index++) {
                if (childData[index].ColumnId === ColumnId) {
                    childData = childData[index].CellHistoryList
                }
            }
        }
    } else {
        for (let index = 0; index < DataList.length; index++) {
            if (DataList[index].ColumnId === ColumnId) {
                childData = DataList[index].CellHistoryList
                break
            }
        }
    }
    return childData
}