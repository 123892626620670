export const dataHistoryBinder = (dataHistories) => {
    let dataBindedArray = []
    let rebateRatioCheck = true
    dataHistories.map((dataHistory) => {
        if (!rebateRatioCheck) return
        let historyObject = {}

        //_------
        // public string  TransactionId { get; set; }
        // public bool DoNotSend { get; set; }
        // public decimal? RebateRatio { get; set; }
        // public decimal? RebateAmount { get; set; }
        // public decimal ManualChargeBackAmount { get; set; }
        // public string ManualChargeBackDescription { get; set; }
        // public string RebateRateDescription { get; set; }
        // public bool IsOctet { get; set; }
        // public List<string> EmailAddresses { get; set; }
        // public string CustomerAccountNumber { get; set; }
        // public bool IsCopied { get; set; }
        // public long CopiedPeriod { get; set; }
        //-----------

        historyObject = dataHistory.row

        dataHistory.cells.map((cellHistory) => {
            historyObject[cellHistory.columnId] = cellHistory.dataList[cellHistory.dataList.length - 1]
        })
        if (historyObject.rebateRatio === "" || historyObject.rebateRatio === null || historyObject.rebateRatio === undefined || historyObject.rebateRatio < 0) {
            historyObject.rebateRatio = 0;
            historyObject.rebateAmount = 0;
        }
        historyObject.rebateAmount = historyObject.rebateAmount ? historyObject.rebateAmount : 0

        historyObject.manualChargeBackAmount = dataControl(historyObject.manualChargeBackAmount) ? historyObject.manualChargeBackAmount : 0

        historyObject.invoiceChargeBackAmount = dataControl(historyObject.invoiceChargeBackAmount) ? historyObject.invoiceChargeBackAmount : 0

        historyObject.manualChargeBackDescription = historyObject.manualChargeBackDescription ? historyObject.manualChargeBackDescription : ""

        historyObject.rebateRateDescription = historyObject.rebateRateDescription ? historyObject.rebateRateDescription : ""

        historyObject.isOctet = historyObject.customer.isOctet ? historyObject.customer.isOctet : false


        historyObject.emailAddresses = dataControl(historyObject.customer) ? historyObject.customer.emailAddresses : []

        historyObject.isCopied = historyObject.isCopied ? historyObject.isCopied : false

        historyObject.copiedPeriod = historyObject.copiedPeriod ? historyObject.copiedPeriod : false

        dataBindedArray.push(historyObject)
    })
    if (!rebateRatioCheck) return false
    return dataBindedArray
}
const dataControl = (data) => {
    if (data === undefined)
        return false
    else if (data === null)
        return false
    else if (data === NaN)
        return false
    else
        return true
}
export const customerBindHistory = (dataHistories) => {
    let bindedHistories = []
    dataHistories.map((dataHistory) => {
        let history = {}
        history.Number = dataHistory.row.number
        history.BuyerAccountNumber = dataHistory.row.buyerAccountNumber
        history.VendorAccountNumber = dataHistory.row.vendorAccountNumber
        history.IsOctet = dataHistory.row.isOctet
        history.IsRebateCalculationEnabled = dataHistory.row.isRebateCalculationEnabled
        history.EmailAddresses = dataHistory.row.emailAddresses ? dataHistory.row.emailAddresses : []
        bindedHistories.push(history)
    })
    return bindedHistories
}
export const extractAccounts = (dataHistory) => {
    const Accountings = []
    dataHistory.map((H) => {
        let accountObject = H.row
        Accountings.push(accountObject)
    })
    return Accountings
}