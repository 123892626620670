import React, { useState, useRef, useEffect } from 'react'
import ModalTable from './Table/ModalTable'
import './ModalContent.scss'
import { Radio, Input, Button, Label, Select } from '@dfds-ui/react-components'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux'
import {
    getInvoiceFromArchive, setInputInvoiceDate, setInputInvoicePaymentDate,
    setInputInvoiceId, setInputInvoiceAmount, setInputInvoiceCurrency, setInputWATAmount, setInputWATAmountAsTry,
    setInputExchangeRate, setInputTotalAmount, setInputTotalAmountAsTry, setEInvoice, setInputVoucherDate,
    setInputInvoiceAmountAsTry, setValidationTotalAmountValidation, setValidationInvoiceNumber, setInputEttn, setInvoiceAmountValidation,
    setDateValidation, setPaymentDateValidation, setVoucherDateValidation, setExchangeRateValidation, setInputInvoiceDescription, setInputDifferenceAmountAsTry
} from '../../../../../store/action/incomingInvoiceAction'
import moment from 'moment'
import { incomingInvoiceLanguage } from '../../../../../config/language'
import { JustNumber } from '../../../../../core/JustNumber'
import JustWait from '../../../../CommonUI/JustAnimations/JustWait/JustWait'
const NewInvoiceModalContent = (props) => {
    const { WaitForCustomerInvoices, CustomerInvoices, Language, SelectedTransaction,
        WaitForCustomerArchive, CustomerArchive, getInvoiceFromArchive, invoiceAmount,
        invoiceAmountAsTry, invoiceCurrency, invoiceId, invoiceDate, invoicePaymentDate, exchangeRate,
        ettn, setInputInvoiceDate, setInputInvoicePaymentDate, setInputInvoiceId,
        setInputInvoiceAmount, setInputInvoiceCurrency, setInputWATAmount,
        setInputWATAmountAsTry, watAmount, watAmountAsTry, setInputExchangeRate, setInputTotalAmount,
        setInputTotalAmountAsTry, setEInvoice, EInvoice, voucherDate, setInputVoucherDate,
        setInputInvoiceAmountAsTry, InvoiceNumberValidation, InvoiceAmountValidation,
        TotalAmountValidation, setValidationTotalAmountValidation, setValidationInvoiceNumber,
        setInputEttn, InvoicePaymentDateValidation, InvoiceDateValidation, VoucherDateValidation
        , setDateValidation, setPaymentDateValidation, setVoucherDateValidation,
        setInvoiceAmountValidation, setExchangeRateValidation, ExchangeRateValidation, setInputInvoiceDescription,
        Description, TotalAmount, TotalAmountAsTry, differenceAmountAsTry, setInputDifferenceAmountAsTry } = props

    useEffect(() => {
        totalInvoiceCalc()
        invoiceAmountAsTryCalc()
    }, [invoiceAmount])

    useEffect(() => {
        totalInvoiceAsTryCalc()
    }, [invoiceAmountAsTry])

    useEffect(() => {
        totalInvoiceCalc()
        watAsTryCalc()
    }, [watAmount])

    useEffect(() => {
        totalInvoiceAsTryCalc()
    }, [watAmountAsTry])

    useEffect(() => {
        totalInvoiceAsTryCalc()
    }, [differenceAmountAsTry])

    useEffect(() => {
        watAsTryCalc()
        invoiceAmountAsTryCalc()
    }, [exchangeRate])

    const totalInvoiceCalc = () => {
        const invoiceAmountFloat = parseFloat(invoiceAmount) ? parseFloat(invoiceAmount) : 0
        const watAmountFloat = parseFloat(watAmount) ? parseFloat(watAmount) : 0
        setInputTotalAmount((watAmountFloat + invoiceAmountFloat).toFixed(4))
    }
    const totalInvoiceAsTryCalc = () => {
        const invoiceAmountFloat = parseFloat(invoiceAmountAsTry) ? parseFloat(invoiceAmountAsTry) : 0
        const watAmountFloat = parseFloat(watAmountAsTry) ? parseFloat(watAmountAsTry) : 0
        const differenceAmountAsTryFloat = parseFloat(differenceAmountAsTry) ? parseFloat(differenceAmountAsTry) : 0
        setInputTotalAmountAsTry((invoiceAmountFloat + watAmountFloat + differenceAmountAsTryFloat).toFixed(2))
    }
    const watAsTryCalc = () => {
        const exchangeRateFloat = parseFloat(exchangeRate) ? parseFloat(exchangeRate) : 0
        const watAmountFloat = parseFloat(watAmount) ? parseFloat(watAmount) : 0
        const invoiceVatAmountAsTryInt = parseInt(exchangeRateFloat * watAmountFloat * 100)
        const invoiceVatAmountAsTryFloat = parseFloat(invoiceVatAmountAsTryInt / 100)
        setInputWATAmountAsTry(invoiceVatAmountAsTryFloat)
        return invoiceVatAmountAsTryFloat

    }
    const invoiceAmountAsTryCalc = () => {
        const exchangeRateFloat = parseFloat(exchangeRate) ? parseFloat(exchangeRate) : 0
        const invoiceAmountFloat = parseFloat(invoiceAmount) ? parseFloat(invoiceAmount) : 0
        const invoiceAmountAsTryInt = parseInt(exchangeRateFloat * invoiceAmountFloat * 100)
        const invoiceAmountAsTryFloat = parseFloat(invoiceAmountAsTryInt / 100)
        setInputInvoiceAmountAsTry(invoiceAmountAsTryFloat)
        return invoiceAmountAsTryFloat

    }
    const showZeros = (value, count) => {
        if (value) {
            const valueAsStr = value.toString()
            const afterDot = valueAsStr.split('.')[1]
            if (afterDot) {
                let newValue = value.toString()
                for (let i = 0; i < count; i++) {
                    if (!afterDot[i]) {
                        newValue = newValue + "0"
                    }
                }
                return newValue
            }
        }
        return value
    }
    return (
        <div className="NewInvoiceModalContent-Container">
            <ModalTable />
            <div className="Radio-Content">
                <Radio size="small" disabled={!SelectedTransaction} className="manualInvoice radioButtonInvoice" name="invoiceRadio" defaultChecked value="1"
                    onChange={(event) => {
                        setEInvoice(!event.target.checked)
                        setInputEttn("")
                    }}>
                    {incomingInvoiceLanguage[Language].ModalLanguage.PaperInvoice}
                </Radio>
                <Radio size="small" disabled={!SelectedTransaction} className="eInvoice radioButtonInvoice" name="invoiceRadio" value="2"
                    onChange={(event) => setEInvoice(event.target.checked)}>
                    {incomingInvoiceLanguage[Language].ModalLanguage.EInvoice}

                </Radio>
                <div className="InputWithLabel" id="GUIDInput">
                    <span className="Input-Label">

                    </span>
                    <Input
                        value={ettn}
                        onChange={(event) => setInputEttn(event.target.value)}
                        disabled={!EInvoice || WaitForCustomerArchive}
                        size={"small"}
                    />
                </div>
                <Button
                    disabled={!(EInvoice && SelectedTransaction) || WaitForCustomerArchive}
                    className="getInvoiceButton"
                    size={"small"}
                    variation={"secondary"}
                    onClick={() => getInvoiceFromArchive(ettn)}
                >
                    {incomingInvoiceLanguage[Language].ModalLanguage.GetEInvoiceButton}
                </Button>
            </div>

            <div className="ContentSelf-Container">
                <div className="LeftContent-Container">

                    {WaitForCustomerArchive && <JustWait />}
                    <div className="DateField-Wrapper" id="invoicePeriod">
                        <Label id="declarationDate-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.InvoiceDate}
                        </Label>
                        <DatePicker
                            // value={invoiceDate && invoiceDate}
                            disabled={!SelectedTransaction}
                            className={"DeclarationDate-Picker " + (InvoiceDateValidation ? "ERROR_BORDER" : "")}
                            selected={invoiceDate && new Date(invoiceDate)}
                            onChange={date => { setInputInvoiceDate(date); setDateValidation(false); }}
                            // onChange={date => setInputInvoiceDate(date)}
                            // isClearable={true}
                            placeholderText={incomingInvoiceLanguage[Language].ModalLanguage.InvoiceDate}

                            dateFormat={"dd/MM/yyyy"}
                        />
                    </div>
                    <div className="InputWithLabel" id="invoiceNumber">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.InvoiceId}

                        </span>
                        <Input
                            onChange={(event) => { setInputInvoiceId(event.target.value); setValidationInvoiceNumber(false) }}
                            value={invoiceId}
                            error={InvoiceNumberValidation}
                            disabled={!SelectedTransaction}
                            size={"small"}
                        />
                    </div>
                    <div className="DateField-Wrapper" id="datePicker_payment">
                        <Label id="declarationDate-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.InvoicePaymentDate}

                        </Label>
                        <DatePicker
                            // value={invoicePaymentDate && invoicePaymentDate}
                            className={"DeclarationDate-Picker " + (InvoicePaymentDateValidation ? "ERROR_BORDER" : "")}
                            // selected={new Date()}
                            selected={invoicePaymentDate && new Date(invoicePaymentDate)}
                            onChange={date => { setInputInvoicePaymentDate(date); setPaymentDateValidation(false); }}
                            // isClearable={true}
                            disabled={!SelectedTransaction}
                            placeholderText={incomingInvoiceLanguage[Language].ModalLanguage.InvoicePaymentDate}
                            dateFormat={"dd/MM/yyyy"}
                        />
                    </div>
                    <div className="DateField-Wrapper" id="datePicker_mah">
                        <Label id="declarationDate-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.VoucherDate}

                        </Label>
                        <DatePicker
                            // value={invoicePaymentDate && invoicePaymentDate}
                            className={"DeclarationDate-Picker " + (VoucherDateValidation ? "ERROR_BORDER" : "")}
                            // selected={new Date()}
                            selected={voucherDate && new Date(voucherDate)}
                            onChange={date => { setInputVoucherDate(date); setVoucherDateValidation(false); }}
                            // isClearable={true}
                            disabled={!SelectedTransaction}
                            placeholderText={incomingInvoiceLanguage[Language].ModalLanguage.VoucherDate}
                            dateFormat={"dd/MM/yyyy"}
                        />
                    </div>

                    <div className="InputWithLabel" id="invoiceDescription">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.Description}
                        </span>
                        <textarea

                            value={Description}
                            onChange={(event) => { setInputInvoiceDescription(event.target.value) }}
                            disabled={!SelectedTransaction}
                        />
                    </div>
                    <div className="InputWithLabel" id="invoiceRate">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.ExchangeRate}
                        </span>
                        <Input
                            className={ExchangeRateValidation ? "ERROR_BORDER" : ""}
                            onKeyDown={(event) => JustNumber(event, 6)}
                            value={exchangeRate}
                            onChange={(event) => { setInputExchangeRate(event.target.value); setExchangeRateValidation(false); }}
                            disabled={!SelectedTransaction}
                            size={"small"}
                        />
                    </div>
                    <div className="InputWithLabel" id="differenceAmountAsTry">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.TLGap}
                        </span>
                        <Input
                            // className={ExchangeRateValidation ? "ERROR_BORDER" : ""}
                            onKeyDown={(event) => JustNumber(event, 2)}
                            value={differenceAmountAsTry}
                            onChange={(event) => { setInputDifferenceAmountAsTry(event.target.value); }}
                            disabled={!SelectedTransaction}
                            size={"small"}
                        />
                    </div>
                </div>
                <div className="RightContent-Container">
                    {WaitForCustomerArchive && <JustWait />}
                    <div className="InputWithLabel" id="invoiceAmount">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.InvoiceAmount}
                        </span>
                        <Input
                            className={InvoiceAmountValidation ? "ERROR_BORDER" : ""}
                            onKeyDown={(event) => JustNumber(event, 2)}
                            onChange={(event) => {
                                setInputInvoiceAmount(event.target.value);
                                setInvoiceAmountValidation(false);
                                setValidationTotalAmountValidation(false);
                            }}
                            value={invoiceAmount}
                            error={InvoiceAmountValidation}
                            disabled={!SelectedTransaction || EInvoice}
                            size={"small"}
                        />
                    </div>

                    <div className="InputWithLabel" id="invoiceAmountAsTry">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.InvoiceAmountTL}

                        </span>
                        <Input
                            value={showZeros(invoiceAmountAsTry, 2)}
                            disabled={true}
                            size={"small"}
                        />
                    </div>

                    <div className="InputWithLabel" id="watAmount">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.VatAmount}

                        </span>
                        <Input
                            onKeyDown={(event) => JustNumber(event, 2)}
                            value={watAmount}
                            onChange={(event) => {
                                setInputWATAmount(event.target.value)
                            }}
                            disabled={!SelectedTransaction || EInvoice}
                            size={"small"}
                        />
                    </div>

                    <div className="InputWithLabel" id="invoiceTotal">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.InvoiceTotal}

                        </span>
                        <Input
                            className={TotalAmountValidation ? "ERROR_BORDER" : ""}
                            value={showZeros(TotalAmount, 2)}
                            error={TotalAmountValidation}
                            disabled={true}
                            size={"small"}
                        />
                    </div>

                    <div className="InputWithLabel" id="watAmountAsTL">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.VatAmountTL}

                        </span>
                        <Input
                            value={showZeros(watAmountAsTry, 2)}
                            disabled={true}
                            size={"small"}
                        />
                    </div>

                    <div className="InputWithLabel" id="invoiceAmountTL">
                        <span className="Input-Label">
                            {incomingInvoiceLanguage[Language].ModalLanguage.InvoiceTotalTL}

                        </span>
                        <Input
                            value={showZeros(TotalAmountAsTry, 2)}
                            disabled={true}
                            size={"small"}
                        />
                    </div>

                </div>
            </div>

        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        WaitForCustomerInvoices: state.incomingInvoice.WaitForCustomerInvoices,
        CustomerInvoicesNotSearched: state.incomingInvoice.CustomerInvoicesNotSearched,
        CustomerInvoices: state.incomingInvoice.CustomerInvoices,
        SelectedTransaction: state.incomingInvoice.SelectedTransaction,
        WaitForCustomerArchive: state.incomingInvoice.WaitForCustomerArchive,
        CustomerArchive: state.incomingInvoice.CustomerArchive,

        invoiceAmount: state.incomingInvoice.invoiceAmount,
        invoiceAmountAsTry: state.incomingInvoice.invoiceAmountAsTry,
        invoiceCurrency: state.incomingInvoice.invoiceCurrency,
        invoiceId: state.incomingInvoice.invoiceId,
        invoiceDate: state.incomingInvoice.invoiceDate,
        invoicePaymentDate: state.incomingInvoice.invoicePaymentDate,
        exchangeRate: state.incomingInvoice.exchangeRate,
        ettn: state.incomingInvoice.ettn,
        watAmount: state.incomingInvoice.watAmount,
        watAmountAsTry: state.incomingInvoice.watAmountAsTry,
        EInvoice: state.incomingInvoice.EInvoice,
        voucherDate: state.incomingInvoice.voucherDate,
        TotalAmount: state.incomingInvoice.TotalAmount,
        TotalAmountAsTry: state.incomingInvoice.TotalAmountAsTry,

        InvoiceNumberValidation: state.incomingInvoice.InvoiceNumberValidation,
        InvoiceAmountValidation: state.incomingInvoice.InvoiceAmountValidation,
        TotalAmountValidation: state.incomingInvoice.TotalAmountValidation,
        InvoiceDateValidation: state.incomingInvoice.InvoiceDateValidation,
        VoucherDateValidation: state.incomingInvoice.VoucherDateValidation,
        InvoicePaymentDateValidation: state.incomingInvoice.InvoicePaymentDateValidation,
        ExchangeRateValidation: state.incomingInvoice.ExchangeRateValidation,
        Description: state.incomingInvoice.Description,

        differenceAmountAsTry: state.incomingInvoice.differenceAmountAsTry,

        Language: state.user.Language,

    }
}
export default connect(mapStateToProps, {
    getInvoiceFromArchive, setInputInvoiceDate,
    setInputInvoicePaymentDate, setInputInvoiceId, setInputInvoiceAmount,
    setInputInvoiceCurrency, setInputWATAmount, setInputWATAmountAsTry,
    setInputExchangeRate, setInputTotalAmount, setInputTotalAmountAsTry, setEInvoice,
    setInputVoucherDate, setInputInvoiceAmountAsTry, setValidationTotalAmountValidation, setValidationInvoiceNumber,
    setInputEttn, setDateValidation, setPaymentDateValidation, setVoucherDateValidation, setInvoiceAmountValidation,
    setExchangeRateValidation, setInputInvoiceDescription, setInputDifferenceAmountAsTry
})(NewInvoiceModalContent);
