import React from 'react'
import { rebateLanguage } from '../../../config/language'
import { FaSave, FaCopy, FaHourglassEnd } from 'react-icons/fa'
import { Lock, LockOpen, LoadingSpinner } from '@dfds-ui/icons'
import { FiSend } from 'react-icons/fi'


export const StatusSelector = (Status, Language) => {
    if (!Status) return { StatusIcon: null, StatusText: null }
    switch (Status.toUpperCase()) {
        case "LOCKING":
            return { StatusIcon: <Lock />, StatusText: rebateLanguage[Language].Locking }
        case "SAVING":
            return { StatusIcon: <FaSave />, StatusText: rebateLanguage[Language].Saving }
        case "UNLOCKING":
            return { StatusIcon: <LockOpen />, StatusText: rebateLanguage[Language].Unlocking }
        case "PROCESSING":
            return { StatusIcon: <LoadingSpinner />, StatusText: rebateLanguage[Language].Processing }
        case "COPYING":
            return { StatusIcon: <FaCopy />, StatusText: rebateLanguage[Language].Copying }
        case "SENDING":
            return { StatusIcon: <FiSend style={{ width: "32px", height: "32px" }} />, StatusText: rebateLanguage[Language].Sending }
        default:
            return { StatusIcon: null, StatusText: null }

    }
}

