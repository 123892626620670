import React from 'react'
import { JustTable } from '../../../../CommonUI/JustTable/JustTable'
import { setAccountNumbersHistory } from '../../../../../store/action/accountNumberAction'
import './AccountTable.scss'
import { connect } from 'react-redux'

import { customerLanguage } from '../../../../../config/language'
import { AccountNumberSchema } from './AccountTableSchema'
import JustWait from '../../../../CommonUI/JustAnimations/JustWait/JustWait'

const CustomerTable = (props) => {
    const { CustomerAccountNumbers, Language, setAccountNumbersHistory, WaitForCustomerNumbers } = props;
    const JustDataHistory = (dataHistory) => {
        setAccountNumbersHistory(dataHistory)
    }
    const JustAnimations = {
        WaitForRequest: WaitForCustomerNumbers,
        CanvasWidth: 450
    }
    return (
        <div className="AccountNumber-Table-Container">
            {
                <JustTable
                    JustSchema={AccountNumberSchema(customerLanguage[Language])}
                    JustData={CustomerAccountNumbers ? CustomerAccountNumbers : [{ currency: "EUR", buyerAccountNumber: "", vendorAccountNumber: "" }]}
                    JustDataHistory={JustDataHistory}
                    JustAnimations={JustAnimations}
                />
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        WaitForSaveCustomers: state.accountNumber.WaitForSaveCustomers,
        AccountNumbersHistory: state.accountNumber.AccountNumbersHistory,
        WaitForCustomerNumbers: state.accountNumber.WaitForCustomerNumbers,
        CustomerAccountNumbers: state.accountNumber.CustomerAccountNumbers,
        Language: state.user.Language,
    }
}
export default connect(mapStateToProps, { setAccountNumbersHistory })(CustomerTable);