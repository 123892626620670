import React, { useState, useEffect } from 'react';
import { NavBar } from '@dfds-ui/react-components'
import './Header.scss';
import { connect } from 'react-redux';
import { userOnline, changeLanguage } from '../../store/action/userActions'
import NavBarItemDesigner from './NavBarItemDesigner'
import { NavBarConfig } from './NavBarConfig'
import { FaUserCircle } from "react-icons/fa";
import UserDrawer from './UserDrawer.js';
import { isTokenExist } from '../../core/authentication';
import JustLanguage from '../CommonUI/JustLanguage/JustLanguage'
import { existLanguages } from '../../config/language'
import { getLanguage, setLanguage } from '../../core/projectStorage';
import { navbarPagenames } from '../../config/language'

const Header = (props) => {
    const { pageName, userOnline, Username, changeLanguage, Language } = props;
    const [DrawerShow, setDrawer] = useState(false)
    const [Initialized, setInit] = useState(false)
    const checkLanguage = (Language) => {
        const storeLanguage = getLanguage()
        if (Language) {
            if (storeLanguage && !Initialized && Language !== storeLanguage) {
                changeLanguage(storeLanguage)
                return storeLanguage
            }
            setLanguage(Language)
            return Language.toUpperCase()
        } else if (storeLanguage) {
            changeLanguage(storeLanguage)
            return storeLanguage
        } else {
            setLanguage('tr')
            changeLanguage('tr')
            return 'TR'
        }
    }
    useEffect(() => { userOnline(); setInit(true) }, [])
    return (
        <>
            <NavBar className="PageNavbar">
                <NavBarItemDesigner pageName={pageName} config={NavBarConfig(navbarPagenames[Language])} />
                <div className="PageHeader-User">
                    <JustLanguage onChange={changeLanguage} Languages={existLanguages} defaultLanguage={checkLanguage(Language)} />
                    {
                        isTokenExist() &&
                        <div
                            className="userSection"
                            onClick={() => setDrawer(!DrawerShow)}>

                            <span id="userName">{Username}</span>

                            <FaUserCircle className="userButton" />

                            {DrawerShow && <UserDrawer onBlur={() => {
                                if (DrawerShow)
                                    setDrawer(false)
                            }} />}
                        </div>
                    }
                </div>
            </NavBar>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        pageName: state.page.pageName,
        Username: state.user.Username,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { userOnline, changeLanguage })(Header);
