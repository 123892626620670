import React, { useState } from 'react'
import DateInput from '../../CommonUI/DateInput/DateInput'
import { rebateLanguage, invoiceControlLanguage } from '../../../config/language'
import { connect } from 'react-redux'
import { Button } from '@dfds-ui/react-components'
import './InvoiceControlHeader.scss'
import { setDate, getInvoices, setError } from '../../../store/action/invoiceControlAction'
import { beforeThanDate, oneWeeksBetween } from '../../../core/dateStr'
const InvoiceControlHeader = (props) => {
    const { Language, setDate, StartDate, EndDate, WaitForInvoices, getInvoices,setError } = props
    const [dateValidation, setDateValidation] = useState(true)
    const _onButtonClick = () => {
        if (beforeThanDate(EndDate, StartDate)) {
            if (oneWeeksBetween(EndDate, StartDate)) {
                getInvoices(StartDate, EndDate)
            } else {
                setError(500, invoiceControlLanguage[Language].ValidationErrorJustOneWeeks)
                setDateValidation(false)
            }
        } else {
            setError(500, invoiceControlLanguage[Language].ValidationErrorStartDateLater)
            setDateValidation(false)
        }
    }
    return (
        <div className="InvoiceControl-Header">
            <DateInput
                className="datePickerContainer"
                InitStart={StartDate}
                InitEnd={EndDate}
                Valid={dateValidation}
                OnDateChange={(StartDate, EndDate) => { setDate(StartDate, EndDate); setDateValidation(true); }}
            />
            <Button
                // waitForRequest={WaitForTransactions}
                disabled={(WaitForInvoices)}
                onClick={() => _onButtonClick()}
                className="SearchButton"
            >{rebateLanguage[Language].SearchButtonText}
            </Button>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        StartDate: state.invoiceControl.StartDate,
        EndDate: state.invoiceControl.EndDate,
        WaitForInvoices: state.invoiceControl.WaitForInvoices,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { setDate, getInvoices,setError })(InvoiceControlHeader);