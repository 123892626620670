import { incomingInvoiceLanguage } from "../../../config/language"

export const InputValidations = (InvoiceNumber, InvoiceDate, InvoicePaymentDate, VoucherDate, InvoiceAmount, ExchangeRate, ErrorMessage) => {
    let InputValidationArray = []
    if (!InvoiceNumber) {
        InputValidationArray.push("INVOICE_NUMBER")
    }
    if (!InvoicePaymentDate) {
        InputValidationArray.push("INVOICE_PAYMENT_DATE")
    }
    if (!InvoiceDate) {
        InputValidationArray.push("INVOICE_DATE")
    }
    if (!VoucherDate) {
        InputValidationArray.push("INVOICE_VOUCHER_DATE")
    }
    if (!InvoiceAmount) {
        InputValidationArray.push("INVOICE_AMOUNT")
    }
    if (!ExchangeRate) {
        InputValidationArray.push("EXCHANGE_RATE")
    }
    if (InputValidationArray.length > 0) {
        return { Code: 500, Message: ErrorMessage, ValidationArray: InputValidationArray }
    }
    return null
}