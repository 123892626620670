import React from 'react'
import './NewInvoiceModal.scss'
import { Modal } from '@dfds-ui/modal'
import { hideNewInvoiceModal } from '../../../../store/action/incomingInvoiceAction'
import { connect } from 'react-redux'
import NewInvoiceModalHeader from './Header/ModalHeader'
import NewInvoiceModalContent from './Content/ModalContent'
import NewInvoiceModalFooter from './Footer/ModalFooter'
import { incomingInvoiceLanguage } from '../../../../config/language'

const NewInvoiceModal = (props) => {
    const { _NewInvoiceModal, hideNewInvoiceModal, Language } = props
    return (
        <Modal className={"NewInvoiceModal"} isOpen={_NewInvoiceModal} heading={incomingInvoiceLanguage[Language].NewInvoiceModalHeader} onRequestClose={() => hideNewInvoiceModal()}>
            <NewInvoiceModalHeader />
            <NewInvoiceModalContent />
            <NewInvoiceModalFooter />
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        _NewInvoiceModal: state.incomingInvoice.NewInvoiceModal,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { hideNewInvoiceModal })(NewInvoiceModal);
