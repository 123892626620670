import React from 'react'
import JustCanvas from '../JustCanvas/JustCanvas'
import { FaSearch } from "react-icons/fa";
import PropTypes from 'prop-types';
import './JustNotFound.scss'
const JustNotFound = (props) => {
    const { Text, CanvasWidth, CanvasHeight, FontSize } = props
    return (
        <JustCanvas CanvasWidth={CanvasWidth} CanvasHeight={CanvasHeight}>
            <div className="JustCanvas-NotFound">
                <FaSearch className="NotFound-Icon" />
                <span style={{ fontSize: FontSize }} id="NotFound-Text">{Text}</span>
            </div>
        </JustCanvas>
    )
}
JustNotFound.propTypes = {
    Text: PropTypes.string,
    CanvasWidth: PropTypes.string,
    CanvasHeight: PropTypes.string,
    FontSize: PropTypes.string,
}
JustNotFound.defaultProps = {
    Text: "Not Found",
    CanvasWidth: "",
    CanvasHeight: "",
    FontSize: "24px",
}
export default JustNotFound;