const incomingInvoiceTypes = Object.freeze({
    SET_YEAR: Symbol("SET_YEAR"),
    SET_STARTMONTH: Symbol("SET_STARTMONTH"),
    SET_ENDMONTH: Symbol("SET_ENDMONTH"),
    SET_CUSTOMERNAME: Symbol("SET_CUSTOMERNAME"),
    SET_CUSTOMERNUMBER: Symbol("SET_CUSTOMERNUMBER"),
    SET_INTEGRATION_INPUT: Symbol("SET_INTEGRATION_INPUT"),
    SET_EINVOICE_INPUT: Symbol("SET_EINVOICE_INPUT"),

    NOT_FOUND: Symbol("NOT_FOUND"),

    SET_ERROR: Symbol("SET_ERROR"),
    CLEAR_ERROR: Symbol("CLEAR_ERROR"),

    SET_NETWORK_ERROR: Symbol("SET_NETWORK_ERROR"),
    CLEAR_NETWORK_ERROR: Symbol("CLEAR_NETWORK_ERROR"),

    GETTING_INCOMING_INVOICES: Symbol("GETTING_INCOMING_INVOICES"),
    GET_INCOMING_INVOICES: Symbol("GET_INCOMING_INVOICES"),

    SHOW_EDIT_MODAL: Symbol("SHOW_EDIT_MODAL"),
    HIDE_EDIT_MODAL: Symbol("HIDE_EDIT_MODAL"),

    SHOW_NEW_INVOICE_MODAL: Symbol("SHOW_NEW_INVOICE_MODAL"),
    HIDE_NEW_INVOICE_MODAL: Symbol("HIDE_NEW_INVOICE_MODAL"),

    GETTING_CUSTOMERS: Symbol('GETTING_CUSTOMERS'),
    GET_CUSTOMERS: Symbol('GET_CUSTOMERS'),
    GET_CUSTOMER_NOT_FOUND: Symbol('GET_CUSTOMER_NOT_FOUND'),
    GET_CUSTOMER_ERROR: Symbol('GET_CUSTOMER_ERROR'),

    SET_SELECTED_CUSTOMER: Symbol('SET_SELECTED_CUSTOMER'),

    SET_CUSTOMER_STARTMONTH: Symbol('SET_CUSTOMER_STARTMONTH'),
    SET_CUSTOMER_ENDMONTH: Symbol('SET_CUSTOMER_ENDMONTH'),
    SET_CUSTOMER_YEAR: Symbol('SET_CUSTOMER_YEAR'),

    GETTING_CUSTOMER_INVOICES: Symbol('GETTING_CUSTOMER_INVOICES'),
    GET_CUSTOMER_INVOICES: Symbol('GET_CUSTOMER_INVOICES'),
    GET_CUSTOMER_INVOICES_ERROR: Symbol('GET_CUSTOMER_INVOICES_ERROR'),

    GETTING_CUSTOMER_TRANSACTION: Symbol('GETTING_CUSTOMER_TRANSACTION'),
    GET_CUSTOMER_TRANSACTION: Symbol('GET_CUSTOMER_TRANSACTION'),
    GET_CUSTOMER_TRANSACTION_ERROR: Symbol('GET_CUSTOMER_TRANSACTION_ERROR'),

    SELECT_TRANSACTION: Symbol('SELECT_TRANSACTION'),


    GETTING_CUSTOMER_ARCHIVE: Symbol('GETTING_CUSTOMER_ARCHIVE'),
    GET_CUSTOMER_ARCHIVE: Symbol('GET_CUSTOMER_ARCHIVE'),
    GET_CUSTOMER_ARCHIVE_ERROR: Symbol('GET_CUSTOMER_ARCHIVE_ERROR'),

    INPUT_SET_INVOICEAMOUNT: Symbol('INPUT_SET_INVOICEAMOUNT'),
    INPUT_SET_AMOUNTASTRY: Symbol('INPUT_SET_AMOUNTASTRY'),
    INPUT_SET_INVOICECURRENCY: Symbol('INPUT_SET_INVOICECURRENCY'),
    INPUT_SET_INVOICEID: Symbol('INPUT_SET_INVOICEID'),
    INPUT_SET_INVOICEDATE: Symbol('INPUT_SET_INVOICEDATE'),
    INPUT_SET_INVOICEPAYMENTDATE: Symbol('INPUT_SET_INVOICEPAYMENTDATE'),
    INPUT_SET_EXCHANGERATE: Symbol('INPUT_SET_EXCHANGERATE'),
    INPUT_SET_WATAMOUNT: Symbol('INPUT_SET_WATAMOUNT'),
    INPUT_SET_WATAMOUNTASTRY: Symbol('INPUT_SET_WATAMOUNTASTRY'),
    INPUT_SET_TOTALAMOUNT: Symbol('INPUT_SET_TOTALAMOUNT'),
    INPUT_SET_TOTALAMOUUNTASTRY: Symbol('INPUT_SET_TOTALAMOUUNTASTRY'),
    INPUT_SET_EINVOICE: Symbol('INPUT_SET_EINVOICE'),
    INPUT_SET_VOUCHERDATE: Symbol('INPUT_SET_VOUCHERDATE'),
    INPUT_SET_ETTN: Symbol('INPUT_SET_ETTN'),
    INPUT_SET_INVOICEDESCRIPTION: Symbol('INPUT_SET_INVOICEDESCRIPTION'),

    INPUT_SET_DIFFERENCEAMOUNTASTRY: Symbol('INPUT_SET_DIFFERENCEAMOUNTASTRY'),

    SAVING_NEW_INVOICE: Symbol('SAVING_NEW_INVOICE'),
    SAVE_NEW_INVOICE: Symbol('SAVE_NEW_INVOICE'),
    SAVE_NEW_INVOICE_ERROR: Symbol('SAVE_NEW_INVOICE_ERROR'),

    DELETING_INVOICE: Symbol('DELETING'),
    DELETE_INVOICE: Symbol('DELETE_INVOICE'),
    DELETE_INVOICE_ERROR: Symbol('DELETE_INVOICE_ERROR'),
    PAGE_STATUS: Symbol('PAGE_STATUS'),

    SHOW_DELETE_INVOICE_MODAL: Symbol('SHOW_DELETE_INVOICE_MODAL'),
    HIDE_DELETE_INVOICE_MODAL: Symbol('HIDE_DELETE_INVOICE_MODAL'),

    SET_SELECTED_INVOICE: Symbol('SET_SELECTED_INVOICE'),




    SHOW_INTEGRATE_MODAL: Symbol('SHOW_INTEGRATE_MODAL'),
    HIDE_INTEGRATE_MODAL: Symbol('HIDE_INTEGRATE_MODAL'),

    INTEGRATING: Symbol('INTEGRATING'),
    INTEGRATE: Symbol('INTEGRATE'),
    INTEGRATE_ERROR: Symbol('INTEGRATE_ERROR'),

    SHOW_CHECK_INTEGRATE_MODAL: Symbol('SHOW_CHECK_INTEGRATE_MODAL'),
    HIDE_CHECK_INTEGRATE_MODAL: Symbol('HIDE_CHECK_INTEGRATE_MODAL'),

    CHECKING_INTEGRATE: Symbol('CHECKING_INTEGRATE'),
    CHECK_INTEGRATE: Symbol('CHECK_INTEGRATE'),
    CHECK_INTEGRATE_ERROR: Symbol('CHECK_INTEGRATE_ERROR'),

    SET_SELECTED_INVOICE: Symbol('SET_SELECTED_INVOICE'),

    SET_INVOICE_NUMBER_VALIDATION: Symbol('SET_INVOICE_NUMBER_VALIDATION'),
    SET_INVOICE_AMOUNT_VALIDATION: Symbol('SET_INVOICE_AMOUNT_VALIDATION'),
    SET_INVOICE_TOTAL_AMOUNT_VALIDATION: Symbol('SET_INVOICE_TOTAL_AMOUNT_VALIDATION'),
    SET_INVOICE_DATE_VALIDATION: Symbol('SET_INVOICE_DATE_VALIDATION'),
    SET_INVOICE_PAYMENTDATE_VALIDATION: Symbol('SET_INVOICE_PAYMENTDATE_VALIDATION'),
    SET_VOUCHERDATE_VALIDATION: Symbol('SET_VOUCHERDATE_VALIDATION'),
    SET_EXCHANGERATE_VALIDATION: Symbol('SET_EXCHANGERATE_VALIDATION'),

    RESET_INCOMING_INVOICE_PAGE: Symbol('RESET_INCOMING_INVOICE_PAGE'),

    OK_MODAL_CLOSE: Symbol('OK_MODAL_CLOSE'),

});

export default incomingInvoiceTypes;    