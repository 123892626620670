import React, { createRef } from 'react'
import PropTypes from 'prop-types';
import './JustSearchInput.scss';
import { Input } from '@dfds-ui/react-components';
import { Spinner } from '@dfds-ui/react-components'
import JustWait from '../JustAnimations/JustWait/JustWait';
import JustError from '../JustAnimations/JustError/JustError';
import JustNotFound from '../JustAnimations/JustNotFound/JustNotFound';

export default class JustSearchInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = { SearchIntervalId: null, Searched: false, Focused: true }
        this.inputRef = createRef()
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== this.props) return true
        if (nextState.Searched !== this.state.Searched) return true
        if (nextState.Focused !== this.state.Focused) return true
        return false
    }
    clearInput = () => {
        clearTimeout(this.state.timeOutId)
        this.setState({ SearchIntervalId: null, Searched: false })
        this.props.SelectedValue("")
    }
    componentDidMount() {
        if (this.inputRef.current) {
            this.inputRef.current.focus()
        }
    }
    _onChange = (event) => {
        const value = event.target.value
        if (value) {
            if (this.props.SearchStartLenght <= value.length) {
                const searchFunction = () => {
                    const timeOutId = setTimeout(() => {
                        this.props.SearchFunction(value).then(() => { 
                            this.setState({ Searched: true }); 
                            this.inputRef.current.focus() 
                        })
                        this.setState({ SearchIntervalId: null, })
                    }
                        , this.props.IntervalWaitTime)
                    this.setState({ SearchIntervalId: timeOutId })
                }
                if (!this.state.timeOutId) {
                    searchFunction()
                } else {
                    clearTimeout(this.state.timeOutId)
                    searchFunction()
                }
            } else {
                this.clearInput()
            }
        } else {
            this.clearInput()
        }
    }

    render() {
        return (
            <div className="JustSearchInput-Container" style={{ width: this.props.Width }}
                onFocus={() => this.setState({ Focused: true })}
                onMouseEnter={() => this.inputRef.current.focus()}
                onMouseLeave={() => { this.inputRef.current.blur(); this.setState({ Focused: false }) }}
            >
                <span className="Input-Label" id={this.props.labelId}>
                    {this.props.labelText}
                </span>
                <Input size={this.props.InputSize}
                    className={this.props.className}
                    name={this.props.name}
                    placeholder={this.props.placeholder}
                    onChange={(event) => { this.props.onChange(event); this._onChange(event); }}
                    onKeyUp={(event) => { }}
                    icon={this.props.WaitForRequest && <Spinner className="Input-Spinner" instant={true} />}
                    iconAlignment={"right"}
                    ref={this.inputRef}
                />
                {

                    this.state.Searched && this.state.Focused &&
                    <div className="SearchBox-Container">
                        {
                            !this.props.WaitForRequest ?
                                !this.props.RequestHasError ?
                                    this.props.SearchList &&
                                    this.props.SearchList.length > 0 &&
                                    this.props.SearchList.map((ListValue, index) => {
                                        return <span id="foundElement" onClick={() => {
                                            this.inputRef.current.value = ListValue.name
                                            this.props.SelectedValue(ListValue)
                                            this.setState({ Focused: false })
                                        }}>{ListValue.name}</span>
                                    })
                                   
                                    : <JustError FontSize={"16px"} CanvasWidth="100%" CanvasHeight="80px" />
                                : <></>
                        }


                    </div>

                }
            </div>
        )
    }
}
JustSearchInput.propTypes = {
    SearchStartLenght: PropTypes.number,
    SearchFunction: PropTypes.func,
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    InputSize: PropTypes.string,
    Width: PropTypes.string,
    Height: PropTypes.string,
    labelId: PropTypes.string,
    labelText: PropTypes.string,
    IntervalWaitTime: PropTypes.number,
    SearchList: PropTypes.array,
    WaitForRequest: PropTypes.bool,
    RequestHasError: PropTypes.bool,
    SelectedValue: PropTypes.func,
};
JustSearchInput.defaultProps = {
    SearchStartLenght: 3,
    SearchFunction: () => { },
    className: "",
    name: "",
    placeholder: "",
    onChange: () => { },
    InputSize: "small",
    Width: "300px",
    Height: "40px",
    labelId: "",
    labelText: "",
    IntervalWaitTime: 300, //300ms
    SearchList: [],
    RequestHasError: false,
    SelectedValue: () => { },
    // WaitForRequest: false,
};