/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import RebateHeader from "./RebateHeader/RebateHeader";
import RebateTables from "./RebateTables/RebateTables";
import { connect } from "react-redux";
import {
  getTransactions,
  clearTransactions,
  processLock,
  processTransactions,
  clearRebateError,
  setExcelModal,
  exportExcel,
  exportPDF,
  hideHistoryCheck,
  reprocessTransaction,
  setServerExcelStatus,
  createExportExcel,
  setExcelIntervalId,
  setProcessIntervalId,
  setProcessProcessingIntervalId,
  setIntegrationModal,
  setInvoiceRebateModal,
  setIntegrationExcelIntervalId,
  setPDFIntervalId,
  invoiceRebate,
  createIntegrationExcel,
  integrationExcelExport,
  setIntegrationExcelStatus,
  getProcessStatus,
  setInvoiceRebateIntervalId,
  getInvoiceRebateStatus,
  processSuccess,
  isProcessProcessing,
  setProcessProcessingLock,
  cancelProcessTransactions,
  processModalVisibilty,
  setProcessProcessingModal,
  setPDFStatus,
} from "../../store/action/rebateAction";
import "./Rebate.scss";
import { ErrorModal } from "../CommonUI/Modals/ErrorModal";
import { rebateLanguage } from "../../config/language";
import ExcelModal from "../CommonUI/Modals/YesNoModal/YesNoModal";
import ProcessModal from "../CommonUI/Modals/YesNoModal/YesNoModal";
import ProcessProcessingModal from "../CommonUI/Modals/YesNoModal/YesNoModal";
import IntegrationExcelModal from "../CommonUI/Modals/YesNoModal/YesNoModal";
import InvoiceRebateModal from "../CommonUI/Modals/YesNoModal/YesNoModal";
import CheckChangesModal from "../CommonUI/Modals/YesNoModal/YesNoModal";
import {
  getMo,
  getMonthFirstDayngetMonthFirstDay,
  getMonthFirstDay,
} from "../../core/dateStr";
import JustLoading from "../CommonUI/JustLoading/JustLoading";
import JustPortal from "../CommonUI/JustPortal/JustPortal";

const Rebate = (props) => {
  const {
    getTransactions,
    clearTransactions,
    processTransactions,
    setServerExcelStatus,
    WaitForProcessTransaction,
    ErrorHandler,
    processLock,
    ProcessQuestion,
    ProcessProcessing,
    ProcessModalVisibility,
    WaitForCancelTransaction,
    clearRebateError,
    ProcessProcessingLock,
    CustomerName,
    createExportExcel,
    CustomerCode,
    Language,
    setExcelModal,
    FileDownloadStatus,
    exportExcel,
    exportPDF,
    showExcelModal,
    hideHistoryCheck,
    ServerExcelDownloadStatus,
    PDFStatus,
    pdfName,
    pdfId,
    ForwardAction,
    ShowHistoryModal,
    ProcessSuccess,
    reprocessTransaction,
    setExcelIntervalId,
    setProcessIntervalId,
    setProcessProcessingIntervalId,
    ExcelIntervalId,
    PDFIntervalId,
    ProcessIntervalId,
    ProcessProcessingIntervalId,
    ProcessProcessingStatus,
    IntegrationExcelQuestion,
    WaitForIntegrationExcel,
    IntegrationExcelIntervalId,
    InvoiceRebateQuestion,
    WaitForInvoiceRebate,
    InvoiceRebateIntervalId,
    setIntegrationModal,
    setInvoiceRebateIntervalId,
    setInvoiceRebateModal,
    InvoiceRebateStatus,
    IntegrationExcelStatus,
    createIntegrationExcel,
    invoiceRebate,
    integrationExcelExport,
    setIntegrationExcelStatus,
    setIntegrationExcelIntervalId,
    setPDFIntervalId,
    setPDFStatus,
    getInvoiceRebateStatus,
    processSuccess,
    SelectedTransactions,
    ProcessAll,
    ProcessStatus,
    getProcessStatus,
    StartMonth,
    EndMonth,
    TransactionYear,
    ExcelTransactionIds,
    ExcelFilename,
    ExcelTransactionYear,
    ExcelStartMonth,
    ExcelEndMonth,
    IntervalWaitTime,
    EmailStatus,
    isProcessProcessing,
    setProcessProcessingLock,
    cancelProcessTransactions,
    processModalVisibilty,
    setProcessProcessingModal,
  } = props;

  const intervalWaitTime = 15 * 1000; //15 sec
  useEffect(() => {
    return () => {
      clearTransactions();
    };
  }, []);

  useEffect(() => {
    if (ProcessSuccess) {
      setTimeout(() => {
        getTransactions(
          StartMonth,
          EndMonth,
          TransactionYear,
          CustomerName,
          CustomerCode,
          EmailStatus
        );
      }, 100);
    }
  }, [ProcessSuccess]);
  useEffect(() => {
    if (ErrorHandler && ErrorHandler.ErrorCode !== -1) {
      console.log(ErrorHandler.ErrorDescription);
    }
  }, [ErrorHandler]);

  const _onProcessClick = (e) => {
    if (ProcessAll && ProcessAll.toUpperCase() === "SELECTED") {
      reprocessTransaction(
        SelectedTransactions.filter((T) => !T.isLocked).map(
          (T) => T.transactionId
        )
      );
    } else {
      processTransactions(StartMonth, EndMonth, TransactionYear, true);
    }
  };
  useEffect(() => {
    switch (ProcessStatus) {
      case "GETTING_PROCESS_STATUS":
        console.log("GETTING PROCESS STATUS");
        break;
      case "PROCESS_STARTED":
        getProcessStatus(StartMonth, EndMonth, TransactionYear);
        console.log("PROCESS STARTED");
        break;
      case "PROCESS_PROCESSING":
        if (ProcessIntervalId) return;
        const pId = setInterval(
          () => getProcessStatus(StartMonth, EndMonth, TransactionYear),
          intervalWaitTime
        );
        setProcessIntervalId(pId);
        console.log("PROCESS PROCESSING");
        break;
      case "PROCESS_SUCCESS":
        clearInterval(ProcessIntervalId);
        setProcessIntervalId(null);
        console.log("PROCESS SUCCESS");
        break;
      case "PROCESS_FAILED":
        clearInterval(ProcessIntervalId);
        setProcessIntervalId(null);
        console.log("PROCESS FAILED");
        break;
    }
  }, [ProcessStatus]);
  let count = 0;
  const _processTextCounter = () => {
    count = count + 1;
    if (count == 3) count = 0;
  };
  useEffect(() => {
    switch (ProcessProcessingStatus) {
      case "PROCESS_PROCESSING_IS":
        if (!ProcessProcessing) setProcessProcessingModal(true);
        if (ProcessProcessingIntervalId) return;
        console.log("PROCESS PROCESSING (PROCESS PROCESSING STATUS)");
        break;
      case "PROCESS_SUCCESS_IS":
        console.log("PROCESS SUCCESS (PROCESS PROCESSING STATUS)");
        break;
      case "PROCESS_FAILED":
        console.log("PROCESS FAILED (PROCESS PROCESSING STATUS)");
        break;
    }
  }, [ProcessProcessingStatus]);
  useEffect(() => {
    const clearExcelInterval = () => {
      clearInterval(IntegrationExcelIntervalId);
      setIntegrationExcelIntervalId(null);
      setIntegrationExcelStatus("");
    };
    switch (IntegrationExcelStatus) {
      case "INTEGRATION_EXCEL_CREATE_REQUEST_STARTED":
        console.log("Integration Create Excel Started");
        break;
      case "INTEGRATION_EXCEL_CREATE_REQUEST_END":
        integrationExcelExport(
          null,
          `${StartMonth}-${EndMonth}-${TransactionYear}_${
            CustomerName ? CustomerName : "ALLCUSTOMERS"
          }-${
            CustomerCode ? CustomerCode : "ALLCUSTOMERCODES"
          }_Integration_Excel`,
          TransactionYear,
          StartMonth,
          EndMonth
        );
        console.log("Integration Create Excel REQUEST END");
        break;
      case "INTEGRATION_EXCEL_CREATE_REQUEST_PROCESSING":
        if (IntegrationExcelIntervalId) break;
        const integrationExcelId = setInterval(
          () =>
            integrationExcelExport(
              null,
              `${StartMonth}-${EndMonth}-${TransactionYear}_${
                CustomerName ? CustomerName : "ALLCUSTOMERS"
              }-${CustomerCode ? CustomerCode : "ALLCUSTOMERCODES"}_Excel`,
              TransactionYear,
              StartMonth,
              EndMonth
            ),
          intervalWaitTime
        );
        setIntegrationExcelIntervalId(integrationExcelId);
        console.log("INTEGRATION_EXCEL_CREATE_REQUEST_PROCESSING");
        break;
      case "INTEGRATION_EXCEL_DOWNLOADING":
        console.log("INTEGRATION EXCEL DOWNLOADING");
        break;
      case "INTEGRATION_EXCEL_DOWNLOADED":
        clearExcelInterval();
        console.log("INTEGRATION EXCEL DOWNLOADED");
        break;
      case "INTEGRATION_EXCEL_REQUEST_FAILED":
        clearExcelInterval();
        console.log("INTEGRATION EXCEL FAILED");
        break;
      default:
        console.log("");
        break;
    }
  }, [IntegrationExcelStatus]);

  useEffect(() => {
    switch (InvoiceRebateStatus) {
      case "INVOICE_REBATE_REQUEST_STARTED":
        console.log("INVOICE_REBATE_REQUEST_STARTED");
        break;
      case "INVOICE_REBATE_STARTED":
        getInvoiceRebateStatus(getMonthFirstDay(TransactionYear, StartMonth));
        console.log("INVOICE_REBATE_STARTED");
        break;
      case "GETTING_INVOICE_REBATE_STATUS":
        console.log("GETTING_INVOICE_REBATE_STATUS");
        break;
      case "INVOICE_REBATE_PROCESSING":
        console.log("INVOICE_REBATE_PROCESSING");
        if (InvoiceRebateIntervalId) return;
        const pId = setInterval(
          () =>
            getInvoiceRebateStatus(
              getMonthFirstDay(TransactionYear, StartMonth)
            ),
          intervalWaitTime
        );
        setInvoiceRebateIntervalId(pId);
        break;
      case "INVOICE_REBATE_SUCCESS":
        clearInterval(InvoiceRebateIntervalId);
        setInvoiceRebateIntervalId(null);
        processSuccess();
        console.log("INVOICE_REBATE_SUCCESS");
        break;
      case "INVOICE_REBATE_FAILED":
        clearInterval(InvoiceRebateIntervalId);
        setInvoiceRebateIntervalId(null);
        console.log("INVOICE_REBATE_FAILED");
        break;
    }
  }, [InvoiceRebateStatus]);
  useEffect(() => {
    const clearExcelInterval = () => {
      clearInterval(ExcelIntervalId);
      setExcelIntervalId(null);
      setServerExcelStatus("");
    };
    switch (ServerExcelDownloadStatus) {
      case "EXCEL_CREATE_REQUEST_END":
        exportExcel(
          ExcelTransactionIds,
          ExcelFilename,
          ExcelTransactionYear,
          ExcelStartMonth,
          ExcelEndMonth
        );
        console.log("EXCEL REQUEST END");
        break;
      case "EXCEL_CREATE_REQUEST_PROCESSING":
        if (ExcelIntervalId) break;
        const exportExcelIntervalId = setInterval(
          () =>
            exportExcel(
              ExcelTransactionIds,
              ExcelFilename,
              ExcelTransactionYear,
              ExcelStartMonth,
              ExcelEndMonth
            ),
          IntervalWaitTime
        );
        setExcelIntervalId(exportExcelIntervalId);
        break;
      case "EXCEL_DOWNLOADING":
        console.log("EXCEL DOWNLOADING");
        break;
      case "EXCEL_DOWNLOADED":
        clearExcelInterval();
        console.log("EXCEL DOWNLOADED");

        break;
      case "EXCEL_REQUEST_FAILED":
        clearExcelInterval();
        console.log("EXCEL FAILED");
        break;
      default:
        console.log("");
        break;
    }
  }, [ServerExcelDownloadStatus]);

  useEffect(() => {
    const clearPDFInterval = () => {
      clearInterval(PDFIntervalId);
      setPDFIntervalId(null);
      setPDFStatus("");
    };
    switch (PDFStatus) {
      case "PDF_PROCESSING":
        if (pdfInternalId) break;
        const pdfInternalId = setInterval(
          () => exportPDF(pdfId, pdfName),
          IntervalWaitTime
        );
        setPDFIntervalId(pdfInternalId);
        break;
      case "PDF_DOWNLOADING":
        console.log("PDF DOWNLOADING");
        break;
      case "PDF_DOWNLOADED":
        clearPDFInterval();
        console.log("PDF DOWNLOADED");
        break;
      case "PDF_FAILED":
        clearPDFInterval();
        console.log("PDF FAILED");
        break;
      default:
        console.log("");
        break;
    }
  }, [PDFStatus]);

  const _excelModalOnYesClick = () => {
    createExportExcel(
      null,
      `${StartMonth}-${EndMonth}-${TransactionYear}_${
        CustomerName ? CustomerName : "ALLCUSTOMERS"
      }-${CustomerCode ? CustomerCode : "ALLCUSTOMERCODES"}_Excel`,
      TransactionYear,
      StartMonth,
      EndMonth,
      15000
    );
  };
  const _onIntegrationExcelClick = () => {
    createIntegrationExcel(null, TransactionYear, StartMonth, EndMonth);
  };
  const _onInvoiceRebateClick = () => {
    invoiceRebate(getMonthFirstDay(TransactionYear, StartMonth));
  };
  useEffect(() => {
    if (ProcessQuestion) {
      const isProcessing = _isProcessProcessing();
      processModalVisibilty(isProcessing);
    } else {
      processModalVisibilty(false);
    }
  }, [ProcessQuestion]);
  const _isProcessProcessing = () => {
    if (ProcessProcessingLock) return true;
    setProcessProcessingLock(true); //sadece bir kere girmesi için yoksa sonsuz döngüye giriyor.
    isProcessProcessing(StartMonth, EndMonth, TransactionYear);
    return !ProcessProcessing;
  };
  const ErrorCode = ErrorHandler && ErrorHandler.ErrorCode;
  const ErrorDescription = ErrorHandler && ErrorHandler.ErrorDescription;
  const _textCounter = () => {
    let dotStr = "";
    for (let i = 0; i <= dotStr.length; i++) {
      dotStr = dotStr + ".";
    }
    return dotStr;
  };
  const _cancelProcess = () => {
    console.log("CANCEL PROCESS");
    cancelProcessTransactions(StartMonth, EndMonth, TransactionYear);
  };
  const _onCancelProcessModalClose = () => {
    processLock(false);
    setProcessProcessingLock(false);
    setProcessProcessingModal(false);
  };
  return (
    <div className="Rebate-Container">
      {(PDFStatus === "PDF_DOWNLOADING" || PDFStatus === "PDF_PROCESSING") && (
        <JustLoading title={rebateLanguage[Language].ExportPDF} />
      )}
      <RebateHeader />
      <RebateTables />

      {ProcessModalVisibility && (
        <ProcessModal
          OpenModal={true}
          Title={rebateLanguage[Language].Process.ProcessHeader}
          Message={rebateLanguage[Language].Process.ProcessQuestion}
          OnClose={() => {
            processLock(false);
            setProcessProcessingLock(false);
          }}
          onYesClick={(e) => _onProcessClick(e)}
          waitForRequest={WaitForProcessTransaction}
          ButtonYesText={rebateLanguage[Language].ButtonYes}
          ButtonNoText={rebateLanguage[Language].ButtonNo}
          InfoMessage={rebateLanguage[Language].ProcessInfoText}
        />
      )}
      {ProcessProcessing && (
        <ProcessProcessingModal
          OpenModal={true}
          Title={"Hesaplama İşlemi Devam Ediyor"}
          Message={
            "Devam eden bir hesaplama işlemi var. Hesaplama işlemini durdurmak için İşlemi Durdur butonuna basınız."
          }
          OnClose={() => _onCancelProcessModalClose()}
          onYesClick={(e) => _cancelProcess()}
          waitForRequest={WaitForCancelTransaction}
          YesClassName={"W180-Button"}
          ButtonYesText={"İşlemi Durdur"}
          ButtonNoText={"Geri Dön"}
          // InfoMessage={"İşlem devam ediyor" + _textCounter()}
        />
      )}
      {IntegrationExcelQuestion && (
        <IntegrationExcelModal
          OpenModal={true}
          Title={rebateLanguage[Language].IntegrationExcelModalTitle}
          Message={rebateLanguage[Language].IntegrationExcelModalMessage}
          OnClose={() => setIntegrationModal(false)}
          onYesClick={(e) => {
            _onIntegrationExcelClick();
          }}
          waitForRequest={WaitForIntegrationExcel}
          ButtonYesText={rebateLanguage[Language].ButtonYes}
          ButtonNoText={rebateLanguage[Language].ButtonNo}
        />
      )}
      {InvoiceRebateQuestion && (
        <InvoiceRebateModal
          OpenModal={true}
          Title={rebateLanguage[Language].RebateInvoiceModalTitle}
          Message={rebateLanguage[Language].RebateInvoiceModalMessage}
          OnClose={() => setInvoiceRebateModal(false)}
          onYesClick={(e) => {
            _onInvoiceRebateClick();
          }}
          waitForRequest={WaitForInvoiceRebate}
          ButtonYesText={rebateLanguage[Language].ButtonYes}
          ButtonNoText={rebateLanguage[Language].ButtonNo}
        />
      )}
      {ShowHistoryModal && (
        <CheckChangesModal
          OpenModal={true}
          Title={rebateLanguage[Language].ChangesTitle}
          Message={rebateLanguage[Language].ChangesModalMessage}
          ButtonYesText={rebateLanguage[Language].ButtonYes}
          ButtonNoText={rebateLanguage[Language].ButtonNo}
          OnClose={() => hideHistoryCheck()}
          onYesClick={() => {
            ForwardAction();
            hideHistoryCheck();
          }}
        />
      )}
      {ErrorCode !== -1 && (
        <ErrorModal
          ButtonLabel={rebateLanguage[Language].GoBackButtonText}
          ErrorTitle={rebateLanguage[Language].ErrorTitle}
          ErrorMessage={ErrorDescription}
          OnClose={() => {
            clearRebateError();
          }}
        />
      )}

      {showExcelModal && (
        <ExcelModal
          OpenModal={true}
          Title={rebateLanguage[Language].ExcelModalTitle}
          Message={rebateLanguage[Language].ExcelModalMessage}
          ButtonYesText={rebateLanguage[Language].ButtonYes}
          ButtonNoText={rebateLanguage[Language].ButtonNo}
          OnClose={() => setExcelModal(false)}
          onYesClick={() => _excelModalOnYesClick()}
          waitForRequest={FileDownloadStatus}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    // WaitForSaveTransaction: state.rebate.WaitForSaveTransaction,
    StartMonth: state.rebate.StartMonth,
    EndMonth: state.rebate.EndMonth,
    TransactionYear: state.rebate.TransactionYear,
    CustomerName: state.rebate.CustomerName,
    CustomerCode: state.rebate.CustomerCode,
    WaitForProcessTransaction: state.rebate.WaitForProcessTransaction,
    ProcessQuestion: state.rebate.ProcessQuestion,
    ProcessProcessing: state.rebate.ProcessProcessing,
    ProcessModalVisibility: state.rebate.ProcessModalVisibility,
    WaitForCancelTransaction: state.rebate.WaitForCancelTransaction,

    ProcessProcessingLock: state.rebate.ProcessProcessingLock,

    SelectedTransactions: state.rebate.SelectedTransactions,
    ProcessAll: state.rebate.ProcessAll,
    ErrorHandler: state.rebate.ErrorHandler,
    showExcelModal: state.rebate.ExcelModal,
    FileDownloadStatus: state.rebate.FileDownloadStatus,
    ForwardAction: state.rebate.ForwardAction,
    ShowHistoryModal: state.rebate.ShowHistoryModal,
    ProcessSuccess: state.rebate.ProcessSuccess,
    ServerExcelDownloadStatus: state.rebate.ServerExcelDownloadStatus,
    ProcessStatus: state.rebate.ProcessStatus,

    ProcessProcessingStatus: state.rebate.ProcessProcessingStatus,

    ExcelIntervalId: state.rebate.ExcelIntervalId,
    ProcessIntervalId: state.rebate.ProcessIntervalId,
    ProcessProcessingIntervalId: state.rebate.ProcessProcessingIntervalId,

    PDFIntervalId: state.rebate.PDFIntervalId,
    PDFStatus: state.rebate.PDFStatus,
    pdfName: state.rebate.pdfName,
    pdfId: state.rebate.pdfId,

    IntegrationExcelQuestion: state.rebate.IntegrationExcelQuestion,
    WaitForIntegrationExcel: state.rebate.WaitForIntegrationExcel,
    IntegrationExcelIntervalId: state.rebate.IntegrationExcelIntervalId,
    IntegrationExcelStatus: state.rebate.IntegrationExcelStatus,

    InvoiceRebateQuestion: state.rebate.InvoiceRebateQuestion,
    WaitForInvoiceRebate: state.rebate.WaitForInvoiceRebate,
    InvoiceRebateIntervalId: state.rebate.InvoiceRebateIntervalId,
    InvoiceRebateStatus: state.rebate.InvoiceRebateStatus,

    ExcelTransactionIds: state.rebate.ExcelTransactionIds,
    ExcelFilename: state.rebate.ExcelFilename,
    ExcelTransactionYear: state.rebate.ExcelTransactionYear,
    ExcelStartMonth: state.rebate.ExcelStartMonth,
    ExcelEndMonth: state.rebate.ExcelEndMonth,
    IntervalWaitTime: state.rebate.IntervalWaitTime,

    EmailStatus: state.rebate.EmailStatus,

    Language: state.user.Language,
  };
};
export default connect(mapStateToProps, {
  clearTransactions,
  getTransactions,
  processLock,
  processTransactions,
  clearRebateError,
  setExcelModal,
  exportExcel,
  exportPDF,
  hideHistoryCheck,
  reprocessTransaction,
  setServerExcelStatus,
  createExportExcel,
  setExcelIntervalId,
  setPDFIntervalId,
  setPDFStatus,
  setProcessIntervalId,
  setProcessProcessingIntervalId,
  setIntegrationModal,
  setInvoiceRebateModal,
  invoiceRebate,
  createIntegrationExcel,
  setIntegrationExcelStatus,
  setIntegrationExcelIntervalId,
  integrationExcelExport,
  processSuccess,
  getProcessStatus,
  setInvoiceRebateIntervalId,
  getInvoiceRebateStatus,
  isProcessProcessing,
  processModalVisibilty,
  cancelProcessTransactions,
  setProcessProcessingLock,
  setProcessProcessingModal,
})(Rebate);
