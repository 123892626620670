import React, { useRef } from 'react'
import { JustNumber } from '../../JustNumber';
import { useSelector } from 'react-redux';

const InputType = (props) => {
    const { InputType, Name, onBlur, DefaultValue,
        TabIndex, ChangeData, ChangeUIData,
        UpdateDataList, CheckValue, onRowUpdate, InputEvents,
        DataType, RowData, RowIndex, ParentIds,
        SetRow, Init, onKeyUp, RowLocked, inputOut, inputRef
    } = props;
    var defaultValue = ""
    if (DefaultValue !== undefined && DefaultValue !== null) {
        if (Array.isArray(DefaultValue)) {
            DefaultValue.forEach(_data => {
                defaultValue = defaultValue + _data + " ; "
            })
        } else {
            defaultValue = DefaultValue
        }
    } else {
        defaultValue = DefaultValue
    }
    const _inputRef = useRef()
    const _onChangeData = (oldData, newData) => {
        let _oldData = oldData === null ? "" : typeof oldData === "string" ? oldData.trim() : oldData
        let _newData = (typeof newData === "string") ? (newData.trim() === "" ? "" : newData.trim()) : newData
        if (_oldData !== _newData) {
            UpdateDataList(_newData, (rowId, columnId, dataList) => {
                const cloneRowData = JSON.parse(JSON.stringify(RowData))
                ChangeData(RowIndex, rowId, cloneRowData, columnId, dataList, DataType, ParentIds, (rowUpdated, row, afterUpdate) => {
                    onRowUpdate(rowUpdated, row, afterUpdate)
                }, (row) => {
                    SetRow(row)
                })

            })

        }
        // ChangeUIData(_newData)
    }
    const _onBlur = (e) => {
        const oldData = defaultValue
        const inputData = e.target.value
        const isNumber = (DataType && DataType.toUpperCase() === "NUMBER")
        const newData = (DataType && inputData) ? (isNumber ? Number(inputData) : inputData) : (isNumber ? 0 : inputData)
        _onChangeData(oldData, newData)
        onBlur(e)
    }


    const _onKeyDown = (e) => {
        if (e.key === "Enter") {
            inputOut()
            _inputRef.current.parentNode.focus()
        }
        if (e.key === "Escape") {
            inputOut()
            _inputRef.current.parentNode.focus()
        }

        if (DataType === "number") {
            JustNumber(e)
        }

    }
    const _checkChange = (e) => {
        const newValue = e.target.checked
        _onChangeData(!newValue, newValue)
    }
    if (InputType === undefined || InputType === null)
        return <input
            ref={_inputRef}
            tabIndex={TabIndex}
            name={Name}
            onBlur={(e) => _onBlur(e)} autoFocus={true}
            id={"data-input"}
            type="text"
            className={DataType === "number" ? "DataType-Number" : ""}
            onKeyDown={(e) => _onKeyDown(e)}
            defaultValue={defaultValue} />
    switch (InputType.toUpperCase()) {
        case "TEXT":
            return <input
                ref={_inputRef}
                tabIndex={TabIndex}
                name={Name}
                onBlur={(e) => _onBlur(e)}
                autoFocus={true}
                id={"data-input"}
                type="text"
                className={DataType === "number" ? "DataType-Number" : ""}
                onKeyDown={(e) => _onKeyDown(e)}
                defaultValue={defaultValue} />
        case "TEXTAREA":
            return <textarea
                ref={_inputRef}
                tabIndex={TabIndex}
                name={Name}
                onBlur={(e) => _onBlur(e)}
                autoFocus={true}
                id={"data-input"}
                onKeyDown={(e) => _onKeyDown(e)}
                defaultValue={defaultValue} />
        case "CHECKBOX":
            if (RowLocked) {
                return (
                    <span id="JustTable-RowCell-Icon">
                        <input type="checkbox" checked={CheckValue} disabled />
                    </span>
                )
            } else {
                return (
                    <span id="JustTable-RowCell-Icon">
                        <input type="checkbox" ref={(input) => inputRef(input)} onChange={(e) => _checkChange(e)} checked={CheckValue} />
                    </span>
                )
            }
        default:
            return <></>
    }
}
export default InputType