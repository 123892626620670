import React, { useState, useEffect } from 'react'
import { Drawer, ListItem, ListText } from '@dfds-ui/react-components'
import { logOutUser } from '../../store/action/userActions'
import { connect } from 'react-redux';
import history from '../../core/history';
import { getAuthorization } from '../../core/authentication';
import { headerContextNames } from '../../config/language';
const UserDrawer = ({ logOutUser, onBlur, Language }) => {
    const [drawerRef, setDrawerRef] = useState(null)
    // var executeChild = new Promise()
    const userRole = getAuthorization()
    useEffect(() => {
        if (drawerRef) {
            drawerRef.style.outline = "none"
            drawerRef.focus()
        }
    }, [drawerRef])
    return (
        <Drawer
            ref={(element) => setDrawerRef(element)}
            tabIndex="0"
            onBlur={(e) => {
                // await executeChild
                setTimeout(() => onBlur(e), 200) // this is going to fix
                // onBlur(e)
            }}
            position={"right"}>
            {
                userRole === "Admin" &&
                <ListItem
                    onClick={() => {
                        history.push('/adminpanel')
                    }}
                    clickable
                    noBorder>
                    <ListText bold={true}>{headerContextNames[Language].AdminPanel}</ListText>
                </ListItem>
            }
            <ListItem
                onClick={() => {
                    logOutUser()
                    history.push("/")
                }}
                clickable noBorder >

                <ListText bold={true}>{headerContextNames[Language].Logout}</ListText>
            </ListItem>
        </Drawer>

    )
}
const mapStateToProps = (state) => {
    return {
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { logOutUser })(UserDrawer);
