import { symbol } from "prop-types";

const adminPanelTypes = Object.freeze({
    IDENTITY_USER_MODAL_SHOW: Symbol('IDENTITY_USER_MODAL_SHOW'),
    IDENTITY_USER_MODAL_HIDE: Symbol('IDENTITY_USER_MODAL_HIDE'),

    DELETE_USER_MODAL_SHOW: Symbol('DELETE_USER_MODAL_SHOW'),
    DELETE_USER_MODAL_HIDE: Symbol('DELETE_USER_MODAL_HIDE'),

    GETTING_USERS: Symbol('GETTING_USERS'),
    GET_USERS: Symbol('GET_USERS'),
    GET_USERS_ERROR: Symbol('GET_USERS_ERROR'),

    GETTING_USER_ROLES: Symbol('GETTING_USER_ROLES'),
    GET_USER_ROLES: Symbol('GET_USER_ROLES'),
    GET_USER_ROLES_ERROR: Symbol('GET_USER_ROLES_ERROR'),

    SAVING_USER: Symbol('SAVING_USER'),
    SAVE_USER: Symbol('SAVE_USER'),
    SAVE_USER_ERROR: Symbol('SAVE_USER_ERROR'),

    SET_ERROR: Symbol('SET_ERROR'),
    SET_NETWORK_ERROR: Symbol('SET_NETWORK_ERROR'),

    DELETING_USER: Symbol('DELETING_USER'),
    DELETE_USER: Symbol('DELETE_USER'),
    DELETE_USER_ERROR: Symbol('DELETE_USER_ERROR'),

    SET_SELECTED_USER: Symbol('SET_SELECTED_USER'),
    SET_IDENTITY_USER_STATUS: Symbol('SET_IDENTITY_USER_STATUS'),
    SET_IDENTITY_USER_USER_NAME: Symbol('SET_IDENTITY_USER_USER_NAME'),
    SET_IDENTITY_USER_NAME: Symbol('SET_IDENTITY_USER_NAME'),
    SET_IDENTITY_USER_SURNAME: Symbol('SET_IDENTITY_USER_SURNAME'),
    SET_IDENTITY_USER_EMAIL: Symbol('SET_IDENTITY_USER_EMAIL'),
    SET_IDENTITY_USER_ROLE: Symbol('SET_IDENTITY_USER_ROLE'),
    SET_IDENTITY_USER_COMPANY_CODE: Symbol('SET_IDENTITY_USER_COMPANY_CODE'),
    SET_IDENTITY_USER_DEPARTMENT: Symbol('SET_IDENTITY_USER_DEPARTMENT'),
    SET_IDENTITY_USER_PASSWORD: Symbol('SET_IDENTITY_USER_PASSWORD'),

    //Tariffs starts here
    TARIFF_MODAL_SHOW: Symbol('TARIFF_MODAL_SHOW'),
    TARIFF_MODAL_HIDE: Symbol('TARIFF_MODAL_HIDE'),

    DELETE_TARIFF_MODAL_SHOW: Symbol('DELETE_TARIFF_MODAL_SHOW'),
    DELETE_TARIFF_MODAL_HIDE: Symbol('DELETE_TARIFF_MODAL_HIDE'),

    SET_TARIFF_START_DATE: Symbol('SET_TARIFF_START_DATE'),
    SET_TARIFF_END_DATE: Symbol('SET_TARIFF_END_DATE'),

    SET_TARIFF_AGREEMENT_NUMBER: Symbol('SET_TARIFF_AGREEMENT_NUMBER'),
    SET_TARIFF_NUMBER: Symbol('SET_TARIFF_NUMBER'),

    SET_TARIFF_START_DATE_VALIDATION: Symbol('SET_TARIFF_START_DATE_VALIDATION'),
    SET_TARIFF_END_DATE_VALIDATION: Symbol('SET_TARIFF_END_DATE_VALIDATION'),

    GETTING_TARIFFS: Symbol('GETTING_TARIFFS'),
    GET_TARIFFS: Symbol('GET_TARIFFS'),
    GET_TARIFFS_ERROR: Symbol('GET_TARIFFS_ERROR'),

    SAVING_TARIFF: Symbol('SAVING_TARIFF'),
    SAVE_TARIFF: Symbol('SAVE_TARIFF'),
    SAVE_TARIFF_ERROR: Symbol('SAVE_TARIFF_ERROR'),

    DELETING_TARIFF: Symbol('DELETING_TARIFF'),
    DELETE_TARIFF: Symbol('DELETE_TARIFF'),
    DELETE_TARIFF_ERROR: Symbol('DELETE_TARIFF_ERROR'),

    SET_SELECTED_TARIFF: Symbol('SET_SELECTED_TARIFF'),

    //Tariffs ends here

    //RebateRate start
    REBATE_RATE_MODAL_SHOW: Symbol('REBATE_RATE_MODAL_SHOW'),
    REBATE_RATE_MODAL_HIDE: Symbol('REBATE_RATE_MODAL_HIDE'),

    //DELETE_RATE_MODAL_SHOW: Symbol('DELETE_RATE_MODAL_SHOW'),
    //DELETE_RATE_MODAL_HIDE: Symbol('DELETE_RATE_MODAL_HIDE'),

    SET_REBATE_RATE_ID: Symbol('SET_REBATE_RATE_ID'),
    SET_REBATE_RATE_NAME: Symbol('SET_REBATE_RATE_NAME'),
    SET_REBATE_RATE_RATIO: Symbol('SET_REBATE_RATE_RATIO'),
    SET_REBATE_RATE_NAME : Symbol('SET_REBATE_RATE_NAME'),
    SET_REBATE_RATE_RATIO : Symbol('SET_REBATE_RATE_RATIO'),

    GETTING_RATES: Symbol('GETTING_RATES'),
    GET_RATES: Symbol('GET_RATES'),
    GET_RATES_ERROR: Symbol('GET_RATES_ERROR'),

    SAVING_RATE: Symbol('SAVING_RATE'),
    SAVE_RATE: Symbol('SAVE_RATE'),
    SAVE_RATE_ERROR: Symbol('SAVE_RATE_ERROR'),

    //DELETING_RATE: Symbol('DELETING_RATE'),
    //DELETE_RATE: Symbol('DELETE_RATE'),
    //DELETE_RATE_ERROR: Symbol('DELETE_RATE_ERROR'),

    SET_SELECTED_RATE: Symbol('SET_SELECTED_RATE'),
    //RebateRate end

    //Accounting Vessel Start


    ACCOUNTING_VESSEL_MODAL_SHOW: Symbol('ACCOUNTING_VESSEL_MODAL_SHOW'),
    ACCOUNTING_VESSEL_MODAL_HIDE: Symbol('ACCOUNTING_VESSEL_MODAL_HIDE'),
    SET_SELECTED_ACCOUNTING_VESSEL: Symbol('SET_SELECTED_ACCOUNTING_VESSEL'),
    SET_SELECTED_ACCOUNTING_VESSEL_CODE: Symbol('SET_SELECTED_ACCOUNTING_VESSEL_CODE'),
    SET_SELECTED_ACCOUNTING_VESSEL_ID: Symbol('SET_SELECTED_ACCOUNTING_VESSEL_ID'),
    SET_SELECTED_ACCOUNTING_CODE: Symbol('SET_SELECTED_ACCOUNTING_CODE'),
    SET_SELECTED_ACCOUNTING_VESSEL_NAME: Symbol('SET_SELECTED_ACCOUNTING_VESSEL_NAME'),
    SET_SELECTED_ACCOUNTING_VESSEL_RENTED: Symbol('SET_SELECTED_ACCOUNTING_VESSEL_RENTED'),
    GETTING_ACCOUNTING_VESSEL: Symbol('GETTING_ACCOUNTING_VESSEL'),
    GET_ACCOUNTING_VESSEL: Symbol('GET_ACCOUNTING_VESSEL'),
    GET_ACCOUNTING_VESSEL_ERROR: Symbol('GET_ACCOUNTING_VESSEL_ERROR'),
    SAVING_VESSEL: Symbol('SAVING_VESSEL'),
    SAVE_VESSEL: Symbol('SAVE_VESSEL'),
    SAVE_VESSEL_ERROR: Symbol('SAVE_VESSEL_ERROR'),
    ACCOUNTING_VESSEL_DELETE_MODAL_SHOW: Symbol('ACCOUNTING_VESSEL_DELETE_MODAL_SHOW'),
    ACCOUNTING_VESSEL_DELETE_MODAL_HIDE: Symbol('ACCOUNTING_VESSEL_DELETE_MODAL_HIDE'),
    DELETING_VESSEL: Symbol('DELETING_VESSEL'),
    DELETE_VESSEL: Symbol('DELETE_VESSEL'),
    DELETE_VESSEL_ERROR: Symbol('DELETE_VESSEL_ERROR'),

    //Accounting Vessel End

    //Accounting VOYAGE start

    ACCOUNTING_VOYAGE_MODAL_SHOW: Symbol('ACCOUNTING_VOYAGE_MODAL_SHOW'),
    ACCOUNTING_VOYAGE_MODAL_HIDE: Symbol('ACCOUNTING_VOYAGE_MODAL_HIDE'),
    SET_SELECTED_ACCOUNTING_VOYAGE: Symbol('SET_SELECTED_ACCOUNTING_VOYAGE'),
    SET_ACCOUNTING_VOYAGE_NAME: Symbol('SET_ACCOUNTING_VOYAGE_NAME'),
    SET_ACCOUNTING_VOYAGE_CODE: Symbol('SET_ACCOUNTING_VOYAGE_CODE'),
    SET_ACCOUNTING_VOYAGE_ACCOUNT_NAME: Symbol('SET_ACCOUNTING_VOYAGE_ACCOUNT_NAME'),
    SET_ACCOUNTING_VOYAGE_RENT_CODE: Symbol('SET_ACCOUNTING_VOYAGE_RENT_CODE'),
    SET_ACCOUNTING_VOYAGE_RENT_NAME: Symbol('SET_ACCOUNTING_VOYAGE_RENT_NAME'),
    SET_ACCOUNTING_VOYAGE_GROUP_NAME: Symbol('SET_ACCOUNTING_VOYAGE_GROUP_NAME'),
    GETTING_ACCOUNTING_VOYAGE: Symbol('GETTING_ACCOUNTING_VOYAGE'),
    GET_ACCOUNTING_VOYAGE: Symbol('GET_ACCOUNTING_VOYAGE'),
    GET_ACCOUNTING_VOYAGE_ERROR: Symbol('GET_ACCOUNTING_VOYAGE_ERROR'),
    SAVING_ACCOUNTING_VOYAGE: Symbol('SAVING_ACCOUNTING_VOYAGE'),
    SAVE_ACCOUNTING_VOYAGE: Symbol('SAVE_ACCOUNTING_VOYAGE'),
    SAVE_ACCOUNTING_VOYAGE_ERROR: Symbol('SAVE_ACCOUNTING_VOYAGE_ERROR'),
    
    ACCOUNTING_VOYAGE_DELETE_MODAL_SHOW: Symbol('ACCOUNTING_VOYAGE_DELETE_MODAL_SHOW'),
    ACCOUNTING_VOYAGE_DELETE_MODAL_HIDE: Symbol('ACCOUNTING_VOYAGE_DELETE_MODAL_HIDE'),
    DELETING_VOYAGE: Symbol('DELETING_VOYAGE'),
    DELETE_VOYAGE: Symbol('DELETE_VOYAGE'),
    DELETE_VOYAGE_ERROR: Symbol('DELETE_VOYAGE_ERROR'),

    //Accounting VOYAGE End



    //unit type   
    GETTING_UNIT_TYPES: Symbol('GETTING_UNIT_TYPES'),
    GET_UNIT_TYPES: Symbol('GET_UNIT_TYPES'),
    GET_UNIT_TYPES_ERROR: Symbol('GET_UNIT_TYPES_ERROR'),


    UNIT_TYPE_MODAL_SHOW: Symbol('UNIT_TYPE_MODAL_SHOW'),
    UNIT_TYPE_MODAL_HIDE: Symbol('UNIT_TYPE_MODAL_HIDE'),

    DELETE_USER_MODAL_SHOW: Symbol('DELETE_USER_MODAL_SHOW'),
    DELETE_USER_MODAL_HIDE: Symbol('DELETE_USER_MODAL_HIDE'),

    SET_SELECTED_UNIT_TYPE: Symbol('SET_SELECTED_UNIT_TYPE'),

    SAVING_UNIT_TYPE: Symbol('SAVING_UNIT_TYPE'),
    SAVE_UNIT_TYPE: Symbol('SAVE_UNIT_TYPE'),
    SAVE_UNIT_TYPE_ERROR: Symbol('SAVE_UNIT_TYPE_ERROR'),

    DELETING_UNIT_TYPE: Symbol('DELETING_UNIT_TYPE'),
    DELETE_UNIT_TYPE: Symbol('DELETE_UNIT_TYPE'),
    DELETE_UNIT_TYPE_ERROR: Symbol('DELETE_UNIT_TYPE_ERROR'),

    DELETE_UNIT_TYPE_MODAL_SHOW:Symbol('DELETE_UNIT_TYPE_MODAL_SHOW'),
    DELETE_UNIT_TYPE_MODAL_HIDE:Symbol('DELETE_UNIT_TYPE_MODAL_HIDE'),
    //unit type
});

export default adminPanelTypes;