import asyncProcessTypes from '../type/asyncProcess';
const initialState = {
    WaitForRebateExcelFile: false,
    RebateExcelInternalId: null,
}
export default (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;

    }
}