import React, { useEffect } from 'react'
import './Tariff.scss'
import { Button } from '@dfds-ui/react-components'
import { ErrorModal } from '../../CommonUI/Modals/ErrorModal';
import { JustTable } from '../../../component/CommonUI/JustTable/JustTable'
import { TariffTableSchema, DummyCreator } from './TariffTableSchema'
import { TariffModalShow,clearNetworkError, clearError, setSelectedTariff, deleteTariffModalShow, deleteTariffModalHide, getTariffs, deleteTariff } from '../../../store/action/adminPanelAction'
import DeleteModal from '../../CommonUI/Modals/YesNoModal/YesNoModal';

import TariffModal from './Modal/Modal'
import { connect } from 'react-redux'
import { rebateLanguage,TariffTableLanguage } from '../../../config/language'

const Tariff = (props) => {
    const { display, TariffModalShow,RefreshTariffsTable, setSelectedTariff, ShowDeleteTariffModal, ErrorHandler,clearNetworkError, clearError,
        WaitForDeleteTariff, Language, deleteTariffModalShow, deleteTariffModalHide, Tariffs, WaitForTariffs,getTariffs, SelectedTariff, deleteTariff } = props
    const JustRowContextMenu = [
        {
            Name: "editTariff",
            Action: (Row) => {
                setSelectedTariff(Row)
            }
        },
        {
            Name: "deleteTariff",
            Action: (Row) => {
                deleteTariffModalShow(Row)
            }
        }
    ]
    useEffect(() => {
        if (display) {
             getTariffs()
        }
    }, [display])
    useEffect(() => {
        if (RefreshTariffsTable) {
            getTariffs()
        }
    }, [RefreshTariffsTable])


    const JustAnimations = {
        CanvasWidth: 1063,
        WaitForRequest: WaitForTariffs
    }

    const ErrorCode = ErrorHandler && ErrorHandler.ErrorCode;
    const ErrorDescription = ErrorHandler && ErrorHandler.ErrorDescription
    const ErrorState = ErrorHandler && ErrorHandler.ErrorState

    const _onDeleteTariffClick = () => {
        deleteTariff(SelectedTariff.id)
    }

    return (
        display &&
        <>
            <div className="IdentityUser-Container">
                <div className="IdentityUser-Header">
                    <h4 id="HeaderText">{rebateLanguage[Language].TariffManagement}</h4>
                </div>
                <div className="IdentityUser-Content-Container">
                    <div className="IdentityUser-Content-Header">
                        <Button
                            className="IdentitiyUser-AddUser"
                            onClick={() => TariffModalShow()}
                            disabled={false}
                        >
                            {rebateLanguage[Language].NewTariff}
                    </Button>
                    </div>
                    <div className="IdentityUser-Table-Container">
                        <JustTable
                            JustSchema={TariffTableSchema(TariffTableLanguage[Language])}
                            JustData={Tariffs}
                            JustAnimations={JustAnimations}
                            JustContextFunctions={JustRowContextMenu}
                        />
                    </div>

                </div>
            </div>
            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ButtonLabel={rebateLanguage[Language].GoBackButtonText}
                    ErrorTitle={rebateLanguage[Language].ErrorTitle}
                    ErrorMessage={ErrorDescription}
                    OnClose={() => {
                        switch (ErrorState) {
                            case "NETWORK_ERROR":
                                clearNetworkError()
                                break;
                            case "CUSTOM_ERROR":
                                clearError()
                                break;
                                default:
                                    clearError()
                                    break;
                        }
                    }}
                />
            }

            <TariffModal />
            <DeleteModal
                OpenModal={ShowDeleteTariffModal}
                Title={rebateLanguage[Language].DeleteTariffModalTitle}
                Message={rebateLanguage[Language].DeleteTariffModalMessage}
                OnClose={() => deleteTariffModalHide()}
                onYesClick={(e) => { _onDeleteTariffClick() }}
                waitForRequest={WaitForDeleteTariff}
                ButtonYesText={rebateLanguage[Language].ButtonYes}
                ButtonNoText={rebateLanguage[Language].ButtonNo}
            />
        </>

    )
}
const mapStateToProps = (state) => {
    return {
        ShowDeleteTariffModal: state.adminPanel.ShowDeleteTariffModal,
        WaitForDeleteTariff: state.adminPanel.WaitForDeleteTariff,
        WaitForTariffs: state.adminPanel.WaitForTariffs,
        Tariffs: state.adminPanel.Tariffs,
        Language: state.user.Language,
        SelectedTariff: state.adminPanel.SelectedTariff,
        ErrorHandler: state.adminPanel.ErrorHandler,
        RefreshTariffsTable: state.adminPanel.RefreshTariffsTable
    }
}
export default connect(mapStateToProps, { TariffModalShow, setSelectedTariff, deleteTariffModalShow, deleteTariffModalHide,getTariffs,clearNetworkError, clearError, deleteTariff })(Tariff);