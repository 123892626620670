import React from 'react'
import './JustLockComponent.scss';
const JustLockComponent = (props) => {
    return (
        <div className={"JustLockComponent-Lock " + (props.className ? props.className : "")}>
            <div className="JustLockComponent-IconText-Container">
                <span id="JustLockComponent-Icon">
                    {props.Icon}
                </span>
                <span id="JustLockComponent-Text">
                    {props.Text}
                </span>
            </div>

        </div>
    )
}
export default JustLockComponent;