import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import PropTypes from 'prop-types';

export const JustExcelExport = (Filename, ExcelSchema, ExcelData, FinallyFunction) => {
    if (!ExcelSchema || !ExcelData) return
    var workbook = new Excel.Workbook();
    const excelSheets = ExcelSchema.Sheets;
    excelSheets.map((eSheet) => {
        var sheetSelf = null
        if (eSheet.property) {
            sheetSelf = workbook.addWorksheet(eSheet.name, eSheet.property)
        } else {
            sheetSelf = workbook.addWorksheet(eSheet.name)
        }
        sheetSelf.autoFilter = eSheet.autoFilter
        const eSheetColumns = eSheet.columns
        sheetSelf.columns = eSheetColumns
        const columnIds = JustExcelColumnIds(eSheetColumns)
        const columnRow = sheetSelf.getRow(1)
        const columnStyle = eSheet.columnStyle
        const haveFilter = eSheet.autoFilter

        if (columnStyle) {
            columnRow.eachCell((cell, colNumber) => {
                cell.style = columnStyle
            })
            columnRow.commit()
        }
        const sheetData = ExcelData[eSheet.id]
        if (!sheetData) return
        sheetData.map((justRow) => {
            sheetSelf.addRow(JustExcelSheetDataBinder(justRow, columnIds, eSheet.processData))
        })

        if (haveFilter) {
            sheetSelf.autoFilter = {
                from: {
                    column: 1,
                    row: 1,
                },
                to: {
                    column: columnIds.length,
                    row: (sheetData.length + 1)
                }
            }
        }
    })

    const buf = workbook.xlsx.writeBuffer().then((response) => {
        saveAs(new Blob([response]), `${Filename}.xlsx`)
    }).finally(() => {
        if (FinallyFunction && typeof FinallyFunction === "function")
            FinallyFunction()
    })
}
JustExcelExport.propTypes = {
    Filename: PropTypes.string,
    ExcelSchema: PropTypes.object,
    ExcelData: PropTypes.object,
    FinallyFunction: PropTypes.func,
}
JustExcelExport.defaultProps = {
    Filename: "JustExcelExport",
    FinallyFunction: () => { }
}
const JustExcelColumnIds = (Columns) => {
    let columnIds = []
    Columns.map((Column) => {
        columnIds.push(Column.key)
    })
    return columnIds
}
const JustExcelSheetDataBinder = (SheetData, ColumnIds, processData) => {
    let excelRow = {}
    ColumnIds.map((cKey) => {
        if (processData && processData[cKey]) {
            excelRow[cKey] = processData[cKey](SheetData[cKey])
            return
        }
        excelRow[cKey] = SheetData[cKey]

    })
    return excelRow
}
export const JustExcelExtraDataBinder = (Datas, ExtraData) => {
    let withExtraArray = []
    Object.values(Datas).map((data) => {
        let withExtraObject = {}
        withExtraObject = data
        for (let [key, value] of Object.entries(ExtraData)) {
            withExtraObject[key] = value
        }
        withExtraArray.push(withExtraObject)
    })
    return withExtraArray
}
