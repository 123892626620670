import React from 'react';
import './SpinButton.scss';
import { LoadingSpinner } from '@dfds-ui/icons';
import { Spinner } from '@dfds-ui/react-components';

const SpinButton = props => {
    const className = props.className ? props.className : ""
    return (
        !(props.waitForRequest) ?
            <button data-tip data-for={props.dataFor} className={"spinBtn " + className}
                onClick={props.onClick}>
                {props.ButtonLabel}
            </button> :
            // :
            <button data-tip data-for={props.dataFor} className={"spinBtn " + className + " disabled"}
                disabled >
                <Spinner instant={true} />
            </button >
    )
}
export default SpinButton;