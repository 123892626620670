const rebateTypes = Object.freeze({
  GET_TRANSACTIONS: Symbol("GET_TRANSACTIONS"),
  GETTING_TRANSACTIONS: Symbol("GETTING_TRANSACTIONS"),
  GET_INVOICES: Symbol("GET_INVOICES"),
  CLEAR_REBATES: Symbol("CLEAR_REBATES"),
  REBATE_ERROR: Symbol("REBATE_ERROR"),
  GETTING_INVOICES: Symbol("GETTING_INVOICES"),
  NOT_FOUND: Symbol("NOT_FOUND"),
  TRANSACTION_DATA_CHANGED: Symbol("TRANSACTION_DATA_CHANGED"),
  SAVING_TRANSACTION: Symbol("SAVING_TRANSACTION"),
  TRANSACTION_SAVED: Symbol("TRANSACTION_SAVED"),
  SET_FILTER: Symbol("SET_FILTER"),

  PROCESSING_TRANSACTION: Symbol("PROCESSING_TRANSACTION"),
  PROCESSING_CANCEL_TRANSACTION: Symbol("PROCESSING_CANCEL_TRANSACTION"),
  PROCESS_OVER: Symbol("PROCESS_OVER"),
  GETTING_PROCESS_STATUS: Symbol("GETTING_PROCESS_STATUS"),
  PROCESS_FAILED: Symbol("PROCESS_FAILED"),

  GETTING_PROCESS_STATUS_IS: Symbol("GETTING_PROCESS_STATUS_IS"),

  PROCESS_PROCESSING_LOCK: Symbol("PROCESS_PROCESSING_LOCK"),

  MODAL_PROCESS_PROCESSING: Symbol("MODAL_PROCESS_PROCESSING"),

  PROCESS_FAILED_IS: Symbol("PROCESS_FAILED_IS"),

  OPEN_PROCESSLOCK: Symbol("OPEN_PROCESSLOCK"),
  SET_DATA_HISTORY: Symbol("SET_DATA_HISTORY"),
  CALCULATING_TRANSACTION: Symbol("CALCULATING_TRANSACTION"),
  TRANSACTION_CALCULATED: Symbol("TRANSACTION_CALCULATED"),
  SET_EXCELMODAL: Symbol("SET_EXCELMODAL"),
  FILE_DOWNLOADING: Symbol("FILE_DOWNLOADING"),
  FILE_DOWNLOADED: Symbol("FILE_DOWNLOADED"),
  PAGE_STATUS: Symbol("PAGE_STATUS"),
  SHOW_HISTORY_MODAL: Symbol("SHOW_HISTORY_MODAL"),
  HIDE_HISTORY_MODAL: Symbol("HIDE_HISTORY_MODAL"),
  PROCESS_SUCCESS: Symbol("PROCESS_SUCCESS"),

  INITIAL_PDF: Symbol("INITIAL_PDF"),


  PROCESS_SUCCESS_IS: Symbol("PROCESS_SUCCESS_IS"),

  SET_SELECTED_TRANSACTIONS: Symbol("SET_SELECTED_TRANSACTIONS"),
  CLEAR_INVOICES: Symbol("CLEAR_INVOICES"),
  SORT_TRANSACTIONS: Symbol("SORT_TRANSACTIONS"),
  GET_TRANSACTIONS_ERROR: Symbol("GET_TRANSACTIONS_ERROR"),
  GET_INVOICES_ERROR: Symbol("GET_INVOICES_ERROR"),

  EXCEL_CREATE_REQUEST_END: Symbol("EXCEL_CREATE_REQUEST_END"),
  EXCEL_CREATE_REQUEST_PROCESSING: Symbol("EXCEL_CREATE_REQUEST_PROCESSING"),
  CHANGE_EXCEL_STATUS_NAME: Symbol("CHANGE_EXCEL_STATUS_NAME"),
  EXCEL_REQUEST_STARTED: Symbol("EXCEL_REQUEST_STARTED"),
  EXCEL_DOWNLOADING: Symbol("EXCEL_DOWNLOADING"),
  EXCEL_DOWNLOADED: Symbol("EXCEL_DOWNLOADED"),
  EXCEL_REQUEST_FAILED: Symbol("EXCEL_REQUEST_FAILED"),

  PDF_DOWNLOADED: Symbol("PDF_DOWNLOADED"),
  PDF_PROCESSING: Symbol("PDF_PROCESSING"),
  PDF_FAILED: Symbol("PDF_FAILED"),
  CHANGE_PDF_STATUS: Symbol("CHANGE_PDF_STATUS"),

  PROCESS_STARTED: Symbol("PROCESS_STARTED"),
  PROCESS_CANCEL_COMPLETED: Symbol("PROCESS_CANCEL_COMPLETED"),
  PROCESS_PROCESSING: Symbol("PROCESS_PROCESSING"),
  PROCESS_CANCEL_PROCESSING: Symbol("PROCESS_CANCEL_PROCESSING"),
  SET_EXCEL_INTERVAL_ID: Symbol("SET_EXCEL_INTERVAL_ID"),
  SET_PROCESS_INTERVAL_ID: Symbol("SET_PROCESS_INTERVAL_ID"),

  SET_PROCESS_PROCESSING_INTERVAL_ID: Symbol(
    "SET_PROCESS_PROCESSING_INTERVAL_ID"
  ),

  PROCESS_PROCESSING_IS: Symbol("PROCESS_PROCESSING_IS"),
  SET_PROCESS_MODAL: Symbol("SET_PROCESS_MODAL"),

  INTEGRATION_EXCEL_CREATE_REQUEST_STARTED: Symbol(
    "INTEGRATION_EXCEL_CREATE_REQUEST_STARTED"
  ),
  INTEGRATION_EXCEL_CREATE_REQUEST_END: Symbol(
    "INTEGRATION_EXCEL_CREATE_REQUEST_END"
  ),
  INTEGRATION_EXCEL_CREATE_REQUEST_PROCESSING: Symbol(
    "INTEGRATION_EXCEL_CREATE_REQUEST_PROCESSING"
  ),
  INTEGRATION_EXCEL_REQUEST_PROCESSING: Symbol(
    "INTEGRATION_EXCEL_REQUEST_PROCESSING"
  ),
  INTEGRATION_EXCEL_DOWNLOADING: Symbol("INTEGRATION_EXCEL_DOWNLOADING"),
  INTEGRATION_EXCEL_DOWNLOADED: Symbol("INTEGRATION_EXCEL_DOWNLOADED"),
  SHOW_INTEGRATION_MODAL: Symbol("SHOW_INTEGRATION_MODAL"),
  SET_INTEGRATION_EXCEL_INTERVAL_ID: Symbol(
    "SET_INTEGRATION_EXCEL_INTERVAL_ID"
  ),
  SET_INTEGRATION_EXCEL_STATUS: Symbol("SET_INTEGRATION_EXCEL_STATUS"),
  INTEGRATION_EXCEL_REQUEST_FAILED: Symbol("INTEGRATION_EXCEL_REQUEST_FAILED"),

  SET_PDF_INTERVAL_ID: Symbol("SET_PDF_INTERVAL_ID"),

  INVOICE_REBATE_REQUEST_STARTED: Symbol("INVOICE_REBATE_REQUEST_STARTED"),
  GETTING_INVOICE_REBATE_STATUS: Symbol("GETTING_INVOICE_REBATE_STATUS"),
  INVOICE_REBATE_FAILED: Symbol("INVOICE_REBATE_FAILED"),
  INVOICE_REBATE_STARTED: Symbol("INVOICE_REBATE_STARTED"),
  SHOW_INVOICE_REBATE_MODAL: Symbol("SHOW_INVOICE_REBATE_MODAL"),
  INVOICE_REBATE_PROCESSING: Symbol("INVOICE_REBATE_PROCESSING"),
  SET_INVOICE_REBATE_INTERVAL_ID: Symbol("SET_INVOICE_REBATE_INTERVAL_ID"),
  INVOICE_REBATE_SUCCESS: Symbol("INVOICE_REBATE_SUCCESS"),

  SET_START_MONTH: Symbol("SET_START_MONTH"),
  SET_END_MONTH: Symbol("SET_END_MONTH"),
  SET_EMAIL_STATUS: Symbol("SET_EMAIL_STATUS"),
  SET_TRANSACTION_YEAR: Symbol("SET_TRANSACTION_YEAR"),
});

export default rebateTypes;
