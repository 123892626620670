export const NavBarConfig = (Pagenames) => {
    return (
        [
            {
                pageName: "/login",
                Items: [
                    {
                        text: "Login",
                        path: "/login",
                        active: true
                    }
                ]
            },
            {
                pageName: "/rebate",
                Items: [
                    {
                        text: Pagenames.RebateNavText,
                        path: "/Rebate",
                        selected: true,
                    },
                    {
                        text: Pagenames.CustomerNavText,
                        path: "/customer",
                        active: true
                    },
                    {
                        text: Pagenames.InvoiceControlNavText,
                        path: "/invoiceControl",
                        active: true
                    },
                    {
                        text: Pagenames.IncomingInvoices,
                        path: "/incomingInvoices",
                        active: true
                    },
                ]
            },
            {
                pageName: "/customer",
                Items: [
                    {
                        text: Pagenames.RebateNavText,
                        path: "/Rebate",
                        active: true
                    },
                    {
                        text: Pagenames.CustomerNavText,
                        path: "/customer",
                        selected: true
                    },
                    {
                        text: Pagenames.InvoiceControlNavText,
                        path: "/invoiceControl",
                        active: true
                    },
                    {
                        text: Pagenames.IncomingInvoices,
                        path: "/incomingInvoices",
                        active: true
                    },
                ]
            },
            {
                pageName: "/invoiceControl",
                Items: [
                    {
                        text: Pagenames.RebateNavText,
                        path: "/Rebate",
                        active: true
                    },
                    {
                        text: Pagenames.CustomerNavText,
                        path: "/customer",
                        active: true,
                    },
                    {
                        text: Pagenames.InvoiceControlNavText,
                        path: "/invoiceControl",
                        selected: true
                    },
                    {
                        text: Pagenames.IncomingInvoices,
                        path: "/incomingInvoices",
                        active: true
                    },
                ]
            },
            {
                pageName: "/incomingInvoices",
                Items: [
                    {
                        text: Pagenames.RebateNavText,
                        path: "/Rebate",
                        active: true
                    },
                    {
                        text: Pagenames.CustomerNavText,
                        path: "/customer",
                        active: true,
                    },
                    {
                        text: Pagenames.InvoiceControlNavText,
                        path: "/invoiceControl",
                        active: true
                    },
                    {
                        text: Pagenames.IncomingInvoices,
                        path: "/incomingInvoices",
                        selected: true
                    },
                ]
            },
            {
                pageName: "/adminpanel",
                Items: [
                    {
                        text: Pagenames.RebateNavText,
                        path: "/Rebate",
                        active: true
                    },
                    {
                        text: Pagenames.CustomerNavText,
                        path: "/customer",
                        active: true,
                    },
                    {
                        text: Pagenames.InvoiceControlNavText,
                        path: "/invoiceControl",
                        active: true
                    },
                    {
                        text: Pagenames.IncomingInvoices,
                        path: "/incomingInvoices",
                        active: true
                    },
                ]
            },
        ]
    )
}