import React, { useEffect } from 'react'
import './AccountingVessel.scss'
import { Button } from '@dfds-ui/react-components'
import { JustTable } from '../../../component/CommonUI/JustTable/JustTable'
import { AccountingVesselSchema, DummyCreator } from './AccountingVesselTableSchema'
import {
    AccountingVesselModalShow, setSelectedVessel, deleteVesselModalShow, getVessel,
    deleteVesselModalHide, clearNetworkError, clearError, deleteVessel
} from '../../../store/action/adminPanelAction'
import DeleteModal from '../../CommonUI/Modals/YesNoModal/YesNoModal';

import AccountingVesselModal from './Modal/Modal'
import { connect } from 'react-redux'
import { rebateLanguage, accountingVesselLanguage, accountingVoyageLanguage } from '../../../config/language'
import { ErrorModal } from '../../CommonUI/Modals/ErrorModal';


const AccountingVessel = (props) => {
    const { display, AccountingVesselModalShow, setSelectedVessel, Language, WaitForAccountingVessel,
        getVessel, AccountingVessels, ErrorHandler, clearNetworkError, clearError, deleteVesselModalHide, deleteVesselModalShow,
        ShowAccountingVesselDeleteModal, deleteVessel, WaitForDeleteVessel, RefreshAccountingVesselPage, AccountingVesselId  } = props
    const JustRowContextMenu = [
        {
            Name: "editVessel",
            Action: (Row) => {
                setSelectedVessel(Row)
            }
        },
        {
            Name: "deleteVessel",
            Action: (Row) => {
                deleteVesselModalShow(Row.accountingVesselId)
            }
        }
    ]
    useEffect(() => {
        if (display) {
            getVessel()
        }
    }, [display])

    useEffect(() => {
        if (RefreshAccountingVesselPage) {
            getVessel()
        }
    }, [RefreshAccountingVesselPage])

    const JustAnimations = {
        CanvasWidth: 1063,
        WaitForRequest: WaitForAccountingVessel
    }

    const ErrorCode = ErrorHandler && ErrorHandler.ErrorCode;
    const ErrorDescription = ErrorHandler && ErrorHandler.ErrorDescription
    const ErrorState = ErrorHandler && ErrorHandler.ErrorState

    return (
        display &&
        <>
            <div className="AccountingVessel-Container">
                <div className="AccountingVessel-Header">
                    <h4 id="HeaderText">{accountingVesselLanguage[Language].PageTitle}</h4>
                </div>
                <div className="AccountingVessel-Content-Container">
                    <div className="AccountingVessel-Content-Header">
                        <Button
                            className="AccountingVessel-AddRate"
                            onClick={() => AccountingVesselModalShow()}
                            disabled={false}
                        >
                            {accountingVesselLanguage[Language].NewButton}
                        </Button>
                    </div>
                    <div className="AccountingVessel-Table-Container">
                        <JustTable
                            JustSchema={AccountingVesselSchema(accountingVesselLanguage[Language].Table)}
                            JustData={AccountingVessels}
                            JustAnimations={JustAnimations}
                            JustContextFunctions={JustRowContextMenu}
                        />
                    </div>

                </div>
            </div>
            <AccountingVesselModal />
            <DeleteModal
                OpenModal={ShowAccountingVesselDeleteModal}
                Title={accountingVesselLanguage[Language].DeleteTitle}
                Message={accountingVesselLanguage[Language].DeleteMessage}
                OnClose={() => deleteVesselModalHide()}
                onYesClick={(e) => { deleteVessel(AccountingVesselId) }}
                waitForRequest={WaitForDeleteVessel}
                ButtonYesText={rebateLanguage[Language].ButtonYes}
                ButtonNoText={rebateLanguage[Language].ButtonNo}
            />

            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ButtonLabel={rebateLanguage[Language].GoBackButtonText}
                    ErrorTitle={rebateLanguage[Language].ErrorTitle}
                    ErrorMessage={ErrorDescription}
                    OnClose={() => {
                        switch (ErrorState) {
                            case "NETWORK_ERROR":
                                clearNetworkError()
                                break;
                            case "CUSTOM_ERROR":
                                clearError()
                                break;
                            default:
                                clearError()
                                break;
                        }
                    }}
                />
            }
        </>

    )
}
const mapStateToProps = (state) => {
    return {
        SelectedAccountingVessel: state.adminPanel.SelectedAccountingVessel,
        WaitForAccountingVessel: state.adminPanel.WaitForAccountingVessel,
        AccountingVessels: state.adminPanel.AccountingVessels,
        ShowAccountingVesselDeleteModal: state.adminPanel.ShowAccountingVesselDeleteModal,
        WaitForDeleteVessel: state.adminPanel.WaitForDeleteVessel,
        RefreshAccountingVesselPage: state.adminPanel.RefreshAccountingVesselPage,
        AccountingVesselId : state.adminPanel.AccountingVesselId ,
        ErrorHandler: state.adminPanel.ErrorHandler,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, {
    AccountingVesselModalShow, setSelectedVessel, deleteVessel,
    deleteVesselModalShow, deleteVesselModalHide, getVessel, clearNetworkError, clearError
})(AccountingVessel);