import axios from 'axios';
import authenticationInterceptorHandler from './interceptors/authentication.interceptor';
import ProjectConfig from '../config/config';
//import MockAdapter from 'axios-mock-adapter';

const httpClient = axios.create({
    // baseURL: process.env.REACT_APP_BASE_API_URL
});
// httpClient.
//If 'data' is null, then Content-Type is removed automatically by AXIOS
httpClient.defaults.headers.post['Content-Type'] = 'application/json';
httpClient.defaults.headers.post['ApplicationName'] = `${ProjectConfig.projectName}`;
httpClient.interceptors.request.use(
    request => authenticationInterceptorHandler(request)
);
export default httpClient;