import React from 'react'
import './ModalFooter.scss'
import { Button } from '@dfds-ui/react-components'
import { connect } from 'react-redux'
import {
    saveNewInvoice, setValidationTotalAmountValidation, setValidationInvoiceNumber, setError,
    setDateValidation, setInvoiceAmountValidation, setPaymentDateValidation, setVoucherDateValidation, setExchangeRateValidation
} from '../../../../../store/action/incomingInvoiceAction'
import { InputValidations } from '../../ModalValidations'
import { incomingInvoiceLanguage } from '../../../../../config/language'
const NewInvoiceModalFooter = (props) => {
    const { SelectedTransaction, invoiceAmount, invoiceAmountAsTry, invoiceCurrency,
        invoiceId, invoiceDate, invoicePaymentDate, exchangeRate, ettn, watAmount,
        watAmountAsTry, TotalAmount, TotalAmountAsTry, voucherDate, EInvoice, WaitForSavingNewInvoice,
        saveNewInvoice, setValidationTotalAmountValidation, setValidationInvoiceNumber,
        setDateValidation, setPaymentDateValidation, setVoucherDateValidation, setInvoiceAmountValidation,
        setError, Language, setExchangeRateValidation, Description, differenceAmountAsTry } = props
    const onSaveButton = () => {
        const errorResult = InputValidations(invoiceId, invoiceDate, invoicePaymentDate, voucherDate, invoiceAmount, exchangeRate, incomingInvoiceLanguage[Language].InputValidationError)
        if (errorResult) {
            setError(errorResult.Code, errorResult.Message)
            if (errorResult.ValidationArray.indexOf("INVOICE_NUMBER") > -1) {
                setValidationInvoiceNumber(true)
            }
            if (errorResult.ValidationArray.indexOf("INVOICE_PAYMENT_DATE") > -1) {
                setPaymentDateValidation(true)
            }
            if (errorResult.ValidationArray.indexOf("INVOICE_DATE") > -1) {
                setDateValidation(true)
            }
            if (errorResult.ValidationArray.indexOf("INVOICE_VOUCHER_DATE") > -1) {
                setVoucherDateValidation(true)
            }
            if (errorResult.ValidationArray.indexOf("INVOICE_AMOUNT") > -1) {
                setInvoiceAmountValidation(true)
            }
            if (errorResult.ValidationArray.indexOf("EXCHANGE_RATE") > -1) {
                setExchangeRateValidation(true)
            }
            return
        }
        if (SelectedTransaction.rebateAmount !== TotalAmount) {
            setError(500, "Total Amount is not equal to Rebate Amount")
            setValidationTotalAmountValidation(true)
            return
        }
        saveNewInvoice(SelectedTransaction.period, SelectedTransaction.customer.name, SelectedTransaction.customer.number, EInvoice,
            voucherDate, SelectedTransaction.transactionId, invoiceAmount, invoiceAmountAsTry,
            invoiceCurrency, invoiceId, invoiceDate, invoicePaymentDate, exchangeRate, ettn, watAmount, watAmountAsTry, Description, differenceAmountAsTry)
        // if()
    }
    return (
        <div className="NewInvoiceModalFooter-Container">
            <Button
                size={"small"}
                className="NewInvoiceModal-SaveButton"
                onClick={() => onSaveButton()}
                disabled={WaitForSavingNewInvoice || !SelectedTransaction}
            >
                {incomingInvoiceLanguage[Language].ModalLanguage.SaveButton}
            </Button>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        SelectedTransaction: state.incomingInvoice.SelectedTransaction,
        invoiceAmount: state.incomingInvoice.invoiceAmount,
        invoiceAmountAsTry: state.incomingInvoice.invoiceAmountAsTry,
        invoiceCurrency: state.incomingInvoice.invoiceCurrency,
        invoiceId: state.incomingInvoice.invoiceId,
        invoiceDate: state.incomingInvoice.invoiceDate,
        invoicePaymentDate: state.incomingInvoice.invoicePaymentDate,
        exchangeRate: state.incomingInvoice.exchangeRate,
        ettn: state.incomingInvoice.ettn,
        watAmount: state.incomingInvoice.watAmount,
        watAmountAsTry: state.incomingInvoice.watAmountAsTry,
        TotalAmount: state.incomingInvoice.TotalAmount,
        TotalAmountAsTry: state.incomingInvoice.TotalAmountAsTry,
        voucherDate: state.incomingInvoice.voucherDate,
        Description: state.incomingInvoice.Description,
        EInvoice: state.incomingInvoice.EInvoice,
        WaitForSavingNewInvoice: state.incomingInvoice.WaitForSavingNewInvoice,
        differenceAmountAsTry: state.incomingInvoice.differenceAmountAsTry,
        Language: state.user.Language,
    }
}
export default connect(mapStateToProps, {
    saveNewInvoice, setValidationTotalAmountValidation,
    setValidationInvoiceNumber, setError,
    setDateValidation, setPaymentDateValidation, setVoucherDateValidation, setInvoiceAmountValidation, setExchangeRateValidation
})(NewInvoiceModalFooter);
