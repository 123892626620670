// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './component/App'
import store from './store/store';
import { Provider } from 'react-redux';


ReactDOM.render(<Provider store={store}> <App /> </Provider>, document.getElementById('root'));
