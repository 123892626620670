import React, { useEffect } from 'react'
import './Modal.scss'
import { Modal } from '@dfds-ui/modal'
import { connect } from 'react-redux'
import {
    TariffModalHide, setInputTariffStartDate, setInputTariffEndDate,
    setTariffStartDateValidation, setTariffEndDateValidation, saveTariff,
    setInputTariffAgreementNumber, setInputTariffNumber
} from '../../../../store/action/adminPanelAction'
import { Input, Button, Label, SelectField } from '@dfds-ui/react-components'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { rebateLanguage } from '../../../../config/language'

const TariffModal = (props) => {
    const { Language, TariffModalHide, setInputTariffStartDate, setInputTariffEndDate,
        setTariffStartDateValidation, setTariffEndDateValidation, ShowTariffModal, SelectedTariff, TariffStartDate,
        TariffEndDate, TariffAgreementNumber, TariffNumber, TariffStartDateValidation, TariffEndDateValidation,
        saveTariff, setInputTariffAgreementNumber, setInputTariffNumber } = props
    useEffect(() => {

    }, [ShowTariffModal])



    return (
        <Modal className="IdentityUser-Modal" heading={SelectedTariff ? rebateLanguage[Language].EditTariff : rebateLanguage[Language].NewTariff} onRequestClose={() => TariffModalHide()} isOpen={ShowTariffModal}>
            <div className="IdentityUser-Modal-Container">
                <div className="IdentityUser-Modal-Header">

                </div>
                <div className="IdentityUser-Modal-Content">
                    <div className="LeftContent-Container">
                        <div className="InputWithLabel" id="aggreementNumber">
                            <span className="Input-Label">
                                {rebateLanguage[Language].AggrementNumber}
                            </span>
                            {
                                <Input
                                    size={"small"}
                                    defaultValue={SelectedTariff && SelectedTariff.agreementNumber}
                                    onChange={(event) => { setInputTariffAgreementNumber(event.target.value) }}
                                />
                            }
                        </div>
                        <div className="InputWithLabel" id="tariffNumber">
                            <span className="Input-Label">
                                {rebateLanguage[Language].TariffNumber}
                            </span>
                            {
                                <Input
                                    size={"small"}
                                    defaultValue={SelectedTariff && SelectedTariff.tariffNumber}
                                    onChange={(event) => { setInputTariffNumber(event.target.value) }}
                                />
                            }
                        </div>
                    </div>
                    <div className="RightContent-Container">
                        <div className="DateField-Wrapper" id="startDate">
                            <Label id="declarationDate-Label">
                                {rebateLanguage[Language].StartDate}
                            </Label>
                            <DatePicker
                                className={"DeclarationDate-Picker " + (TariffStartDateValidation ? "ERROR_BORDER" : "")}
                                selected={TariffStartDate && new Date(TariffStartDate)}
                                onChange={date => { setInputTariffStartDate(date); setTariffStartDateValidation(false); }}
                                placeholderText={rebateLanguage[Language].StartDate}
                                dateFormat={"dd/MM/yyyy"}
                            />
                        </div>
                        <div className="DateField-Wrapper" id="endDate">
                            <Label id="declarationDate-Label">
                                {rebateLanguage[Language].EndDate}
                            </Label>
                            <DatePicker
                                className={"DeclarationDate-Picker " + (TariffEndDateValidation ? "ERROR_BORDER" : "")}
                                selected={TariffEndDate && new Date(TariffEndDate)}
                                onChange={date => { setInputTariffEndDate(date); setTariffEndDateValidation(false); }}
                                placeholderText={rebateLanguage[Language].EndDate}
                                dateFormat={"dd/MM/yyyy"}
                            />
                        </div>

                    </div>
                </div>
                <div className="IdentityUser-Modal-Footer">
                    <Button
                        className="IdentityUser-Button-Save"
                        disabled={false}
                        onClick={() => {
                            if (!TariffStartDate) {
                                setTariffStartDateValidation(true)
                            }

                            if (!TariffEndDate) {
                                setTariffEndDateValidation(true)
                            }

                            var tariffId = SelectedTariff ? SelectedTariff.id : 0
                            saveTariff(tariffId, TariffStartDate, TariffEndDate, TariffAgreementNumber, TariffNumber)
                        }}
                    >
                        Kaydet
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        ShowTariffModal: state.adminPanel.ShowTariffModal,
        SelectedTariff: state.adminPanel.SelectedTariff,
        Language: state.user.Language,
        TariffStartDate: state.adminPanel.TariffStartDate,
        TariffEndDate: state.adminPanel.TariffEndDate,
        TariffStartDateValidation: state.adminPanel.TariffStartDateValidation,
        TariffStartEndValidation: state.adminPanel.TariffEndDateValidation,
        TariffAgreementNumber: state.adminPanel.TariffAgreementNumber,
        TariffNumber: state.adminPanel.TariffNumber
    }
}
export default connect(mapStateToProps, {
    TariffModalHide, setTariffStartDateValidation,
    setTariffEndDateValidation, setInputTariffStartDate, setInputTariffEndDate, saveTariff,
    setInputTariffAgreementNumber, setInputTariffNumber
})(TariffModal)