import React from 'react'
import JustCanvas from '../JustCanvas/JustCanvas'
import { Spinner } from '@dfds-ui/react-components';
import PropTypes from 'prop-types';

import './JustWait.scss'

const JustWait = (props) => {
    const { Text, CanvasWidth, CanvasHeight, Iconsize } = props;
    return (
        <JustCanvas CanvasWidth={CanvasWidth} CanvasHeight={CanvasHeight}>
            <div className="JustCanvas-WaitForRequest">
                <Spinner className={"WaitForRequest-Icon " + (Iconsize === "small" ? "SMALL-ICON" : "")} instant={true} />
                <span id="WaitForRequest-Text">{Text}</span>
            </div>
        </JustCanvas>
    )
}
JustWait.propTypes = {
    Text: PropTypes.string,
    CanvasWidth: PropTypes.string,
    CanvasHeight: PropTypes.string,
    Iconsize: PropTypes.string
}
JustWait.defaultProps = {
    Text: "",
    CanvasWidth: "",
    CanvasHeight: "",
    Iconsize: "",
}
export default JustWait;