import React, { useEffect } from 'react'
import './Modal.scss'
import { Modal } from '@dfds-ui/modal'
import { connect } from 'react-redux'
import { RebateRateModalHide ,setInputRebateRateName,
    setInputRebateRateRatio,saveRate } from '../../../../store/action/adminPanelAction'
import { Input, Button, Label, SelectField } from '@dfds-ui/react-components'
import { adminPagesLanguage } from '../../../../config/language'

const RebateRateModal = (props) => {
    const { Language,RebateRateModalHide,setInputRebateRateName,
        setInputRebateRateRatio, ShowRebateRateModal, SelectedRate,RebateRateId,RebateRateName,
        RebateRateRatio,saveRate } = props
    useEffect(() => { }, [ShowRebateRateModal])
    return (
        <Modal className="RebateRate-Modal"
               heading={SelectedRate ? adminPagesLanguage[Language].EditRate :
                   adminPagesLanguage[Language].NewRate}
               onRequestClose={() => RebateRateModalHide()}
               isOpen={ShowRebateRateModal}>
            <div className="RebateRate-Modal-Container">
                <div className="RebateRate-Modal-Header">

                </div>
                <div className="RebateRate-Modal-Content">
                    <div className="LeftContent-Container">
                        <div className="InputWithLabel" id="Name">
                            <Label /*id="rebateRateName-Label"*/>
                                {adminPagesLanguage[Language].Name}
                            </Label>
                            <Input
                                size={"small"}
                                defaultValue={SelectedRate && SelectedRate.name}
                                onChange={(event)=>{setInputRebateRateName(event.target.value)}}
                            />
                        </div>
                    </div>
                    <div className="RightContent-Container">
                        <div className="InputWithLabel" id="ratio">
                            <Label id="rebateRateRatio-Label">
                                {adminPagesLanguage[Language].Ratio}
                            </Label>
                            <Input
                                size={"small"}
                                defaultValue={SelectedRate && SelectedRate.ratio}
                                onChange={(event)=>{setInputRebateRateRatio(event.target.value)}}

                            />
                        </div>
                    </div>

                </div>
                <div className="RebateRate-Modal-Footer">
                    <Button
                        className="RebateRate-Button-Save"
                        disabled={false}
                        onClick={() => {
                            var rateId = SelectedRate ? SelectedRate.id : -1
                            if(SelectedRate)
                            {

                            }


                            saveRate(rateId, RebateRateName, RebateRateRatio)
                        }}
                    >
                        {adminPagesLanguage[Language].Save}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        ShowRebateRateModal: state.adminPanel.ShowRebateRateModal,
        SelectedRate: state.adminPanel.SelectedRate,
        Language: state.user.Language,
        RebateRateId: state.adminPanel.RebateRateId,
        RebateRateName: state.adminPanel.RebateRateName,
        RebateRateRatio: state.adminPanel.RebateRateRatio
    }
}
export default connect(mapStateToProps, { RebateRateModalHide ,
    setInputRebateRateName,setInputRebateRateRatio,saveRate})(RebateRateModal)