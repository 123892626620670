import React from 'react'
export const InvoiceControlTableSchema = (schemaLanguage) => {
    //{"phxId":"400002325","legalId":"PHE2020000002265","type":"INVOICE","status":6,"lastMessage":""}
    return {
        rowId: "phxId", //unique ID
        ColumnDesign: [
            {
                columnId: "phxId",
                Text: schemaLanguage.phxId,

            },
            {
                columnId: "legalId",
                Text: schemaLanguage.legalId,
            },
            {
                columnId: "type",
                Text: schemaLanguage.type,
            },
            {
                columnId: "status",
                Text: schemaLanguage.status
            },
            {
                columnId: "lastMessage",
                Text: schemaLanguage.lastMessage
            }
        ],
        DataDesign: [
            {
                columnId: "phxId",
            },
            {
                columnId: "legalId",
            },
            {
                columnId: "type",
            },
            {
                columnId: "status",
            },
            {
                columnId: "lastMessage",
            }
        ]
    }
}


const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 100; index++) {
        let myObject = {}
        myObject.phxId = 1579507610
        myObject.legalId = 1111111111
        myObject.type = "DENEME DENEME"
        myObject.status = "5"
        DummyArray.push(myObject)
    }
    return DummyArray
}
export const Dummy = DummyCreator()