import React from 'react'
import { Lock, Delete, Edit, LockOpen, YesTick, Close, LoadingSpinner, Document } from '@dfds-ui/icons'
import moment from 'moment'

export const IdentityUserTableSchema = (schemaLanguage) => {
    return {
        rowId: "username", //unique ID
        RowContextMenu: [
            {
                Text: schemaLanguage.EditUser,
                Icon: <Edit />,
                Name: "editUser",
            },
            {
                Text: schemaLanguage.DeleteUser,
                Icon: <Delete />,
                Name: "deleteUser",
            },
        ],
        ColumnDesign: [
            {
                columnId: "username",
                Text: schemaLanguage.Username,
            },
            {
                columnId: "name",
                Text: schemaLanguage.FirstName
            },
            {
                columnId: "surname",
                Text: schemaLanguage.LastName,

            },
            {
                columnId: "email",
                Text: schemaLanguage.Email,

            },
            {
                columnId: "roles",
                Text: schemaLanguage.Role,
            },
            {
                columnId: "status",
                Text: schemaLanguage.Status,
            },

        ],
        DataDesign: [
            {
                columnId: "username",
            },
            {
                columnId: "name",
            },
            {
                columnId: "surname",
            },
            {
                columnId: "email",
            },
            {
                columnId: "roles",
            },
            {
                columnId: "status",
                dataType: "icon",
                dataCheck: (data) => isDeleted(data),
            },

        ]
    }
}
 const isDeleted = (data) => {
     if (data !== 1) {
         return <Close style={{ color: "#be1e2d" }} />
     } else {
         return <YesTick style={{ color: "#4caf50" }} />
     }
 }
function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
export const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 200; index++) {
        let myObject = {}
        myObject.username = makeid(8)
        myObject.name = makeid(8)
        myObject.surname = makeid(8)
        myObject.email = makeid(8)
        myObject.roles = makeid(8)
        DummyArray.push(myObject)
    }
    return DummyArray
}
function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
