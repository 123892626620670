import customerTypes from '../type/customer';
const initialState = {
    Customers: null,
    WaitForCustomers: false,
    CustomerNotFound: false,
    WaitForSaveCustomers: false,
    NoConnection: false,
    CustomerName: null,
    CustomerNumber: null,
    DataHistory: [],
    HistoryModal: false,
    SelectedRow: null,
    WaitForHistory: false,
    HistoryDocument: null,
    ChangesModal: false,
    ForwardAction: null,
    ShowAccountNumbersModal: false,
    AccountCustomerNumbersSaved: false,
    ErrorHandler: {
        ErrorCode: -1,
        ErrorDescription: "",
    },

}
export default (state = initialState, action) => {
    switch (action.type) {
        case customerTypes.GETTING_CUSTOMERS:
            return {
                ...state,
                WaitForCustomers: true,
                NoConnection: false,
                ChangesModal: false
            }
        case customerTypes.GET_CUSTOMERS:
            return {
                ...state,
                Customers: action.Customers,
                WaitForCustomers: false,
                NoConnection: false,
                DataHistory: [],
                CustomerAccountHistory: null,
                ForwardAction: null,
                AccountCustomerNumbersSaved: false,
            }
        case customerTypes.SAVING_CUSTOMERS:
            return {
                ...state,
                WaitForSaveCustomers: true,
                WaitForCustomers: false,
                CustomerNotFound: false,
            }
        case customerTypes.CUSTOMERS_SAVED:
            return {
                ...state,
                WaitForSaveCustomers: false,
                WaitForCustomers: false,
                CustomerNotFound: false,
            }
        case customerTypes.SET_CUSTOMER:
            return {
                ...state,
                CustomerName: action.CustomerName,
                CustomerNumber: action.CustomerNumber
            }

        case customerTypes.NOT_FOUND:
            return {
                ...state,
                WaitForCustomers: false,
                CustomerNotFound: true,
                NoConnection: false
            }

        case customerTypes.SHOW_CHANGES_MODAL:
            return {
                ...state,
                ForwardAction: action.ForwardAction,
                ChangesModal: true
            }
        case customerTypes.HIDE_CHANGES_MODAL:
            return {
                ...state,
                ChangesModal: false
            }
        //----------------- DATA HISTORY ----------------- 
        case customerTypes.SET_DATA_HISTORY:
            return {
                ...state,
                DataHistory: action.DataHistory
            }
        case customerTypes.GETTING_HISTORY:
            return {
                ...state,
                WaitForHistory: true,
                HistoryDocument: null
            }
        case customerTypes.GET_HISTORY:
            return {
                ...state,
                WaitForHistory: false,
                HistoryDocument: action.DocumentHistory
            }
        case customerTypes.DOWNLOADING_HISTORY:
            return {
                ...state,
                WaitForHistoryDownload: true
            }
        case customerTypes.HISTORY_DOWNLOADED:
            return {
                ...state,
                WaitForHistoryDownload: false
            }
        case customerTypes.CUSTOMER_HISTORY_MODAL:
            return {
                ...state,
                HistoryModal: action.HistoryModal,
                SelectedRow: action.SelectedRow
            }


        //----------------- DATA HISTORY ----------------- 

        //----------- CUSTOMER ACCOUNT NUMBERS MODAL ------------
        case customerTypes.SHOW_CUSTOMER_ACCOUNT_NUMBERS_MODAL:
            return {
                ...state,
                ShowAccountNumbersModal: true,
                SelectedRow: action.SelectedRow,
            }
        case customerTypes.HIDE_CUSTOMER_ACCOUNT_NUMBERS_MODAL:
            return {
                ...state,
                ShowAccountNumbersModal: false,
                SelectedRow: null,
                AccountCustomerNumbersSaved: action.AccountCustomerNumbersSaved
            }
        //----------- CUSTOMER ACCOUNT NUMBERS MODAL ------------

        case customerTypes.CUSTOMER_ERROR:
            return {
                ...state,
                WaitForCustomers: false,
                CustomerNotFound: false,
                WaitForHistory: false,
                HistoryModal: false,
                NoConnection: true,
                WaitForSaveCustomerNumbers: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }

        default:
            return state;

    }
}