import React from 'react'
import CustomerTable from './CustomerTable/CustomerTable'
import './CustomerTables.scss'
const CustomerTables = () => {
    return (
        <div className="CustomerTables-Container">
            <CustomerTable />
        </div>
    )
}
export default CustomerTables;