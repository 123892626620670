import invoiceControlTypes from '../type/invoiceControl';
import { decreaseDate, increaseDate } from '../../core/dateStr';

const initialState = {
    WaitForInvoices: false,
    StartDate: decreaseDate(2),
    EndDate: increaseDate(4),
    Invoices: null,
    ExcelInvoices: null,
    WaitForExcelInvoices: false,
    ErrorHandler: {
        ErrorCode: -1,
        ErrorDescription: "",
    },
}
export default (state = initialState, action) => {
    switch (action.type) {
        case invoiceControlTypes.SET_DATE_FILTER:
            return {
                ...state,
                StartDate: action.StartDate,
                EndDate: action.EndDate
            }
        case invoiceControlTypes.GETTING_INVOICES:
            return {
                ...state,
                WaitForInvoices: true
            }
        case invoiceControlTypes.GET_INVOICES:
            return {
                ...state,
                WaitForInvoices: false,
                Invoices: action.Invoices
            }
        case invoiceControlTypes.INVOICE_ERROR:
            return {
                ...state,
                WaitForInvoices: false,
                // Invoices: null,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case invoiceControlTypes.GET_EXCEL_INVOICES:
            return {
                ...state,
                WaitForExcelInvoices: false,
                ExcelInvoices: action.ExcelInvoices,
            }
        case invoiceControlTypes.GETTING_EXCEL_INVOICES:
            return {
                ...state,
                WaitForExcelInvoices: true,
            }
        case invoiceControlTypes.INVOICE_NOT_FOUND:
            return {
                ...state,
                Invoices: null,
            }
        case invoiceControlTypes.EXCEL_INVOICE_ERROR:
            return {
                ...state,
                WaitForExcelInvoices: false,
                ExcelInvoices: null,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case invoiceControlTypes.DELETE_EXCEL_INVOICES:
            return {
                ...state,
                ExcelInvoices: null
            }
        default:
            return state;

    }
}

