import React, { useEffect } from 'react'
import './RebateRate.scss'
import { Button } from '@dfds-ui/react-components'
import { JustTable } from '../../../component/CommonUI/JustTable/JustTable'
import { RebateRateTableSchema, DummyCreator } from './RebateRateTableSchema'
import { RebateRateModalShow, setSelectedRate, deleteModalShow, getRates, deleteModalHide } from '../../../store/action/adminPanelAction'
import DeleteModal from '../../CommonUI/Modals/YesNoModal/YesNoModal';

import RebateRateModal from './Modal/Modal'
import { connect } from 'react-redux'
import {adminPagesLanguage, IdentityUserTableLanguage, rebateLanguage} from '../../../config/language'

const RebateRate = (props) => {
    const { display, RefreshRatesTable,RebateRateModalShow, setSelectedRate, ShowDeleteModal,
        WaitForDeleteRate, Language, deleteModalShow, deleteModalHide, Rates, WaitForRates,getRates } = props
    const JustRowContextMenu = [
        {
            Name: "editRate",
            Action: (Row) => {
                setSelectedRate(Row)
            }
        },
        // {
        //     Name: "deleteRate",
        //     Action: (Row) => {
        //         deleteModalShow(Row)
        //     }
        // }
    ]
    useEffect(() => {
        if (display) {
            getRates()
        }
    }, [display])

    useEffect(() => {
        if (RefreshRatesTable) {
            getRates()
        }
    }, [RefreshRatesTable])

    const JustAnimations = {
        CanvasWidth: 1063,
        WaitForRequest: WaitForRates
    }
    return (
        display &&
        <>
            <div className="RebateRate-Container">
                <div className="RebateRate-Header">
                    <h4 id="HeaderText">{adminPagesLanguage[Language].RebateRateManagement}</h4>
                </div>
                <div className="RebateRate-Content-Container">
                    <div className="RebateRate-Content-Header">
                        <Button
                            className="RebateRate-AddRate"
                            onClick={() => RebateRateModalShow()}
                            disabled={false}
                        >
                            {adminPagesLanguage[Language].NewRate}
                    </Button>
                    </div>
                    <div className="RebateRate-Table-Container">
                        <JustTable
                            JustSchema={RebateRateTableSchema(adminPagesLanguage[Language])}
                            JustData={Rates}
                            JustAnimations={JustAnimations}
                            JustContextFunctions={JustRowContextMenu}
                        />
                    </div>

                </div>
            </div>
            <RebateRateModal />
            <DeleteModal
                OpenModal={ShowDeleteModal}
                Title={rebateLanguage[Language].DeleteInvoiceModalTitle}
                Message={rebateLanguage[Language].DeleteInvoiceModalMessage}
                OnClose={() => deleteModalHide()}
                onYesClick={(e) => { deleteModalHide() }}
                waitForRequest={WaitForDeleteRate}
                ButtonYesText={rebateLanguage[Language].ButtonYes}
                ButtonNoText={rebateLanguage[Language].ButtonNo}
            />
        </>

    )
}
const mapStateToProps = (state) => {
    return {
        ShowDeleteModal: state.adminPanel.ShowDeleteModal,
        RefreshRatesTable:state.adminPanel.RefreshRatesTable,
        WaitForDeleteRate: state.adminPanel.WaitForDeleteRate,
        WaitForRates: state.adminPanel.WaitForRates,
        Rates: state.adminPanel.Rates,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { RebateRateModalShow, setSelectedRate, deleteModalShow, deleteModalHide, getRates })(RebateRate);