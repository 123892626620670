import React, { useState } from 'react'
import UserInput from '../CommonUI/UserInput/UserInput'
import { Account, Close } from '@dfds-ui/icons';
import SendButton from '../CommonUI/SpinButton/SpinButton';
import './ForgotPassword.scss';
import httpClient from "../../core/httpclient";
import "toasted-notes/src/styles.css"; // optional styles
import { Modal } from '@dfds-ui/modal'

import Error from '../CommonUI/ErrorMessage/ErrorMessage'
import Success from '../CommonUI/SuccessMessage/SuccessMessage'

import ProjectConfig from '../../config/config'

import { forgotPasswordLanguage } from '../../config/language'


const ForgotPassword = (props) => {
    const { ForgetPass_OnClose, Language } = props
    const language = Language;
    const [getInputState, setInputState] = useState({
        Validation: true,
        Input: "",
        waitForRequest: false,
    })
    const [ErrorMessage, setErrorMessage] = useState(null)
    const [SuccessMessage, setSuccessMessage] = useState(null)
    const onSend = () => {
        setErrorMessage(null)
        setSuccessMessage(null)
        if (getInputState.Input.trim() === "") {
            setInputState({ ...getInputState, Validation: !getInputState.Validation })
            return;
        }
        setInputState({ ...getInputState, waitForRequest: true })
        const endPoint = `${ProjectConfig.apiUrl}/user/sendpasswordlink`;
        httpClient.post(endPoint, JSON.stringify(getInputState.Input))
            .then(response => {
                setInputState({ ...getInputState, Input: "", waitForRequest: false })
                setSuccessMessage(forgotPasswordLanguage[language].SuccessMessage)
            })
            .catch(error => {
                setInputState({ ...getInputState, waitForRequest: false })
                setErrorMessage(error.message)
            })
    }
    const FormHeader = () => {
        return (
            <div className="FPFormHeader">
                <span className="FPFormTitle">{forgotPasswordLanguage[language].ForgotHeader}</span>
                <Close onClick={ForgetPass_OnClose} className="ModelCloseButton" />
            </div>
        )
    }
    const FormArea = () => {
        return (
            <div className="FPFormArea">
                <UserInput
                    className={getInputState.Validation ? "Valid" : "Invalid"}
                    isValid={getInputState.Validation}
                    name='userName'
                    imgComp={<Account />}
                    holder={forgotPasswordLanguage[language].UserNameHolder}
                    type='text'
                    onChange={(event) => {
                        setInputState({ ...getInputState, Input: event.target.value, Validation: true });
                        setErrorMessage(null)
                        setSuccessMessage(null)
                    }}
                    value={getInputState.Input}
                    onKeyPress={(event) => input_onKeyPress(event.key)}
                    ValidationMessage={forgotPasswordLanguage[language].ValidationMessage}
                />
            </div>
        )
    }
    const input_onKeyPress = (key) => {
        if (key === "Enter")
            onSend()
    }
    const FormFooter = () => {
        return (
            <div className="FPFormFooter">

                <SendButton
                    onClick={onSend}
                    disable={getInputState.Input}
                    ButtonLabel={forgotPasswordLanguage[language].ButtonText}
                    className="sendBtn"
                    waitForRequest={getInputState.waitForRequest}
                />
                <Error
                    ErrorMessage={ErrorMessage}
                />
                <Success
                    SuccessMessage={SuccessMessage}
                />
            </div>
        )
    }
    const calcHeight = () => {
        const formOffHeight = (getInputState.Validation ? 0 : 25);
        const formHeight = 180;
        return formHeight + formOffHeight;
    }
    return (
        <>
            <Modal
                className="ForgetPassModal"
                isOpen={true}
                onRequestClose={ForgetPass_OnClose}
                heading={<span className="headerTitle">{forgotPasswordLanguage[language].ForgotHeader}</span>}>

                <div style={{ height: calcHeight() + "px" }} className="ForgotPasswordForm">
                    {FormHeader()}
                    {FormArea()}
                    {FormFooter()}
                </div>

            </Modal>
        </>
    );
}

export default ForgotPassword;