import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { } from '../../store/action/rebateAction'
import './IncomingInvoice.scss'
import { ErrorModal } from '../CommonUI/Modals/ErrorModal';
import { rebateLanguage, incomingInvoiceLanguage } from '../../config/language';
import {
    showCheckIntegrateModal, hideCheckIntegrateModal, showIntegrateModal, hideIntegrateModal, getIncomingInvoices, showDeleteInvoiceModal,
    hideDeleteInvoiceModal, clearNetworkError, clearError, deleteInvoice, integrate, checkIntegrate, resetIncomingInvoicePage, okModalClose
} from '../../store/action/incomingInvoiceAction';
import IncomingInvoiceHeader from './IncomingInvoiceHeader/IncomingInvoiceHeader';
import IncomingInvoiceTable from './IncomingInvoiceTable/IncomingInvoiceTable';
import NewInvoiceModal from './Modals/NewInvoiceModal/NewInvoiceModal';
import EditInvoiceModal from './Modals/EditInvoiceModal/EditInvoiceModal';

import DeleteModal from '../CommonUI/Modals/YesNoModal/YesNoModal';
import IntegrateModal from '../CommonUI/Modals/YesNoModal/YesNoModal';
import CheckIntegrateModal from '../CommonUI/Modals/YesNoModal/YesNoModal';

const IncomingInvoice = (props) => {

    const { DeleteInvoiceQuestion, deleteInvoice, WaitForDeleteInvoice, ErrorHandler,
        clearNetworkError, clearError, Language, SelectedInvoice, showDeleteInvoiceModal, hideDeleteInvoiceModal, showIntegrateModal,
        hideIntegrateModal, _DeleteModal, _IntegrateModal, integrate, WaitForIntegrate,
        showCheckIntegrateModal, RefreshPage, Year, StartMonth, EndMonth, CustomerName, CustomerNumber, getIncomingInvoices,
        hideCheckIntegrateModal, _CheckIntegrateModal, checkIntegrate, WaitForCheckIntegrate, resetIncomingInvoicePage, okModalClose, Integrated, IntegratedCheck } = props;
    useEffect(() => {
        if (RefreshPage) {
            setTimeout(() => {
                getIncomingInvoices(StartMonth, EndMonth, Year, CustomerNumber, CustomerName)
            }, 500)
        }
    }, [RefreshPage])
    useEffect(() => {
        return () => {
            resetIncomingInvoicePage()
        }
    }, [])
    const ErrorCode = ErrorHandler && ErrorHandler.ErrorCode;
    const ErrorDescription = ErrorHandler && ErrorHandler.ErrorDescription
    const ErrorState = ErrorHandler && ErrorHandler.ErrorState

    const _onDeleteInvoiceClick = () => {
        deleteInvoice(SelectedInvoice.sourceInvoiceId)
    }

    const _onIntegrateClick = () => {
        integrate(SelectedInvoice.sourceInvoiceId)
    }

    const _onCheckIntegrateClick = () => {
        checkIntegrate(SelectedInvoice.sourceInvoiceId)
    }

    return (
        <div className="IncomingInvoice-Container">
            <IncomingInvoiceHeader />
            <IncomingInvoiceTable />
            <NewInvoiceModal />
            <EditInvoiceModal />
            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ButtonLabel={rebateLanguage[Language].GoBackButtonText}
                    ErrorTitle={rebateLanguage[Language].ErrorTitle}
                    ErrorMessage={ErrorDescription}
                    OnClose={() => {
                        switch (ErrorState) {
                            case "NETWORK_ERROR":
                                clearNetworkError()
                                break;
                            case "CUSTOM_ERROR":
                                clearError()
                                break;
                        }
                    }}
                />
            }
            {
                (Integrated) &&
                <ErrorModal
                    ButtonLabel={rebateLanguage[Language].GoBackButtonText}
                    ErrorTitle={incomingInvoiceLanguage[Language].SuccessTitle}
                    ErrorMessage={incomingInvoiceLanguage[Language].IntegratedSuccess}
                    OnClose={() => { okModalClose() }}
                />
            }
            {
                (IntegratedCheck) &&
                <ErrorModal
                    ButtonLabel={rebateLanguage[Language].GoBackButtonText}
                    ErrorTitle={incomingInvoiceLanguage[Language].SuccessTitle}
                    ErrorMessage={incomingInvoiceLanguage[Language].IntegratedCheckSuccess}
                    OnClose={() => { okModalClose() }}
                />
            }
            {
                _DeleteModal &&
                <DeleteModal
                    OpenModal={true}
                    Title={rebateLanguage[Language].DeleteInvoiceModalTitle}
                    Message={rebateLanguage[Language].DeleteInvoiceModalMessage}
                    OnClose={() => hideDeleteInvoiceModal()}
                    onYesClick={(e) => { _onDeleteInvoiceClick() }}
                    waitForRequest={WaitForDeleteInvoice}
                    ButtonYesText={rebateLanguage[Language].ButtonYes}
                    ButtonNoText={rebateLanguage[Language].ButtonNo}
                />
            }
            {
                _IntegrateModal &&
                <IntegrateModal
                    OpenModal={true}
                    Title={rebateLanguage[Language].IntegrateModalTitle}
                    Message={rebateLanguage[Language].IntegrateModalMessage}
                    OnClose={() => hideIntegrateModal()}
                    onYesClick={(e) => { _onIntegrateClick() }}
                    waitForRequest={WaitForIntegrate}
                    ButtonYesText={rebateLanguage[Language].ButtonYes}
                    ButtonNoText={rebateLanguage[Language].ButtonNo}
                />
            }
            {
                _CheckIntegrateModal &&
                <CheckIntegrateModal
                    OpenModal={true}
                    Title={rebateLanguage[Language].CheckIntegrateModalTitle}
                    Message={rebateLanguage[Language].CheckIntegrateModalMessage}
                    OnClose={() => hideCheckIntegrateModal()}
                    onYesClick={(e) => { _onCheckIntegrateClick() }}
                    waitForRequest={WaitForCheckIntegrate}
                    ButtonYesText={rebateLanguage[Language].ButtonYes}
                    ButtonNoText={rebateLanguage[Language].ButtonNo}
                />
            }

        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        ErrorHandler: state.incomingInvoice.ErrorHandler,
        Language: state.user.Language,
        SelectedInvoice: state.incomingInvoice.SelectedInvoice,
        RefreshPage: state.incomingInvoice.RefreshPage,

        Year: state.incomingInvoice.Year,
        StartMonth: state.incomingInvoice.StartMonth,
        EndMonth: state.incomingInvoice.EndMonth,
        CustomerName: state.incomingInvoice.CustomerName,
        CustomerNumber: state.incomingInvoice.CustomerNumber,

        _IntegrateModal: state.incomingInvoice.IntegrateModal,
        _CheckIntegrateModal: state.incomingInvoice.CheckIntegrateModal,
        _DeleteModal: state.incomingInvoice.DeleteModal,
        //DeleteInvoiceQuestion: state.incomingInvoice.DeleteInvoiceQuestion,
        WaitForDeleteInvoice: state.incomingInvoice.WaitForDeleteInvoice,
        WaitForIntegrate: state.incomingInvoice.WaitForIntegrate,
        WaitForCheckIntegrate: state.incomingInvoice.WaitForCheckIntegrate,
        Integrated: state.incomingInvoice.Integrated,
        IntegratedCheck: state.incomingInvoice.IntegratedCheck,


    }
}
export default connect(mapStateToProps, {
    clearNetworkError, clearError, showDeleteInvoiceModal, hideDeleteInvoiceModal, showIntegrateModal,
    hideIntegrateModal, deleteInvoice, integrate, checkIntegrate, showCheckIntegrateModal, hideCheckIntegrateModal, getIncomingInvoices, resetIncomingInvoicePage, okModalClose
})(IncomingInvoice);
