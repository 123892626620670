import adminPanelTypes from '../type/adminPanel';
import moment from 'moment';
const initialState = {
    ShowIdentityUserModal: false,

    RefreshUsersTable: false,
    WaitForUsers: false,
    WaitForUserRoles: false,
    WaitForSaveUser: false,
    WaitForDeleteUser: false,
    ErrorHandler: {
        ErrorCode: -1,
        ErrorDescription: "",
    },
    Users: [],
    Roles: [],
    SelectedUser: null,
    ShowDeleteModal: false,

    IdentityUsername: null,
    IdentityName: null,
    IdentitySurname: null,
    IdentityEmail: null,
    IdentityRole: null,
    IdentityStatus: null,
    IdentityCompanyCode: null,
    IdentityDepartment: null,
    IdentityPassword: null,

    ////tariff

    ShowTariffModal: false,
    RefreshTariffsTable: false,
    TariffAgreementNumber: null,
    TariffNumber: null,
    TariffStartDate: null,
    TariffEndDate: null,
    TariffStartDateValidation: false,
    TariffEndDateValidation: false,

    WaitForTariffs: false,
    WaitForSaveTariff: false,
    WaitForDeleteTariff: false,
    Tariffs: [],
    SelectedTariff: null,
    ShowDeleteTariffModal: false,

    //RebateRate
    ShowRebateRateModal: false,
    RefreshRatesTable: false,
    RebateRateId: null,
    RebateRateName: null,
    RebateRateRatio: null,

    WaitForRates: false,
    WaitForSaveRates: false,
    Rates: [],
    SelectedRate: null,
    //RebateRate


    //Accounting Vessel
    ShowAccountingVesselModal: false,
    SelectedAccountingVessel: null,
    AccountingCode: null,
    AccountingVesselCode: null,
    AccountingVesselId: null,
    AccountingVesselName: null,
    AccountingVesselRented: false,
    WaitForAccountingVessel: false,
    AccountingVessels: null,
    ShowAccountingVesselDeleteModal: false,
    WaitForDeleteVessel: false,
    RefreshAccountingVesselPage: false,

    //Accounting Vessel

    //Accounting Voyage Start

    ShowAccountingVoyageModal: false,
    SelectedAccountingVoyage: null,
    AccountingVoyageName: null,
    AccountingVoyageCode: null,
    AccountingVoyageAccountName: null,
    AccountingVoyageAccountId : null,
    AccountingVoyageRentCode: null,
    AccountingVoyageRentName: null,
    AccountingVoyageGroupName: null,
    WaitForAccountingVoyage: false,
    WaitForSaveAccountingVoyage: false,
    AccountingVoyages: null,
    RefreshAccountingVoyagePage: false,

    //Accounting Voyage End

    //Unit Type
    ShowUnitTypeModal: false,
    RefreshUnitTypeTable: false,
    WaitForUnitTypes: false,
    WaitForSaveUniteType: false,
    WaitForDeleteUnitType: false,
    UniteTypes: [],
    SelectedUnitType: {
        id: -1,
        name: null,
        lineType: null,
        isEmpty: 0,
        isValid: false
    },
    ShowDeleteUnitTypeModal: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case adminPanelTypes.IDENTITY_USER_MODAL_SHOW:
            return {
                ...state,
                ShowIdentityUserModal: true
            }
        case adminPanelTypes.IDENTITY_USER_MODAL_HIDE:
            return {
                ...state,
                ShowIdentityUserModal: false,
                SelectedUser: null,
                IdentityUsername: null,
                IdentityName: null,
                IdentitySurname: null,
                IdentityEmail: null,
                IdentityRole: null,
                IdentityStatus: null,
                IdentityCompanyCode: null,
                IdentityDepartment: null,
                IdentityPassword: null,

            }
        case adminPanelTypes.DELETE_USER_MODAL_SHOW:
            return {
                ...state,
                ShowDeleteModal: true,
                SelectedUser: action.SelectedUser,
                IdentityUsername: action.IdentityUsername,
                IdentityName: action.IdentityName,
                IdentitySurname: action.IdentitySurName,
                IdentityEmail: action.IdentityEmail,
                IdentityRole: action.IdentityRole,
                IdentityStatus: action.IdentityStatus,
                IdentityCompanyCode: action.IdentityCompanyCode,
                IdentityDepartment: action.IdentityDepartment,
                IdentityPassword: action.IdentityPassword,
            }
        case adminPanelTypes.DELETE_USER_MODAL_HIDE:
            return {
                ...state,
                ShowDeleteModal: false,
                WaitForDeleteUser: false,
                SelectedUser: action.SelectedUser,
            }
        case adminPanelTypes.SET_SELECTED_USER:
            return {
                ...state,

                SelectedUser: action.SelectedUser,
                ShowIdentityUserModal: true,
                IdentityUsername: action.IdentityUsername,
                IdentityName: action.IdentityName,
                IdentitySurname: action.IdentitySurname,
                IdentityEmail: action.IdentityEmail,
                IdentityRole: action.IdentityRole,
                IdentityStatus: action.IdentityStatus,
                IdentityCompanyCode: action.IdentityCompanyCode,
                IdentityDepartment: action.IdentityDepartment,
                IdentityPassword: action.IdentityPassword,

            }
        case adminPanelTypes.DELETING_USER:
            return {
                ...state,
                WaitForDeleteUser: false,
            }
        case adminPanelTypes.DELETE_USER:
            return {
                ...state,
                WaitForDeleteUser: true,
                RefreshUsersTable: true,
                ShowDeleteModal: false,
            }

        case adminPanelTypes.DELETE_USER_ERROR:
            return {
                ...state,
                WaitForDeleteUser: false,
                ShowDeleteModal: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SAVING_USER:
            return {
                ...state,
                WaitForSaveUser: true
            }
        case adminPanelTypes.SAVE_USER:
            return {
                ...state,
                RefreshUsersTable: true,
                WaitForSaveUser: false,
                ShowIdentityUserModal: false,
                IdentityUsername: null,
                IdentityName: null,
                IdentitySurname: null,
                IdentityEmail: null,
                IdentityRole: null,
                IdentityStatus: null,
                IdentityCompanyCode: null,
                IdentityDepartment: null,
                IdentityPassword: null,
                SelectedUser: null,

            }
        case adminPanelTypes.SAVE_USER_ERROR:
            return {
                ...state,
                WaitForSaveUser: false,

                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.GETTING_USERS:
            return {
                ...state,
                WaitForUsers: true,
                RefreshUsersTable: false,
            }
        case adminPanelTypes.GET_USERS:
            return {
                ...state,
                WaitForUsers: false,
                Users: action.Users
            }
        case adminPanelTypes.GET_USERS_ERROR:
            return {
                ...state,
                WaitForUsers: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.GETTING_USER_ROLES:
            return {
                ...state,
                WaitForUserRoles: true
            }
        case adminPanelTypes.GET_USER_ROLES:
            return {
                ...state,
                WaitForUserRoles: false,
                Roles: action.Roles,
                IdentityRole: action.IdentityRole
            }
        case adminPanelTypes.GET_USER_ROLES_ERROR:
            return {
                ...state,
                WaitForUserRoles: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SET_IDENTITY_USER_USER_NAME:
            return {
                ...state,
                ShowIdentityUserModal: true,
                IdentityUsername: action.IdentityUsername,
            }
        case adminPanelTypes.SET_IDENTITY_USER_NAME:
            return {
                ...state,
                ShowIdentityUserModal: true,
                IdentityName: action.IdentityName,
            }
        case adminPanelTypes.SET_IDENTITY_USER_SURNAME:
            return {
                ...state,
                ShowIdentityUserModal: true,
                IdentitySurname: action.IdentitySurname,
            }
        case adminPanelTypes.SET_IDENTITY_USER_EMAIL:
            return {
                ...state,
                ShowIdentityUserModal: true,
                IdentityEmail: action.IdentityEmail,
            }
        case adminPanelTypes.SET_IDENTITY_USER_ROLE:
            return {
                ...state,
                ShowIdentityUserModal: true,
                IdentityRole: action.IdentityRole,

            }
        case adminPanelTypes.SET_IDENTITY_USER_STATUS:
            return {
                ...state,
                ShowIdentityUserModal: true,
                IdentityStatus: action.IdentityStatus,

            }
        case adminPanelTypes.SET_IDENTITY_USER_COMPANY_CODE:
            return {
                ...state,
                ShowIdentityUserModal: true,
                IdentityCompanyCode: action.IdentityCompanyCode,

            }
        case adminPanelTypes.SET_IDENTITY_USER_DEPARTMENT:
            return {
                ...state,
                ShowIdentityUserModal: true,
                IdentityDepartment: action.IdentityDepartment,

            }
        case adminPanelTypes.SET_IDENTITY_USER_PASSWORD:
            return {
                ...state,
                ShowIdentityUserModal: true,
                IdentityPassword: action.IdentityPassword,

            }




        //Tariffs start
        case adminPanelTypes.TARIFF_MODAL_SHOW:
            return {
                ...state,
                ShowTariffModal: true
            }
        case adminPanelTypes.TARIFF_MODAL_HIDE:
            return {
                ...state,
                ShowTariffModal: false,
                TariffAgreementNumber: null,
                TariffNumber: null,
                TariffStartDate: null,
                TariffEndDate: null,
                TariffStartDateValidation: false,
                TariffEndDateValidation: false,
                SelectedTariff: null,
            }
        case adminPanelTypes.DELETE_TARIFF_MODAL_SHOW:
            return {
                ...state,
                SelectedTariff: action.SelectedTariff,
                ShowDeleteTariffModal: true,
            }
        case adminPanelTypes.DELETE_TARIFF_MODAL_HIDE:
            return {
                ...state,
                ShowDeleteTariffModal: false,
                WaitForDeleteTariff: false,
                SelectedTariff : null,

            }
        case adminPanelTypes.SET_SELECTED_TARIFF:
            return {
                ...state,
                SelectedTariff: action.SelectedTariff,
                TariffAgreementNumber: action.TariffAgreementNumber,
                TariffNumber: action.TariffNumber,
                TariffStartDate: moment(action.TariffStartDate * 1000).toDate(),
                TariffEndDate: moment(action.TariffEndDate * 1000).toDate(),
                ShowTariffModal: true,
            }
        case adminPanelTypes.DELETING_TARIFF:
            return {
                ...state,
                WaitForDeleteTariff: true,
                RefreshTariffsTable : false,
            }
        case adminPanelTypes.DELETE_TARIFF:
            return {
                ...state,
                WaitForDeleteTariff: false,
                ShowDeleteTariffModal: false,
                RefreshTariffsTable : true,
                SelectedTariff : null,

            }

        case adminPanelTypes.DELETE_TARIFF_ERROR:
            return {
                ...state,
                WaitForDeleteTariff: false,
                ShowDeleteTariffModal: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SAVING_TARIFF:
            return {
                ...state,
                WaitForSaveTariff: true,
                RefreshTariffsTable: false
            }
        case adminPanelTypes.SAVE_TARIFF_ERROR:
            return {
                ...state,
                WaitForSaveTariff: false,

                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.GETTING_TARIFFS:
            return {
                ...state,
                WaitForTariffs: true
            }
        case adminPanelTypes.GET_TARIFFS:
            return {
                ...state,
                WaitForTariffs: false,
                Tariffs: action.Tariffs
            }
        case adminPanelTypes.GET_TARIFFS_ERROR:
            return {
                ...state,
                WaitForTariffs: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SET_TARIFF_AGREEMENT_NUMBER:
            return {
                ...state,
                TariffAgreementNumber: action.TariffAgreementNumber,
                ShowTariffModal: true,
            }
        case adminPanelTypes.SET_TARIFF_NUMBER:
            return {
                ...state,
                TariffNumber: action.TariffNumber,
                ShowTariffModal: true,
            }
        case adminPanelTypes.SET_TARIFF_START_DATE:
            return {
                ...state,
                TariffStartDate: action.TariffStartDate,
                ShowTariffModal: true,
            }
        case adminPanelTypes.SET_TARIFF_END_DATE:
            return {
                ...state,
                TariffEndDate: action.TariffEndDate,
                ShowTariffModal: true,
            }
        case adminPanelTypes.SET_TARIFF_START_DATE_VALIDATION:
            return {
                ...state,
                TariffStartDateValidation: action.Validation
            }
        case adminPanelTypes.SET_TARIFF_END_DATE_VALIDATION:
            return {
                ...state,
                TariffEndDateValidation: action.Validation
            }
        case adminPanelTypes.SET_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case adminPanelTypes.SET_NETWORK_ERROR:
            return {
                ...state,
                NoConnection: true,
                WaitForIncomingInvoices: false,
                NotFound: false,
                ErrorHandler: {
                    ErrorState: "NETWORK_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case adminPanelTypes.CLEAR_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "",
                    ErrorCode: -1,
                    ErrorDescription: ""
                }
            }
        case adminPanelTypes.CLEAR_NETWORK_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "",
                    ErrorCode: -1,
                    ErrorDescription: ""
                }
            }


        //Tariffs finish
        //RebateRate Start
        //Tariffs start
        case adminPanelTypes.TARIFF_MODAL_SHOW:
            return {
                ...state,
                ShowTariffModal: true
            }
        case adminPanelTypes.TARIFF_MODAL_HIDE:
            return {
                ...state,
                ShowTariffModal: false,
                TariffAgreementNumber: null,
                TariffNumber: null,
                TariffStartDate: null,
                TariffEndDate: null,
                TariffStartDateValidation: false,
                TariffEndDateValidation: false,
                SelectedTariff: null,
            }
        case adminPanelTypes.DELETE_TARIFF_MODAL_SHOW:
            return {
                ...state,
                SelectedTariff: action.SelectedTariff,
                ShowDeleteTariffModal: true,
            }
        case adminPanelTypes.DELETE_TARIFF_MODAL_HIDE:
            return {
                ...state,
                ShowDeleteTariffModal: false,
            }
        case adminPanelTypes.SET_SELECTED_TARIFF:
            return {
                ...state,
                SelectedTariff: action.SelectedTariff,
                TariffAgreementNumber: action.TariffAgreementNumber,
                TariffNumber: action.TariffNumber,
                TariffStartDate: moment(action.TariffStartDate * 1000).toDate(),
                TariffEndDate: moment(action.TariffEndDate * 1000).toDate(),
                ShowTariffModal: true,
            }
        case adminPanelTypes.DELETING_TARIFF:
            return {
                ...state,
                WaitForDeleteTariff: false,
            }
        case adminPanelTypes.DELETE_TARIFF:
            return {
                ...state,
                WaitForDeleteTariff: true,
                RefreshTariffsTable: true,
                ShowDeleteTariffModal: false,
            }

        case adminPanelTypes.DELETE_TARIFF_ERROR:
            return {
                ...state,
                WaitForDeleteTariff: false,
                ShowDeleteTariffModal: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SAVING_TARIFF:
            return {
                ...state,
                WaitForSaveTariff: true
            }
        case adminPanelTypes.SAVE_TARIFF:
            return {
                ...state,
                WaitForSaveTariff: false,
                RefreshTariffsTable: true,
                ShowTariffModal: false,
                TariffAgreementNumber: null,
                TariffNumber: null,
                TariffStartDate: null,
                TariffEndDate: null,
                TariffStartDateValidation: false,
                TariffEndDateValidation: false,
                SelectedTariff : null,

            }
        case adminPanelTypes.GETTING_TARIFFS:
            return {
                ...state,
                RefreshTariffsTable: false,
                WaitForTariffs: true
            }
        case adminPanelTypes.GET_TARIFFS:
            return {
                ...state,
                WaitForTariffs: false,
                Tariffs: action.Tariffs
            }
        case adminPanelTypes.GET_TARIFFS_ERROR:
            return {
                ...state,
                WaitForTariffs: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SET_TARIFF_AGREEMENT_NUMBER:
            return {
                ...state,
                TariffAgreementNumber: action.TariffAgreementNumber,
                ShowTariffModal: true,
            }
        case adminPanelTypes.SET_TARIFF_NUMBER:
            return {
                ...state,
                TariffNumber: action.TariffNumber,
                ShowTariffModal: true,
            }
        case adminPanelTypes.SET_TARIFF_START_DATE:
            return {
                ...state,
                TariffStartDate: action.TariffStartDate,
                ShowTariffModal: true,
            }
        case adminPanelTypes.SET_TARIFF_END_DATE:
            return {
                ...state,
                TariffEndDate: action.TariffEndDate,
                ShowTariffModal: true,
            }
        case adminPanelTypes.SET_TARIFF_START_DATE_VALIDATION:
            return {
                ...state,
                TariffStartDateValidation: action.Validation
            }
        case adminPanelTypes.SET_TARIFF_END_DATE_VALIDATION:
            return {
                ...state,
                TariffEndDateValidation: action.Validation
            }
        case adminPanelTypes.SET_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case adminPanelTypes.SET_NETWORK_ERROR:
            return {
                ...state,
                NoConnection: true,
                WaitForIncomingInvoices: false,
                NotFound: false,
                ErrorHandler: {
                    ErrorState: "NETWORK_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case adminPanelTypes.CLEAR_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "",
                    ErrorCode: -1,
                    ErrorDescription: ""
                }
            }
        case adminPanelTypes.CLEAR_NETWORK_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "",
                    ErrorCode: -1,
                    ErrorDescription: ""
                }
            }


        //Tariffs finish
        //UnitType
        case adminPanelTypes.UNIT_TYPE_MODAL_SHOW:
            return {
                ...state,
                ShowUnitTypeModal: true
            }
        case adminPanelTypes.UNIT_TYPE_MODAL_HIDE:
            return {
                ...state,
                ShowUnitTypeModal: false,
                SelectedUnitType: {
                    id: -1,
                    name: null,
                    lineType: null,
                    isEmpty: false,
                    isValid: false

                },
            }
        case adminPanelTypes.DELETE_UNIT_TYPE_MODAL_SHOW:
            return {
                ...state,
                SelectedUnitType: action.SelectedUnitType,
                ShowDeleteUnitTypeModal: true,
            }
        case adminPanelTypes.DELETE_UNIT_TYPE_MODAL_HIDE:
            return {
                ...state,
                SelectedUnitType: {
                    id: -1,
                    name: null,
                    lineType: null,
                    isEmpty: false,
                    isValid: false

                },
                ShowDeleteUnitTypeModal: false,
            }
        case adminPanelTypes.SET_SELECTED_UNIT_TYPE:
            return {
                ...state,
                SelectedUnitType: action.SelectedUnitType,
                ShowUnitTypeModal: true,
            }
        case adminPanelTypes.DELETING_UNIT_TYPE:
            return {
                ...state,
                WaitForDeleteUnitType: true,
            }
        case adminPanelTypes.DELETE_UNIT_TYPE:
            return {
                ...state,
                WaitForDeleteUnitType: false,
                ShowDeleteUnitTypeModal: false,
                RefreshUnitTypeTable: true,
                SelectedUnitType: {
                    id: -1,
                    name: null,
                    lineType: null,
                    isEmpty: false,
                    isValid: false

                },
            }
        case adminPanelTypes.DELETE_UNIT_TYPE_ERROR:
            return {
                ...state,
                WaitForDeleteUnitType: false,
                ShowDeleteUnitTypeModal: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SAVING_UNIT_TYPE:
            return {
                ...state,
                WaitForSaveUnitType: true,
            }
        case adminPanelTypes.SAVE_UNIT_TYPE:
            return {
                ...state,
                WaitForSaveUnitType: false,
                RefreshUnitTypeTable: true,
                ShowUnitTypeModal: false,
                SelectedUnitType: {
                    id: -1,
                    name: null,
                    lineType: null,
                    isEmpty: false,
                    isValid: false

                },
            }
        case adminPanelTypes.SAVE_UNIT_TYPE_ERROR:
            return {
                ...state,
                WaitForSaveUnitType: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.GETTING_UNIT_TYPES:
            return {
                ...state,
                WaitForUnitTypes: true,
                RefreshUnitTypeTable: false,

            }
        case adminPanelTypes.GET_UNIT_TYPES:
            return {
                ...state,
                WaitForUnitTypes: false,
                UnitTypes: action.UnitTypes
            }
        case adminPanelTypes.GET_UNIT_TYPES_ERROR:
            return {
                ...state,
                WaitForUnitTypes: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }

        //UnitType End
        //RebateRate Start
        case adminPanelTypes.REBATE_RATE_MODAL_SHOW:
            return {
                ...state,
                ShowRebateRateModal: true
            }
        case adminPanelTypes.REBATE_RATE_MODAL_HIDE:
            return {
                ...state,
                ShowRebateRateModal: false,
                RebateRateId: null,
                RebateRateName: null,
                RebateRateRatio: null,
                SelectedRate: null,
            }
        case adminPanelTypes.SET_SELECTED_RATE:
            return {
                ...state,
                SelectedRate: action.SelectedRate,
                RebateRateId: action.RebateRateId,
                RebateRateName: action.RebateRateName,
                RebateRateRatio: action.RebateRateRatio,
                ShowRebateRateModal: true,
            }
        case adminPanelTypes.SAVING_RATE:
            return {
                ...state,
                WaitForSaveRates: true
            }
        case adminPanelTypes.SAVE_RATE:
            return {
                ...state,
                RefreshRatesTable: true,
                WaitForSaveRates: false,
                ShowRebateRateModal: false,
                RebateRateId: null,
                RebateRateName: null,
                RebateRateRatio: null
            }
        case adminPanelTypes.SAVE_RATE_ERROR:
            return {
                ...state,
                WaitForSaveRates: false,

                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.GETTING_RATES:
            return {
                ...state,
                WaitForRates: true,
                RefreshRatesTable: false,
            }
        case adminPanelTypes.GET_RATES:
            return {
                ...state,
                WaitForRates: false,
                Rates: action.Rates
            }
        case adminPanelTypes.GET_RATES_ERROR:
            return {
                ...state,
                WaitForRates: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SET_REBATE_RATE_NAME:
            return {
                ...state,
                RebateRateName: action.RebateRateName,
                ShowRebateModal: true,
            }
        case adminPanelTypes.SET_REBATE_RATE_RATIO:
            return {
                ...state,
                RebateRateRatio: action.RebateRateRatio,
                ShowRebateModal: true,
            }

        //RebateRate End

        //ACCOUNTING VESSEL START

        case adminPanelTypes.ACCOUNTING_VESSEL_MODAL_SHOW:
            return {
                ...state,
                ShowAccountingVesselModal: true,
            }
        case adminPanelTypes.ACCOUNTING_VESSEL_MODAL_HIDE:
            return {
                ...state,
                ShowAccountingVesselModal: false,
                SelectedAccountingVessel: null,
                AccountingCode: null,
                AccountingVesselCode: null,
                AccountingVesselName: null,
                AccountingVesselRented: false,
                WaitForAccountingVessel: false,
            }
        case adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL:
            return {
                ...state,
                SelectedAccountingVessel: action.SelectedAccountingVessel,
                ShowAccountingVesselModal: true
            }
        case adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL_CODE:
            return {
                ...state,
                AccountingVesselCode: action.VesselCode,
            }
        case adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL_ID:
            return {
                ...state,
                AccountingVesselId: action.VesselId,
            }
        case adminPanelTypes.SET_SELECTED_ACCOUNTING_CODE:
            return {
                ...state,
                AccountingCode: action.AccountingCode,
            }
        case adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL_NAME:
            return {
                ...state,
                AccountingVesselName: action.VesselName,
            }
        case adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL_RENTED:
            return {
                ...state,
                AccountingVesselRented: action.AccountingVesselRented
            }

        case adminPanelTypes.GETTING_ACCOUNTING_VESSEL:
            return {
                ...state,
                WaitForAccountingVessel: true,
                RefreshAccountingVesselPage: false,
            }
        case adminPanelTypes.GET_ACCOUNTING_VESSEL:
            return {
                ...state,
                WaitForAccountingVessel: false,
                AccountingVessels: action.AccountingVessels
            }
        case adminPanelTypes.GET_ACCOUNTING_VESSEL_ERROR:
            return {
                ...state,
                WaitForAccountingVessel: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SAVING_VESSEL:
            return {
                ...state,
                WaitForSaveVessel: true,
            }
        case adminPanelTypes.SAVE_VESSEL:
            return {
                ...state,
                ShowAccountingVesselModal: false,
                WaitForSaveVessel: false,
                SelectedAccountingVessel: null,
                RefreshAccountingVesselPage: true,
            }
        case adminPanelTypes.SAVE_VESSEL_ERROR:
            return {
                ...state,
                WaitForSaveVessel: false,
                SelectedAccountingVessel: null,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.ACCOUNTING_VESSEL_DELETE_MODAL_SHOW:
            return {
                ...state,
                ShowAccountingVesselDeleteModal: true,
                AccountingVesselId: action.AccountingVesselId

            }
        case adminPanelTypes.ACCOUNTING_VESSEL_DELETE_MODAL_HIDE:
            return {
                ...state,
                ShowAccountingVesselDeleteModal: false,
                WaitForDeleteVessel: false,
            }
        case adminPanelTypes.DELETING_VESSEL:
            return {
                ...state,
                WaitForDeleteVessel: true,
            }
        case adminPanelTypes.DELETE_VESSEL:
            return {
                ...state,
                WaitForDeleteVessel: false,
                ShowAccountingVesselDeleteModal: false,
                RefreshAccountingVesselPage: true,
            }
        case adminPanelTypes.DELETE_VESSEL_ERROR:
            return {
                ...state,
                WaitForDeleteVessel: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }

        //Account Vessel End

        //ACCOUNT VOYAGE START

        case adminPanelTypes.ACCOUNTING_VOYAGE_MODAL_SHOW:
            return {
                ...state,
                ShowAccountingVoyageModal: true
            }
        case adminPanelTypes.ACCOUNTING_VOYAGE_MODAL_HIDE:
            return {
                ...state,
                ShowAccountingVoyageModal: false,
                SelectedAccountingVoyage: null,
                AccountingVoyageName: null,
                AccountingVoyageCode: null,
                AccountingVoyageAccountName: null,
                AccountingVoyageAccountId : null,
                AccountingVoyageRentCode: null,
                AccountingVoyageRentName: null,
                AccountingVoyageGroupName: null,
            }
        case adminPanelTypes.SET_SELECTED_ACCOUNTING_VOYAGE:
            return {
                ...state,
                ShowAccountingVoyageModal: true,
                SelectedAccountingVoyage: action.SelectedAccountingVoyage,
                AccountingVoyageName: action.SelectedAccountingVoyage.name,
                AccountingVoyageCode: action.SelectedAccountingVoyage.accountingCode,
                AccountingVoyageAccountName: action.SelectedAccountingVoyage.accountingName,
                AccountingVoyageAccountId : action.SelectedAccountingVoyage.accountingVoyageId,
                AccountingVoyageRentCode: action.SelectedAccountingVoyage.accountingRentCode,
                AccountingVoyageRentName: action.SelectedAccountingVoyage.accountingRentName,
                AccountingVoyageGroupName: action.SelectedAccountingVoyage.groupName,
            }
        case adminPanelTypes.SET_ACCOUNTING_VOYAGE_NAME:
            return {
                ...state,
                AccountingVoyageName: action.AccountingVoyageName
            }
        case adminPanelTypes.SET_ACCOUNTING_VOYAGE_CODE:
            return {
                ...state,
                AccountingVoyageCode: action.AccountingVoyageCode
            }
        case adminPanelTypes.SET_ACCOUNTING_VOYAGE_ACCOUNT_NAME:
            return {
                ...state,
                AccountingVoyageAccountName: action.AccountingVoyageAccountName
            }
            case adminPanelTypes.SET_ACCOUNTING_VOYAGE_ID:
                return {
                    ...state,
                    AccountingVoyageAccountId : action.AccountingVoyageAccountId,
        }
        case adminPanelTypes.SET_ACCOUNTING_VOYAGE_RENT_CODE:
            return {
                ...state,
                AccountingVoyageRentCode: action.AccountingVoyageRentCode
            }
        case adminPanelTypes.SET_ACCOUNTING_VOYAGE_RENT_NAME:
            return {
                ...state,
                AccountingVoyageRentName: action.AccountingVoyageRentName
            }
        case adminPanelTypes.SET_ACCOUNTING_VOYAGE_GROUP_NAME:
            return {
                ...state,
                AccountingVoyageGroupName: action.AccountingVoyageGroupName
            }
        case adminPanelTypes.GETTING_ACCOUNTING_VOYAGE:
            return {
                ...state,
                WaitForAccountingVoyage: true,
                RefreshAccountingVoyagePage: false
            }
        case adminPanelTypes.GET_ACCOUNTING_VOYAGE:
            return {
                ...state,
                WaitForAccountingVoyage: false,
                AccountingVoyages: action.AccountingVoyages
            }
        case adminPanelTypes.GET_ACCOUNTING_VOYAGE_ERROR:
            return {
                ...state,
                WaitForAccountingVoyage: false,

                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.SAVING_ACCOUNTING_VOYAGE:
            return {
                ...state,
                WaitForSaveAccountingVoyage: true,
            }

        case adminPanelTypes.SAVE_ACCOUNTING_VOYAGE:
            return {
                ...state,
                WaitForSaveAccountingVoyage: false,
                ShowAccountingVoyageModal: false,
                RefreshAccountingVoyagePage: true,
            }
        case adminPanelTypes.SAVE_ACCOUNTING_VOYAGE_ERROR:
            return {
                ...state,
                WaitForSaveAccountingVoyage: false,
                ShowAccountingVoyageModal: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }
        case adminPanelTypes.ACCOUNTING_VOYAGE_DELETE_MODAL_SHOW:
            return {
                ...state,
                ShowAccountingVoyageDeleteModal: true,
                AccountingVoyageAccountName: action.AccountingVoyageAccountName
            }
        case adminPanelTypes.ACCOUNTING_VOYAGE_DELETE_MODAL_HIDE:
            return {
                ...state,
                ShowAccountingVoyageDeleteModal: false,
                WaitForDeleteVoyage: false,
            }
        case adminPanelTypes.DELETING_VOYAGE:
            return {
                ...state,
                WaitForDeleteVoyage: true,
            }
        case adminPanelTypes.DELETE_VOYAGE:
            return {
                ...state,
                WaitForDeleteVoyage: false,
                ShowAccountingVoyageDeleteModal: false,
                RefreshAccountingVoyagePage: true,
            }
        case adminPanelTypes.DELETE_VOYAGE_ERROR:
            return {
                ...state,
                WaitForDeleteVoyage: false,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription,
                },
            }

        //ACCOUNT VOYAGE END
        default:
            return state;

    }
}

