import React from 'react'
import { Lock, Delete, Edit, LockOpen, YesTick, Close, LoadingSpinner, Document } from '@dfds-ui/icons'
import moment from 'moment'

export const AccountingVoyageSchema = (schemaLanguage) => {
    return {
        rowId: "code", //unique ID
        RowContextMenu: [
            {
                Text: schemaLanguage.Edit,//"Edit Accounting Voyage",
                Icon: <Edit />,
                Name: "editVoyage",
            },
            {
                Text: schemaLanguage.Delete,//"Delete Accounting Voyage",
                Icon: <Delete />,
                Name: "deleteVoyage",
            },
        ],
        ColumnDesign: [
            {
                columnId: "accountVoyageId",
                Text: "ID",//"Name",
            },
            {
                columnId: "name",
                Text: schemaLanguage.Name,//"Name",
            },
            {
                columnId: "accountingCode",
                Text: schemaLanguage.AccountingCode//"Accounting Code"
            },
            {
                columnId: "accountingName",
                Text: schemaLanguage.AccountingName//"Accounting Name",
            },
            {
                columnId: "accountingRentCode",
                Text: schemaLanguage.AccountingRentCode,//"Accounting Rent Code",
            },
            {
                columnId: "accountingRentName",
                Text: schemaLanguage.AccountingRentName//"Accounting Rent Name",
            }
            ,
            {
                columnId: "groupName",
                Text: schemaLanguage.GroupName//"Group Name",
            }
        ],
        DataDesign: [
            {
                columnId: "name",
            },
            {
                columnId: "accountingCode",
            },

            {
                columnId: "accountingName",
            },
            {
                columnId: "accountingRentCode",
            },
            {
                columnId: "accountingRentName",
            },
            {
                columnId: "groupName",
            },
        ]
    }
}
const isRentedIcon = (data) => {
    if (!data) {
        return <Close style={{ color: "#be1e2d" }} />
    } else {
        return <YesTick style={{ color: "#4caf50" }} />
    }
}

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
export const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 10; index++) {
        let myObject = {}
        myObject.id = makeid(2)
        myObject.name = makeid(8)
        myObject.ratio = makeid(3)
        DummyArray.push(myObject)
    }
    return DummyArray
}

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
