import rebateTypes from "../type/rebate";
const initialState = {
  Transactions: null,
  Invoices: null,
  WaitForInvoices: false,
  WaitForTransactions: false,
  RebateNotFound: false,
  NoConnection: false,
  WaitForSaveTransaction: false,
  UpdateHistory: null,
  ErrorHandler: {
    ErrorCode: -1,
    ErrorDescription: "",
  },
  // StartMonth: null,
  // EndMonth: null,
  // TransactionYear: null,
  CustomerName: null,
  CustomerCode: null,
  WaitForProcessTransaction: false,
  WaitForCancelTransaction: false,
  WaitForProcessCalcelTransaction: false,
  ProcessQuestion: false,
  ProcessModalVisibility: false,
  ProcessProcessing: false,
  DataHistory: [],
  WaitForCalculateTransaction: false,
  CalculatedTransaction: {},
  CalculatedTransactionIndex: -1,
  ExcelModal: false,
  FileDownloadStatus: false,
  Statusname: "",
  ShowHistoryModal: false,
  ForwardAction: null,
  ProcessSuccess: false,
  SelectedTransactions: [],
  ProcessAll: "",
  ServerExcelDownloadStatus: "",
  ProcessStatus: "",
  ExcelIntervalId: null,
  ProcessIntervalId: null,

  ProcessProcessingIntervalId: null,
  ProcessProcessingLock: false,

  IntegrationExcelQuestion: false,
  WaitForIntegrationExcel: false,
  IntegrationExcelIntervalId: null,
  IntegrationExcelStatus: "",

  InvoiceRebateQuestion: false,
  WaitForInvoiceRebate: false,
  InvoiceRebateIntervalId: null,
  InvoiceRebateStatus: "",

  TransactionYear: new Date().getFullYear(),
  EndMonth: new Date().getMonth() + 1,
  StartMonth: new Date().getMonth() + 1,

  ExcelTransactionIds: null,
  ExcelFilename: "",
  ExcelTransactionYear: null,
  ExcelStartMonth: null,
  ExcelEndMonth: null,

  EmailStatus: null,

  IntervalWaitTime: 15000, //15sec

  PDFIntervalId: null,
  PDFStatus: "",

  pdfId: null,
  pdfName: "",
};
export default (state = initialState, action) => {
  switch (action.type) {
    case rebateTypes.SET_START_MONTH:
      return {
        ...state,
        StartMonth: action.StartMonth,
      };

    case rebateTypes.SET_END_MONTH:
      return {
        ...state,
        EndMonth: action.EndMonth,
      };
    case rebateTypes.SET_TRANSACTION_YEAR:
      return {
        ...state,
        TransactionYear: action.TransactionYear,
      };
    case rebateTypes.SET_EMAIL_STATUS:
      return {
        ...state,
        EmailStatus: action.EmailStatus,
      };
    case rebateTypes.GET_TRANSACTIONS:
      return {
        ...state,
        Transactions: action.Transactions,
        UpdateHistory: null,
        WaitForTransactions: false,
        NoConnection: false,
        SelectedTransactions: [],
        DataHistory: [],
      };
    case rebateTypes.GETTING_TRANSACTIONS:
      return {
        ...state,
        WaitForTransactions: true,
        WaitForProcessTransaction: false,
        UpdateHistory: null,
        WaitForInvoices: true,
        NoConnection: false,
        Invoices: [],
        Transactions: [],
      };
    case rebateTypes.SORT_TRANSACTIONS:
      return {
        ...state,
        Transactions: action.Transactions,
      };
    case rebateTypes.GETTING_INVOICES:
      return {
        ...state,
        // WaitForTransactions: false,
        WaitForProcessTransaction: false,

        WaitForInvoices: true,
        NoConnection: false,
      };
    case rebateTypes.GET_INVOICES:
      return {
        ...state,
        Invoices: action.Invoices,
        WaitForTransactions: false,
        WaitForProcessTransaction: false,

        WaitForInvoices: false,
        NoConnection: false,
      };
    case rebateTypes.EXCEL_REQUEST_STARTED:
      return {
        ...state,
        // ServerExcelDownloadStatus: "",
        FileDownloadStatus: true,

        ExcelTransactionIds: action.TransactionIds,
        ExcelFilename: action.Filename,
        ExcelTransactionYear: action.TransactionYear,
        ExcelStartMonth: action.StartMonth,
        ExcelEndMonth: action.EndMonth,
        IntervalWaitTime: action.IntervalWaitTime,
      };
    case rebateTypes.EXCEL_REQUEST_FAILED:
      return {
        ...state,
        ServerExcelDownloadStatus: "EXCEL_REQUEST_FAILED",
        FileDownloadStatus: false,
        ExcelModal: false,
      };
    case rebateTypes.PDF_FAILED:
      return {
        ...state,
        PDFStatus: "PDF_FAILED",
      };
    case rebateTypes.PROCESS_SUCCESS:
      return {
        ...state,
        ProcessSuccess: true,
        WaitForProcessTransaction: false,
        ProcessQuestion: false,
        ProcessProcessing: false,
        SelectedTransactions: [],
        DataHistory: [],
        ProcessStatus: "PROCESS_SUCCESS",
      };
    case rebateTypes.PROCESS_SUCCESS_IS:
      return {
        ...state,
        // ProcessSuccess: true,
        // WaitForProcessTransaction: false,
        // ProcessProcessing: false,
        // SelectedTransactions: [],
        // DataHistory: [],
        ProcessProcessing: false,
        ProcessProcessingStatus: "PROCESS_SUCCESS_IS",
      };
    case rebateTypes.PROCESS_STARTED:
      return {
        ...state,
        ProcessStatus: "PROCESS_STARTED",
      };
    case rebateTypes.PROCESS_CANCEL_COMPLETED:
      return {
        ...state,
        WaitForCancelTransaction: false,
        Lock: false,
        ProcessQuestion: false,
        ProcessProcessingLock: false,
        ProcessProcessing: false,
        ProcessModalVisibility: false,
      };
    case rebateTypes.INTEGRATION_EXCEL_REQUEST_FAILED:
      return {
        ...state,
        IntegrationExcelStatus: "INTEGRATION_EXCEL_REQUEST_FAILED",
        WaitForIntegrationExcel: false,
        IntegrationExcelQuestion: false,
      };
    case rebateTypes.SET_INTEGRATION_EXCEL_INTERVAL_ID:
      return {
        ...state,
        IntegrationExcelIntervalId: action.IntegrationExcelIntervalId,
      };
    case rebateTypes.SET_PDF_INTERVAL_ID:
      return {
        ...state,
        PDFIntervalId: action.PDFIntervalId,
      };
    case rebateTypes.SET_INVOICE_REBATE_INTERVAL_ID:
      return {
        ...state,
        InvoiceRebateIntervalId: action.InvoiceRebateIntervalId,
      };
    case rebateTypes.PROCESS_PROCESSING:
      return {
        ...state,
        ProcessStatus: "PROCESS_PROCESSING",
      };
    case rebateTypes.PROCESS_CANCEL_PROCESSING:
      return {
        ...state,
        ProcessStatus: "PROCESS_CANCEL_PROCESSING",
        WaitForCancelTransaction: true,
      };

    case rebateTypes.PROCESS_PROCESSING_IS:
      return {
        ...state,
        ProcessProcessingStatus: "PROCESS_PROCESSING_IS",
        // ProcessQuestion: false,
        // ProcessProcessing: true,
        // ProcessSuccess: true,
        // WaitForProcessTransaction: false,
        // ProcessStatus: "PROCESS_SUCCESS"
        // ProcessProcessing : action.Lock,
        // ProcessAll: action.ProcessAll
        // Lock: true,
        // ProcessAll: "ALL"
      };
    case rebateTypes.SET_PROCESS_MODAL:
      return {
        ...state,
        ProcessModalVisibility: action.ProcessModalVisibility,
      };

    case rebateTypes.INVOICE_REBATE_PROCESSING:
      return {
        ...state,
        InvoiceRebateStatus: "INVOICE_REBATE_PROCESSING",
      };
    case rebateTypes.INVOICE_REBATE_SUCCESS:
      return {
        ...state,
        InvoiceRebateStatus: "INVOICE_REBATE_SUCCESS",
        InvoiceRebateQuestion: false,
        WaitForInvoiceRebate: false,
        // InvoiceRebateIntervalId: null,
      };
    case rebateTypes.INTEGRATION_EXCEL_PROCESSING:
      return {
        ...state,
        InvoiceRebateStatus: "INTEGRATION_EXCEL_PROCESSING",
      };
    case rebateTypes.CALCULATING_TRANSACTION:
      return {
        ...state,
        WaitForCalculateTransaction: true,
      };
    case rebateTypes.CLEAR_INVOICES:
      return {
        ...state,
        Invoices: null,
      };
    case rebateTypes.PAGE_STATUS:
      return {
        ...state,
        Statusname: action.Statusname,
        WaitForProcessTransaction: true,
      };
    case rebateTypes.SET_SELECTED_TRANSACTIONS:
      return {
        ...state,
        SelectedTransactions: action.SelectedTransactions,
      };
    case rebateTypes.TRANSACTION_CALCULATED:
      return {
        ...state,
        WaitForCalculateTransaction: false,
        CalculatedTransaction: action.calculatedRow,
        CalculatedTransactionIndex: action.calculatedRowIndex,
      };
    case rebateTypes.SAVING_TRANSACTION:
      return {
        ...state,
        WaitForSaveTransaction: true,
        WaitForTransactions: false,
        WaitForInvoices: false,
        NoConnection: false,
      };
    case rebateTypes.SHOW_HISTORY_MODAL:
      return {
        ...state,
        ShowHistoryModal: true,
        ForwardAction: action.Action,
      };
    case rebateTypes.HIDE_HISTORY_MODAL:
      return {
        ...state,
        ShowHistoryModal: false,
        ForwardAction: null,
      };
    case rebateTypes.TRANSACTION_SAVED:
      return {
        ...state,
        WaitForSaveTransaction: false,
        ProcessSuccess: true,
        SelectedTransactions: [],
      };
    case rebateTypes.NOT_FOUND:
      return {
        ...state,
        WaitForTransactions: false,
        WaitForInvoices: false,
        RebateNotFound: true,
        UpdateHistory: null,
        NoConnection: false,
      };

    case rebateTypes.INTEGRATION_EXCEL_CREATE_STARTED:
      return {
        ...state,
        WaitForIntegrationExcel: true,
        IntegrationExcelStatus: "INTEGRATION_EXCEL_CREATE_STARTED",
      };
    case rebateTypes.INTEGRATION_EXCEL_CREATE_REQUEST_END:
      return {
        ...state,
        IntegrationExcelStatus: "INTEGRATION_EXCEL_CREATE_REQUEST_END",
      };
    case rebateTypes.INTEGRATION_EXCEL_CREATE_REQUEST_PROCESSING:
      return {
        ...state,
        IntegrationExcelStatus: "INTEGRATION_EXCEL_CREATE_REQUEST_PROCESSING",
      };
    case rebateTypes.INTEGRATION_EXCEL_DOWNLOADING:
      return {
        ...state,
        IntegrationExcelStatus: "INTEGRATION_EXCEL_DOWNLOADING",
      };
    case rebateTypes.INTEGRATION_EXCEL_CREATE_REQUEST_STARTED:
      return {
        ...state,
        IntegrationExcelStatus: "INTEGRATION_EXCEL_CREATE_REQUEST_STARTED",
        WaitForIntegrationExcel: true,
      };
    case rebateTypes.INTEGRATION_EXCEL_DOWNLOADED:
      return {
        ...state,
        WaitForIntegrationExcel: false,
        IntegrationExcelStatus: "INTEGRATION_EXCEL_DOWNLOADED",
        IntegrationExcelQuestion: false,
      };
    case rebateTypes.SET_INTEGRATION_EXCEL_STATUS:
      return {
        ...state,
        IntegrationExcelStatus: action.Statusname,
      };
    case rebateTypes.EXCEL_CREATE_REQUEST_END:
      return {
        ...state,
        ServerExcelDownloadStatus: "EXCEL_CREATE_REQUEST_END",
      };
    case rebateTypes.EXCEL_CREATE_REQUEST_PROCESSING:
      return {
        ...state,
        ServerExcelDownloadStatus: "EXCEL_CREATE_REQUEST_PROCESSING",
      };
    case rebateTypes.PDF_PROCESSING:
      return {
        ...state,
        PDFStatus: "PDF_PROCESSING",
      };
    case rebateTypes.GET_INVOICES_ERROR:
      return {
        ...state,
        WaitForInvoices: false,
        Invoices: [],
        ErrorHandler: {
          ErrorCode: action.ErrorCode,
          ErrorDescription: action.ErrorDescription,
        },
      };
    case rebateTypes.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        WaitForSaveTransaction: false,
        WaitForTransactions: false,
        WaitForInvoices: false,
        RebateNotFound: false,
        UpdateHistory: null,
        NoConnection: true,
        ProcessSuccess: false,
        ProcessQuestion: false,
        ProcessProcessing: false,
        ExcelModal: false,
        FileDownloadStatus: false,
        Transactions: null,
        Invoices: [],
        DataHistory: [],
        SelectedTransactions: [],
        ErrorHandler: {
          ErrorCode: action.ErrorCode,
          ErrorDescription: action.ErrorDescription,
        },
      };
    case rebateTypes.REBATE_ERROR: //every error but while getting transaction and invoices
      return {
        ...state,
        WaitForSaveTransaction: false,
        WaitForTransactions: false,
        WaitForInvoices: false,
        RebateNotFound: false,
        IntegrationExcelQuestion: false,
        UpdateHistory: null,
        ProcessSuccess: false,
        ExcelModal: false,
        WaitForIntegrationExcel: false,
        InvoiceRebateQuestion: false,
        WaitForInvoiceRebate: false,
        ServerExcelDownloadStatus: "",
        IntegrationExcelStatus: "",
        InvoiceRebateStatus: "",
        FileDownloadStatus: false,
        ErrorHandler: {
          ErrorCode: action.ErrorCode,
          ErrorDescription: action.ErrorDescription,
        },
      };
    case rebateTypes.TRANSACTION_DATA_CHANGED:
      return {
        ...state,
        UpdateHistory: action.UpdateHistory,
      };
    case rebateTypes.SET_FILTER:
      return {
        ...state,
        StartMonth: action.StartMonth,
        EndMonth: action.EndMonth,
        TransactionYear: action.TransactionYear,
        CustomerName: action.CustomerName,
        CustomerCode: action.CustomerCode,
        EmailStatus: action.eStatus,
      };
    case rebateTypes.PROCESSING_TRANSACTION:
      return {
        ...state,
        WaitForProcessTransaction: true,
        WaitForInvoices: false,
        WaitForSaveTransaction: false,
        WaitForTransactions: false,
      };

    case rebateTypes.PROCESSING_CANCEL_TRANSACTION:
      return {
        ...state,
        WaitForCancelTransaction: true,
        // WaitForProcessTransaction: true,
        // WaitForInvoices: false,
        // WaitForSaveTransaction: false,
        // WaitForTransactions: false
      };
    case rebateTypes.OPEN_PROCESSLOCK:
      return {
        ...state,
        ProcessQuestion: action.Lock,
        // ProcessProcessing : action.Lock,
        ProcessAll: action.ProcessAll,
      };
    case rebateTypes.INVOICE_REBATE_STARTED:
      return {
        ...state,
        InvoiceRebateStatus: "INVOICE_REBATE_STARTED",
      };
    case rebateTypes.INVOICE_REBATE_REQUEST_STARTED:
      return {
        ...state,
        WaitForInvoiceRebate: true,
      };
    case rebateTypes.GETTING_INVOICE_REBATE_STATUS:
      return {
        ...state,
        InvoiceRebateStatus: "GETTING_INVOICE_REBATE_STATUS",
      };
    case rebateTypes.INVOICE_REBATE_FAILED:
      return {
        ...state,
        InvoiceRebateStatus: "INVOICE_REBATE_FAILED",
        WaitForInvoiceRebate: false,
        InvoiceRebateQuestion: false,
      };
    case rebateTypes.PROCESS_OVER:
      return {
        ...state,
        WaitForProcessTransaction: false,
        WaitForCancelTransaction: false,
        ProcessQuestion: false,
        ProcessProcessing: false,

        ProcessSuccess: false,
      };
    case rebateTypes.GETTING_PROCESS_STATUS:
      return {
        ...state,
        ProcessStatus: "GETTING_PROCESS_STATUS",
        WaitForProcessTransaction: true,
      };
    case rebateTypes.GETTING_PROCESS_STATUS_IS:
      return {
        ...state,
        ProcessProcessingStatus: "GETTING_PROCESS_STATUS_IS",
        // WaitForProcessTransaction: true,
      };
    case rebateTypes.PROCESS_PROCESSING_LOCK:
      return {
        ...state,
        ProcessProcessingLock: action.ProcessProcessingLock,
      };

    case rebateTypes.MODAL_PROCESS_PROCESSING:
      return {
        ...state,
        ProcessProcessing: action.ProcessProcessing,
      };

    case rebateTypes.PROCESS_FAILED:
      return {
        ...state,
        ProcessStatus: "PROCESS_FAILED",
        WaitForProcessTransaction: false,
        ProcessQuestion: false,
        ProcessProcessing: false,
      };

    case rebateTypes.PROCESS_FAILED_IS:
      return {
        ...state,
        ProcessProcessingStatus: "PROCESS_FAILED_IS",
        WaitForProcessTransaction: false,
        WaitForProcessCalcelTransaction: false,
        ProcessQuestion: false,
        ProcessProcessing: false,
      };
    case rebateTypes.SET_EXCELMODAL:
      return {
        ...state,
        ExcelModal: action.ExcelModal,
      };
    case rebateTypes.CHANGE_EXCEL_STATUS_NAME:
      return {
        ...state,
        ServerExcelDownloadStatus: action.ServerExcelDownloadStatus,
      };
    case rebateTypes.CHANGE_PDF_STATUS:
      return {
        ...state,
        PDFStatus: action.PDFStatus,
      };
    case rebateTypes.FILE_DOWNLOADING:
      return {
        ...state,
        FileDownloadStatus: true,
      };
    case rebateTypes.INITIAL_PDF:
      return {
        ...state,
        pdfId: action.pdfId,
        pdfName: action.pdfName,
      };

    case rebateTypes.FILE_DOWNLOADED:
      return {
        ...state,
        FileDownloadStatus: false,
        ExcelModal: false,
      };
    case rebateTypes.EXCEL_DOWNLOADING:
      return {
        ...state,
        FileDownloadStatus: true,
        ServerExcelDownloadStatus: "EXCEL_DOWNLOADING",
      };
    case rebateTypes.EXCEL_DOWNLOADED:
      return {
        ...state,
        FileDownloadStatus: false,
        ExcelModal: false,
        ServerExcelDownloadStatus: "EXCEL_DOWNLOADED",
      };

    case rebateTypes.PDF_DOWNLOADED:
      return {
        ...state,
        PDFStatus: "PDF_DOWNLOADED",
      };

    case rebateTypes.SET_EXCEL_INTERVAL_ID:
      return {
        ...state,
        ExcelIntervalId: action.ExcelIntervalId,
      };
    case rebateTypes.SET_PROCESS_INTERVAL_ID:
      return {
        ...state,
        ProcessIntervalId: action.ProcessIntervalId,
      };
    case rebateTypes.SET_PROCESS_PROCESSING_INTERVAL_ID:
      return {
        ...state,
        ProcessProcessingIntervalId: action.ProcessProcessingIntervalId,
      };
    case rebateTypes.SHOW_INTEGRATION_MODAL:
      return {
        ...state,
        IntegrationExcelQuestion: action.Show,
      };
    case rebateTypes.SHOW_INVOICE_REBATE_MODAL:
      return {
        ...state,
        InvoiceRebateQuestion: action.Show,
      };

    case rebateTypes.CLEAR_REBATES:
      return initialState;
    case rebateTypes.SET_DATA_HISTORY:
      return {
        ...state,
        DataHistory: action.DataHistory,
      };

    default:
      return state;
  }
};
