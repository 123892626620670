import incomingInvoiceTypes from '../type/incomingInvoice';
import moment from 'moment';
const initialState = {
    IncomingInvoices: null,
    WaitForIncomingInvoices: false,
    IncomingInvoicesNotSearched: true,
    NoConnection: false,
    NotFound: false,
    CustomerName: "",
    CustomerNumber: "",
    IntegrationFilterValue : null,
    EInvoiceFilterValue : null,
    Year: new Date().getFullYear(),
    EndMonth: new Date().getMonth() + 1,
    StartMonth: new Date().getMonth() + 1,
    EditModal: false,
    NewInvoiceModal: false,

    WaitForCustomers: false,
    CustomerDTO: null,
    // CustomerNotFound : false,
    CustomerHasError: false,
    CustomerYear: new Date().getFullYear(),
    CustomerEndMonth: new Date().getMonth() + 1,
    CustomerStartMonth: new Date().getMonth() + 1,
    ErrorHandler: {
        ErrorState: "",
        ErrorCode: -1,
        ErrorDescription: "",
    },

    SelectedCustomer: null,

    WaitForCustomerInvoices: false,
    CustomerInvoices: null,
    CustomerInvoicesNotSearched: true,
    SelectedTransaction: null,

    WaitForCustomerArchive: false,
    CustomerArchive: null,


    invoiceAmount: 0.0,
    invoiceAmountAsTry: 0.0,
    invoiceCurrency: "EUR",
    invoiceId: "",
    invoiceDate: 0,
    invoicePaymentDate: 0,
    exchangeRate: 0.0,
    invoiceId: "",
    ettn: "",
    watAmount: 0.0,
    watAmountAsTry: 0.0,
    TotalAmount: 0.0,
    TotalAmountAsTry: 0.0,
    voucherDate: 0,
    Description: "",
    differenceAmountAsTry: 0.0,

    EInvoice: false,

    WaitForSavingNewInvoice: false,
    WaitForDeleteInvoice: false,
    WaitForIntegrate: false,
    WaitForCheckIntegrate: false,
    SelectedInvoice: null,

    CheckIntegrateModal: false,
    IntegrateModal: false,
    DeleteModal: false,
    RefreshPage: true,

    InvoiceDateValidation: false,
    VoucherDateValidation: false,
    InvoicePaymentDateValidation: false,
    ExchangeRateValidation: false,

    Integrated: false,
    IntegratedCheck: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case incomingInvoiceTypes.GETTING_INCOMING_INVOICES:
            return {
                ...state,
                WaitForIncomingInvoices: true,
                NoConnection: false,
                NotFound: false,
                IncomingInvoicesNotSearched: false,

            }
        case incomingInvoiceTypes.GET_INCOMING_INVOICES:
            return {
                ...state,
                IncomingInvoices: action.IncomingInvoices,
                WaitForIncomingInvoices: false,
                RefreshPage: false,
            }
        case incomingInvoiceTypes.SET_YEAR:
            return {
                ...state,
                Year: action.Year
            }

        case incomingInvoiceTypes.SET_STARTMONTH:
            return {
                ...state,
                StartMonth: action.StartMonth
            }
        case incomingInvoiceTypes.SET_ENDMONTH:
            return {
                ...state,
                EndMonth: action.EndMonth
            }
        case incomingInvoiceTypes.SET_CUSTOMERNAME:
            return {
                ...state,
                CustomerName: action.CustomerName
            }
        case incomingInvoiceTypes.SET_CUSTOMERNUMBER:
            return {
                ...state,
                CustomerNumber: action.CustomerNumber,
            }

        case incomingInvoiceTypes.SET_INTEGRATION_INPUT:
            return {
                ...state,
                IntegrationFilterValue: action.IntegrationFilterValue,
            }
        case incomingInvoiceTypes.SET_EINVOICE_INPUT:
            return {
                ...state,
                EInvoiceFilterValue: action.EInvoiceFilterValue,
            }

        case incomingInvoiceTypes.OK_MODAL_CLOSE:
            return {
                ...state,
                Integrated: false,
                IntegratedCheck: false,
            }
        case incomingInvoiceTypes.SET_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case incomingInvoiceTypes.SET_NETWORK_ERROR:
            return {
                ...state,
                NoConnection: true,
                WaitForIncomingInvoices: false,
                NotFound: false,
                ErrorHandler: {
                    ErrorState: "NETWORK_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case incomingInvoiceTypes.CLEAR_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "",
                    ErrorCode: -1,
                    ErrorDescription: ""
                }
            }
        case incomingInvoiceTypes.CLEAR_NETWORK_ERROR:
            return {
                ...state,
                ErrorHandler: {
                    ErrorState: "",
                    ErrorCode: -1,
                    ErrorDescription: ""
                }
            }
        case incomingInvoiceTypes.NOT_FOUND:
            return {
                ...state,
                NotFound: true,
                NoConnection: false,
                WaitForIncomingInvoices: false,
            }
        case incomingInvoiceTypes.SHOW_EDIT_MODAL:
            return {
                ...state,
                EditModal: true,
                SelectedInvoice: action.SelectedInvoice,
                invoiceAmount: action.SelectedInvoice.invoiceAmount,
                invoiceAmountAsTry: action.SelectedInvoice.invoiceAmountAsTry,
                invoiceCurrency: action.SelectedInvoice.invoiceCurrency,
                invoiceId: action.SelectedInvoice.invoiceId,
                invoiceDate: moment(action.SelectedInvoice.invoiceDate * 1000).toDate(),
                invoicePaymentDate: moment(action.SelectedInvoice.invoicePaymentDate * 1000).toDate(),
                exchangeRate: action.SelectedInvoice.exchangeRate,
                ettn: action.SelectedInvoice.ettn,
                watAmount: action.SelectedInvoice.vatAmount,
                watAmountAsTry: action.SelectedInvoice.vatAmountAsTry,
                differenceAmountAsTry: action.SelectedInvoice.differenceAmountAsTry,
                TotalAmount: action.SelectedInvoice.TotalAmount,
                TotalAmountAsTry: action.SelectedInvoice.TotalAmountAsTry,
                voucherDate: moment(action.SelectedInvoice.voucherDate * 1000).toDate(),
                EInvoice: action.SelectedInvoice.isEInvoice,
                Description: action.SelectedInvoice.description
            }
        case incomingInvoiceTypes.RESET_INCOMING_INVOICE_PAGE:
            return {
                ...initialState
            }
        case incomingInvoiceTypes.HIDE_EDIT_MODAL:
            return {
                ...state,
                EditModal: false,
                WaitForCustomers: false,
                CustomerDTO: null,
                // CustomerNotFound : false,
                CustomerHasError: false,
                CustomerYear: new Date().getFullYear(),
                CustomerEndMonth: new Date().getMonth() + 1,
                CustomerStartMonth: new Date().getMonth() + 1,
                SelectedCustomer: null,
                WaitForCustomerInvoices: false,
                CustomerInvoices: null,
                CustomerInvoicesNotSearched: true,
                SelectedTransaction: null,
                WaitForCustomerArchive: false,
                CustomerArchive: null,
                invoiceAmount: 0.0,
                differenceAmountAsTry: 0.0,

                invoiceAmountAsTry: 0.0,
                invoiceCurrency: "EUR",
                invoiceId: "",
                invoiceDate: 0,
                invoicePaymentDate: 0,
                exchangeRate: 0.0,
                invoiceId: "",
                ettn: "",
                watAmount: 0.0,
                watAmountAsTry: 0.0,
                TotalAmount: 0.0,
                TotalAmountAsTry: 0.0,
                voucherDate: 0,
                Description: "",

                EInvoice: false,
                WaitForSavingNewInvoice: false,
                SelectedInvoice: null,
                InvoiceNumberValidation: false,
                InvoiceAmountValidation: false,
                TotalAmountValidation: false,
                InvoiceDateValidation: false,
                VoucherDateValidation: false,
                InvoicePaymentDateValidation: false,
                ExchangeRateValidation: false,
            }
        case incomingInvoiceTypes.SHOW_NEW_INVOICE_MODAL:
            return {
                ...state,
                NewInvoiceModal: true,
            }
        case incomingInvoiceTypes.HIDE_NEW_INVOICE_MODAL:
            return {
                ...state,
                NewInvoiceModal: false,
                WaitForCustomers: false,
                CustomerDTO: null,
                // CustomerNotFound : false,
                CustomerHasError: false,
                CustomerYear: new Date().getFullYear(),
                CustomerEndMonth: new Date().getMonth() + 1,
                CustomerStartMonth: new Date().getMonth() + 1,
                SelectedCustomer: null,
                WaitForCustomerInvoices: false,
                CustomerInvoices: null,
                CustomerInvoicesNotSearched: true,
                SelectedTransaction: null,
                WaitForCustomerArchive: false,
                CustomerArchive: null,
                invoiceAmount: 0.0,
                differenceAmountAsTry: 0.0,

                invoiceAmountAsTry: 0.0,
                invoiceCurrency: "EUR",
                invoiceId: "",
                invoiceDate: 0,
                invoicePaymentDate: 0,
                exchangeRate: 0.0,
                invoiceId: "",
                ettn: "",
                watAmount: 0.0,
                watAmountAsTry: 0.0,
                TotalAmount: 0.0,
                TotalAmountAsTry: 0.0,
                voucherDate: 0,
                Description: "",

                EInvoice: false,
                WaitForSavingNewInvoice: false,
                SelectedInvoice: null,
                InvoiceNumberValidation: false,
                InvoiceAmountValidation: false,
                TotalAmountValidation: false,
                InvoiceDateValidation: false,
                VoucherDateValidation: false,
                InvoicePaymentDateValidation: false,
                ExchangeRateValidation: false,
            }
        case incomingInvoiceTypes.GETTING_CUSTOMERS:
            return {
                ...state,
                WaitForCustomers: true,
                CustomerHasError: false,
                CustomerDTO: null,
            }
        case incomingInvoiceTypes.GET_CUSTOMERS:
            return {
                ...state,
                WaitForCustomers: false,
                CustomerHasError: false,
                CustomerDTO: action.CustomerDTO,
            }
        case incomingInvoiceTypes.GET_CUSTOMER_ERROR:
            return {
                ...state,
                WaitForCustomers: false,
                CustomerHasError: true,
                CustomerDTO: null,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case incomingInvoiceTypes.SET_SELECTED_CUSTOMER:
            return {
                ...state,
                SelectedCustomer: action.SelectedCustomer,
            }
        case incomingInvoiceTypes.SET_CUSTOMER_STARTMONTH:
            return {
                ...state,
                CustomerStartMonth: action.StartMonth
            }
        case incomingInvoiceTypes.SET_CUSTOMER_ENDMONTH:
            return {
                ...state,
                CustomerEndMonth: action.EndMonth

            }
        case incomingInvoiceTypes.SET_CUSTOMER_YEAR:
            return {
                ...state,
                CustomerYear: action.Year
            }
        case incomingInvoiceTypes.GETTING_CUSTOMER_INVOICES:
            return {
                ...state,
                WaitForCustomerInvoices: true,
                CustomerInvoicesNotSearched: false,
                SelectedTransaction: null,
            }
        case incomingInvoiceTypes.GET_CUSTOMER_INVOICES:
            return {
                ...state,
                WaitForCustomerInvoices: false,
                CustomerInvoices: action.CustomerInvoices
            }
        case incomingInvoiceTypes.GETTING_CUSTOMER_ERROR:
            return {
                ...state,
                WaitForCustomerInvoices: false,
                CustomerInvoices: null,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case incomingInvoiceTypes.GETTING_CUSTOMER_TRANSACTION:
            return {
                ...state,
                WaitForCustomerInvoices: true,
                CustomerInvoicesNotSearched: false,
                SelectedTransaction: null,
            }
        case incomingInvoiceTypes.GET_CUSTOMER_TRANSACTION:
            return {
                ...state,
                WaitForCustomerInvoices: false,
                CustomerInvoices: action.CustomerInvoices
            }
        case incomingInvoiceTypes.GET_CUSTOMER_TRANSACTION_ERROR:
            return {
                ...state,
                WaitForCustomerInvoices: false,
                CustomerInvoices: action.CustomerInvoices,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case incomingInvoiceTypes.SELECT_TRANSACTION:
            return {
                ...state,
                SelectedTransaction: action.SelectedTransaction
            }
        case incomingInvoiceTypes.SET_EXCHANGERATE_VALIDATION:
            return {
                ...state,
                ExchangeRateValidation: action.Validation,
            }
        case incomingInvoiceTypes.INPUT_SET_INVOICEDESCRIPTION:
            return {
                ...state,
                Description: action.Description
            }

        case incomingInvoiceTypes.INPUT_SET_DIFFERENCEAMOUNTASTRY:
            return {
                ...state,
                differenceAmountAsTry: action.differenceAmountAsTry,
            }
        case incomingInvoiceTypes.GETTING_CUSTOMER_ARCHIVE:
            return {
                ...state,
                WaitForCustomerArchive: true,
                invoiceAmount: 0.0,

                differenceAmountAsTry: 0.0,

                invoiceAmountAsTry: 0.0,
                invoiceCurrency: "EUR",
                invoiceId: "",
                invoiceDate: 0,
                invoicePaymentDate: 0,
                exchangeRate: 0.0,
                watAmount: 0.0,
                watAmountAsTry: 0.0,
                TotalAmount: 0.0,
                TotalAmountAsTry: 0.0,
                voucherDate: 0,
                Description: "",
                InvoiceNumberValidation: false,
                InvoiceAmountValidation: false,
                TotalAmountValidation: false,
                InvoiceDateValidation: false,
                VoucherDateValidation: false,
                InvoicePaymentDateValidation: false,
                ExchangeRateValidation: false,
                CustomerArchive: null,
            }
        case incomingInvoiceTypes.GET_CUSTOMER_ARCHIVE:
            return {
                ...state,
                WaitForCustomerArchive: false,
                CustomerArchive: action.CustomerArchive,
                invoiceAmount: action.invoiceAmount,
                differenceAmountAsTry: action.differenceAmountAsTry,
                invoiceAmountAsTry: action.invoiceAmountAsTry,
                invoiceCurrency: action.invoiceCurrency,
                invoiceId: action.invoiceId,
                invoiceDate: action.invoiceDate,
                invoicePaymentDate: action.invoicePaymentDate,
                voucherDate: action.voucherDate,
                exchangeRate: action.exchangeRate,
                invoiceId: action.invoiceId,
                ettn: action.ettn,
            }
        case incomingInvoiceTypes.GET_CUSTOMER_ARCHIVE_ERROR:
            return {
                ...state,
                WaitForCustomerArchive: false,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case incomingInvoiceTypes.INPUT_SET_INVOICEAMOUNT:
            return {
                ...state,
                invoiceAmount: action.invoiceAmount,
            }
        case incomingInvoiceTypes.INPUT_SET_AMOUNTASTRY:
            return {
                ...state,
                invoiceAmountAsTry: action.invoiceAmountAsTry,
            }
        case incomingInvoiceTypes.INPUT_SET_INVOICECURRENCY:
            return {
                ...state,
                invoiceCurrency: action.invoiceCurrency,
            }
        case incomingInvoiceTypes.INPUT_SET_INVOICEID:
            return {
                ...state,
                invoiceId: action.invoiceId,
            }
        case incomingInvoiceTypes.INPUT_SET_INVOICEDATE:
            return {
                ...state,
                invoiceDate: action.invoiceDate,
            }
        case incomingInvoiceTypes.INPUT_SET_INVOICEPAYMENTDATE:
            return {
                ...state,
                invoicePaymentDate: action.invoicePaymentDate,
            }
        case incomingInvoiceTypes.INPUT_SET_EXCHANGERATE:
            return {
                ...state,
                exchangeRate: action.exchangeRate,
            }
        case incomingInvoiceTypes.INPUT_SET_WATAMOUNT:
            return {
                ...state,
                watAmount: action.watAmount,
            }
        case incomingInvoiceTypes.INPUT_SET_WATAMOUNTASTRY:
            return {
                ...state,
                watAmountAsTry: action.watAmountAsTry,
            }
        case incomingInvoiceTypes.INPUT_SET_TOTALAMOUNT:
            return {
                ...state,
                TotalAmount: action.TotalAmount,
            }
        case incomingInvoiceTypes.INPUT_SET_ETTN:
            return {
                ...state,
                ettn: action.ettn,
            }
        case incomingInvoiceTypes.INPUT_SET_TOTALAMOUUNTASTRY:
            return {
                ...state,
                TotalAmountAsTry: action.TotalAmountAsTry,
            }
        case incomingInvoiceTypes.INPUT_SET_EINVOICE:
            return {
                ...state,
                EInvoice: action.EInvoice,
            }
        case incomingInvoiceTypes.INPUT_SET_VOUCHERDATE:
            return {
                ...state,
                voucherDate: action.voucherDate
            }
        case incomingInvoiceTypes.SAVING_NEW_INVOICE:
            return {
                ...state,
                WaitForSavingNewInvoice: true
            }
        case incomingInvoiceTypes.SAVE_NEW_INVOICE:
            return {
                ...state,
                WaitForSavingNewInvoice: false,
                EditModal: false,
                NewInvoiceModal: false,
                WaitForCustomers: false,
                CustomerDTO: null,
                // CustomerNotFound : false,
                CustomerHasError: false,
                CustomerYear: new Date().getFullYear(),
                CustomerEndMonth: new Date().getMonth() + 1,
                CustomerStartMonth: new Date().getMonth() + 1,
                SelectedCustomer: null,
                WaitForCustomerInvoices: false,
                CustomerInvoices: null,

                differenceAmountAsTry: 0.0,
                CustomerInvoicesNotSearched: true,
                SelectedTransaction: null,
                WaitForCustomerArchive: false,
                CustomerArchive: null,
                invoiceAmount: 0.0,
                invoiceAmountAsTry: 0.0,
                invoiceCurrency: "EUR",
                invoiceId: "",
                invoiceDate: 0,
                invoicePaymentDate: 0,
                exchangeRate: 0.0,
                invoiceId: "",
                ettn: "",
                watAmount: 0.0,
                watAmountAsTry: 0.0,
                TotalAmount: 0.0,
                TotalAmountAsTry: 0.0,
                voucherDate: 0,
                Description: "",
                EInvoice: false,
                WaitForSavingNewInvoice: false,
                SelectedInvoice: null,
                RefreshPage: true,
                InvoiceNumberValidation: false,
                InvoiceAmountValidation: false,
                TotalAmountValidation: false,

            }
        case incomingInvoiceTypes.SAVE_NEW_INVOICE_ERROR:
            return {
                ...state,
                WaitForSavingNewInvoice: false,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case incomingInvoiceTypes.SET_SELECTED_INVOICE:
            return {
                ...state,
                SelectedInvoice: action.SelectedInvoice
            }
        // SAVING_NEW_INVOICE: Symbol('SAVING_NEW_INVOICE'),
        case incomingInvoiceTypes.DELETING_INVOICE:
            return {
                ...state,
                WaitForDeletingInvoice: true,
            }
        case incomingInvoiceTypes.DELETE_INVOICE:
            return {
                ...state,
                WaitForDeleteInvoice: false,
                DeleteModal: false,
                RefreshPage: true,
            }
        case incomingInvoiceTypes.DELETE_INVOICE_ERROR:
            return {
                ...state,
                WaitForDeleteInvoice: false,
                DeleteModal: false,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }

            }
        case incomingInvoiceTypes.SHOW_DELETE_INVOICE_MODAL:
            return {
                ...state,
                DeleteModal: true,
                SelectedInvoice: action.SelectedInvoice,
            }
        case incomingInvoiceTypes.HIDE_DELETE_INVOICE_MODAL:
            return {
                ...state,
                DeleteModal: false,
                SelectedInvoice: null,
            }
        case incomingInvoiceTypes.SET_INVOICE_NUMBER_VALIDATION:
            return {
                ...state,
                InvoiceNumberValidation: action.Validation
            }
        case incomingInvoiceTypes.SET_INVOICE_AMOUNT_VALIDATION:
            return {
                ...state,
                InvoiceAmountValidation: action.Validation
            }
        case incomingInvoiceTypes.SET_INVOICE_TOTAL_AMOUNT_VALIDATION:
            return {
                ...state,
                TotalAmountValidation: action.Validation
            }
        case incomingInvoiceTypes.INTEGRATING:
            return {
                ...state,
                WaitForIntegrate: true
            }
        case incomingInvoiceTypes.INTEGRATE:
            return {
                ...state,
                WaitForIntegrate: false,
                IntegrateModal: false,
                RefreshPage: true,
                Integrated: true,
            }
        case incomingInvoiceTypes.INTEGRATE_ERROR:
            return {
                ...state,
                WaitForIntegrate: false,
                IntegrateModal: false,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case incomingInvoiceTypes.SHOW_INTEGRATE_MODAL:
            return {
                ...state,
                IntegrateModal: true,
                SelectedInvoice: action.SelectedInvoice,
            }
        case incomingInvoiceTypes.HIDE_INTEGRATE_MODAL:
            return {
                ...state,
                IntegrateModal: false,
                SelectedInvoice: null,
            }

        /////

        case incomingInvoiceTypes.CHECKING_INTEGRATE:
            return {
                ...state,
                WaitForCheckIntegrate: true
            }
        case incomingInvoiceTypes.CHECK_INTEGRATE:
            return {
                ...state,
                WaitForCheckIntegrate: false,
                CheckIntegrateModal: false,
                RefreshPage: true,
                IntegratedCheck: true,
            }
        case incomingInvoiceTypes.CHECK_INTEGRATE_ERROR:
            return {
                ...state,
                WaitForCheckIntegrate: false,
                CheckIntegrateModal: false,
                ErrorHandler: {
                    ErrorState: "CUSTOM_ERROR",
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }
        case incomingInvoiceTypes.SHOW_CHECK_INTEGRATE_MODAL:
            return {
                ...state,
                CheckIntegrateModal: true,
                SelectedInvoice: action.SelectedInvoice,
            }
        case incomingInvoiceTypes.HIDE_CHECK_INTEGRATE_MODAL:
            return {
                ...state,
                CheckIntegrateModal: false,
                SelectedInvoice: null,
            }

        case incomingInvoiceTypes.SET_INVOICE_DATE_VALIDATION:
            return {
                ...state,
                InvoiceDateValidation: action.Validation
            }
        case incomingInvoiceTypes.SET_INVOICE_PAYMENTDATE_VALIDATION:
            return {
                ...state,
                InvoicePaymentDateValidation: action.Validation
            }
        case incomingInvoiceTypes.SET_VOUCHERDATE_VALIDATION:
            return {
                ...state,
                VoucherDateValidation: action.Validation
            }

        default:
            return state;

    }
}