import React from 'react';
import CustomerPage from '../component/Customer/Customer'

import { connect } from 'react-redux';
import { pageChanged } from '../store/action/pageActions'

const customer = (props) => {
    const { pageName, pageChanged, match } = props;
    if (pageName !== match.path) {
        pageChanged(match.path)
    }
    return <CustomerPage />
}
const mapStateToProps = (state) => {
    return {
        pageName: state.page.pageName
    }
}
export default connect(mapStateToProps, { pageChanged })(customer);
