import React, { useEffect, useState } from 'react'
import './Modal.scss'
import { Modal } from '@dfds-ui/modal'
import { connect } from 'react-redux'
import { UnitTypeModalHide, saveUnitType} from '../../../../store/action/adminPanelAction'
import { Input, Button, SelectField } from '@dfds-ui/react-components'
import { rebateLanguage, unitTypeLanguage } from '../../../../config/language';
const UnitTypeModal = (props) => {
    const { UnitTypeModalHide, ShowUnitTypeModal, SelectedUnitType,saveUnitType,Language} = props
    useEffect(() => {
        if (ShowUnitTypeModal) {
            
        }
    }, [ShowUnitTypeModal])

    return (
        <Modal className="UnitType-Modal" heading={SelectedUnitType.id > 0
                                        ? unitTypeLanguage[Language].ModalLanguage.EditModalHeader 
                                        : unitTypeLanguage[Language].ModalLanguage.NewModalHeader} 
                                        onRequestClose={() => UnitTypeModalHide()} isOpen={ShowUnitTypeModal}>
            <div className="UnitType-Modal-Container">
                <div className="UnitType-Modal-Header">

                </div>
                <div className="UnitType-Modal-Content">

                        <div className="InputWithLabel" id="id" 
                            style={ SelectedUnitType.id <0 ? { display:'none'} : {display:'block'}}
                            >
                            <span className="Input-Label">
                                {unitTypeLanguage[Language].ModalLanguage.IdInputText}
                            </span>
                            {
                                <Input
                                    size={"small"}
                                    defaultValue={SelectedUnitType.id && SelectedUnitType.id}
                                    disabled={true}
                                />
                            }
                        </div>
                        <div className="InputWithLabel" id="name">
                            <span className="Input-Label">
                            {unitTypeLanguage[Language].ModalLanguage.NameInputText}
                        </span>

                            <Input
                                defaultValue={SelectedUnitType.name && SelectedUnitType.name}
                                onChange={(event) => SelectedUnitType.name = event.target.value}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="lineType">
                            <span className="Input-Label">
                            {unitTypeLanguage[Language].ModalLanguage.LineTypeInputText}
                        </span>
                            <Input
                                defaultValue={SelectedUnitType.lineType && SelectedUnitType.lineType}
                                onChange={(event) => SelectedUnitType.lineType = event.target.value}
                                size={"small"}
                            />
                        </div>


                        <div className="InputWithLabel" id="isEmpty">
                            <span className="Input-Label">
                            {unitTypeLanguage[Language].ModalLanguage.IsEmptyText}
                        </span>
                        <input type="checkbox" className ="UnitType-Modal-CheckBox"
                            defaultChecked ={SelectedUnitType.isEmpty && SelectedUnitType.isEmpty===1}
                            onChange= {(event) => {
                                SelectedUnitType.isEmpty = event.target.checked ? 1 : 0;
                          }}
                            />
                        </div>
                        <div className="InputWithLabel" id="isValid">
                            <span className="Input-Label">
                            {unitTypeLanguage[Language].ModalLanguage.IsValidText}
                        </span>
                        <input type="checkbox" className ="UnitType-Modal-CheckBox"
                            defaultChecked ={SelectedUnitType.isValid && SelectedUnitType.isValid}
                            onChange= {(event) => {
                                SelectedUnitType.isValid = event.target.checked
                              }}
                            />
                        </div>



                </div>
                <div className="UnitType-Modal-Footer">
                    <Button
                        className="UnitType-Button-Save"
                        disabled={false}
                        onClick={() => {
                            saveUnitType(SelectedUnitType); 
                        }}
                    >
                        {unitTypeLanguage[Language].ModalLanguage.SaveButton}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}


const mapStateToProps = (state) => {
    return {
        ShowUnitTypeModal: state.adminPanel.ShowUnitTypeModal,
        SelectedUnitType: state.adminPanel.SelectedUnitType,
        Roles: state.adminPanel.Roles,
        Language: state.user.Language,
    }
}
export default connect(mapStateToProps, { UnitTypeModalHide ,saveUnitType})(UnitTypeModal)