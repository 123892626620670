import React, { useEffect } from 'react'
import { Modal } from '@dfds-ui/modal';

import { setHistoryModal, getHistoryDocument, getTransactionFile } from '../../../../store/action/customerAction'
import { customerLanguage } from '../../../../config/language'
import { connect } from 'react-redux'
import './HistoryModal.scss'
import JustWait from '../../../CommonUI/JustAnimations/JustWait/JustWait';
import 'react-accessible-accordion/dist/fancy-example.css';


import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import JustLockComponent from '../../../CommonUI/JustLockComponent/JustLockComponent';


const HistoryModal = (props) => {
    const { SelectedRow, Language, HistoryModal,
        setHistoryModal, WaitForHistory, getHistoryDocument,
        HistoryDocument, getTransactionFile, WaitForHistoryDownload, NoHistoryFoundMessage } = props
    useEffect(() => {
        if (HistoryModal) {
            getHistoryDocument(SelectedRow.number)
        }
    }, [HistoryModal])
    const onDocumentClick = (documentKey, documentPeriod) => {
        getTransactionFile(SelectedRow.number, documentPeriod, documentKey)
    }

    return (
        <Modal
            isOpen={HistoryModal}
            className="Customer-HistoryModal"
            heading={customerLanguage[Language].HistoryModalHeader}
            onRequestClose={() => setHistoryModal(false)}>
            <div className="HistoryModal-Container">
                {
                    WaitForHistoryDownload &&
                    <JustLockComponent />
                }
                {
                    WaitForHistory ?
                        <JustWait />
                        :
                        (HistoryDocument && HistoryDocument.length > 0) ?
                            <Accordion>
                                {


                                    HistoryDocument.map((documents) => {
                                        return (
                                            <AccordionItem>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>
                                                        {documents.period}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    {
                                                        documents.documents &&
                                                        documents.documents.map((document) => {
                                                            return <p id="HistoryModal-Documentname" onClick={() => { onDocumentClick(document.key, documents.period) }}>{document.name}</p>
                                                        })}
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        )
                                    })

                                }
                            </Accordion>
                            :
                            <p>{NoHistoryFoundMessage}</p>
                }
            </div>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        SelectedRow: state.customer.SelectedRow,
        HistoryModal: state.customer.HistoryModal,
        WaitForHistory: state.customer.WaitForHistory,
        HistoryDocument: state.customer.HistoryDocument,
        WaitForHistoryDownload: state.customer.WaitForHistoryDownload,
        Language: state.user.Language,

    }
}
export default connect(mapStateToProps, { setHistoryModal, getHistoryDocument, getTransactionFile })(HistoryModal);