import React, { useEffect } from 'react'
import './Modal.scss'
import { Modal } from '@dfds-ui/modal'
import { connect } from 'react-redux'
import {
    AccountingVoyageModalHide, setAccountingVesselRented,
    setAccountingVesselName, setAccountingVesselId,
    setAccountingCode, saveVessel, setAccountingVoyageName,
    setAccountingVoyageCode, setAccountingVoyageAccountName, setAccountingVoyageRentCode, setAccountingVoyageRentName, setAccountingVoyageGroupName, saveAccountingVoyages,
} from '../../../../store/action/adminPanelAction'
import { Input, Button, SelectField } from '@dfds-ui/react-components'
import { accountingVoyageLanguage } from '../../../../config/language'

const AccountingVesselModal = (props) => {
    const { AccountingVoyageModalHide, ShowAccountingVoyageModal, SelectedAccountingVoyage, AccountingVoyageName,
        AccountingVoyageCode, AccountingVoyageAccountName, AccountingVoyageRentCode, AccountingVoyageRentName, AccountingVoyageGroupName,
        setAccountingVoyageName, saveAccountingVoyages,
        setAccountingVoyageAccountName, WaitForSaveAccountingVoyage,
        setAccountingVoyageCode, setAccountingVoyageRentCode, setAccountingVoyageRentName, setAccountingVoyageGroupName, Language
    } = props

    useEffect(() => {
        if (ShowAccountingVoyageModal) {
        }
    }, [ShowAccountingVoyageModal])
    return (
        //ModalHeaderEdit
        <Modal className="AccountingVoyage-Modal" heading={SelectedAccountingVoyage ?
            accountingVoyageLanguage[Language].ModalHeaderEdit : accountingVoyageLanguage[Language].ModalHeaderNew}
            onRequestClose={() => AccountingVoyageModalHide()} isOpen={ShowAccountingVoyageModal}>
            <div className="AccountingVoyage-Modal-Container">
                <div className="AccountingVoyage-Modal-Header">

                </div>
                <div className="AccountingVoyage-Modal-Content">
                    <div className="RightContent-Container">
                        <div className="InputWithLabel" id="name">
                            <span className="Input-Label">
                                {accountingVoyageLanguage[Language].AccountingVoyageTable.Name}
                            </span>
                            {
                                <Input
                                    size={"small"}
                                    onChange={(event) => setAccountingVoyageName(event.target.value)}
                                    value={SelectedAccountingVoyage && SelectedAccountingVoyage.name}
                                    disabled={SelectedAccountingVoyage}
                                />
                            }
                        </div>
                        <div className="InputWithLabel" id="vesselCode">
                            <span className="Input-Label">
                                {accountingVoyageLanguage[Language].AccountingVoyageTable.AccountingCode}
                            </span>

                            <Input
                                onChange={(event) => { setAccountingVoyageCode(event.target.value); }}
                                defaultValue={SelectedAccountingVoyage && SelectedAccountingVoyage.accountingCode}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="accountingName">
                            <span className="Input-Label">
                                {accountingVoyageLanguage[Language].AccountingVoyageTable.AccountingName}
                            </span>
                            <Input
                                onChange={(event) => { setAccountingVoyageAccountName(event.target.value) }}
                                defaultValue={SelectedAccountingVoyage && SelectedAccountingVoyage.accountingName}
                                size={"small"}
                            />
                        </div>
                    </div>
                    <div className="LeftContent-Container">
                        <div className="InputWithLabel" id="accountingRentCode">
                            <span className="Input-Label">
                                {accountingVoyageLanguage[Language].AccountingVoyageTable.AccountingRentCode}
                            </span>
                            <Input
                                onChange={(event) => { setAccountingVoyageRentCode(event.target.value); }}
                                defaultValue={SelectedAccountingVoyage && SelectedAccountingVoyage.accountingRentCode}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="accountingRentName">
                            <span className="Input-Label">
                                {accountingVoyageLanguage[Language].AccountingVoyageTable.AccountingRentName}
                            </span>
                            <Input
                                onChange={(event) => { setAccountingVoyageRentName(event.target.value); }}
                                defaultValue={SelectedAccountingVoyage && SelectedAccountingVoyage.accountingRentName}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="accountingVoyageGroupName">
                            <span className="Input-Label">
                                {accountingVoyageLanguage[Language].AccountingVoyageTable.GroupName}
                            </span>
                            <Input
                                onChange={(event) => { setAccountingVoyageGroupName(event.target.value); }}
                                defaultValue={SelectedAccountingVoyage && SelectedAccountingVoyage.groupName}
                                size={"small"}
                            />
                        </div>
                    </div>
                </div>
                <div className="AccountingVoyage-Modal-Footer">
                    <Button
                        className="AccountingVoyage-Button-Save"
                        disabled={WaitForSaveAccountingVoyage}
                        onClick={() => {
                            saveAccountingVoyages(AccountingVoyageName, AccountingVoyageGroupName, AccountingVoyageCode,
                                AccountingVoyageRentCode, AccountingVoyageAccountName, AccountingVoyageRentName)
                        }}
                    >
                        {accountingVoyageLanguage[Language].SaveButton}
                    </Button>
                </div>
            </div>
        </Modal >
    )
}
const mapStateToProps = (state) => {
    return {
        ShowAccountingVoyageModal: state.adminPanel.ShowAccountingVoyageModal,
        SelectedAccountingVoyage: state.adminPanel.SelectedAccountingVoyage,
        WaitForSaveAccountingVoyage: state.adminPanel.WaitForSaveAccountingVoyage,
        AccountingVoyageName: state.adminPanel.AccountingVoyageName,
        AccountingVoyageCode: state.adminPanel.AccountingVoyageCode,
        AccountingVoyageAccountName: state.adminPanel.AccountingVoyageAccountName,
        AccountingVoyageRentCode: state.adminPanel.AccountingVoyageRentCode,
        AccountingVoyageRentName: state.adminPanel.AccountingVoyageRentName,
        AccountingVoyageGroupName: state.adminPanel.AccountingVoyageGroupName,
        Language: state.user.Language

    }
}
export default connect(mapStateToProps, {
    AccountingVoyageModalHide, setAccountingVesselRented, setAccountingVesselName,
    setAccountingVesselId, setAccountingCode, saveVessel,
    setAccountingVoyageName, setAccountingVoyageCode, setAccountingVoyageAccountName, saveAccountingVoyages,
    setAccountingVoyageRentCode, setAccountingVoyageRentName, setAccountingVoyageGroupName
})(AccountingVesselModal)