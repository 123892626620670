import React, { useEffect } from 'react'
import './AccountingVoyage.scss'
import { Button } from '@dfds-ui/react-components'
import { JustTable } from '../../../component/CommonUI/JustTable/JustTable'
import { AccountingVoyageSchema } from './AccountingVoyageTableSchema'
import {
    AccountingVoyageModalShow, setSelectedAccountingVoyage, deleteVoyageModalShow, deleteVoyageModalHide,
    getAccountingVoyages, clearNetworkError, clearError, deleteAccountingVoyage
} from '../../../store/action/adminPanelAction'
import DeleteModal from '../../CommonUI/Modals/YesNoModal/YesNoModal';

import AccountingVesselModal from './Modal/Modal'
import { connect } from 'react-redux'
import { rebateLanguage, accountingVoyageLanguage } from '../../../config/language'
import { ErrorModal } from '../../CommonUI/Modals/ErrorModal';


const AccountingVoyage = (props) => {
    const { display, AccountingVoyageModalShow, setSelectedAccountingVoyage, Language, WaitForAccountingVoyage,
        getAccountingVoyages, AccountingVoyages, ErrorHandler, clearNetworkError,
        clearError, deleteVoyageModalShow, deleteVoyageModalHide, deleteAccountingVoyage,
        WaitForDeleteVoyage, ShowAccountingVoyageDeleteModal, RefreshAccountingVoyagePage, AccountingVoyageAccountName } = props

    const JustRowContextMenu = [
        {
            Name: "editVoyage",
            Action: (Row) => {
                setSelectedAccountingVoyage(Row)
            }
        },
        {
            Name: "deleteVoyage",
            Action: (Row) => {
                deleteVoyageModalShow(Row.accountingName)
            }
        }
    ]
    useEffect(() => {
        if (display) {
            getAccountingVoyages()
        }
    }, [display])

    useEffect(() => {
        if (RefreshAccountingVoyagePage) {
            getAccountingVoyages()
        }
    }, [RefreshAccountingVoyagePage])

    const JustAnimations = {
        CanvasWidth: 1063,
        WaitForRequest: WaitForAccountingVoyage
    }

    const ErrorCode = ErrorHandler && ErrorHandler.ErrorCode;
    const ErrorDescription = ErrorHandler && ErrorHandler.ErrorDescription
    const ErrorState = ErrorHandler && ErrorHandler.ErrorState

    return (
        display &&
        <>
            <div className="AccountingVoyage-Container">
                <div className="AccountingVoyage-Header">
                    <h4 id="HeaderText">{accountingVoyageLanguage[Language].PageTitle}</h4>
                </div>
                <div className="AccountingVoyage-Content-Container">
                    <div className="AccountingVoyage-Content-Header">
                        <Button
                            className="AccountingVoyage-AddRate"
                            onClick={() => AccountingVoyageModalShow()}
                            disabled={false}
                        >
                            {accountingVoyageLanguage[Language].NewAccountingVoyage}
                        </Button>
                    </div>
                    <div className="AccountingVoyage-Table-Container">
                        <JustTable
                            JustSchema={AccountingVoyageSchema(accountingVoyageLanguage[Language].AccountingVoyageTable)}
                            JustData={AccountingVoyages}
                            JustAnimations={JustAnimations}
                            JustContextFunctions={JustRowContextMenu}
                        />
                    </div>

                </div>
            </div>
            <AccountingVesselModal />
            <DeleteModal
                OpenModal={ShowAccountingVoyageDeleteModal}
                Title={accountingVoyageLanguage[Language].DeleteTitle}
                Message={accountingVoyageLanguage[Language].DeleteMessage}
                OnClose={() => deleteVoyageModalHide()}
                onYesClick={(e) => { deleteAccountingVoyage(AccountingVoyageAccountName) }}
                waitForRequest={WaitForDeleteVoyage}
                ButtonYesText={rebateLanguage[Language].ButtonYes}
                ButtonNoText={rebateLanguage[Language].ButtonNo}
            />

            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ButtonLabel={rebateLanguage[Language].GoBackButtonText}
                    ErrorTitle={rebateLanguage[Language].ErrorTitle}
                    ErrorMessage={ErrorDescription}
                    OnClose={() => {
                        switch (ErrorState) {
                            case "NETWORK_ERROR":
                                clearNetworkError()
                                break;
                            case "CUSTOM_ERROR":
                                clearError()
                                break;
                            default:
                                clearError()
                                break;
                        }
                    }}
                />
            }
        </>

    )
}
const mapStateToProps = (state) => {
    return {
        WaitForAccountingVoyage: state.adminPanel.WaitForAccountingVoyage,
        AccountingVoyages: state.adminPanel.AccountingVoyages,
        ErrorHandler: state.adminPanel.ErrorHandler,
        WaitForDeleteVoyage: state.adminPanel.WaitForDeleteVoyage,
        ShowAccountingVoyageDeleteModal: state.adminPanel.ShowAccountingVoyageDeleteModal,
        RefreshAccountingVoyagePage: state.adminPanel.RefreshAccountingVoyagePage,
        AccountingVoyageAccountName: state.adminPanel.AccountingVoyageAccountName,

        Language: state.user.Language
    }
}
export default connect(mapStateToProps, {
    AccountingVoyageModalShow, setSelectedAccountingVoyage,
    getAccountingVoyages, clearNetworkError, clearError,
    deleteVoyageModalShow, deleteVoyageModalHide, deleteAccountingVoyage
})(AccountingVoyage);