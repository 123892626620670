import React from 'react'
import { Lock, Delete, Edit, LockOpen, YesTick, Close, LoadingSpinner, Document } from '@dfds-ui/icons'
import moment from 'moment'

export const TariffTableSchema = (schemaLanguage) => {
    return {
        rowId: "id",
        RowContextMenu: [
            {
                Text: schemaLanguage.EditTariff,
                Icon: <Edit />,
                Name: "editTariff",
            },
            {
                Text: schemaLanguage.DeleteTariff,
                Icon: <Delete />,
                Name: "deleteTariff",
            },
        ],
        ColumnDesign: [
            {
                columnId: "agreementNumber",
                Text: schemaLanguage.AgreementNumber,
            },
            {
                columnId: "tariffNumber",
                Text: schemaLanguage.TariffNumber,
            },
            {
                columnId: "startDate",
                Text: schemaLanguage.StartDate,

            },
            {
                columnId: "endDate",
                Text: schemaLanguage.EndDate,

            },
        ],
        DataDesign: [
            {
                columnId: "agreementNumber",
            },
            {
                columnId: "tariffNumber",
            },
            {
                columnId: "startDate",
                dataType: "EPOCHDATE-SHORT",
            },
            {
                columnId: "endDate",
                dataType: "EPOCHDATE-SHORT",

            },
        ]
    }
}

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
export const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 200; index++) {
        let myObject = {}
        myObject.username = makeid(8)
        myObject.name = makeid(8)
        myObject.surname = makeid(8)
        myObject.email = makeid(8)
        myObject.roles = makeid(8)
        DummyArray.push(myObject)
    }
    return DummyArray
}
function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
