import React, { useEffect } from 'react'
import InvoiceControlHeader from './InvoiceControlHeader/InvoiceControlHeader'
import InvoiceControlTable from './InvoiceControlTable/InvoiceControlTable'
import { getInvoices, clearInvoiceError } from '../../store/action/invoiceControlAction'
import { connect } from 'react-redux'
import { ErrorModal } from '../CommonUI/Modals/ErrorModal'
import { customerLanguage, rebateLanguage } from '../../config/language'
const InvoiceControl = (props) => {
    const { getInvoices, StartDate, EndDate, clearInvoiceError, Language, ErrorHandler } = props
    useEffect(() => {
        getInvoices(StartDate, EndDate)
    }, [])

    const { ErrorCode, ErrorDescription } = ErrorHandler

    return (
        <div className="InvoiceControlPage-Container">
            <InvoiceControlHeader />
            <InvoiceControlTable />
            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ButtonLabel={rebateLanguage[Language].GoBackButtonText}
                    ErrorTitle={customerLanguage[Language].ErrorTitle}
                    ErrorMessage={ErrorDescription}
                    OnClose={() => {
                        clearInvoiceError()
                    }}
                />
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        StartDate: state.invoiceControl.StartDate,
        EndDate: state.invoiceControl.EndDate,
        ErrorHandler: state.invoiceControl.ErrorHandler,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { getInvoices, clearInvoiceError })(InvoiceControl);
