import React, { useState } from 'react'
import './JustLanguage.scss'
import PropTypes from 'prop-types';
import FlagSelector from './FlagSelector';
import FlagNameSelector from './FlagNameSelector';
const JustLanguage = (props) => {
    const { onChange, Languages, defaultLanguage } = props;
    const [showLanguageDialog, setDialog] = useState(false)

    if (!(Languages && typeof Languages === "object" && Languages.length > 0)) return <></>

    return (
        <div onClick={() => setDialog(!showLanguageDialog)} className="JustLanguage-SelectBox">
            {
                <span id="FlagText">{FlagNameSelector(defaultLanguage)}</span>
            }
            {
                FlagSelector(defaultLanguage)
            }

            {
                showLanguageDialog &&
                <div className="JustLanguage-Dialog">
                    {Languages.map((Language, key) => {
                        return (
                            <div onClick={() => onChange(Language)} key={key} className={"JustLanguage-Language " + (Language.toUpperCase() === defaultLanguage.toUpperCase() && "SelectedLanguage")}>
                                <span id="JustLanguage-LanguageIcon">
                                    {FlagSelector(Language)}
                                </span>
                                <span id="JustLanguage-LanguageText">
                                    {FlagNameSelector(Language)}
                                </span>
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}
JustLanguage.propTypes = {
    Languages: PropTypes.array,
    onChange: PropTypes.func
};
JustLanguage.defaultProps = {
    onChange: () => { }
};
export default JustLanguage