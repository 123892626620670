import adminPanelTypes from "../type/adminPanel";
import httpClient from "../../core/httpclient";
import ProjectConfig from "../../config/config";
import XMSErrorHandler from "../../core/xmsErrorHandler";
import PropTypes from 'prop-types';
import moment from "moment";
import { dateToEpoch } from "../../core/epochToDateConverter";
import Axios from "axios";

export const getUsers = () => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/user/getusers`
    dispatch({
        type: adminPanelTypes.GETTING_USERS
    })
    httpClient.post(endPoint, null)
        .then((response) => {
            const users = response.data.result
            dispatch({
                type: adminPanelTypes.GET_USERS,
                Users: users
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.GET_USERS_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
}
export const getUserRoles = () => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/user/getroles`
    dispatch({
        type: adminPanelTypes.GETTING_USER_ROLES
    })
    httpClient.post(endPoint, null)
        .then((response) => {
            const roles = response.data.result
            dispatch({
                type: adminPanelTypes.GET_USER_ROLES,
                Roles: roles,
                IdentityRole: roles[0]
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.GET_USER_ROLES_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const saveUser = (IdentityUsername, IdentityName, IdentitySurname, IdentityEmail, IdentityRole, IdentityStatus, IdentityCompanyCode, IdentityDepartment, IdentityPassword) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/user/saveuser`
    //başladı
    dispatch({
        type: adminPanelTypes.SAVING_USER
    })

    const user = {
        Username: IdentityUsername,
        Email: IdentityEmail,
        Name: IdentityName,
        Surname: IdentitySurname,
        Role: IdentityRole,
        Status: parseInt(IdentityStatus),
        Department: IdentityDepartment,
        CompanyCode: IdentityCompanyCode,
        Password: IdentityPassword
    }

    httpClient.post(endPoint, user)
        .then((response) => {
            dispatch({
                type: adminPanelTypes.SAVE_USER,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.SAVE_USER_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const deleteUser = (username) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/user/deleteuser`
    //başladı
    dispatch({
        type: adminPanelTypes.DELETING_USER
    })
    httpClient.post(endPoint, JSON.stringify(username))
        .then((response) => {
            dispatch({
                type: adminPanelTypes.DELETE_USER,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.DELETE_USER_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const IdentityUserModalShow = () => dispatch => {
    dispatch({
        type: adminPanelTypes.IDENTITY_USER_MODAL_SHOW,
    })
}
export const IdentityUserModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.IDENTITY_USER_MODAL_HIDE,
    })
}
export const setSelectedUser = (Row) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_USER,
        SelectedUser: Row,
        IdentityUsername: Row.username,
        IdentityName: Row.name,
        IdentitySurname: Row.surname,
        IdentityEmail: Row.email,
        IdentityRole: Row.roles[0],
        IdentityStatus: Row.status,
        IdentityCompanyCode: Row.companyCode,
        IdentityDepartment: Row.department,
        IdentityPassword: Row.password,
    })
}
export const deleteModalShow = (Row) => dispatch => {
    dispatch({
        type: adminPanelTypes.DELETE_USER_MODAL_SHOW,
        SelectedUser: Row

    })
}
export const deleteModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.DELETE_USER_MODAL_HIDE,
        SelectedUser: null,
    })
}
export const setInputIdentityUsername = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_USER_NAME,
        IdentityUsername: value
    })
}
export const setInputIdentityName = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_NAME,
        IdentityName: value
    })
}
export const setInputIdentitySurname = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_SURNAME,
        IdentitySurname: value
    })
}
export const setInputIdentityEmail = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_EMAIL,
        IdentityEmail: value
    })
}
export const setInputIdentityRole = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_ROLE,
        IdentityRole: value
    })
}
export const setInputIdentityStatus = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_STATUS,
        IdentityStatus: value
    })
}
export const setInputIdentityCompanyCode = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_COMPANY_CODE,
        IdentityCompanyCode: value
    })
}
export const setInputIdentityDepartment = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_DEPARTMENT,
        IdentityDepartment: value
    })
}
export const setInputIdentityPassword = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_IDENTITY_USER_PASSWORD,
        IdentityPassword: value
    })
}

//Tariffs starts here
export const getTariffs = () => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/tariff/gettariffs`
    //başladı
    dispatch({
        type: adminPanelTypes.GETTING_TARIFFS
    })
    httpClient.post(endPoint, null)
        .then((response) => {
            const tariffs = response.data.result
            dispatch({
                type: adminPanelTypes.GET_TARIFFS,
                Tariffs: tariffs
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.GET_TARIFFS_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const saveTariff = (TariffId, TariffStartDate, TariffEndDate, TariffAgreementNumber, TariffNumber) => dispatch => {
    //başladı
    dispatch({
        type: adminPanelTypes.SAVING_TARIFF
    })

    const endPoint = `${ProjectConfig.apiUrl}/tariff/savetariff`
    const epochStartDate = moment(TariffStartDate).add(moment.unix(TariffStartDate).utcOffset() * 60 * 1000).unix()
    const epochEndDate = moment(TariffEndDate).add(moment.unix(TariffEndDate).utcOffset() * 60 * 1000).unix()

    const tariff = {
        StartDate: epochStartDate,
        EndDate: epochEndDate,
        Id: TariffId,
        AgreementNumber: TariffAgreementNumber,
        TariffNumber: TariffNumber,
        IsDeleted: false
    }

    httpClient.post(endPoint, tariff)
        .then((response) => {
            dispatch({
                type: adminPanelTypes.SAVE_TARIFF,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.SAVE_TARIFF_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const deleteTariff = (tariffId) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/tariff/delete`
    //başladı
    dispatch({
        type: adminPanelTypes.DELETING_TARIFF
    })
    httpClient.post(endPoint, JSON.stringify(tariffId))
        .then((response) => {
            dispatch({
                type: adminPanelTypes.DELETE_TARIFF,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.DELETE_TARIFF_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const TariffModalShow = () => dispatch => {
    dispatch({
        type: adminPanelTypes.TARIFF_MODAL_SHOW,
    })
}
export const TariffModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.TARIFF_MODAL_HIDE,
    })
}
export const setSelectedTariff = (Row) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_TARIFF,
        TariffAgreementNumber: Row.agreementNumber,
        TariffNumber: Row.tariffNumber,
        SelectedTariff: Row,
        TariffStartDate: Row.startDate,
        TariffEndDate: Row.endDate,
    })
}
export const deleteTariffModalShow = (Row) => dispatch => {
    dispatch({
        type: adminPanelTypes.DELETE_TARIFF_MODAL_SHOW,
        SelectedTariff: Row,
        ShowDeleteTariffModal: true
    })
}
export const deleteTariffModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.DELETE_TARIFF_MODAL_HIDE,
        SelectedTariff: null,
    })
}
export const setInputTariffAgreementNumber = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_TARIFF_AGREEMENT_NUMBER,
        TariffAgreementNumber: value,
    })
}
export const setInputTariffNumber = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_TARIFF_NUMBER,
        TariffNumber: value,
    })
}
export const setInputTariffStartDate = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_TARIFF_START_DATE,
        TariffStartDate: value,
    })
}
export const setInputTariffEndDate = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_TARIFF_END_DATE,
        TariffEndDate: value,
    })
}
export const setTariffStartDateValidation = (Validation) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_TARIFF_START_DATE_VALIDATION,
        Validation: Validation
    })
}
export const setTariffEndDateValidation = (Validation) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_TARIFF_END_DATE_VALIDATION,
        Validation: Validation
    })
}
export const setError = (ErrorCode, ErrorDescription) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription
    })
}
export const clearError = () => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ERROR,
        ErrorCode: -1,
        ErrorDescription: null
    })
}
export const setNetworkError = (ErrorCode, ErrorDescription) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_NETWORK_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription
    })
}
export const clearNetworkError = () => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_NETWORK_ERROR,
        ErrorCode: -1,
        ErrorDescription: ""
    })
}

//Tarrifs ends here

//RebateRate start
export const getRates = () => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/rebaterate/getrates`
    //başladı
    dispatch({
        type: adminPanelTypes.GETTING_RATES
    })
    httpClient.post(endPoint, null)
        .then((response) => {
            const rates = response.data.result
            dispatch({
                type: adminPanelTypes.GET_RATES,
                Rates: rates
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.GET_RATES_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const saveRate = (RebateId, RebateName, RebateRatio) => dispatch => {
    //başladı
    dispatch({
        type: adminPanelTypes.SAVING_RATE
    })

    const endPoint = `${ProjectConfig.apiUrl}/rebaterate/saverate`

    const rebateRate = {
        Id: RebateId,
        Name: RebateName,
        Ratio: RebateRatio
    }

    httpClient.post(endPoint, rebateRate)
        .then((response) => {
            dispatch({
                type: adminPanelTypes.SAVE_RATE,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.SAVE_RATE_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const RebateRateModalShow = () => dispatch => {
    dispatch({
        type: adminPanelTypes.REBATE_RATE_MODAL_SHOW,
    })
}
export const RebateRateModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.REBATE_RATE_MODAL_HIDE,
    })
}
export const setSelectedRate = (Row) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_RATE,
        SelectedRate: Row,
        RebateRateName: Row.name,
        RebateRateRatio: Row.ratio
    })
}
export const setInputRebateRateName = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_REBATE_RATE_NAME,
        RebateRateName: value
    })
}
export const setInputRebateRateRatio = (value) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_REBATE_RATE_RATIO,
        RebateRateRatio: value
    })
}

//RebateRate end

//Accounting Vessel Start
export const AccountingVesselModalShow = () => dispatch => {
    dispatch({
        type: adminPanelTypes.ACCOUNTING_VESSEL_MODAL_SHOW
    })
}
export const AccountingVesselModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.ACCOUNTING_VESSEL_MODAL_HIDE
    })
}
export const setSelectedVessel = (SelectedAccountingVessel) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL,
        SelectedAccountingVessel: SelectedAccountingVessel
    })
}
export const getVessel = () => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/accounting/getvessels`
    dispatch({
        type: adminPanelTypes.GETTING_ACCOUNTING_VESSEL
    })
    httpClient.post(endPoint, null)
        .then((response) => {
            const AccountingVessels = response.data.result
            dispatch({
                type: adminPanelTypes.GET_ACCOUNTING_VESSEL,
                AccountingVessels: AccountingVessels
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.GET_ACCOUNTING_VESSEL_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
}
export const deleteVessel = (VesselId) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/accounting/deleteVessel`
    //başladı
    dispatch({
        type: adminPanelTypes.DELETING_VESSEL
    })
    httpClient.post(endPoint, JSON.stringify(VesselId))
        .then((response) => {
            dispatch({
                type: adminPanelTypes.DELETE_VESSEL,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.DELETE_VESSEL_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}

export const saveVessel = (AccountingVesselId, AccountingCode, AccountingVesselCode, AccountingVesselName, Rented) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/accounting/savevessels`
    //başladı
    dispatch({
        type: adminPanelTypes.SAVING_VESSEL
    })

    const AccountingVesselDTO = [{
        AccountingVesselId: AccountingVesselId,
        Code: AccountingCode,
        VesselCode: AccountingVesselCode,
        VesselName: AccountingVesselName,
        IsRented: Rented,
        IsDeleted: false
    }]

    httpClient.post(endPoint, JSON.stringify(AccountingVesselDTO))
        .then((response) => {
            dispatch({
                type: adminPanelTypes.SAVE_VESSEL,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.SAVE_VESSEL_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}

export const setAccountingCode = (AccountingCode) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_ACCOUNTING_CODE,
        AccountingCode: AccountingCode
    })
}
export const setAccountingVesselCode = (VesselCode) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL_CODE,
        VesselCode: VesselCode
    })
}
export const setAccountingVesselId = (VesselId) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL_ID,
        VesselId: VesselId
    })
}

export const setAccountingVesselName = (VesselName) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL_NAME,
        VesselName: VesselName
    })
}
export const setAccountingVesselRented = (Rented) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_ACCOUNTING_VESSEL_RENTED,
        AccountingVesselRented: Rented
    })
}
export const deleteVesselModalShow = (accountingVesselId) => dispatch => {
    dispatch({
        type: adminPanelTypes.ACCOUNTING_VESSEL_DELETE_MODAL_SHOW,
        AccountingVesselId: accountingVesselId
    })
}
export const deleteVesselModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.ACCOUNTING_VESSEL_DELETE_MODAL_HIDE,
    })
}
//Accounting Vessel End

//Accounting Voyage Start
export const AccountingVoyageModalShow = () => dispatch => {
    dispatch({
        type: adminPanelTypes.ACCOUNTING_VOYAGE_MODAL_SHOW,
    })
}
export const AccountingVoyageModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.ACCOUNTING_VOYAGE_MODAL_HIDE,
    })
}
export const setSelectedAccountingVoyage = (SelectedAccountingVoyage) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_ACCOUNTING_VOYAGE,
        SelectedAccountingVoyage: SelectedAccountingVoyage
    })
}
export const setAccountingVoyageName = (AccountingVoyageName) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ACCOUNTING_VOYAGE_NAME,
        AccountingVoyageName: AccountingVoyageName
    })
}
export const setAccountingVoyageCode = (AccountingVoyageCode) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ACCOUNTING_VOYAGE_CODE,
        AccountingVoyageCode: AccountingVoyageCode
    })
}
export const setAccountingVoyageAccountName = (AccountingVoyageAccountName) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ACCOUNTING_VOYAGE_ACCOUNT_NAME,
        AccountingVoyageAccountName: AccountingVoyageAccountName
    })
}
export const setAccountingVoyageRentCode = (AccountingVoyageRentCode) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ACCOUNTING_VOYAGE_RENT_CODE,
        AccountingVoyageRentCode: AccountingVoyageRentCode
    })
}
export const setAccountingVoyageRentName = (AccountingVoyageRentName) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ACCOUNTING_VOYAGE_RENT_NAME,
        AccountingVoyageRentName: AccountingVoyageRentName
    })
}
export const setAccountingVoyageGroupName = (AccountingVoyageGroupName) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_ACCOUNTING_VOYAGE_GROUP_NAME,
        AccountingVoyageGroupName: AccountingVoyageGroupName
    })
}
export const getAccountingVoyages = (AccountingVoyageRentName) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/accounting/getvoyages`
    //başladı
    dispatch({
        type: adminPanelTypes.GETTING_ACCOUNTING_VOYAGE
    })
    httpClient.post(endPoint, null)
        .then((response) => {
            const AccountingVoyages = response.data.result
            dispatch({
                type: adminPanelTypes.GET_ACCOUNTING_VOYAGE,
                AccountingVoyages: AccountingVoyages
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.GET_ACCOUNTING_VOYAGE_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
}
export const deleteAccountingVoyage = (VoyageId) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/accounting/deleteVoyage`
    dispatch({
        type: adminPanelTypes.DELETING_VOYAGE
    })
    httpClient.post(endPoint, JSON.stringify(VoyageId))
        .then((response) => {
            dispatch({
                type: adminPanelTypes.DELETE_VOYAGE,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.DELETE_VOYAGE_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const deleteVoyageModalShow = (AccountingVoyageAccountId) => dispatch => {
    dispatch({
        type: adminPanelTypes.ACCOUNTING_VOYAGE_DELETE_MODAL_SHOW,
        AccountingVoyageAccountId: AccountingVoyageAccountId
    })
}
export const deleteVoyageModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.ACCOUNTING_VOYAGE_DELETE_MODAL_HIDE,
    })
}
export const saveAccountingVoyages = (AccountingVoyageName, AccountingVoyageGroupName, AccountingVoyageCode,
    AccountingRentCode, AcountingName, AccountingRentName) => dispatch => {
        const endPoint = `${ProjectConfig.apiUrl}/accounting/savevoyages`
        //başladı
        dispatch({
            type: adminPanelTypes.SAVING_ACCOUNTING_VOYAGE
        })
        const AccountingVesselDTO = [{
            Name: AccountingVoyageName,
            GroupName: AccountingVoyageGroupName,
            AccountingCode: AccountingVoyageCode,
            AccountingRentCode: AccountingRentCode,
            AccountingName: AcountingName,
            AccountingRentName: AccountingRentName,
            IsDeleted: false
        }]

        httpClient.post(endPoint, JSON.stringify(AccountingVesselDTO))
            .then((response) => {
                dispatch({
                    type: adminPanelTypes.SAVE_ACCOUNTING_VOYAGE,
                })
            })
            .catch((error) => {
                const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
                dispatch({
                    type: adminPanelTypes.SAVE_ACCOUNTING_VOYAGE_ERROR,
                    ErrorCode: ErrorCode,
                    ErrorDescription: ErrorDescription
                })
                //hata
            })
    }

//Accounting Voyage End
//UnitTypes

export const getUnitTypes = () => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/unittype/getunittypes`
    //başladı
    dispatch({
        type: adminPanelTypes.GETTING_UNIT_TYPES
    })
    httpClient.post(endPoint, null)
        .then((response) => {
            const unitTypes = response.data.result
            dispatch({
                type: adminPanelTypes.GET_UNIT_TYPES,
                UnitTypes: unitTypes
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.GET_UNIT_TYPES_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const saveUnitType = (SelectedUnitType) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/unittype/saveunittype`
    //başladı
    dispatch({
        type: adminPanelTypes.SAVING_UNIT_TYPE
    })

    const unitTypeDto =
    {
        Id: SelectedUnitType.id,
        Name: SelectedUnitType.name,
        LineType: SelectedUnitType.lineType,
        IsEmpty: SelectedUnitType.isEmpty,
        IsValid: SelectedUnitType.isValid
    }
    httpClient.post(endPoint, JSON.stringify(unitTypeDto))
        .then((response) => {
            dispatch({
                type: adminPanelTypes.SAVE_UNIT_TYPE,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.SAVE_UNIT_TYPE_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const deleteUnitType = (Id) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/unittype/deleteunittype`
    //başladı
    dispatch({
        type: adminPanelTypes.DELETING_UNIT_TYPE
    })
    httpClient.post(endPoint, JSON.stringify(Id))
        .then((response) => {
            dispatch({
                type: adminPanelTypes.DELETE_UNIT_TYPE,
                SelectedUnitType: null,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: adminPanelTypes.DELETE_UNIT_TYPE_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            //hata
        })
}
export const UnitTypeModalShow = () => dispatch => {
    dispatch({
        type: adminPanelTypes.UNIT_TYPE_MODAL_SHOW,
    })
}
export const UnitTypeModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.UNIT_TYPE_MODAL_HIDE,
    })
}
export const setSelectedUnitType = (Row) => dispatch => {
    dispatch({
        type: adminPanelTypes.SET_SELECTED_UNIT_TYPE,
        SelectedUnitType: Row
    })
}
export const unitTypeDeleteModalShow = (Row) => dispatch => {
    dispatch({
        type: adminPanelTypes.DELETE_UNIT_TYPE_MODAL_SHOW,
        SelectedUnitType: Row
    })
}
export const unitTypeDeleteModalHide = () => dispatch => {
    dispatch({
        type: adminPanelTypes.DELETE_UNIT_TYPE_MODAL_HIDE,
        SelectedUnitType: null,
    })
}
