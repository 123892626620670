
import React from 'react'
import YesNoModal from '../../CommonUI/Modals/YesNoModal/YesNoModal';
import { rebateLanguage } from '../../../config/language';

const CustomerChangesModal = (props) => {
    const { ChangesModal, Language, ForwardAction, hideChangesModal } = props
    return (
        <>
            {
                ChangesModal &&
                <YesNoModal
                    OpenModal={true}
                    Title={rebateLanguage[Language].ChangesTitle}
                    Message={rebateLanguage[Language].ChangesModalMessage}
                    ButtonYesText={rebateLanguage[Language].ButtonYes}
                    ButtonNoText={rebateLanguage[Language].ButtonNo}
                    OnClose={() => hideChangesModal()}
                    onYesClick={() => { ForwardAction(); }}
                />
            }
        </>
    )
}

export default CustomerChangesModal