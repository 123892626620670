import React, { useEffect } from 'react'
import './Modal.scss'
import { Modal } from '@dfds-ui/modal'
import { connect } from 'react-redux'
import { AccountingVesselModalHide, setAccountingVesselRented, setAccountingVesselName, setAccountingVesselCode, setAccountingCode, saveVessel } from '../../../../store/action/adminPanelAction'
import { Input, Button, SelectField } from '@dfds-ui/react-components'
import { accountingVesselLanguage } from '../../../../config/language'

const AccountingVesselModal = (props) => {
    const { AccountingVesselModalHide, ShowAccountingVesselModal, SelectedAccountingVessel, AccountingCode,
        AccountingVesselName, AccountingVesselCode, AccountingVesselRented,
        setAccountingVesselRented, setAccountingVesselName, setAccountingVesselCode, setAccountingCode, saveVessel, WaitForSaveVessel, Language, AccountingVesselId
    } = props
    useEffect(() => {
        if (ShowAccountingVesselModal) {
        }
    }, [ShowAccountingVesselModal])
    return (
        <Modal className="AccountingVessel-Modal" heading={SelectedAccountingVessel ? accountingVesselLanguage[Language].ModalEdit : accountingVesselLanguage[Language].ModalNew}
            onRequestClose={() => AccountingVesselModalHide()} isOpen={ShowAccountingVesselModal}>
            <div className="AccountingVessel-Modal-Container">
                <div className="AccountingVessel-Modal-Header">

                </div>
                <div className="AccountingVessel-Modal-Content">
                    <div className="RightContent-Container">
                        <div className="InputWithLabel" id="code">
                            <span className="Input-Label">
                                {accountingVesselLanguage[Language].Table.Code}
                            </span>
                            {
                                <Input
                                    size={"small"}
                                    onChange={(event) => setAccountingCode(event.target.value)}
                                    value={SelectedAccountingVessel && SelectedAccountingVessel.code}
                                    disabled={SelectedAccountingVessel}
                                />
                            }
                        </div>
                        <div className="InputWithLabel" id="vesselCode">
                            <span className="Input-Label">
                                {accountingVesselLanguage[Language].Table.VesselCode}
                            </span>

                            <Input
                                onChange={(event) => { setAccountingVesselCode(event.target.value); }}
                                defaultValue={SelectedAccountingVessel && SelectedAccountingVessel.vesselCode}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="vesselName">
                            <span className="Input-Label">
                                {accountingVesselLanguage[Language].Table.VesselName}
                            </span>
                            <Input
                                onChange={(event) => { setAccountingVesselName(event.target.value) }}
                                defaultValue={SelectedAccountingVessel && SelectedAccountingVessel.vesselName}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="vesselRented">
                            <span className="Input-Label">
                                {accountingVesselLanguage[Language].Table.Rented}
                            </span>
                            <input
                                type="checkbox"
                                defaultChecked={SelectedAccountingVessel && SelectedAccountingVessel.isRented}
                                onChange={(event) => { setAccountingVesselRented(event.target.checked) }}
                            ></input>
                        </div>
                    </div>
                </div>
                <div className="AccountingVessel-Modal-Footer">
                    <Button
                        className="AccountingVessel-Button-Save"
                        disabled={WaitForSaveVessel}
                        onClick={() => {
                            if (SelectedAccountingVessel) {
                                saveVessel(SelectedAccountingVessel.accountingVesselId, SelectedAccountingVessel.code,
                                    AccountingVesselCode ? AccountingVesselCode : SelectedAccountingVessel.vesselCode,
                                    AccountingVesselName ? AccountingVesselName : SelectedAccountingVessel.vesselName,
                                    AccountingVesselRented !== null ? AccountingVesselRented : SelectedAccountingVessel.isRented)
                            } else {
                                saveVessel(AccountingVesselId, AccountingCode, AccountingVesselCode, AccountingVesselName, AccountingVesselRented)
                            }
                        }}
                    >
                        {accountingVesselLanguage[Language].SaveButton}
                    </Button>
                </div>
            </div>
        </Modal >
    )
}
const mapStateToProps = (state) => {
    return {
        ShowAccountingVesselModal: state.adminPanel.ShowAccountingVesselModal,
        AccountingCode: state.adminPanel.AccountingCode,
        AccountingVesselId: state.adminPanel.AccountingVesselId,
        AccountingVesselName: state.adminPanel.AccountingVesselName,
        SelectedAccountingVessel: state.adminPanel.SelectedAccountingVessel,
        AccountingVesselCode: state.adminPanel.AccountingVesselCode,
        AccountingVesselRented: state.adminPanel.AccountingVesselRented,
        WaitForSaveVessel: state.adminPanel.WaitForSaveVessel,
        Language: state.user.Language,

    }
}
export default connect(mapStateToProps, {
    AccountingVesselModalHide, setAccountingVesselRented, setAccountingVesselName,
    setAccountingVesselCode, setAccountingCode, saveVessel
})(AccountingVesselModal)