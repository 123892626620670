import React, { useState } from 'react'
import './AdminPanel.scss';
import { connect } from 'react-redux';
import IdentityUser from './IdentityUser/IdentityUser';
import RebateRate from './RebateRate/RebateRate';
import Tariff from './Tariff/Tariff';
import AccountingVessel from './AccountingVessel/AccountingVessel';
import AccountingVoyage from './AccountingVoyage/AccountingVoyage';
import UnitType from './UnitType/UnitType';

const AdminPanel = () => {
    const [pageNavigation, setPageNavigation] = useState("IDENTITIY_USER")
    return (
        <div className="AdminPanel-Container">
            <div className="Submenu-Container">
                <div className="Menu-Container">
                    <span id="Menu-Header">Admin Panel</span>
                    <span
                        className={pageNavigation === "IDENTITIY_USER" ? "ACTIVE_MENU" : ""}
                        onClick={() => { setPageNavigation("IDENTITIY_USER") }}
                        id="Menu-Text">
                        User
                     </span>
                    <span
                        className={pageNavigation === "UNIT_TYPE" ? "ACTIVE_MENU" : ""}
                        onClick={() => { setPageNavigation("UNIT_TYPE") }}
                        id="Menu-Text">
                        Unit Type
                    </span>
                    <span
                        className={pageNavigation === "REBATE_RATE" ? "ACTIVE_MENU" : ""}
                        onClick={() => { setPageNavigation("REBATE_RATE") }}
                        id="Menu-Text">
                        Rebate Rate
                         </span>
                    <span
                        className={pageNavigation === "ACCOUNTING_VOYAGE" ? "ACTIVE_MENU" : ""}
                        onClick={() => { setPageNavigation("ACCOUNTING_VOYAGE") }}
                        id="Menu-Text">Accounting Voyage
                    </span>
                    <span
                        className={pageNavigation === "ACCOUNTING_VESSEL" ? "ACTIVE_MENU" : ""}
                        onClick={() => { setPageNavigation("ACCOUNTING_VESSEL") }}
                        id="Menu-Text">Accounting Vessel
                    </span>
                    <span
                        className={pageNavigation === "TARIFF" ? "ACTIVE_MENU" : ""}
                        onClick={() => { setPageNavigation("TARIFF") }}
                        id="Menu-Text">Tariff
                    </span>
                </div>

            </div>
            <div className="Content-Container">
                <IdentityUser display={pageNavigation === "IDENTITIY_USER"} />
                <UnitType display={pageNavigation === "UNIT_TYPE"} />
                <RebateRate display={pageNavigation === "REBATE_RATE"} />
                <AccountingVoyage display={pageNavigation === "ACCOUNTING_VOYAGE"} />
                <AccountingVessel display={pageNavigation === "ACCOUNTING_VESSEL"} />
                <Tariff display={pageNavigation === "TARIFF"} />

            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {

    }
}
export default connect(mapStateToProps, {})(AdminPanel);