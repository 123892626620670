import httpClient from "../../core/httpclient";
import rebateTypes from "../type/rebate";
import ProjectConfig from "../../config/config";
import XMSErrorHandler from "../../core/xmsErrorHandler";
import PropTypes from "prop-types";
import moment from "moment";
import { dateToEpoch } from "../../core/epochToDateConverter";
import { dataHistoryBinder } from "../../core/dataBinder";
import DownloadFile from "../../core/downloadFile";
import Axios from "axios";

export const getTransactions =
  (
    periodStartMonth,
    periodEndMonth,
    periodYear,
    customerName,
    customerCode,
    eStatus
  ) =>
  async (dispatch) => {
    const today = new Date();
    const todayMonth =
      today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1;
    const _pStartMonth =
      periodStartMonth === undefined
        ? todayMonth
        : periodStartMonth < 10
        ? "0" + periodStartMonth
        : periodStartMonth;
    const _pEndMonth =
      periodStartMonth === undefined
        ? todayMonth
        : periodEndMonth < 10
        ? "0" + periodEndMonth
        : periodEndMonth;

    var startDate = moment(
      (periodYear === undefined ? today.getFullYear() : periodYear) +
        "-" +
        _pStartMonth +
        "-01"
    );
    var endDate = moment(
      (periodYear === undefined ? today.getFullYear() : periodYear) +
        "-" +
        _pEndMonth +
        "-01"
    );

    endDate = moment(endDate).add(1, "M").subtract(1, "S");

    const startDateEpoch = dateToEpoch(startDate.toDate());
    const endDateEpoch = dateToEpoch(endDate.toDate());

    const endPoint = `${ProjectConfig.apiUrl}/transaction/gettransactions`;
    const rebateInformation = {
      periodStart: startDateEpoch,
      periodEnd: endDateEpoch,
      customerName: customerName,
      customerCode: customerCode,
      isEmailSent: eStatus,
    };
    dispatch({
      type: rebateTypes.GETTING_TRANSACTIONS,
    });
    httpClient
      .post(endPoint, rebateInformation)
      .then((response) => {
        const transactions = response.data.result;
        dispatch({
          type: rebateTypes.GET_TRANSACTIONS,
          Transactions: transactions,
        });

        if (transactions.length < 1) {
          dispatch({
            type: rebateTypes.NOT_FOUND,
          });
        }
      })
      .catch((error) => {
        const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
        dispatch({
          type: rebateTypes.GET_TRANSACTIONS_ERROR,
          ErrorCode: ErrorCode,
          ErrorDescription: ErrorDescription,
        });
      });
  };
getTransactions.propTypes = {
  periodStartMonth: PropTypes.number,
  periodEndMonth: PropTypes.number,
  periodYear: PropTypes.number,
  customerName: PropTypes.string,
  customerCode: PropTypes.string,
  eStatus: PropTypes.bool,
};
getTransactions.defaultProps = {
  periodStartMonth: null,
  periodEndMonth: null,
  periodYear: null,
  customerName: "",
  customerCode: "",
  eStatus: null,
};

export const clearTransactions = () => (dispatch) => {
  dispatch({
    type: rebateTypes.CLEAR_REBATES,
  });
};
const CancelToken = Axios.CancelToken;
let cancel;
export const getInvoices = (transactionId) => (dispatch) => {
  cancel && cancel();
  const endPoint = `${ProjectConfig.apiUrl}/transaction/gettransactioninvoices?transactionId=${transactionId}`;
  dispatch({
    type: rebateTypes.GETTING_INVOICES,
  });

  httpClient
    .post(endPoint, null, {
      cancelToken: new CancelToken((c) => (cancel = c)),
    })
    .then((response) => {
      dispatch({
        type: rebateTypes.GET_INVOICES,
        Invoices: response.data.result,
      });
    })
    .catch((error) => {
      if (error.message === undefined) return; //calceled
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      dispatch({
        type: rebateTypes.GET_INVOICES_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription,
      });
      dispatch({
        type: rebateTypes.CLEAR_INVOICES,
      });
    });
};
export const setSelectedTransactions = (selectedTransactions) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_SELECTED_TRANSACTIONS,
    SelectedTransactions: selectedTransactions,
  });
};

export const saveHistory = (dataHistory, allData, callBack) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/transaction/savetransaction`;
  dispatch({
    type: rebateTypes.SAVING_TRANSACTION,
  });
  dispatch({
    type: rebateTypes.PAGE_STATUS,
    Statusname: "Saving",
  });
  const bindedHistory = dataHistoryBinder(dataHistory);
  if (!bindedHistory) {
    //Rebate Ratio 0 or null
    dispatch({
      type: rebateTypes.REBATE_ERROR,
      ErrorCode: 1,
      ErrorDescription: "Rebate Ratio cannot 0 or less or null.",
    });
    return;
  }

  httpClient
    .post(endPoint, bindedHistory)
    .then((response) => {
      dispatch({
        type: rebateTypes.TRANSACTION_SAVED,
      });
    })
    .catch((error) => {
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      dispatch({
        type: rebateTypes.REBATE_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription,
      });
    })
    .finally(() => {
      dispatch({
        type: rebateTypes.PROCESS_OVER,
      });
      callBack();
    });
};
export const setTransactionFilter =
  (
    StartMonth,
    EndMonth,
    TransactionYear,
    CustomerName,
    CustomerCode,
    eStatus
  ) =>
  (dispatch) => {
    dispatch({
      type: rebateTypes.SET_FILTER,
      StartMonth: StartMonth,
      EndMonth: EndMonth,
      TransactionYear: TransactionYear,
      CustomerName: CustomerName,
      CustomerCode: CustomerCode,
      eStatus: eStatus,
    });
  };
//------------------
export const updateHistory = (History) => (dispatch) => {
  dispatch({
    type: rebateTypes.TRANSACTION_DATA_CHANGED,
    UpdateHistory: History,
  });
};
//------------------
export const sortTransaction = (sortedArray) => (dispatch) => {
  dispatch({
    type: rebateTypes.SORT_TRANSACTIONS,
    Transactions: sortedArray,
  });
};
export const processTransactions =
  (StartMonth, EndMonth, TransactionYear, firstCall, callBack) =>
  (dispatch) => {
    const endPoint = `${ProjectConfig.apiUrl}/transaction/processtransactions`;
    dispatch({
      type: rebateTypes.PROCESSING_TRANSACTION,
    });
    const RequestObject = {
      PeriodStart: StartMonth,
      PeriodEnd: EndMonth,
      PeriodYear: TransactionYear,
    };
    httpClient
      .post(endPoint, JSON.stringify(RequestObject))
      .then((response) => {
        dispatch({
          type: rebateTypes.PROCESS_STARTED,
        });
      })
      .catch((error) => {
        const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
        if (
          ErrorDescription &&
          ErrorDescription.toUpperCase() === "PROCESSING"
        ) {
          dispatch({
            type: rebateTypes.PROCESS_PROCESSING,
          });
        } else {
          dispatch({
            type: rebateTypes.REBATE_ERROR,
            ErrorCode: ErrorCode,
            ErrorDescription: ErrorDescription,
          });
        }
      });
  };
export const getProcessStatus =
  (StartMonth, EndMonth, TransactionYear) => (dispatch) => {
    const endPoint = `${ProjectConfig.apiUrl}/transaction/getstatusofprocesstransactions`;
    dispatch({
      type: rebateTypes.GETTING_PROCESS_STATUS,
    });
    const RequestObject = {
      PeriodStart: StartMonth,
      PeriodEnd: EndMonth,
      PeriodYear: TransactionYear,
    };
    httpClient
      .post(endPoint, JSON.stringify(RequestObject))
      .then((response) => {
        dispatch({
          type: rebateTypes.PROCESS_SUCCESS,
        });
      })
      .catch((error) => {
        const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
        if (
          ErrorDescription &&
          ErrorDescription.toUpperCase() === "PROCESSING"
        ) {
          dispatch({
            type: rebateTypes.PROCESS_PROCESSING,
          });
        } else {
          dispatch({
            type: rebateTypes.PROCESS_FAILED,
          });
          dispatch({
            type: rebateTypes.REBATE_ERROR,
            ErrorCode: ErrorCode,
            ErrorDescription: ErrorDescription,
          });
        }
      });
  };
export const setProcessProcessingLock = (lockStatus) => (dispatch) => {
  dispatch({
    type: rebateTypes.PROCESS_PROCESSING_LOCK,
    ProcessProcessingLock: lockStatus,
  });
};
export const setProcessProcessingModal = (ModalVisibilty) => (dispatch) => {
  dispatch({
    type: rebateTypes.MODAL_PROCESS_PROCESSING,
    ProcessProcessing: ModalVisibilty,
  });
};

//Work Flow'una çok aykırı olduğu için eklemek durumunda kaldım.
export const isProcessProcessing =
  (StartMonth, EndMonth, TransactionYear) => (dispatch) => {
    const endPoint = `${ProjectConfig.apiUrl}/transaction/getstatusofprocesstransactions`;
    console.log("GETTING_PROCESS_STATUS_IS");
    dispatch({
      type: rebateTypes.GETTING_PROCESS_STATUS_IS,
    });
    const RequestObject = {
      PeriodStart: StartMonth,
      PeriodEnd: EndMonth,
      PeriodYear: TransactionYear,
    };
    httpClient
      .post(endPoint, JSON.stringify(RequestObject))
      .then((response) => {
        console.log("PROCESS_SUCCESS_IS");

        dispatch({
          type: rebateTypes.PROCESS_SUCCESS_IS,
        });
      })
      .catch((error) => {
        const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);

        if (
          ErrorDescription &&
          ErrorDescription.toUpperCase() === "PROCESSING"
        ) {
          dispatch({
            type: rebateTypes.PROCESS_PROCESSING_IS,
          });
        } else {
          console.log("PROCESS_FAILED_IS");

          dispatch({
            type: rebateTypes.PROCESS_FAILED_IS,
          });
          dispatch({
            type: rebateTypes.REBATE_ERROR,
            ErrorCode: ErrorCode,
            ErrorDescription: ErrorDescription,
          });
        }
      });
  };
export const processLock = (LockStatus, ProcessAll) => (dispatch) => {
  dispatch({
    type: rebateTypes.OPEN_PROCESSLOCK,
    Lock: LockStatus,
    ProcessAll: ProcessAll,
  });
};
export const processModalVisibilty = (modalVisibility) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_PROCESS_MODAL,
    ProcessModalVisibility: modalVisibility,
  });
};
export const setDataHistory = (DataHistory) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_DATA_HISTORY,
    DataHistory: DataHistory,
  });
};
export const setIntegrationModal = (Show) => (dispatch) => {
  dispatch({
    type: rebateTypes.SHOW_INTEGRATION_MODAL,
    Show: Show,
  });
};
export const setInvoiceRebateModal = (Show) => (dispatch) => {
  dispatch({
    type: rebateTypes.SHOW_INVOICE_REBATE_MODAL,
    Show: Show,
  });
};
export const reprocessTransaction = (transactionIds) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/transaction/reprocesstransaction`;
  dispatch({
    type: rebateTypes.PROCESSING_TRANSACTION,
  });
  dispatch({
    type: rebateTypes.PAGE_STATUS,
    Statusname: "Processing",
  });
  httpClient
    .post(endPoint, JSON.stringify(transactionIds))
    .then((response) => {
      dispatch({
        type: rebateTypes.PROCESS_SUCCESS,
      });
    })
    .catch((error) => {
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      dispatch({
        type: rebateTypes.REBATE_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription,
      });
    })
    .finally(() => {
      dispatch({
        type: rebateTypes.PROCESS_OVER,
      });
    });
};
export const cancelProcessTransactions =
  (StartMonth, EndMonth, TransactionYear, firstCall, callBack) =>
  (dispatch) => {
    const endPoint = `${ProjectConfig.apiUrl}/transaction/cancelprocesstransactions`;
    dispatch({
      type: rebateTypes.PROCESSING_CANCEL_TRANSACTION,
    });
    const RequestObject = {
      PeriodStart: StartMonth,
      PeriodEnd: EndMonth,
      PeriodYear: TransactionYear,
    };
    httpClient
      .post(endPoint, JSON.stringify(RequestObject))
      .then((response) => {
        dispatch({
          type: rebateTypes.PROCESS_CANCEL_COMPLETED,
        });
      })
      .catch((error) => {
        const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
        if (
          ErrorDescription &&
          ErrorDescription.toUpperCase() === "PROCESSING"
        ) {
          dispatch({
            type: rebateTypes.PROCESS_CANCEL_PROCESSING,
          });
        } else {
          dispatch({
            type: rebateTypes.REBATE_ERROR,
            ErrorCode: ErrorCode,
            ErrorDescription: ErrorDescription,
          });
        }
      });
  };
export const pageStatus = (Statusname) => (dispatch) => {
  dispatch({
    type: rebateTypes.PAGE_STATUS,
    Statusname: Statusname,
  });
};
export const lockTransaction = (transactionIds) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/transaction/locktransactions`;
  dispatch({
    type: rebateTypes.PROCESSING_TRANSACTION,
  });
  dispatch({
    type: rebateTypes.PAGE_STATUS,
    Statusname: "Locking",
  });
  httpClient
    .post(endPoint, JSON.stringify(transactionIds))
    .then((response) => {
      dispatch({
        type: rebateTypes.PROCESS_SUCCESS,
      });
    })
    .catch((error) => {
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      dispatch({
        type: rebateTypes.REBATE_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription,
      });
    })
    .finally(() => {
      dispatch({
        type: rebateTypes.PROCESS_OVER,
      });
    });
};
export const unlockTransaction = (transactionIds) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/transaction/unlocktransactions`;
  dispatch({
    type: rebateTypes.PROCESSING_TRANSACTION,
  });
  dispatch({
    type: rebateTypes.PAGE_STATUS,
    Statusname: "Unlocking",
  });
  httpClient
    .post(endPoint, JSON.stringify(transactionIds))
    .then((response) => {
      dispatch({
        type: rebateTypes.PROCESS_SUCCESS,
      });
    })
    .catch((error) => {
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      dispatch({
        type: rebateTypes.REBATE_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription,
      });
    })
    .finally(() => {
      dispatch({
        type: rebateTypes.PROCESS_OVER,
      });
    });
};
export const copyTransaction = (transactionId) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/transaction/copytransaction`;
  dispatch({
    type: rebateTypes.PROCESSING_TRANSACTION,
  });
  dispatch({
    type: rebateTypes.PAGE_STATUS,
    Statusname: "Copying",
  });
  httpClient
    .post(endPoint, JSON.stringify(transactionId))
    .then((response) => {
      dispatch({
        type: rebateTypes.PROCESS_SUCCESS,
      });
    })
    .catch((error) => {
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      dispatch({
        type: rebateTypes.REBATE_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription,
      });
    })
    .finally(() => {
      dispatch({
        type: rebateTypes.PROCESS_OVER,
      });
    });
};
export const changePageStatus = (Statusname) => (dispatch) => {
  dispatch({
    type: rebateTypes.PAGE_STATUS,
    Statusname: Statusname,
    WaitForProcess: Statusname ? true : false,
  });
};
export const sendTransactionDocument = (transactionIds) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/transaction/sendtransactiondocuments`;
  dispatch({
    type: rebateTypes.PROCESSING_TRANSACTION,
  });
  dispatch({
    type: rebateTypes.PAGE_STATUS,
    Statusname: "Sending",
  });
  httpClient
    .post(endPoint, JSON.stringify(transactionIds))
    .then((response) => {
      dispatch({
        type: rebateTypes.PROCESS_SUCCESS,
      });
    })
    .catch((error) => {
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      dispatch({
        type: rebateTypes.REBATE_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription,
      });
    })
    .finally(() => {
      dispatch({
        type: rebateTypes.PROCESS_OVER,
      });
    });
};
export const setError = (ErrorCode, ErrorDescription) => (dispatch) => {
  dispatch({
    type: rebateTypes.REBATE_ERROR,
    ErrorCode: ErrorCode,
    ErrorDescription: ErrorDescription,
  });
};
export const showHistoryCheck = (Action) => (dispatch) => {
  dispatch({
    type: rebateTypes.SHOW_HISTORY_MODAL,
    Action: Action,
  });
};
export const hideHistoryCheck = () => (dispatch) => {
  dispatch({
    type: rebateTypes.HIDE_HISTORY_MODAL,
  });
};
export const clearRebateError = () => (dispatch) => {
  dispatch({
    type: rebateTypes.REBATE_ERROR,
    ErrorCode: -1,
    ErrorDescription: null,
  });
};
export const calculateTransaction =
  (
    transactionId,
    rebateRatio,
    manualChargeBackAmount,
    invoiceChargeBackAmount,
    rowIndex
  ) =>
  (dispatch) => {
    const endPoint = `${ProjectConfig.apiUrl}/transaction/calculatetransaction`;
    const calculatePromise = new Promise(function (resolve, reject) {
      dispatch({
        type: rebateTypes.CALCULATING_TRANSACTION,
      });
      const bodyParameters = {
        TransactionId: transactionId,
        RebateRatio: rebateRatio,
        ManualChargeBackAmount: manualChargeBackAmount,
        InvoiceChargeBackAmount: invoiceChargeBackAmount,
      };
      httpClient
        .post(endPoint, JSON.stringify(bodyParameters))
        .then((response) => {
          const calculated = response.data.result;
          dispatch({
            type: rebateTypes.TRANSACTION_CALCULATED,
            calculatedRow: calculated,
            calculatedRowIndex: rowIndex,
          });
          resolve(calculated);
        })
        .catch((error) => {
          const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
          dispatch({
            type: rebateTypes.REBATE_ERROR,
            ErrorCode: ErrorCode,
            ErrorDescription: ErrorDescription,
          });
          reject(ErrorDescription);
        })
        .finally(() => {});
    });
    return calculatePromise;
  };
export const setCalculatedRow = (cRow, cRowIndex) => (dispatch) => {
  dispatch({
    type: rebateTypes.TRANSACTION_CALCULATED,
    calculatedRow: cRow,
    calculatedRowIndex: cRowIndex,
  });
};
export const setExcelModal = (showHide) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_EXCELMODAL,
    ExcelModal: showHide,
  });
};
const exportObject = (transactionIds, PeriodYear, PeriodStart, PeriodEnd) => {
  return {
    PeriodYear: PeriodYear,
    PeriodStart: PeriodStart,
    PeriodEnd: PeriodEnd,
    TransactionIds: transactionIds,
  };
};

export const setServerExcelStatus = (Statusname) => (dispatch) => {
  dispatch({
    type: rebateTypes.CHANGE_EXCEL_STATUS_NAME,
    ServerExcelDownloadStatus: Statusname,
  });
};
export const setPDFStatus = (Statusname) => (dispatch) => {
  dispatch({
    type: rebateTypes.CHANGE_PDF_STATUS,
    PDFStatus: Statusname,
  });
};

export const setIntegrationExcelIntervalId = (IntervalId) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_INTEGRATION_EXCEL_INTERVAL_ID,
    IntegrationExcelIntervalId: IntervalId,
  });
};
export const setPDFIntervalId = (IntervalId) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_PDF_INTERVAL_ID,
    PDFIntervalId: IntervalId,
  });
};
export const setInvoiceRebateIntervalId = (IntervalId) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_INVOICE_REBATE_INTERVAL_ID,
    InvoiceRebateIntervalId: IntervalId,
  });
};
export const setProcessIntervalId = (IntervalId) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_PROCESS_INTERVAL_ID,
    ProcessIntervalId: IntervalId,
  });
};
export const setProcessProcessingIntervalId = (IntervalId) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_PROCESS_PROCESSING_INTERVAL_ID,
    ProcessProcessingIntervalId: IntervalId,
  });
};
export const setExcelIntervalId = (IntervalId) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_EXCEL_INTERVAL_ID,
    ExcelIntervalId: IntervalId,
  });
};
export const setStartMonth = (StartMonth) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_START_MONTH,
    StartMonth: StartMonth,
  });
};
export const setEndMonth = (EndMonth) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_END_MONTH,
    EndMonth: EndMonth,
  });
};
export const setTransactionYear = (TransactionYear) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_TRANSACTION_YEAR,
    TransactionYear: TransactionYear,
  });
};
export const setEmailStatus = (EmailStatus) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_EMAIL_STATUS,
    EmailStatus: EmailStatus,
  });
};
export const invoiceRebate = (firstDayofMonth) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/returninvoice/getreturninvoices`;
  dispatch({
    type: rebateTypes.INVOICE_REBATE_REQUEST_STARTED,
  });
  httpClient
    .post(endPoint, JSON.stringify(firstDayofMonth))
    .then((response) => {
      dispatch({
        type: rebateTypes.INVOICE_REBATE_STARTED,
      });
    })
    .catch((error) => {
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      if (ErrorDescription && ErrorDescription.toUpperCase() === "PROCESSING") {
        dispatch({
          type: rebateTypes.INVOICE_REBATE_PROCESSING,
        });
        return;
      } else {
        dispatch({
          type: rebateTypes.INVOICE_REBATE_FAILED,
        });
        dispatch({
          type: rebateTypes.REBATE_ERROR,
          ErrorCode: ErrorCode,
          ErrorDescription: ErrorDescription,
        });
      }
    });
};
export const processSuccess = () => (dispatch) => {
  dispatch({
    type: rebateTypes.PROCESS_SUCCESS,
  });
};
export const getInvoiceRebateStatus = (firstDayofMonth) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/returninvoice/checkreturninvoices`;
  dispatch({
    type: rebateTypes.GETTING_INVOICE_REBATE_STATUS,
  });
  httpClient
    .post(endPoint, JSON.stringify(firstDayofMonth))
    .then((response) => {
      dispatch({
        type: rebateTypes.INVOICE_REBATE_SUCCESS,
      });
      // dispatch({
      //     type: rebateTypes.PROCESS_SUCCESS
      // })
    })
    .catch((error) => {
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      if (ErrorDescription && ErrorDescription.toUpperCase() === "PROCESSING") {
        dispatch({
          type: rebateTypes.INVOICE_REBATE_PROCESSING,
        });
        return;
      } else {
        dispatch({
          type: rebateTypes.INVOICE_REBATE_FAILED,
        });
        dispatch({
          type: rebateTypes.REBATE_ERROR,
          ErrorCode: ErrorCode,
          ErrorDescription: ErrorDescription,
        });
      }
    });
};
export const createIntegrationExcel =
  (transactionIds, PeriodYear, PeriodStart, PeriodEnd) => (dispatch) => {
    dispatch({
      type: rebateTypes.INTEGRATION_EXCEL_CREATE_REQUEST_STARTED,
    });
    const excelRequestObject = exportObject(
      transactionIds,
      PeriodYear,
      PeriodStart,
      PeriodEnd
    );
    const endPoint = `${ProjectConfig.apiUrl}/accounting/createintegrationexcelfile`;
    httpClient
      .post(endPoint, excelRequestObject)
      .then((response) => {
        dispatch({
          type: rebateTypes.INTEGRATION_EXCEL_CREATE_REQUEST_END,
        });
      })
      .catch((error) => {
        const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
        if (
          ErrorDescription &&
          ErrorDescription.toUpperCase() === "PROCESSING"
        ) {
          dispatch({
            type: rebateTypes.INTEGRATION_EXCEL_CREATE_REQUEST_PROCESSING,
          });
        } else {
          dispatch({
            type: rebateTypes.REBATE_ERROR,
            ErrorCode: error.ErrorCode,
            ErrorDescription: error.ErrorDesc,
          });
        }
      });
  };
export const integrationExcelExport =
  (transactionIds, Filename, PeriodYear, PeriodStart, PeriodEnd) =>
  (dispatch) => {
    dispatch({
      type: rebateTypes.INTEGRATION_EXCEL_DOWNLOADING,
    });
    const excelRequestObject = exportObject(
      transactionIds,
      PeriodYear,
      PeriodStart,
      PeriodEnd
    );
    const endPoint = `${ProjectConfig.apiUrl}/accounting/getintegrationexcelfile`;
    DownloadFile(
      endPoint,
      JSON.stringify(excelRequestObject),
      `${Filename}.xlsx`
    )
      .then(() => {
        dispatch({
          type: rebateTypes.INTEGRATION_EXCEL_DOWNLOADED,
        });
      })
      .catch((error) => {
        if (error.ErrorDesc && error.ErrorDesc.toUpperCase() === "PROCESSING") {
          dispatch({
            type: rebateTypes.INTEGRATION_EXCEL_CREATE_REQUEST_PROCESSING,
          });
        } else {
          dispatch({
            type: rebateTypes.INTEGRATION_EXCEL_REQUEST_FAILED,
          });
          dispatch({
            type: rebateTypes.REBATE_ERROR,
            ErrorCode: error.ErrorCode,
            ErrorDescription: error.ErrorDesc,
          });
        }
      });
  };
export const setIntegrationExcelStatus = (Statusname) => (dispatch) => {
  dispatch({
    type: rebateTypes.SET_INTEGRATION_EXCEL_STATUS,
    Statusname: Statusname,
  });
};
export const createExportExcel =
  (
    transactionIds,
    Filename,
    PeriodYear,
    PeriodStart,
    PeriodEnd,
    IntervalWaitTime
  ) =>
  (dispatch) => {
    dispatch({
      type: rebateTypes.EXCEL_REQUEST_STARTED,
      TransactionIds: transactionIds,
      Filename: Filename,
      TransactionYear: PeriodYear,
      StartMonth: PeriodStart,
      EndMonth: PeriodEnd,
      IntervalWaitTime: IntervalWaitTime,
    });
    const excelRequestObject = exportObject(
      transactionIds,
      PeriodYear,
      PeriodStart,
      PeriodEnd
    );
    const endPoint = `${ProjectConfig.apiUrl}/transaction/createtransactionsexcelfile`;
    httpClient
      .post(endPoint, excelRequestObject)
      .then((response) => {
        dispatch({
          type: rebateTypes.EXCEL_CREATE_REQUEST_END,
        });
      })
      .catch((error) => {
        const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
        if (
          ErrorDescription &&
          ErrorDescription.toUpperCase() === "PROCESSING"
        ) {
          dispatch({
            type: rebateTypes.EXCEL_CREATE_REQUEST_PROCESSING,
          });
        } else {
          dispatch({
            type: rebateTypes.REBATE_ERROR,
            ErrorCode: error.ErrorCode,
            ErrorDescription: error.ErrorDesc,
          });
        }
      });
  };
export const exportExcel =
  (transactionIds, Filename, PeriodYear, PeriodStart, PeriodEnd) =>
  (dispatch) => {
    dispatch({
      type: rebateTypes.EXCEL_DOWNLOADING,
    });
    const excelRequestObject = exportObject(
      transactionIds,
      PeriodYear,
      PeriodStart,
      PeriodEnd
    );
    const endPoint = `${ProjectConfig.apiUrl}/transaction/gettransactionsexcelfile`;
    DownloadFile(
      endPoint,
      JSON.stringify(excelRequestObject),
      `${Filename}.xlsx`
    )
      .then(() => {
        dispatch({
          type: rebateTypes.EXCEL_DOWNLOADED,
        });
      })
      .catch((error) => {
        if (error.ErrorDesc && error.ErrorDesc.toUpperCase() === "PROCESSING") {
          dispatch({
            type: rebateTypes.EXCEL_CREATE_REQUEST_PROCESSING,
          });
        } else {
          dispatch({
            type: rebateTypes.EXCEL_REQUEST_FAILED,
          });
          dispatch({
            type: rebateTypes.REBATE_ERROR,
            ErrorCode: error.ErrorCode,
            ErrorDescription: error.ErrorDesc,
          });
        }
      });
  };
// CreateTransactionPdfFile
export const createPDF = (transactionIds, Filename) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/transaction/createtransactionpdffile`;

  dispatch({
    type: rebateTypes.FILE_DOWNLOADING,
  });

  dispatch({
    type: rebateTypes.INITIAL_PDF,
    pdfId: transactionIds,
    pdfName: Filename,
  });

  httpClient
    .post(endPoint, JSON.stringify(transactionIds))
    .then((response) => {
      dispatch({
        type: rebateTypes.PDF_PROCESSING,
      });
    })
    .catch((error) => {
      const { ErrorCode, ErrorDescription } = XMSErrorHandler(error);
      dispatch({
        type: rebateTypes.REBATE_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription,
      });
    });
};
export const exportPDF = (transactionIds, Filename, init) => (dispatch) => {
  const endPoint = `${ProjectConfig.apiUrl}/transaction/gettransactionpdffile`;

  DownloadFile(endPoint, JSON.stringify(transactionIds), `${Filename}.pdf`)
    .then(() => {
      dispatch({
        type: rebateTypes.PDF_DOWNLOADED,
      });
    })
    .catch((error) => {
      if (error.ErrorDesc && error.ErrorDesc.toUpperCase() === "PROCESSING") {
        dispatch({
          type: rebateTypes.PDF_PROCESSING,
        });
      } else {
        dispatch({
          type: rebateTypes.PDF_FAILED,
        });
        dispatch({
          type: rebateTypes.REBATE_ERROR,
          ErrorCode: error.ErrorCode,
          ErrorDescription: error.ErrorDesc,
        });
      }
    })
    .finally(() => {
      dispatch({
        type: rebateTypes.FILE_DOWNLOADED,
      });
    });
};
