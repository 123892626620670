import React from 'react'
import TransactionTable from './TransactionTable/TransactionTable'
import InvoiceTable from './InvoiceTable/InvoiceTable'

import './RebateTables.scss'
const RebateTables = () => {
    return (
        <div className="RebateTables-Container">
            <TransactionTable />
            <InvoiceTable />
        </div>
    )
}
export default RebateTables;