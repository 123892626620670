import React from 'react'
import './JustSpinButton.scss'
import { Button, Spinner } from '@dfds-ui/react-components'
import PropTypes from 'prop-types';


export const JustSpinButton = (props) => {
    const { ButtonText, variation, className, onClick, WaitForRequest, disabled } = props
    return (
        <Button
            className={className + " JustSpinButton" + (WaitForRequest ? " WaitForRequest" : "")}
            variation={variation}
            onClick={onClick}
            disabled={disabled}
        >
            {WaitForRequest ?
                <span id="JustSpinButton-Spinner">
                    <Spinner instant={true} />
                </span>
                :
                ButtonText
            }
        </Button >
    )
}
JustSpinButton.propTypes = {
    variation: PropTypes.string,
    ButtonText: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    WaitForRequest: PropTypes.bool,
    disabled: PropTypes.bool

};
JustSpinButton.defaultProps = {
    variation: 'primary',
    ButtonText: "",
    className: "",
    onClick: () => { },
    WaitForRequest: false,
    disabled: false
};