import userTypes from '../type/user';
const initialState = {
    token: null,
    WaitForRequest: false,
    error: {
        ErrorCode: -1,
        ErrorMessage: ""
    },
    Username: "",
    Language: "tr"
}
export default (state = initialState, action) => {
    switch (action.type) {
        case userTypes.USER_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                token: {
                    ...action.token
                },
                error: {
                    ErrorCode: -1,
                    ErrorMessage: ""
                },
                WaitForRequest: false,
                Username: action.Username
            }
        case userTypes.USER_LOGOUT:
            return {
                ...initialState
                // token: null,
                // error: {
                //     ErrorCode: -1,
                //     ErrorMessage: ""
                // },
                // WaitForRequest: false,
                // Username: ""
            }
        case userTypes.USER_ONLINE:
            return {
                ...state,
                token: {
                    ...action.token
                },
                error: {
                    ErrorCode: -1,
                    ErrorMessage: ""
                },
                WaitForRequest: false,
                Username: action.Username
            }
        case userTypes.USER_WAITFORREQUEST:
            return {
                ...state,
                WaitForRequest: true
            }
        case userTypes.USER_ERROR:
            return {
                ...state,
                error: {
                    ErrorCode: action.ErrorCode,
                    ErrorMessage: action.ErrorMessage
                },
                WaitForRequest: false
            }
        case userTypes.USER_LANGUAGE:
            return {
                ...state,
                Language: action.Language
            }
        default:
            return state;

    }
}