import React from 'react';
import AdminPanelPage from '../component/AdminPanel/AdminPanel'

import { connect } from 'react-redux';
import { pageChanged } from '../store/action/pageActions'

const adminpanel = (props) => {
    const { pageName, pageChanged, match } = props;
    if (pageName !== match.path) {
        pageChanged(match.path)
    }
    return <AdminPanelPage />
}
const mapStateToProps = (state) => {
    return {
        pageName: state.page.pageName
    }
}
export default connect(mapStateToProps, { pageChanged })(adminpanel);
