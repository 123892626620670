import React from 'react'
import { Lock, Delete, Edit, LockOpen, YesTick, Close, LoadingSpinner, Document } from '@dfds-ui/icons'
import moment from 'moment'

export const AccountingVesselSchema = (schemaLanguage) => {
    return {
        rowId: "code", //unique ID
        RowContextMenu: [
            {
                Text: schemaLanguage.Edit,//"Edit Accounting Vessel",
                Icon: <Edit />,
                Name: "editVessel",
            },
            {
                Text: schemaLanguage.Delete,//"Delete Accounting Vessel",
                Icon: <Delete />,
                Name: "deleteVessel",
            },
        ],
        ColumnDesign: [
            {
                columnId: "isRented",
                Text: schemaLanguage.Rented//"Rented",
            },
            {
                columnId: "code",
                Text: schemaLanguage.Code//"Code"
            },
            {
                columnId: "vesselCode",
                Text: schemaLanguage.VesselCode//"Vessel Code",
            },
            {
                columnId: "vesselName",
                Text: schemaLanguage.VesselName//"Vessel Name",
            }
        ],
        DataDesign: [
            {
                columnId: "isRented",
                dataType: "icon",
                dataCheck: (data) => isRentedIcon(data)
            },
            {
                columnId: "code",
            },

            {
                columnId: "vesselCode",
            },
            {
                columnId: "vesselName",
            },
        ]
    }
}
const isRentedIcon = (data) => {
    if (!data) {
        return <Close style={{ color: "#be1e2d" }} />
    } else {
        return <YesTick style={{ color: "#4caf50" }} />
    }
}

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
export const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 10; index++) {
        let myObject = {}
        myObject.id = makeid(2)
        myObject.name = makeid(8)
        myObject.ratio = makeid(3)
        DummyArray.push(myObject)
    }
    return DummyArray
}

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
