import React from 'react'
import { Modal } from '@dfds-ui/modal'
import { Button } from '@dfds-ui/react-components';
import './YesNoModal.scss';
import { JustSpinButton } from '../../JustSpinButton/JustSpinButton';
const YesNoModal = (props) => {
    const { OpenModal, OnClose, Title, onYesClick, onNoClick, Message, yesButtonComp, waitForRequest, ButtonYesText, ButtonNoText, InfoMessage,YesClassName } = props;
    return (
        <Modal
            className="YesNoModal"
            isOpen={OpenModal}
            onRequestClose={waitForRequest ? () => { } : OnClose}
            heading={
                <span className="YesNoModalTitle">
                    {Title}
                </span>
            }
            headerClassName={"headerYesOrNo" + (waitForRequest ? " disableClose" : "")}>
            <p id="YesNoMessage">{Message}</p>
            {InfoMessage && <p id="InfoMessage">{InfoMessage}</p>}
            {

                !yesButtonComp ?
                    <JustSpinButton
                        className={"YesNoModal-YesButton" + (waitForRequest ? " disabled" : "") +" " +YesClassName}
                        onClick={onYesClick}
                        disabled={waitForRequest}
                        WaitForRequest={waitForRequest}
                        variation="secondary"
                        iconAlign="left"
                        ButtonText={ButtonYesText ? ButtonYesText.toUpperCase() : "Yes"}
                    /> :
                    yesButtonComp
            }
            <Button className="YesNoModal-NoButton"
                onClick={onNoClick ? onNoClick : OnClose}
                variation="secondary"
                disabled={waitForRequest}
                iconAlign="left">
                {ButtonNoText ? ButtonNoText : "No"}
            </Button>
        </Modal>
    );
}
export default YesNoModal;
