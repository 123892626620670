import React from 'react'
import { JustStaticTable } from '../../../../../CommonUI/JustTable/JustTable'
import { connect } from 'react-redux'
import { NewInvoiceTableSchema, DummyCreator } from './ModalTableSchema'
import { rebateLanguage, incomingInvoiceLanguage } from '../../../../../../config/language'
import './ModalTable.scss'
import { setSelectTransaction } from '../../../../../../store/action/incomingInvoiceAction'
const NewInvoiceTable = (props) => {
    const { Language, WaitForCustomerInvoices, CustomerInvoicesNotSearched, CustomerInvoices, setSelectTransaction } = props
    const JustAnimations = {
        WaitForRequest: WaitForCustomerInvoices
    }
    const JustEvents = {
        onRowClick: (e, rowData) => setSelectTransaction(rowData)
    }
    return (
        <div className="NewInvoiceTable-Container">
            <JustStaticTable
                CanvasWidth={1145}
                JustSchema={NewInvoiceTableSchema(incomingInvoiceLanguage[Language].ModalLanguage.ModalTable)}
                JustData={CustomerInvoices}
                JustAnimations={JustAnimations}
                JustEvents={JustEvents}
                notSearched={{ Text: incomingInvoiceLanguage[Language].ModalLanguage.SearchCustomerRebate, Status: CustomerInvoicesNotSearched }}
            />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        WaitForCustomerInvoices: state.incomingInvoice.WaitForCustomerInvoices,
        CustomerInvoicesNotSearched: state.incomingInvoice.CustomerInvoicesNotSearched,
        CustomerInvoices: state.incomingInvoice.CustomerInvoices,
        Language: state.user.Language,
    }
}
export default connect(mapStateToProps, { setSelectTransaction })(NewInvoiceTable);
