import React from 'react';
import './Footer.scss';
import ProjectConfig from '../../config/config';

const Footer = () => {
    return (
        <footer className="main-footer">
            <div className="row pl-3 pr-3">
                <strong>Copyright © 2020 DFDS</strong> <span className="pl-2">All rights reserved.</span>
                <div className="ml-auto">
                    <b>Version</b> {ProjectConfig.version}
                </div>
            </div>
        </footer>
    );
};

export default Footer; 