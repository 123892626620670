import React, { useState, useEffect } from 'react'
import './IncomingInvoiceHeader.scss'
import { SelectField, Input, Button } from '@dfds-ui/react-components';
import OptionIteration from './OptionIteration'
import { connect } from 'react-redux';
import { rebateLanguage } from '../../../config/language';
import { setCustomerName, setCustomerNumber, setStartMonth, setEndMonth, setYear, getIncomingInvoices, setError,setIntegrationInput,setEInvoiceInput } from '../../../store/action/incomingInvoiceAction';
import JustSearchInput from '../../CommonUI/JustSearchInput/JustSearchInput';

const IncomingInvoiceHeader = (props) => {
    const _todayDate = new Date()
    const { setCustomerName, setCustomerNumber, setStartMonth, setEndMonth, setYear, Year,
        CustomerName, CustomerNumber, StartMonth, EndMonth, WaitForIncomingInvoices, Language, getIncomingInvoices, setError,setIntegrationInput,setEInvoiceInput,IntegrationFilterValue,EInvoiceFilterValue } = props;
    const [rangeValidation, setRangeValidation] = useState(false)
    useEffect(() => {
        // getTransactions(StartMonth, EndMonth, TransactionYear, CustomerName, CustomerCode)
        // setTransactionFilter(StartMonth, EndMonth, TransactionYear, CustomerName, CustomerCode)
    }, [])

    const _customerNameOnChange = (event) => {
        const customerName = event.target.value
        setCustomerName(customerName)
    }
    const _customerNumberOnChange = (event) => {
        const customerNumber = event.target.value
        setCustomerNumber(customerNumber)
    }
    const _startMonthOnChange = (event) => {
        const myStartMonth = event.target.value
        setStartMonth(myStartMonth)
        setRangeValidation(false)

    }
    const _endMonthOnChange = (event) => {
        const myEndMonth = event.target.value
        setEndMonth(myEndMonth)
        setRangeValidation(false)

    }
    const _yearOnChange = (event) => {
        const newYear = event.target.value
        setYear(newYear)
    }
    const _integratedOnChange = (event) => {
        var newIntegrationFilterValue = event.target.value
        switch (newIntegrationFilterValue) {
            case "HEPSI":
            case "ALL":
                newIntegrationFilterValue = null
                break;
            case "EVET":
            case "YES":
                newIntegrationFilterValue = true
                break;
            case "HAYIR":
            case "NO":
                newIntegrationFilterValue = false
                break;
            default:
                newIntegrationFilterValue = null;
                break;
        }
        setIntegrationInput(newIntegrationFilterValue);
    }
    
    const _eInvoiceOnChange = (event) => {
        var newEInvoiceFilterValue = event.target.value
        switch (newEInvoiceFilterValue) {
            case "HEPSI":
            case "ALL":
                newEInvoiceFilterValue = null
                break;
            case "EVET":
            case "YES":
                newEInvoiceFilterValue = true
                break;
            case "HAYIR":
            case "NO":
                newEInvoiceFilterValue = false
                break;
            default:
                newEInvoiceFilterValue = null;
                break;
        }
        setEInvoiceInput(newEInvoiceFilterValue);
    }
    const _onButtonClick = (e) => {
        if (!WaitForIncomingInvoices) {
            if (_rangeValidation()) {
                getIncomingInvoices(StartMonth, EndMonth, Year, CustomerName, CustomerNumber,IntegrationFilterValue,EInvoiceFilterValue)
                // getTransactions(StartMonth, EndMonth, TransactionYear, CustomerName, CustomerCode)
                // setTransactionFilter(StartMonth, EndMonth, TransactionYear, CustomerName, CustomerCode)
            } else {
                setError(500, "Validation Error")
                // setError(500, rebateLanguage[Language].FilterValidationError)
                //error
                //The BETWEEN condition was provided a range where the lower bound is greater than the upper bound
            }
        }
    }
    const _rangeValidation = () => {
        const startMonthNumber = StartMonth ? parseFloat(StartMonth) : 0
        const endMonthNumber = EndMonth ? parseFloat(EndMonth) : 0
        if (startMonthNumber > endMonthNumber) {
            setRangeValidation(true)
            return false
        }
        return true
    }
    return (
        <div onKeyDown={(e) => {
            if (e.key === "Enter")
                _onButtonClick()
        }}
            className="IncomingInvoice-Header">
            <div className="IncomingInvoice-Header-Date-Container">
                <div className="Date-Container">
                    <SelectField
                        label={rebateLanguage[Language].Filters.selectYear} //Language
                        size={"small"}
                        name="Date-Container-Year"
                        onChange={(event) => _yearOnChange(event)}
                        className="Date-Container-Year">
                        <OptionIteration Start={(_todayDate.getFullYear() - 2)} End={_todayDate.getFullYear()} Reverse={true} />
                    </SelectField>
                </div>
                <div className="Date-Container">
                    <SelectField
                        label={rebateLanguage[Language].Filters.startMonth} //Language
                        size={"small"}
                        onChange={(event) => _startMonthOnChange(event)}
                        name="Date-Container-StartM"
                        className="Date-Container-StartM"
                        error={rangeValidation}
                        defaultValue={StartMonth}>
                        <OptionIteration Start={1} End={12} Selected={StartMonth} />
                    </SelectField>
                </div>
                <div className="Date-Container">
                    <SelectField
                        label={rebateLanguage[Language].Filters.endMonth} //Language
                        size={"small"}
                        onChange={(event) => _endMonthOnChange(event)}
                        name="Date-Container-EndM"
                        className="Date-Container-EndM"
                        error={rangeValidation}
                        defaultValue={EndMonth}>
                        <OptionIteration Start={1} End={12} Selected={EndMonth} />

                    </SelectField>
                </div>
                
            </div>

            <div className="IncomingInvoice-Header-Customer-Container">
                <div className="Customer-Container">
                    <span className="Input-Label" id="Customer-Container-Label">
                        {rebateLanguage[Language].Filters.customerName}
                    </span>
                    <Input size={"small"}
                        className="CustomerName"
                        name="CustomerName"
                        placeholder={rebateLanguage[Language].Filters.customerNameHolder}
                        value={CustomerName}
                        onChange={(event) => { _customerNameOnChange(event) }}
                    />
                </div>
                <div className="Customer-Container">
                    <span className="Input-Label" id="Customer-Container-Label">
                        {rebateLanguage[Language].Filters.customerCode /*Language*/}
                    </span>
                    <Input
                        size={"small"}
                        className="CustomerCode"
                        name="CustomerCode"
                        value={CustomerNumber}
                        placeholder={rebateLanguage[Language].Filters.customerCodeHolder}
                        onChange={(event) => { _customerNumberOnChange(event) }}

                    />
                </div>
            </div>
            <div className="Integrated-Container">
                    <SelectField
                        label={rebateLanguage[Language].Integrated.Integrated} //Language rebateLanguage[Language].EmailStatus
                        size={"small"}
                         onChange={(event) => _integratedOnChange(event)}
                        className="Integrated-Selectfield"
                        name="Integrated-Selectfield"
                    >
                        <option>{rebateLanguage[Language].Integrated.All}</option>
                        <option>{rebateLanguage[Language].Integrated.Yes}</option>
                        <option>{rebateLanguage[Language].Integrated.No}</option>
                    </SelectField>
                </div>

                <div className="EInvoice-Container">
                    <SelectField
                        label={rebateLanguage[Language].EInvoice.EInvoice} //Language rebateLanguage[Language].EmailStatus
                        size={"small"}
                        onChange={(event) => _eInvoiceOnChange(event)}
                        className="EInvoice-Selectfield"
                        name="EInvoice-Selectfield"
                    >
                        <option>{rebateLanguage[Language].EInvoice.All}</option>
                        <option>{rebateLanguage[Language].EInvoice.Yes}</option>
                        <option>{rebateLanguage[Language].EInvoice.No}</option>
                    </SelectField>
                </div>
            <Button
                // waitForRequest={WaitForTransactions}
                disabled={WaitForIncomingInvoices}
                onClick={() => _onButtonClick()}
                className="SearchButton"
            >
                {rebateLanguage[Language].SearchButtonText}
            </Button>

        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        Year: state.incomingInvoice.Year,
        StartMonth: state.incomingInvoice.StartMonth,
        EndMonth: state.incomingInvoice.EndMonth,
        CustomerName: state.incomingInvoice.CustomerName,
        CustomerNumber: state.incomingInvoice.CustomerNumber,
        WaitForIncomingInvoices: state.incomingInvoice.WaitForIncomingInvoices,
        IntegrationFilterValue: state.incomingInvoice.IntegrationFilterValue,
        EInvoiceFilterValue: state.incomingInvoice.EInvoiceFilterValue,
        Language: state.user.Language

    }
}
export default connect(mapStateToProps, { setCustomerName, setCustomerNumber, setStartMonth, setEndMonth, setYear, getIncomingInvoices, setError,setIntegrationInput,setEInvoiceInput })(IncomingInvoiceHeader);