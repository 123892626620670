import React from 'react'
import { epochToDateTimeNoFormat, epochToDateTime, epochToShortDate, epochToMonthAndYear } from '../../EpochConverter'
import './DataTypes.scss'
import PropTypes from 'prop-types';
import JustHamburger from '../../../../JustHamburger/JustHamburger';
import { Lock, Edit } from '@dfds-ui/icons'
const DataTypes = (DataType, Data, DataCheck, rowContextMenu, rowContextActions,
    RowData, NumberDigit, Language, updatable, rowLocked) => {

    if (DataType === undefined || DataType === null) {
        return (
            <span id="JustTable-RowCell-Data">
                {Data ? Data :
                    (rowLocked && updatable) ?
                        <span id="JustTable-IconContainer"><Lock id="JustTable-RowLocked-Updatable" /> </span> : (!rowLocked && updatable) ?
                            <span id="JustTable-IconContainer"><Edit id="JustTable-RowUnlocked-Updatable" /> </span> : Data
                }
            </span>
        )
    } else {
        let dataObj = {}
        switch (DataType.toUpperCase()) {
            case "DONTSHOW":
                dataObj.hide = true
                break
            case "EPOCHDATE-UTC":
                dataObj.data = epochToDateTime(Data, true)
                dataObj.className = ""
                break
            case "EPOCHDATE-NOFORMAT":
                dataObj.data = epochToDateTimeNoFormat(Data, false)
                dataObj.className = ""
                break

            case "EPOCHDATE-UTC-NOFORMAT":
                dataObj.data = epochToDateTimeNoFormat(Data, true)
                dataObj.className = ""
                break
            case "EPOCHDATE-SHORT":
                dataObj.data = epochToShortDate(Data, false)
                dataObj.className = ""
                break
            case "EPOCHMONTHANDYEAR":
                dataObj.data = epochToMonthAndYear(Data, false, Language)
                dataObj.className = ""
                break
            case "EPOCHMONTHANDYEAR-UTC":
                dataObj.data = epochToMonthAndYear(Data, true, Language)
                dataObj.className = ""
                break
            case "EPOCHDATE-SHORT-UTC":
                dataObj.data = epochToShortDate(Data, true)
                dataObj.className = ""
                break
            case "NUMBER":
                const floatData = parseFloat(Data)
                dataObj.data = Data ? floatData.toFixed(NumberDigit ? NumberDigit : 2) :
                    (Data === "0" || Data === 0) ?
                        floatData.toFixed(NumberDigit ? NumberDigit : 2) : floatData

                dataObj.className = "Cell-DataType-Number"
                break
            case "ICON":
                dataObj.data = (typeof DataCheck === "function") ? DataCheck(Data) : ""
                dataObj.className = "Cell-DataType-Icon"
                dataObj.id = "JustTable-RowCell-Icon"
                break
            case "CONTEXTMENU":
                var DialogOptions = []//Active Text onClick ActiveCheck: [{ columnId: "isLocked", Equal: true }]
                //rowContextMenu, rowContextActions, this.props.RowData
                rowContextMenu.map((contextMenu) => {

                    const rowContextAction = rowContextActions.find((action) => contextMenu.Name === action.Name)
                    let dOption = {}
                    let isActive = true
                    dOption.Text = contextMenu.Text
                    dOption.Icon = contextMenu.Icon
                    if (contextMenu.ActiveCheck) {
                        if (typeof contextMenu.ActiveCheck === "object")
                            contextMenu.ActiveCheck.map((check) => {
                                isActive = isActive && (RowData[check.columnId] === check.Equal)
                            })
                        dOption.Active = isActive
                    } else {
                        dOption.Active = true
                    }
                    dOption.onClick = () => rowContextAction.Action(RowData)
                    DialogOptions.push(dOption)
                })
                return (
                    <JustHamburger DialogOptions={DialogOptions} />
                )
            case "ARRAY":
                dataObj.data = ""
                if (Data !== undefined && Data !== null) {
                    if (Array.isArray(Data)) {
                        Data.forEach(_data => {
                            dataObj.data = dataObj.data + _data + " ; "
                        })
                    } else {
                        dataObj.data = Data
                    }
                }
                dataObj.className = ""
                break;
            default:
                break;
        }
        return (
            !dataObj.hide &&
            <span id={dataObj.id ? dataObj.id : "JustTable-RowCell-Data"} className={dataObj.className}>
                {dataObj.data ? dataObj.data :
                    (rowLocked && updatable) ?
                        <span id="JustTable-IconContainer"><Lock id="JustTable-RowLocked-Updatable" /> </span> : (!rowLocked && updatable) ?
                            <span id="JustTable-IconContainer"><Edit id="JustTable-RowUnlocked-Updatable" /> </span> : dataObj.data
                }
            </span>
        )
    }

}
DataTypes.propTypes = {
    Data: PropTypes.any,
    DataType: PropTypes.string
};

export default DataTypes