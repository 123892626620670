import React, { useState } from 'react'
import './ModalHeader.scss'
import { connect } from 'react-redux'
import { Input, SelectField, Button } from '@dfds-ui/react-components';
import { rebateLanguage } from '../../../../../config/language';

const EditInvoiceModalHeader = (props) => {
    const { SelectedInvoice, Language } = props
    return (
        <div className="Modal-Header">
            <div className="CustomerInformation-Edit">
                <div className="CustomerName">
                    <span className="Input-Label">
                        {rebateLanguage[Language].Filters.customerName}
                        <Input
                            className="CustomerName-Input"
                            size={"small"}
                            disabled={true}
                            value={SelectedInvoice ? SelectedInvoice.customerName : ""}
                        />
                    </span>

                </div>
                <div className="CustomerNumber">
                    <span className="Input-Label">
                        {rebateLanguage[Language].Filters.customerCode}
                        <Input
                            className="CustomerNumber-Input"
                            size={"small"}
                            disabled={true}
                            value={SelectedInvoice ? SelectedInvoice.customerNumber : ""}
                        />
                    </span>

                </div>
            </div>

        </div >

    )
}
const mapStateToProps = (state) => {
    return {
        WaitForCustomers: state.incomingInvoice.WaitForCustomers,
        CustomerDTO: state.incomingInvoice.CustomerDTO,
        CustomerHasError: state.incomingInvoice.CustomerHasError,
        SelectedInvoice: state.incomingInvoice.SelectedInvoice,
        CustomerStartMonth: state.incomingInvoice.CustomerStartMonth,
        CustomerEndMonth: state.incomingInvoice.CustomerEndMonth,
        CustomerYear: state.incomingInvoice.CustomerYear,
        WaitForCustomerInvoices: state.incomingInvoice.WaitForCustomerInvoices,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, {})(EditInvoiceModalHeader);
