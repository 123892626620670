import { combineReducers } from 'redux';
import UserReducer from './userReducer';
import PageReducer from './pageReducer';
import RebateReducer from './rebateReducer';
import CustomerReducer from './customerReducer';
import CustomerAccountReducer from './accountNumberReducer';
import InvoiceControlReducer from './invoiceControlReducer';
import AsyncProcessReducer from './asyncProcessReducer';
import IncomingInvoiceReducer from './incomingInvoiceReducer';
import AdminPanelReducer from './adminPanelReducer';

export default combineReducers({
    user: UserReducer,
    page: PageReducer,
    rebate: RebateReducer,
    customer: CustomerReducer,
    accountNumber: CustomerAccountReducer,
    invoiceControl: InvoiceControlReducer,
    asyncProcess: AsyncProcessReducer,
    incomingInvoice: IncomingInvoiceReducer,
    adminPanel: AdminPanelReducer,

});