import React, { useState } from 'react'
import './ModalHeader.scss'
import { getCustomers, setSelectedCustomer, setCustomerYear, setCustomerStartMonth,setCustomerEndMonth, getCustomerTransactions } from '../../../../../store/action/incomingInvoiceAction'
import { connect } from 'react-redux'
import JustSearchInput from '../../../../CommonUI/JustSearchInput/JustSearchInput'
import { Input, SelectField, Button } from '@dfds-ui/react-components';
import OptionIteration from '../../../IncomingInvoiceHeader/OptionIteration'
import { rebateLanguage, incomingInvoiceLanguage } from '../../../../../config/language';

const NewInvoiceModalHeader = (props) => {
    const { WaitForCustomers, setCustomerStartMonth,setCustomerEndMonth,setCustomerYear,
        CustomerDTO, CustomerHasError, getCustomers,
        setSelectedCustomer, SelectedCustomer, Language,
        CustomerStartMonth, getCustomerTransactions,
        CustomerEndMonth, CustomerYear, WaitForCustomerInvoices } = props
    // const SelectedCustomer = CustomerDTO && CustomerDTO.find((Customer) => Customer.name === SelectedCustomerName)
    const [rangeValidation, setRangeValidation] = useState(false)
    const CustomerDTOSelectableObject = (CustomerName,CustomerNumber) =>{
        return {
            name : CustomerName,
            number : CustomerNumber
        }
    }
    const _startMonthOnChange = (value) => {
        setCustomerStartMonth(value)
        setRangeValidation(false)
    }
    const _endMonthOnChange = (value) => {
        setCustomerEndMonth(value)
        setRangeValidation(false)
    }
    const setSelectedCustomerName = (selectedCustomer) => {
        const SelectedCustomer = CustomerDTO && CustomerDTO.find((Customer) => Customer.name === selectedCustomer.name && Customer.number === selectedCustomer.number)
        setSelectedCustomer(SelectedCustomer)
        
    }
    return (
        <div className="Modal-Header">
            <div className="CustomerInformation">
                <JustSearchInput
                    labelText={rebateLanguage[Language].Filters.customerName}
                    labelId={"Label"}
                    placeholder={rebateLanguage[Language].Filters.customerNameHolder}
                    WaitForRequest={WaitForCustomers}
                    RequestHasError={CustomerHasError}
                    SearchList={CustomerDTO && CustomerDTO.map((cDTO) => CustomerDTOSelectableObject(cDTO.name,cDTO.number))}
                    SearchFunction={getCustomers}
                    SelectedValue={setSelectedCustomerName}
                    className="SearchInput"
                />
                <div className="CustomerNumber">
                    <span className="Input-Label">
                        {rebateLanguage[Language].Filters.customerCode}
                        <Input
                            className="CustomerNumber-Input"
                            size={"small"}
                            disabled={true}
                            value={SelectedCustomer ? SelectedCustomer.number : ""}
                        />
                    </span>

                </div>
            </div>
            <div className="PeriodInformation">
                <div className="Date-Container">
                    <SelectField
                        disabled={!SelectedCustomer}
                        label={rebateLanguage[Language].Filters.selectYear} //Language
                        size={"small"}
                        name="Date-Container-Year"
                        onChange={(event) => setCustomerYear(event.target.value)}
                        className="Date-Container-Year">
                        <OptionIteration Start={(new Date().getFullYear() - 2)} End={new Date().getFullYear()} Reverse={true} />
                    </SelectField>
                </div>
                <div className="Date-Container">
                    <SelectField
                        disabled={!SelectedCustomer}
                        label={rebateLanguage[Language].Filters.startMonth} //Language
                        size={"small"}
                        onChange={(event) => _startMonthOnChange(event.target.value)}
                        name="Date-Container-StartM"
                        className="Date-Container-StartM"
                        error={rangeValidation}
                        defaultValue={CustomerStartMonth}>
                        <OptionIteration Start={1} End={12} Selected={CustomerStartMonth} />
                    </SelectField>
                </div>
                <div className="Date-Container">
                    <SelectField
                        disabled={!SelectedCustomer}
                        label={rebateLanguage[Language].Filters.endMonth} //Language
                        size={"small"}
                        onChange={(event) => _endMonthOnChange(event.target.value)}
                        name="Date-Container-EndM"
                        className="Date-Container-EndM"
                        error={rangeValidation}
                        defaultValue={CustomerEndMonth}>
                        <OptionIteration Start={1} End={12} Selected={CustomerEndMonth} />
                    </SelectField>
                </div >
            </div >

            <Button
                disabled={!SelectedCustomer || WaitForCustomerInvoices}
                onClick={() => getCustomerTransactions(CustomerStartMonth, CustomerEndMonth, CustomerYear, SelectedCustomer.number, SelectedCustomer.name)}
                className="GetTransactions-Button">
                {incomingInvoiceLanguage[Language].ModalLanguage.GetTransactionsButton}
            </Button>

        </div >

    )
}
const mapStateToProps = (state) => {
    return {
        WaitForCustomers: state.incomingInvoice.WaitForCustomers,
        CustomerDTO: state.incomingInvoice.CustomerDTO,
        CustomerHasError: state.incomingInvoice.CustomerHasError,
        SelectedCustomer: state.incomingInvoice.SelectedCustomer,
        CustomerStartMonth: state.incomingInvoice.CustomerStartMonth,
        CustomerEndMonth: state.incomingInvoice.CustomerEndMonth,
        CustomerYear: state.incomingInvoice.CustomerYear,
        WaitForCustomerInvoices: state.incomingInvoice.WaitForCustomerInvoices,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { getCustomers, setSelectedCustomer, getCustomerTransactions, setCustomerYear, setCustomerStartMonth,setCustomerEndMonth, })(NewInvoiceModalHeader);
