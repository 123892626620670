import customerAccountTypes from '../type/accountNumbers';
const initialState = {
    WaitForCustomerNumbers: false,
    CustomerAccountNumbersHistory: null,
    CustomerAccountNumbers: null,
    // AccountCustomerNumbersSaved: false,
    WaitForSaveCustomerNumbers: false,
    ErrorHandler: {
        ErrorCode: -1,
        ErrorDescription: "",
    },
}
export default (state = initialState, action) => {
    switch (action.type) {

        case customerAccountTypes.GETTING_CUSTOMER_NUMBERS:
            return {
                ...state,
                WaitForCustomerNumbers: true,
            }
        case customerAccountTypes.GET_CUSTOMER_ACCOUNT_NUMBERS:
            return {
                ...state,
                CustomerAccountNumbers: action.CustomerAccountNumbers,
                WaitForCustomerNumbers: false,
            }
        case customerAccountTypes.SAVING_CUSTOMER_NUMBERS:
            return {
                ...state,
                WaitForSaveCustomerNumbers: true,
            }
        case customerAccountTypes.SAVED_CUSTOMER_NUMBERS:
            return {
                ...state,
                // AccountCustomerNumbersSaved: true,
                WaitForCustomerNumbers: false,
                CustomerAccountNumbersHistory: null,
                CustomerAccountNumbers: null,
                WaitForSaveCustomerNumbers: false,
            }

        case customerAccountTypes.SET_ACCOUNT_NUMBERS_HISTORY_DATA:
            return {
                ...state,
                CustomerAccountNumbersHistory: action.CustomerAccountNumbersHistory
            }

        case customerAccountTypes.CUSTOMER_NUMBERS_ERROR:
            return {
                ...state,
                WaitForCustomerNumbers: false,
                CustomerAccountNumbersHistory: null,
                CustomerAccountNumbers: null,
                ErrorHandler: {
                    ErrorCode: action.ErrorCode,
                    ErrorDescription: action.ErrorDescription
                }
            }

        case customerAccountTypes.CLEAR_CUSTOMER_NUMBERS:
            return {
                ...state,
                // AccountCustomerNumbersSaved: false,
                WaitForSaveCustomerNumbers: false,
                CustomerAccountNumbersHistory: null,
                CustomerAccountNumbers: null,
            }
        // case customerAccountTypes.CLEAR_SAVE_CONTROL:
        //     return {
        //         ...state,
        //         AccountCustomerNumbersSaved: false
        //     }

        default:
            return state;

    }
}