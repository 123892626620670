export const InvoiceTableSchema = (schemaLanguage, Language) => {
    return {
        ColumnDesign: [
            {
                columnId: "invoiceDate",
                Text: schemaLanguage.InvoiceTableColumns.invoiceDate,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "125px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            // Selects: null // (Columns) => {}
                        }
                    }
                },
            },
            {
                columnId: "invoiceId",
                Text: schemaLanguage.InvoiceTableColumns.invoiceNumber,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "175px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            // Selects: null // (Columns) => {}
                        }
                    }
                },
            },
            {
                columnId: "voyageNumber",
                Text: schemaLanguage.InvoiceTableColumns.voyageNumber,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "150px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            // Selects: null // (Columns) => {}
                        }
                    }
                },
            },
            {
                columnId: "vesselName",
                Text: schemaLanguage.InvoiceTableColumns.vesselName,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "150px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            // Selects: null // (Columns) => {}
                        }
                    }
                },
            },
            {
                columnId: "vesselCode",
                Text: schemaLanguage.InvoiceTableColumns.vesselCode,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "90px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            // Selects: null // (Columns) => {}
                        }
                    }
                },
            },
            {
                columnId: "scheduleDate",
                Text: schemaLanguage.InvoiceTableColumns.scheduleDate,
            },
            {
                columnId: "processedAmount",
                Text: schemaLanguage.InvoiceTableColumns.totalAmount,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "100px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            // Selects: null // (Columns) => {}
                        }
                    }
                },
            },
            {
                columnId: "name",
                parentIds: ["customer"],
                Text: schemaLanguage.InvoiceTableColumns.customerName,
            },
            {
                columnId: "country",
                parentIds: ["customer"],
                Text: schemaLanguage.InvoiceTableColumns.country,
            },
            {
                columnId: "city",
                parentIds: ["customer"],
                Text: schemaLanguage.InvoiceTableColumns.city,
            },
            {
                columnId: "address",
                parentIds: ["customer"],
                Text: schemaLanguage.InvoiceTableColumns.address,
            },
            {
                columnId: "fax",
                parentIds: ["customer"],
                Text: schemaLanguage.InvoiceTableColumns.fax,
            },

        ],
        DataDesign: [
            {
                columnId: "invoiceDate",
                dataType: "EPOCHMONTHANDYEAR",
                dataLanguage: Language ? Language : "en"
            },
            {
                columnId: "invoiceId",

            },
            {
                columnId: "voyageNumber",
            },
            {
                columnId: "vesselName",
            },
            {
                columnId: "vesselCode",
            },
            {
                columnId: "scheduleDate",
                dataType: "EPOCHMONTHANDYEAR",
                dataLanguage: Language ? Language : "en"

            },
            {
                columnId: "processedAmount",
                dataType: "number"
            },
            {
                columnId: "name",
            },
            {
                columnId: "country",
            },
            {
                columnId: "city",
            },
            {
                columnId: "address",
                dataType: "array"
            },
            {
                columnId: "fax",
            },
        ]
    }
}
const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 10; index++) {
        let myObject = {}
        myObject.donem = 1579507610
        myObject.kitlimi = "true"
        myObject.gonderim = "true"
        myObject.kopyac = "false"
        myObject.kopyaa = "true"
        myObject.gonderildi = "true"
        myObject.mustericode = 666
        myObject.musteriname = "Furkan Kubilay"
        myObject.doviz = "Euro"
        myObject.cirotoplamtut = 3506.56
        myObject.hesaplananporan = 3.14
        myObject.hesaplananptutar = 315.36
        myObject.primoran = 8.45
        myObject.primtutar = 1.14
        myObject.manualiadetutar = 605.98
        myObject.octetmi = "true"
        myObject.email = "furkankubilay81@hotmail.com"
        myObject.guncelleyen = "admin@dfds.com"
        myObject.guncellemetarihi = 1579334810
        myObject.kopyalanandonem = 1547798810
        myObject.aciklama1 = "Buraları boş bırakmayalım dimi?"
        myObject.aciklama2 = "Evet haklısın mükemmel bir idea deneme1 deneme 2 deneme3 deneme4 deneme 5 deneme6 boş bırakma ki görelim ne nerede nasıl duruyor dimi?"
        myObject.aciklananmanueliade = "Çok data var ama bilemiyorum altan."
        DummyArray.push(myObject)
    }
    return DummyArray
}
export const Dummy = DummyCreator()