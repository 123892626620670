import React from 'react'
import { Lock, Delete, Edit, LockOpen, YesTick, Close, LoadingSpinner, Document } from '@dfds-ui/icons'
import moment from 'moment'

export const RebateRateTableSchema = (schemaLanguage) => {
    return {
        rowId: "id", //unique ID
        RowContextMenu: [
            {
                Text: "Edit Rate",
                Icon: <Edit />,
                Name: "editRate",
            },

        ],
        ColumnDesign: [
            {
                columnId: "name",
                Text: schemaLanguage.Name
            },
            {
                columnId: "ratio",
                Text: schemaLanguage.Ratio

            }
        ],
        DataDesign: [

            {
                columnId: "name",
            },
            {
                columnId: "ratio",
            },
        ]
    }
}

function randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}
export const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 10; index++) {
        let myObject = {}
        myObject.id = makeid(2)
        myObject.name = makeid(8)
        myObject.ratio = makeid(3)
        DummyArray.push(myObject)
    }
    return DummyArray
}

function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
