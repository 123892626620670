import React from 'react';
import login from '../login';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../../core/history'
import PrivateRoute from './PrivateRoute.js';
import HomePage from '../../component/HomePage/HomePage'
import NoAuth from '../../component/NoAuth/NoAuth';
import resetPassword from '../resetpassword';
import rebate from '../rebate';
import customer from '../customer';
import invoiceControl from '../invoiceControl'
import incomingInvoices from '../incomingInvoices.js'
import adminPanel from '../adminpanel.js'

const SDRouter = () => {
    const authRoles = "internaluser,Admin,StandardUser";
    return (
        <>
            <Router history={history}>
                <Switch>
                    <PrivateRoute exact path='/noAuthorization' component={NoAuth} />
                    <PrivateRoute exact auth={authRoles} path='/rebate' component={rebate} />
                    <PrivateRoute exact auth={authRoles} path='/customer' component={customer} />
                    <PrivateRoute exact auth={authRoles} path='/invoiceControl' component={invoiceControl} />
                    <PrivateRoute exact auth={authRoles} path='/incomingInvoices' component={incomingInvoices} />
                    <PrivateRoute exact auth={"Admin"} path='/adminpanel' component={adminPanel} />
                    <Route path='/password-reset' component={resetPassword} />
                    <Route path='/login' component={login} />
                    <Route path='/' component={HomePage} />
                </Switch>
            </Router>
        </>
    )
}

export default SDRouter;