const customerTypes = Object.freeze({
    GET_CUSTOMERS: Symbol('GET_CUSTOMERS'),
    GETTING_CUSTOMERS: Symbol('GETTING_CUSTOMERS'),
    CUSTOMER_ERROR: Symbol('CUSTOMER_ERROR'),
    NOT_FOUND: Symbol('NOT_FOUND'),
    SET_DATA_HISTORY: Symbol('SET_DATA_HISTORY'),
    SAVING_CUSTOMERS: Symbol('SAVING_CUSTOMERS'),
    CUSTOMERS_SAVED: Symbol('CUSTOMERS_SAVED'),
    SET_CUSTOMER: Symbol('SET_CUSTOMER'),
    SHOW_CHANGES_MODAL: Symbol('SHOW_CHANGES_MODAL'),
    HIDE_CHANGES_MODAL: Symbol('HIDE_CHANGES_MODAL'),

    //---------HISTORY-------
    GET_HISTORY: Symbol('GET_HISTORY'),
    CUSTOMER_HISTORY_MODAL: Symbol('CUSTOMER_HISTORY_MODAL'),
    GETTING_HISTORY: Symbol('GETTING_HISTORY'),
    DOWNLOADING_HISTORY: Symbol('DOWNLOADING_HISTORY'),
    HISTORY_DOWNLOADED: Symbol('HISTORY_DOWNLOADED'),
    //---------HISTORY-------

    //---------CUSTOMER ACCOUNT NUMBERS ---------
    SHOW_CUSTOMER_ACCOUNT_NUMBERS_MODAL: Symbol('SHOW_CUSTOMER_ACCOUNT_NUMBERS_MODAL'),
    HIDE_CUSTOMER_ACCOUNT_NUMBERS_MODAL: Symbol('HIDE_ACCOUNT_NUMBERS_MODAL'),
    //---------CUSTOMER ACCOUNT NUMBERS ---------

});

export default customerTypes;