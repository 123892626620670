import httpClient from "../../core/httpclient";
import customerTypes from "../type/customer";
import ProjectConfig from '../../config/config'
import XMSErrorHandler from "../../core/xmsErrorHandler";
import PropTypes from 'prop-types';
import { customerBindHistory } from "../../core/dataBinder";
import DownloadFile from "../../core/downloadFile";

export const getCustomers = (customerName, customerNumber) => async dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/customer/getcustomers`;
    const customerInformation = {
        customerName: customerName,
        customerNumber: customerNumber
    }
    dispatch({
        type: customerTypes.SET_CUSTOMER,
        CustomerName: customerName,
        CustomerNumber: customerNumber
    })
    dispatch({
        type: customerTypes.GETTING_CUSTOMERS
    })

    httpClient.post(endPoint, customerInformation)
        .then((response) => {
            const customers = response.data.result
            dispatch({
                type: customerTypes.GET_CUSTOMERS,
                Customers: customers,
            })

            if (customers.length < 1) {
                dispatch({
                    type: customerTypes.NOT_FOUND
                })
            }
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: customerTypes.CUSTOMER_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
}
getCustomers.propTypes = {
    customerName: PropTypes.string,
    customerNumber: PropTypes.string
}
getCustomers.defaultProps = {
    customerName: "",
    customerNumber: ""
}

export const showChangesModal = (Action, Show = true) => dispatch => {
    dispatch({
        type: customerTypes.SHOW_CHANGES_MODAL,
        ForwardAction: Action,
    })
}
export const hideChangesModal = () => dispatch => {
    dispatch({
        type: customerTypes.HIDE_CHANGES_MODAL,
    })
}
export const setCustomer = (CustomerName, CustomerNumber) => dispatch => {
    dispatch({
        type: customerTypes.SET_CUSTOMER,
        CustomerName: CustomerName,
        CustomerNumber: CustomerNumber
    })
}
export const setCustomerTableHistory = (DataHistory) => dispatch => {
    dispatch({
        type: customerTypes.SET_DATA_HISTORY,
        DataHistory: DataHistory
    })
}
export const saveCustomers = (DataHistory, callBack) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/customer/savecustomers`
    dispatch({
        type: customerTypes.SAVING_CUSTOMERS,
    })
    const bindedDataHistory = customerBindHistory(DataHistory)

    httpClient.post(endPoint, bindedDataHistory)
        .then((response) => {
            dispatch({
                type: customerTypes.CUSTOMERS_SAVED,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: customerTypes.CUSTOMER_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
        .finally(() => {
            callBack()
        })
}

export const setError = (ErrorCode, ErrorDescription) => dispatch => {
    dispatch({
        type: customerTypes.CUSTOMER_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription
    })
}
export const clearCustomerError = () => dispatch => {
    dispatch({
        type: customerTypes.CUSTOMER_ERROR,
        ErrorCode: -1,
        ErrorDescription: null
    })
}
//-------------HISTORY MODAL---------------------
export const setHistoryModal = (showStatus, Row) => dispatch => {
    dispatch({
        type: customerTypes.CUSTOMER_HISTORY_MODAL,
        HistoryModal: showStatus,
        SelectedRow: Row
    })
}
export const getHistoryDocument = (customerNumber) => dispatch => {
    dispatch({
        type: customerTypes.GETTING_HISTORY
    })

    const endPoint = `${ProjectConfig.apiUrl}/customer/gettransactiondocuments`
    httpClient.post(endPoint, JSON.stringify(customerNumber))
        .then((response) => {
            const documentHistory = response.data.result

            dispatch({
                type: customerTypes.GET_HISTORY,
                DocumentHistory: documentHistory
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: customerTypes.CUSTOMER_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
        .finally(() => {

        })

}
export const getTransactionFile = (customerNumber, period, key) => dispatch => {
    // dispatch({
    //     type: customerTypes.
    // })
    const endPoint = `${ProjectConfig.apiUrl}/customer/gettransactionfile`
    const requestObject = {
        CustomerNumber: customerNumber,
        Period: period,
        Key: key
    }
    dispatch({
        type: customerTypes.DOWNLOADING_HISTORY
    })
    DownloadFile(endPoint, JSON.stringify(requestObject), `${key}`)
        .then(() => {

        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: customerTypes.CUSTOMER_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
        .finally(() => {
            dispatch({
                type: customerTypes.HISTORY_DOWNLOADED
            })
        })
}
//-------------HISTORY MODAL---------------------

//--------------Account Numbers Modal----------------
export const showAccountNumbersModal = (Row) => dispatch => {
    dispatch({
        type: customerTypes.SHOW_CUSTOMER_ACCOUNT_NUMBERS_MODAL,
        SelectedRow: Row
    })
}
export const hideAccountNumbersModal = (isSaved) => dispatch => {
    dispatch({
        type: customerTypes.HIDE_CUSTOMER_ACCOUNT_NUMBERS_MODAL,
        AccountCustomerNumbersSaved: isSaved
    })
}
//--------------Account Numbers Modal----------------
