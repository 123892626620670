import React from 'react'
import PropTypes from 'prop-types';

const OptionIteration = ({ Start, End, Reverse, DisableList, Selected }) => {
    let IterationList = []
    for (let index = (Reverse ? End : Start); (Reverse ? index >= Start : index <= End); Reverse ? index-- : index++) {
        IterationList.push(index)
    }
    if (!IterationList) return <></>
    return (
        IterationList.map((Iteration, key) => {
            const Disable = DisableList.find((it) => Iteration === it)
            if (Disable) {
                return <option key={key} value={Iteration} disabled>{Iteration}</option>
            } else {
                if (Selected === (key + 1)) {
                    return <option key={key} defaultValue={Iteration} value={Iteration}>{Iteration}</option>
                }
                return <option key={key} defaultValue={Iteration} value={Iteration}>{Iteration}</option>
            }
        })
    )
}

OptionIteration.propTypes = {
    Start: PropTypes.number,
    End: PropTypes.number,
    Reverse: PropTypes.bool,
    DisableList: PropTypes.array
}
OptionIteration.defaultProps = {
    Start: 0,
    End: 0,
    Reverse: false,
    DisableList: []
}

export default OptionIteration