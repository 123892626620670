import httpClient from "../../core/httpclient";
import accountNumbersType from "../type/accountNumbers";
import ProjectConfig from '../../config/config'
import XMSErrorHandler from "../../core/xmsErrorHandler";
import { extractAccounts } from "../../core/dataBinder";

export const getCustomerNumbers = (customerNumber) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/customer/getcustomers`;
    const customerInformation = {
        customerNumber: customerNumber
    }
    dispatch({
        type: accountNumbersType.GETTING_CUSTOMER_NUMBERS
    })

    httpClient.post(endPoint, customerInformation)
        .then((response) => {
            const customer = response.data.result
            const CustomerAccountNumbers = customer[0].accountings
            console.log(CustomerAccountNumbers)
            dispatch({
                type: accountNumbersType.GET_CUSTOMER_ACCOUNT_NUMBERS,
                CustomerAccountNumbers: CustomerAccountNumbers,
            })
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: accountNumbersType.CUSTOMER_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
        })
}
export const saveAccountNumbers = (SelectedRow, CustomerAccountNumbersHistory, hideAccountNumbersModal) => dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/customer/savecustomersaccountings`;
    dispatch({
        type: accountNumbersType.SAVING_CUSTOMER_NUMBERS
    })
    const requestObject = {
        Number: SelectedRow.number,
        Accountings: extractAccounts(CustomerAccountNumbersHistory)
    }
    let isSaved = false
    httpClient.post(endPoint, JSON.stringify(requestObject))
        .then((response) => {
            dispatch({
                type: accountNumbersType.SAVED_CUSTOMER_NUMBERS
            })
            isSaved = true
        })
        .catch((error) => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)
            dispatch({
                type: accountNumbersType.CUSTOMER_NUMBERS_ERROR,
                ErrorCode: ErrorCode,
                ErrorDescription: ErrorDescription
            })
            isSaved = false
        })
        .finally(() => {
            hideAccountNumbersModal(isSaved)
        })
}
export const setAccountNumbersHistory = (CustomerAccountNumbersHistory) => dispatch => {
    dispatch({
        type: accountNumbersType.SET_ACCOUNT_NUMBERS_HISTORY_DATA,
        CustomerAccountNumbersHistory: CustomerAccountNumbersHistory
    })
}
export const clearAccountNumbers = () => dispatch => {
    dispatch({
        type: accountNumbersType.CLEAR_CUSTOMER_NUMBERS
    })
}
// export const clearSaveControl = () => dispatch => {
//     dispatch({
//         type: accountNumbersType.CLEAR_SAVE_CONTROL
//     })
// }
export const setError = (ErrorCode, ErrorDescription) => dispatch => {
    dispatch({
        type: accountNumbersType.CUSTOMER_ERROR,
        ErrorCode: ErrorCode,
        ErrorDescription: ErrorDescription
    })
}
export const clearCustomerError = () => dispatch => {
    dispatch({
        type: accountNumbersType.CUSTOMER_ERROR,
        ErrorCode: -1,
        ErrorDescription: null
    })
}
