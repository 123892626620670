import React, { useEffect } from "react";
import { JustTable } from "../../../CommonUI/JustTable/JustTable";
import { TransactionTableSchema } from "./TransactionTableSchema";
import "./TransactionTable.scss";
import { connect } from "react-redux";

import {
  getInvoices,
  saveHistory,
  processLock,
  setDataHistory,
  unlockTransaction,
  lockTransaction,
  reprocessTransaction,
  sendTransactionDocument,
  copyTransaction,
  calculateTransaction,
  setCalculatedRow,
  setExcelModal,
  exportExcel,
  createPDF,
  showHistoryCheck,
  setSelectedTransactions,
  sortTransaction,
  changePageStatus,
  setInvoiceRebateModal,
  setIntegrationModal,
  createExportExcel,
} from "../../../../store/action/rebateAction";

import JustLockComponent from "../../../CommonUI/JustLockComponent/JustLockComponent";
import { rebateLanguage } from "../../../../config/language";
import JustHamburger from "../../../CommonUI/JustHamburger/JustHamburger";
import { FaSave, FaRegFileExcel } from "react-icons/fa";
import { Lock, LockOpen, LoadingSpinner } from "@dfds-ui/icons";

import { epochToMonthAndYear } from "../../../../core/epochToDateConverter";
import { StatusSelector } from "../StatusSelector";
import { FiSend } from "react-icons/fi";
import { Button } from "@dfds-ui/react-components";

const TransactionTable = (props) => {
  const {
    Transactions,
    getInvoices,
    sendTransactionDocument,
    copyTransaction,
    WaitForTransactions,
    NoConnection,
    reprocessTransaction,
    Language,
    setDataHistory,
    saveHistory,
    dataHistory,
    unlockTransaction,
    lockTransaction,
    calculateTransaction,
    WaitForSaveTransaction,
    processLock,
    WaitForProcessTransaction,
    setExcelModal,
    exportExcel,
    createPDF,
    SelectedTransactions,
    setIntegrationModal,
    setInvoiceRebateModal,
    Pagestatus,
    showHistoryCheck,
    setSelectedTransactions,
    sortTransaction,
    changePageStatus,
    createExportExcel,
  } = props;

  let invoiceIntervalId;
  const onRowSelected = (e, RowData) => {
    if (invoiceIntervalId) clearTimeout(invoiceIntervalId);
    invoiceIntervalId = setTimeout(
      () => getInvoices(RowData.transactionId),
      500
    );
  };

  const JustEvents = {
    onRowClick: onRowSelected,
  };
  const checkHistory = (Action) => {
    if (typeof Action !== "function") return;
    if (dataHistory.length > 0) {
      showHistoryCheck(Action);
    } else {
      // setSelectedTransactions([])
      // setDataHistory([])
      Action();
    }
  };
  const JustDataHistory = (dataHistory, updated) => {
    const changeAmount = (newRatio, newChargeBack, newInvoiceBack) => {
      calculateTransaction(
        updated.RowId,
        newRatio ? newRatio : updated.Row["rebateRatio"],
        newChargeBack ? newChargeBack : updated.Row["manualChargeBackAmount"],
        newInvoiceBack ? newInvoiceBack : updated.Row["InvoiceChargeBackAmount"]
      ).then((calculatedRow) => {
        updated.Row["rebateAmount"] = calculatedRow["rebateAmount"];
        updated.setRow(updated.Row);
      });
    };
    if (updated && updated.ColumnId === "rebateRatio") {
      const rebateRatio = updated.Data;
      if (rebateRatio && rebateRatio !== "-") {
        changeAmount(rebateRatio);
      }
    }
    if (updated && updated.ColumnId === "manualChargeBackAmount") {
      const manualChargeBackAmount = updated.Data;
      if (
        manualChargeBackAmount !== undefined &&
        manualChargeBackAmount !== null &&
        manualChargeBackAmount !== "-"
      ) {
        changeAmount(null, manualChargeBackAmount);
      }
    }
    if (updated && updated.ColumnId === "InvoiceChargeBackAmount") {
      const InvoiceChargeBackAmount = updated.Data;
      if (
        InvoiceChargeBackAmount !== undefined &&
        InvoiceChargeBackAmount !== null &&
        InvoiceChargeBackAmount !== "-"
      ) {
        changeAmount(null, null, InvoiceChargeBackAmount);
      }
    }
    setDataHistory(dataHistory);
  };
  const JustSelectedRow = (dataSelected, selectedRow) => {
    console.log(selectedRow);
    console.log(dataSelected);
    setSelectedTransactions(dataSelected);
  };
  const JustAnimations = {
    WaitForRequest: WaitForTransactions,
    NoConnection: NoConnection,
  };

  const JustRowContextMenu = [
    {
      Name: "lockTransaction",
      Action: (Row) => {
        const Action = () => lockTransaction([Row["transactionId"]]);
        checkHistory(Action);
      },
    },
    {
      Name: "unlockTransaction",
      Action: (Row) => {
        const Action = () => unlockTransaction([Row["transactionId"]]);
        checkHistory(Action);
      },
    },
    {
      Name: "reprocessTransaction",
      Action: (Row) => {
        const Action = () => reprocessTransaction([Row["transactionId"]]);
        checkHistory(Action);
      },
    },
    {
      Name: "copyTransaction",
      Action: (Row) => {
        const Action = () => copyTransaction(Row["transactionId"]);
        checkHistory(Action);
      },
    },
    {
      Name: "sendDocument",
      Action: (Row) => {
        const Action = () => sendTransactionDocument([Row["transactionId"]]);
        checkHistory(Action);
      },
    },
    {
      Name: "exportAsExcel",
      Action: (Row) => {
        if (Row.customer)
          createExportExcel(
            [Row["transactionId"]],
            `${Row.customer["number"]}_${epochToMonthAndYear(Row.period)}`,
            undefined,
            undefined,
            undefined,
            1500
          );
      },
    },
    {
      Name: "exportAsPdf",
      Action: (Row) => {
        if (Row.customer)
          createPDF(
            Row["transactionId"],
            `${Row.customer["number"]}_${epochToMonthAndYear(Row.period)}`,
            true
          );
      },
    },
  ];
  const JustTableContextMenu = [
    {
      Text: rebateLanguage[Language].TableContextMenuTexts.lockAll,
      Icon: <Lock />,
      Active: Transactions && !WaitForProcessTransaction,
      onClick: () => {
        const tIds = Transactions.filter((T) => !T.isLocked).map(
          (T) => T.transactionId
        );
        const Action = () => lockTransaction(tIds);
        checkHistory(Action);
      },
    },
    {
      Text: rebateLanguage[Language].TableContextMenuTexts.unlockAll,
      Icon: <LockOpen />,
      Active: Transactions && !WaitForProcessTransaction,
      onClick: () => {
        const tIds = Transactions.filter((T) => T.isLocked).map(
          (T) => T.transactionId
        );
        const Action = () => unlockTransaction(tIds);
        checkHistory(Action);
      },
    },
    {
      Text: rebateLanguage[Language].TableContextMenuTexts.processAll,
      Icon: <LoadingSpinner />,
      Active: Transactions && !WaitForProcessTransaction,
      onClick: () => {
        const Action = () => processLock(true);
        checkHistory(Action);
      },
    },
    {
      Text: rebateLanguage[Language].TableContextMenuTexts.saveAll,
      Icon: <FaSave />,
      Active:
        Transactions &&
        dataHistory &&
        dataHistory.length > 0 &&
        !WaitForProcessTransaction,
      onClick: () => {
        saveHistory(dataHistory, Transactions, () => setDataHistory([]));
      },
    },
    {
      Text: rebateLanguage[Language].TableContextMenuTexts.SendAll,
      Icon: <FiSend />,
      Active: Transactions,
      onClick: () => {
        const tIds = Transactions.filter((T) => !T.doNotSend).map(
          (T) => T.transactionId
        );
        const Action = () => sendTransactionDocument(tIds);
        checkHistory(Action);
      },
    },
  ];
  const JustTableSelectedRowsContextMenu = [
    {
      Text: rebateLanguage[Language].TableSelectedContextMenu.lockSelecteds,
      Icon: <Lock />,
      Active:
        SelectedTransactions &&
        !WaitForProcessTransaction &&
        SelectedTransactions.filter((T) => !T.isLocked).length > 0,
      onClick: () => {
        const tIds = SelectedTransactions.filter((T) => !T.isLocked).map(
          (T) => T.transactionId
        );
        const Action = () => lockTransaction(tIds);
        checkHistory(Action);
      },
    },
    {
      Text: rebateLanguage[Language].TableSelectedContextMenu.unlockSelecteds,
      Icon: <LockOpen />,
      Active:
        SelectedTransactions &&
        !WaitForProcessTransaction &&
        SelectedTransactions.filter((T) => T.isLocked).length > 0,
      onClick: () => {
        const tIds = SelectedTransactions.filter((T) => T.isLocked).map(
          (T) => T.transactionId
        );
        const Action = () => unlockTransaction(tIds);
        checkHistory(Action);
      },
    },
    {
      Text: rebateLanguage[Language].TableSelectedContextMenu.processSelecteds,
      Icon: <LoadingSpinner />,
      Active:
        SelectedTransactions &&
        !WaitForProcessTransaction &&
        SelectedTransactions.length > 0 &&
        SelectedTransactions.filter((T) => !T.isLocked).length > 0,
      onClick: () => {
        const Action = () => processLock(true, "SELECTED");
        checkHistory(Action);
      },
    },
    {
      Text: rebateLanguage[Language].TableSelectedContextMenu.sendSelecteds,
      Icon: <FiSend />,
      Active:
        SelectedTransactions &&
        SelectedTransactions.filter((T) => !T.doNotSend).length > 0,
      onClick: () => {
        const tIds = SelectedTransactions.filter((T) => !T.doNotSend).map(
          (T) => T.transactionId
        );
        const Action = () => sendTransactionDocument(tIds);
        checkHistory(Action);
      },
    },
    {
      Text: rebateLanguage[Language].TableContextMenuTexts.InvoiceRebateProcess,
      Icon: <LoadingSpinner />,
      Active: !WaitForProcessTransaction,
      onClick: () => {
        const Action = () => setInvoiceRebateModal(true);
        checkHistory(Action);
      },
    },
    {
      Text: rebateLanguage[Language].TableContextMenuTexts.processAll,
      Icon: <LoadingSpinner />,
      Active: !WaitForProcessTransaction,
      onClick: () => {
        const Action = () => processLock(true, "ALL");
        checkHistory(Action);
      },
    },
    {
      Text: rebateLanguage[Language].TableContextMenuTexts.exportExcel,
      Icon: <FaRegFileExcel />,
      Active: Transactions && Transactions.length > 0,
      onClick: () => {
        setExcelModal(true);
      },
    },
    {
      Text: rebateLanguage[Language].TableContextMenuTexts.integrationExcel,
      Icon: <FaRegFileExcel />,
      Active: Transactions && Transactions.length > 0,
      onClick: () => {
        setIntegrationModal(true);
        // setExcelModal(true)
      },
    },
  ];
  const JustAnimationMessages = {
    NotFound: rebateLanguage[Language].NotFound,
    NoConnection: rebateLanguage[Language].NoConnection,
  };
  const { StatusIcon, StatusText } = StatusSelector(Pagestatus, Language);
  const JustSortArray = (sortedArray) => {
    sortTransaction(sortedArray);
  };
  const JustTableBusy = (busyStatus) => {
    // changePageStatus(busyStatus ? "Processing" : "")
  };
  useEffect(() => {
    console.log(Transactions);
  }, [Transactions]);
  return (
    <div className="Transaction-Container">
      <div className="Transaction-Table-Header">
        <h4 id="Transaction-HeaderName">
          {rebateLanguage[Language].TransactionTableHeaderName}
        </h4>

        <div className="JustTable-ButtonContainer">
          <Button
            onClick={() =>
              saveHistory(dataHistory, Transactions, () => setDataHistory([]))
            }
            disabled={
              !(
                Transactions &&
                dataHistory &&
                dataHistory.length > 0 &&
                !WaitForProcessTransaction &&
                !WaitForSaveTransaction
              )
            }
            className="JustTable-SaveAll-Button"
          >
            {rebateLanguage[Language].TableContextMenuTexts.saveAll}
          </Button>

          <JustHamburger
            DialogOptions={JustTableSelectedRowsContextMenu}
            Position={"Bottom"} // TOP LEFT RIGHT(DEFAULT)
            Button={{
              className: "Hamburger-Button",
              variation: "secondary",
              Text: rebateLanguage[Language].TableActionsButtonText,
              disabled:
                WaitForProcessTransaction ||
                WaitForSaveTransaction ||
                WaitForTransactions,
              // icon: <FaChevronDown />, iconAlign: "right"
            }}
          />
        </div>
      </div>
      {(WaitForSaveTransaction || WaitForProcessTransaction) && (
        <JustLockComponent
          className="Transaction-Lock"
          Icon={StatusIcon}
          Text={StatusText}
        />
      )}
      <div className="Transaction-Table-Container">
        <JustTable
          JustSchema={TransactionTableSchema(
            rebateLanguage[Language],
            Language
          )}
          JustData={Transactions}
          JustEvents={JustEvents}
          JustDataHistory={JustDataHistory}
          JustAnimations={JustAnimations}
          JustContextFunctions={JustRowContextMenu}
          JustSelectedRow={JustSelectedRow}
          JustAnimationMessages={JustAnimationMessages}
          JustSortArray={JustSortArray}
          JustTableBusy={JustTableBusy}
          // JustInputEvents={JustInputEvents}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    Transactions: state.rebate.Transactions,
    WaitForTransactions: state.rebate.WaitForTransactions,
    NoConnection: state.rebate.NoConnection,
    WaitForSaveTransaction: state.rebate.WaitForSaveTransaction,
    WaitForProcessTransaction: state.rebate.WaitForProcessTransaction,
    WaitForTransactions: state.rebate.WaitForTransactions,
    dataHistory: state.rebate.DataHistory,
    calculatedRow: state.rebate.CalculatedTransaction,
    calculatedRowIndex: state.rebate.CalculatedTransactionIndex,
    Pagestatus: state.rebate.Statusname,
    SelectedTransactions: state.rebate.SelectedTransactions,
    Language: state.user.Language,
  };
};
export default connect(mapStateToProps, {
  getInvoices,
  saveHistory,
  sendTransactionDocument,
  copyTransaction,
  reprocessTransaction,
  unlockTransaction,
  lockTransaction,
  processLock,
  setDataHistory,
  calculateTransaction,
  setCalculatedRow,
  setExcelModal,
  exportExcel,
  setIntegrationModal,
  setInvoiceRebateModal,
  createPDF,
  showHistoryCheck,
  setSelectedTransactions,
  sortTransaction,
  changePageStatus,
  createExportExcel,
})(TransactionTable);
