import React from 'react'
import { JustTable } from '../../../CommonUI/JustTable/JustTable'
import { CustomerTableSchema } from './CustomerTableSchema'
import './CustomerTable.scss'
import { connect } from 'react-redux'

import {
    saveCustomers, setCustomerTableHistory, setHistoryModal, showAccountNumbersModal
} from '../../../../store/action/customerAction'

import JustLockComponent from '../../../CommonUI/JustLockComponent/JustLockComponent'
import { customerLanguage } from '../../../../config/language'
import { FaSave } from 'react-icons/fa'
import { Button } from '@dfds-ui/react-components'


const CustomerTable = (props) => {
    const { Customers, NoConnection, Language, WaitForCustomers, setCustomerTableHistory,
        WaitForSaveCustomers, saveCustomers, DataHistory, setHistoryModal, showAccountNumbersModal } = props;

    const JustRowContextMenu = [
        {
            Name: "showDocumentHistory",
            Action: (Row) => {
                setHistoryModal(true, Row)
            }
        },
        {
            Name: "showAccountEdit",
            Action: (Row) => {
                showAccountNumbersModal(Row)
            }
        },
    ]

    const JustDataHistory = (dataHistory) => {
        setCustomerTableHistory(dataHistory)
    }

    const JustAnimations = {
        WaitForRequest: WaitForCustomers,
        NoConnection: NoConnection
    }
    return (
        <div className="Customer-Container">
            <div className="Customer-Table-Header">
                <h4 id="Customer-HeaderName"> {customerLanguage[Language].Headername}</h4>

                <div className="JustTable-ButtonContainer">

                    <Button
                        onClick={() => saveCustomers(DataHistory, () => setCustomerTableHistory([]))}
                        disabled={!(!WaitForSaveCustomers && !WaitForCustomers && DataHistory && DataHistory.length > 0)}
                        className="JustTable-SaveAll-Button"
                        variation={"secondary"}
                    >
                        {customerLanguage[Language].saveAll}
                    </Button>
                </div>


            </div>
            {
                WaitForSaveCustomers &&
                <JustLockComponent Icon={<FaSave />} Text={customerLanguage[Language].Saving} />
            }
            <div className="Customer-Table-Container">

                <JustTable
                    JustSchema={CustomerTableSchema(customerLanguage[Language])}
                    JustData={Customers}
                    //JustEvents={JustEvents}
                    JustDataHistory={JustDataHistory}
                    JustAnimations={JustAnimations}
                    JustContextFunctions={JustRowContextMenu}
                // JustForceRender={ForceRender}
                />
            </div>
        </div>


    )
}
const mapStateToProps = (state) => {
    return {
        Customers: state.customer.Customers,
        WaitForCustomers: state.customer.WaitForCustomers,
        WaitForSaveCustomers: state.customer.WaitForSaveCustomers,
        DataHistory: state.customer.DataHistory,
        Language: state.user.Language,
    }
}
export default connect(mapStateToProps, {
    setCustomerTableHistory, saveCustomers, setHistoryModal, showAccountNumbersModal
})(CustomerTable);