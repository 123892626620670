import React, { useEffect } from 'react'
import { IncomingInvoiceTableSchema, DummyCreator } from './IncomingInvoiceTableSchema'
import './IncomingInvoiceTable.scss'
import { connect } from 'react-redux'
import { rebateLanguage, incomingInvoiceLanguage } from '../../../config/language'
import { Button } from '@dfds-ui/react-components'
import { JustTable } from '../../CommonUI/JustTable/JustTable'
import { showNewInvoiceModal, showEditInvoiceModal, showDeleteInvoiceModal, showIntegrateModal, showCheckIntegrateModal } from '../../../store/action/incomingInvoiceAction'

const IncomingInvoiceTable = (props) => {
    const { IncomingInvoices, WaitForIncomingInvoices, NoConnection,
        Language, IncomingInvoicesNotSearched, showNewInvoiceModal, showEditInvoiceModal, showDeleteInvoiceModal, showIntegrateModal, showCheckIntegrateModal } = props;

    const JustAnimations = {
        WaitForRequest: WaitForIncomingInvoices,
        NoConnection: NoConnection
    }
    const JustAnimationMessages = {
        NotFound: rebateLanguage[Language].NotFound,
        NoConnection: rebateLanguage[Language].NoConnection
    }
    const JustRowContextMenu = [
        {
            Name: "editInvoice",
            Action: (Row) => {
                showEditInvoiceModal(Row)
            }
        },
        {
            Name: "deleteInvoice",
            Action: (Row) => {
                showDeleteInvoiceModal(Row)
            }
        },
        {
            Name: "integrate",
            Action: (Row) => {
                showIntegrateModal(Row)
            }
        },
        {
            Name: "checkIntegrate",
            Action: (Row) => {
                showCheckIntegrateModal(Row)
            }
        }

    ]
    return (
        <div className="IncomingInvoice-Container">
            <div className="IncomingInvoice-Table-Header">
                <h4 id="IncomingInvoice-HeaderName">{incomingInvoiceLanguage[Language].TableHeaderName}</h4>

                <div className="JustTable-ButtonContainer">
                    <Button
                        onClick={() => showNewInvoiceModal()}
                        // disabled={!(IncomingInvoices && dataHistory && dataHistory.length > 0 && !WaitForProcessTransaction && !WaitForSaveTransaction)}
                        className="JustTable-NewInvoice-Button"
                    >{
                            incomingInvoiceLanguage[Language].NewInvoice}
                    </Button>

                    {/* <JustHamburger
                        DialogOptions={JustTableSelectedRowsContextMenu}
                        Position={"Bottom"} // TOP LEFT RIGHT(DEFAULT)
                        Button={{
                            className: "Hamburger-Button", variation: "secondary", Text: rebateLanguage[Language].TableActionsButtonText,
                            // disabled: (WaitForProcessTransaction || WaitForSaveTransaction || WaitForIncomingInvoices)
                            // icon: <FaChevronDown />, iconAlign: "right"
                        }} /> */}

                </div>

            </div>
            {/* {
                (WaitForSaveTransaction || WaitForProcessTransaction) &&
                <JustLockComponent className="Transaction-Lock" Icon={StatusIcon} Text={StatusText} />
            } */}
            <div className="IncomingInvoice-Table-Container">

                <JustTable
                    JustSchema={IncomingInvoiceTableSchema(incomingInvoiceLanguage[Language].IncomingInvoiceTableLanguage, Language)}
                    JustData={IncomingInvoices}
                    JustAnimations={JustAnimations}
                    JustContextFunctions={JustRowContextMenu}
                    // JustSelectedRow={JustSelectedRow}
                    JustAnimationMessages={JustAnimationMessages}
                    notSearched={{ Text: incomingInvoiceLanguage[Language].SearchIncomingInvoice, Status: IncomingInvoicesNotSearched }}
                // JustInputEvents={JustInputEvents}
                />
            </div>
        </div>

    )
}
const mapStateToProps = (state) => {
    return {
        IncomingInvoices: state.incomingInvoice.IncomingInvoices,
        WaitForIncomingInvoices: state.incomingInvoice.WaitForIncomingInvoices,
        NoConnection: state.incomingInvoice.NoConnection,
        IncomingInvoicesNotSearched: state.incomingInvoice.IncomingInvoicesNotSearched,
        Language: state.user.Language,
    }
}
export default connect(mapStateToProps, {
    showNewInvoiceModal, showEditInvoiceModal,
    showDeleteInvoiceModal, showIntegrateModal, showCheckIntegrateModal
})(IncomingInvoiceTable);
