const invoiceControlTypes = Object.freeze({
    GET_INVOICES: Symbol('GET_INVOICES'),
    GET_EXCEL_INVOICES: Symbol('GET_EXCEL_INVOICES'),
    GETTING_EXCEL_INVOICES: Symbol('GETTING_EXCEL_INVOICES'),
    GETTING_INVOICES: Symbol('GETTING_INVOICES'),
    SET_DATE_FILTER: Symbol('SET_DATE_FILTER'),
    INVOICE_ERROR: Symbol('INVOICE_ERROR'),
    EXCEL_INVOICE_ERROR: Symbol('EXCEL_INVOICE_ERROR'),
    INVOICE_NOT_FOUND: Symbol('INVOICE_NOT_FOUND'),
    DELETE_EXCEL_INVOICES: Symbol('DELETE_EXCEL_INVOICES')
});

export default invoiceControlTypes;