import React from 'react'
import { JustStaticTable } from '../../../CommonUI/JustTable/JustTable'
import { InvoiceTableSchema } from './InvoiceTableSchema'
import './InvoiceTable.scss'
import { connect } from 'react-redux'
import JustLockComponent from '../../../CommonUI/JustLockComponent/JustLockComponent'
import { rebateLanguage } from '../../../../config/language'
import { StatusSelector } from '../StatusSelector'

const InvoiceTable = (props) => {
    const { Invoices, WaitForInvoices, NoConnection,
        WaitForProcessTransaction, WaitForSaveTransaction, Language, Pagestatus } = props;
    const onRowClick = (e, RowData) => {
    }
    const JustEvents = {
        onRowClick: onRowClick
    }
    const JustAnimations = {
        WaitForRequest: WaitForInvoices,
        NoConnection: NoConnection
    }
    const JustAnimationMessages = {
        NotFound: rebateLanguage[Language].NotFound,
        NoConnection: rebateLanguage[Language].NoConnection
    }
    const { StatusIcon, StatusText } = StatusSelector(Pagestatus, Language)
    return (
        <div className="Invoice-Container">
            <div className="Invoice-Table-Header">
                <h4 id="Invoice-HeaderName">{rebateLanguage[Language].InvoicesTableHeaderName}</h4>
            </div>
            {
                (WaitForSaveTransaction || WaitForProcessTransaction) &&
                <JustLockComponent className="Invoice-Lock" Icon={StatusIcon} Text={StatusText} />
            }
            <div className="Invoice-Table-Container">

                <JustStaticTable
                    JustSchema={InvoiceTableSchema(rebateLanguage[Language],Language)}
                    JustData={Invoices}
                    JustEvents={JustEvents}
                    JustAnimations={JustAnimations}
                    JustAnimationMessages={JustAnimationMessages}
                />
            </div>
        </div>

    )
}
const mapsStateToProps = (state) => {
    return {
        Invoices: state.rebate.Invoices,
        WaitForInvoices: state.rebate.WaitForInvoices,
        NoConnection: state.rebate.NoConnection,
        WaitForSaveTransaction: state.rebate.WaitForSaveTransaction,
        WaitForProcessTransaction: state.rebate.WaitForProcessTransaction,
        Pagestatus: state.rebate.Statusname,
        Language: state.user.Language
    }
}
export default connect(mapsStateToProps, null)(InvoiceTable);