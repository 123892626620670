import React, { useEffect } from 'react';
import history from '../../core/history'
import { clearUserStorage } from '../../core/projectStorage'
import { logOutUser } from '../../store/action/userActions'
import { connect } from 'react-redux';
import { ErrorModal } from '../CommonUI/Modals/ErrorModal'
import './NoAuth.css';

import { NoAuthLanguage } from '../../config/language'
const NoAuth = ({ logOutUser, Language }) => {
    const language = Language
    useEffect(() => {
        clearUserStorage();
        logOutUser();
    }, [])
    const onClose = () => {
        history.push('/login')
    }
    return (
        <ErrorModal
            ErrorTitle={NoAuthLanguage[language].NoAuthTitle}
            ErrorMessage={NoAuthLanguage[language].NoAuthMessage}
            OnClose={onClose}
            ButtonLabel={NoAuthLanguage[language].NoAuthButtonLabel}
        />);
}
const mapStateToProps = state => {
    return {
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { logOutUser })(NoAuth);