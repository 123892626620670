const accountNumbers = Object.freeze({
    GETTING_CUSTOMER_NUMBERS: Symbol('GETTING_CUSTOMER_NUMBERS'),
    GET_CUSTOMER_ACCOUNT_NUMBERS: Symbol('GET_CUSTOMER_ACCOUNT_NUMBERS'),
    SAVING_CUSTOMER_NUMBERS: Symbol('SAVING_CUSTOMER_NUMBERS'),
    SAVED_CUSTOMER_NUMBERS: Symbol('SAVED_CUSTOMER_NUMBERS'),
    SET_ACCOUNT_NUMBERS_HISTORY_DATA: Symbol('SET_ACCOUNT_NUMBERS_HISTORY_DATA'),
    CLEAR_CUSTOMER_NUMBERS: Symbol('CLEAR_CUSTOMER_NUMBERS'),
    // CLEAR_SAVE_CONTROL: Symbol('CLEAR_SAVE_CONTROL')
});

export default accountNumbers;