import React, { useEffect } from 'react'
import './UnitType.scss'
import { Button } from '@dfds-ui/react-components'
import { ErrorModal } from '../../CommonUI/Modals/ErrorModal';
import { JustTable } from '../../../component/CommonUI/JustTable/JustTable'
import { UnitTypeTableSchema, DummyCreator } from './UnitTypeTableSchema'
import { UnitTypeModalShow,clearNetworkError, clearError, setSelectedUnitType, unitTypeDeleteModalShow, unitTypeDeleteModalHide, getUnitTypes, deleteUnitType } from '../../../store/action/adminPanelAction'
import DeleteModal from '../../CommonUI/Modals/YesNoModal/YesNoModal';

import UnitTypeModal from './Modal/Modal'
import { connect } from 'react-redux'
import { rebateLanguage, unitTypeLanguage } from '../../../config/language'

const UnitType = (props) => {
    const { display, UnitTypeModalShow, setSelectedUnitType, ShowDeleteUnitTypeModal,ErrorHandler,clearNetworkError, clearError,RefreshUnitTypeTable,
        WaitForDeleteUnitType, Language, unitTypeDeleteModalShow, unitTypeDeleteModalHide,getUnitTypes, UnitTypes, WaitForUnitTypes,SelectedUnitType,deleteUnitType} = props
    const JustRowContextMenu = [
        {
            Name: "editUnitType",
            Action: (Row) => {
                const cloneRow = JSON.parse(JSON.stringify(Row))
                setSelectedUnitType(Row)
            }
        },
        {
            Name: "deleteUnitType",
            Action: (Row) => {
                unitTypeDeleteModalShow(Row)
            }
        }
    ]
    useEffect(() => {
        if (display) {
            getUnitTypes()
        }
    }, [display])

    useEffect(() => {
        if (RefreshUnitTypeTable) {
            getUnitTypes()
        }
    }, [RefreshUnitTypeTable])

    const JustAnimations = {
        CanvasWidth: 1063,
        WaitForRequest: WaitForUnitTypes
    }
    const ErrorCode = ErrorHandler && ErrorHandler.ErrorCode;
    const ErrorDescription = ErrorHandler && ErrorHandler.ErrorDescription
    const ErrorState = ErrorHandler && ErrorHandler.ErrorState
    const _onDeleteUnitTypeClick = () => {
        deleteUnitType(SelectedUnitType.id)
    }
    return (
        display &&
        <>
            <div className="UnitType-Container">
                <div className="UnitType-Header">
                    <h4 id="HeaderText">{unitTypeLanguage[Language].HeaderText}</h4>
                </div>
                <div className="UnitType-Content-Container">
                    <div className="UnitType-Content-Header">
                        <Button
                            className="UnitType-AddUser"
                            onClick={() => UnitTypeModalShow()}
                            disabled={false}
                        >
                            {unitTypeLanguage[Language].NewUnitTypeButtonText}
                    </Button>
                    </div>
                    <div className="UnitType-Table-Container">
                        <JustTable
                            JustSchema={UnitTypeTableSchema(unitTypeLanguage[Language].UnitTypeTableLanguage,Language)}
                            JustData={UnitTypes}
                            JustAnimations={JustAnimations}
                            JustContextFunctions={JustRowContextMenu}
                        />
                    </div>

                </div>
            </div>
            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ButtonLabel={rebateLanguage[Language].GoBackButtonText}
                    ErrorTitle={rebateLanguage[Language].ErrorTitle}
                    ErrorMessage={ErrorDescription}
                    OnClose={() => {
                        switch (ErrorState) {
                            case "NETWORK_ERROR":
                                clearNetworkError()
                                break;
                            case "CUSTOM_ERROR":
                                clearError()
                                break;
                            default:
                                clearError()
                                break;
                        }
                    }}
                />
            }
            <UnitTypeModal />
            <DeleteModal
                OpenModal={ShowDeleteUnitTypeModal}
                Title={unitTypeLanguage[Language].DeleteModalTitle}
                Message={unitTypeLanguage[Language].DeleteModalMessage}
                OnClose={() => unitTypeDeleteModalHide()}
                onYesClick={(e) => { _onDeleteUnitTypeClick() }}
                waitForRequest={WaitForDeleteUnitType}
                ButtonYesText={rebateLanguage[Language].ButtonYes}
                ButtonNoText={rebateLanguage[Language].ButtonNo}
            />
        </>

    )
}
const mapStateToProps = (state) => {
    return {
        ShowDeleteUnitTypeModal: state.adminPanel.ShowDeleteUnitTypeModal,
        WaitForDeleteUnitType: state.adminPanel.WaitForDeleteUnitType,
        WaitForUnitTypes: state.adminPanel.WaitForUnitTypes,
        UnitTypes: state.adminPanel.UnitTypes,
        Language: state.user.Language,
        ErrorHandler: state.adminPanel.ErrorHandler,
        Language : state.user.Language,
        SelectedUnitType: state.adminPanel.SelectedUnitType,
        RefreshUnitTypeTable:state.adminPanel.RefreshUnitTypeTable
    }
}
export default connect(mapStateToProps, { UnitTypeModalShow, setSelectedUnitType, unitTypeDeleteModalShow, unitTypeDeleteModalHide, getUnitTypes,deleteUnitType,clearError, clearNetworkError })(UnitType);