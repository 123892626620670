import React from 'react'
import { FaCircle, FaCopy, FaRegFileExcel, FaRegFilePdf } from "react-icons/fa";
import { Lock, LockOpen, YesTick, Close, LoadingSpinner, Document } from '@dfds-ui/icons'
import { FiSend } from "react-icons/fi";


export const TransactionTableSchema = (schemaLanguage, Language) => {
    return {
        rowId: "transactionId", //unique ID
        RowSelectable: true,
        RowContextMenu: [
            {
                Text: schemaLanguage.TransactionTableContextMenu.lockTransaction,
                Icon: <Lock />,
                Name: "lockTransaction",
                ActiveCheck: [{ columnId: "isLocked", Equal: false }]
            },
            {
                Text: schemaLanguage.TransactionTableContextMenu.unlockTransaction,
                Icon: <LockOpen />,
                Name: "unlockTransaction",
                ActiveCheck: [{ columnId: "isLocked", Equal: true }]
            },
            {
                Text: schemaLanguage.TransactionTableContextMenu.reprocessTransaction,
                Icon: <LoadingSpinner />,
                Name: "reprocessTransaction",
            },
            {
                Text: schemaLanguage.TransactionTableContextMenu.copyTransaction,
                Icon: <FaCopy />,
                Name: "copyTransaction",
                ActiveCheck: [{ columnId: "isLocked", Equal: true }]
            },
            {
                Text: schemaLanguage.TransactionTableContextMenu.sendTransactionDocument,
                Icon: <FiSend />,
                Name: "sendDocument",
                ActiveCheck: [{ columnId: "doNotSend", Equal: false }]
            }
            ,
            {
                Text: schemaLanguage.TransactionTableContextMenu.exportAsExcel,
                Icon: <FaRegFileExcel />,
                Name: "exportAsExcel",
            }
            ,
            {
                Text: schemaLanguage.TransactionTableContextMenu.exportAsPdf,
                Icon: <FaRegFilePdf />,
                Name: "exportAsPdf",
            }
        ],
        RowLock: [
            {
                columnId: "isLocked",
                Equal: true
            },
        ],
        ColumnDesign: [
            {
                columnId: "period",
                Text: schemaLanguage.TransactionTableColumns.period,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "125px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            // Selects: null // (Columns) => {}
                        }
                    }
                },
            },
            {
                columnId: "doNotSend",
                Text: schemaLanguage.TransactionTableColumns.dontSend,
                Info: schemaLanguage.TransactionTableColumns.dontSendInfoText
            },
            {
                columnId: "isLocked",
                Text: schemaLanguage.TransactionTableColumns.isLocked,
                Info: schemaLanguage.TransactionTableColumns.isLockedInfoText

            },

            {
                columnId: "isCopied",
                Text: schemaLanguage.TransactionTableColumns.isCopied,
                Info: schemaLanguage.TransactionTableColumns.isCopiedInfoText,
                Classname: (Data) => { if (Data) return "COPIED-ROW"; else return "" },
                ClassOverWritePriorty: 0 //0 Overwrite everything
            },
            {
                columnId: "isCopiedTransaction",
                Text: schemaLanguage.TransactionTableColumns.isCopiedTransaction,
                Info: schemaLanguage.TransactionTableColumns.isCopiedTransactionInfoText,
                Classname: (Data) => { if (Data) return "COPIEDTRANSACTION-ROW"; else return "" },
                ClassOverWritePriorty: 1
            },
            {
                columnId: "isSent",
                Text: schemaLanguage.TransactionTableColumns.isSent,
                Info: schemaLanguage.TransactionTableColumns.isSentInfoText
            },
            {
                columnId: "isIntegratedBySoft",
                Text: schemaLanguage.TransactionTableColumns.isIntegratedBySoft,//schemaLanguage.TransactionTableColumns.isSent,
                Info: schemaLanguage.TransactionTableColumns.isIntegratedBySoftInfoText,//schemaLanguage.TransactionTableColumns.isSentInfoText
            },
            {
                columnId: "number",
                parentIds: ["customer"],
                Text: schemaLanguage.TransactionTableColumns.customerNumber,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "125px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            // Selects: null // (Columns) => {}
                        }
                    },
                    Sorter: true
                },
            },
            {
                columnId: "name",
                parentIds: ["customer"],
                Text: schemaLanguage.TransactionTableColumns.customerName,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "400px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            Selects: null // (Columns) => {}
                        }
                    }
                },
            },

            {
                columnId: "currency",
                Text: schemaLanguage.TransactionTableColumns.currency

            },
            {
                columnId: "totalRevenueAmount",
                Text: schemaLanguage.TransactionTableColumns.totalRevenueAmount
            },
            {
                columnId: "calculatedRebateRatio",
                Text: schemaLanguage.TransactionTableColumns.calculatedRebateRatio
            },
            {
                columnId: "calculatedRebateAmount",
                Text: schemaLanguage.TransactionTableColumns.calculatedRebateAmount
            },
            {
                columnId: "rebateRatio",
                Text: schemaLanguage.TransactionTableColumns.rebateRatio
            },
            {
                columnId: "rebateAmount",
                Text: schemaLanguage.TransactionTableColumns.rebateAmount
            },
            {
                columnId: "manualChargeBackAmount",
                Text: schemaLanguage.TransactionTableColumns.manualChargeBackAmount
            },
            {
                columnId: "invoiceChargeBackAmount",
                Text: schemaLanguage.TransactionTableColumns.invoiceChargeBackAmount
            },
            {
                columnId: "isOctet",
                parentIds: ["customer"],
                Text: schemaLanguage.TransactionTableColumns.octet

            },
            {
                columnId: "emailAddresses",
                parentIds: ["customer"],
                Text: schemaLanguage.TransactionTableColumns.email

            },
            {
                columnId: "updatedBy",
                Text: schemaLanguage.TransactionTableColumns.updatedBy

            },
            {
                columnId: "updateDate",
                Text: schemaLanguage.TransactionTableColumns.updateDate
            },
            {
                columnId: "copiedPeriod",
                Text: schemaLanguage.TransactionTableColumns.copiedPeriod
            },
            {
                columnId: "buyerAccountNumber",
                parentIds: ["customer", "accountings"],
                parentArrayPosition: 0,

                Text: schemaLanguage.TransactionTableColumns.buyerAccountNumber
            },

            {
                columnId: "vendorAccountNumber",
                parentIds: ["customer", "accountings"],
                parentArrayPosition: 0,

                Text: schemaLanguage.TransactionTableColumns.vendorAccountNumber,

            },
            {
                columnId: "manualChargeBackDescription",
                Text: schemaLanguage.TransactionTableColumns.manualChargeBackDescription
            },
            {
                columnId: "rebateRateDescription",
                Text: schemaLanguage.TransactionTableColumns.rebateRatioDescription
            },
        ],
        DataDesign: [
            {
                columnId: "period",
                dataType: "EPOCHMONTHANDYEAR",
                dataLanguage: Language ? Language : "eng"
            },
            {
                columnId: "doNotSend",
                input:
                {
                    Type: "checkbox", //textarea , select , checkbox
                    Items: [{}]
                }

            },
            {
                columnId: "isLocked",
                dataType: "icon",
                dataCheck: (data) => lockCheckIcon(data)
            },

            {
                columnId: "isCopied",
                dataType: "icon",
                dataCheck: (data) => copyCheckIcon(data)

            },
            {
                columnId: "isCopiedTransaction",
                dataType: "icon",
                dataCheck: (data) => copyCheckIcon(data)

            },
            {
                columnId: "isSent",
                dataType: "icon",
                dataCheck: (data) => copyCheckIcon(data)

            },
            {
                columnId: "isIntegratedBySoft",
                dataType: "icon",
                dataCheck: (data) => copyCheckIcon(data)

            },
            {
                columnId: "number",
            },
            {
                columnId: "name",

            },
            {
                columnId: "currency",

            },
            {
                columnId: "totalRevenueAmount",
                dataType: "number",

            },
            {
                columnId: "calculatedRebateRatio",
                dataType: "number",

            },
            {
                columnId: "calculatedRebateAmount",
                dataType: "number",

            },
            {
                columnId: "rebateRatio",
                dataType: "number",
                updatable: true,
                input:
                {
                    Type: "text", //textarea , select , checkbox
                    Items: [{}],
                    onKeyUp: {
                        name: "onRebateRatioChange"
                    }
                }

            },
            {
                columnId: "rebateAmount",
                dataType: "number",

            },
            {
                columnId: "manualChargeBackAmount",
                dataType: "number",
                //numberDigit : 3
                updatable: true,
                input:
                {
                    Type: "text", //textarea , select , checkbox
                    Items: [{}],
                    onKeyUp: {
                        name: "onManualChargeBackAmountChange"
                    }
                }

            },
            {
                columnId: "invoiceChargeBackAmount",
                dataType: "number",
                //numberDigit : 3
                updatable: true,
                input:
                {
                    Type: "text", //textarea , select , checkbox
                    Items: [{}],
                }

            },
            {
                columnId: "isOctet",
                input:
                {
                    Type: "checkbox", //textarea , select , checkbox
                    Items: [{}]
                }
                // dataType: "icon",
                // dataCheck: (data) => dataCheckBox(data)
            },
            {
                columnId: "emailAddresses",
                updatable: true,
                dataType: "Array",
                input:
                {
                    Type: "text", //textarea , select , checkbox
                    PlaceHolder: "Enter Email Address",
                    Items: [{}]
                }
            },
            {
                columnId: "updatedBy",
            },
            {
                columnId: "updateDate",
                dataType: "EPOCHMONTHANDYEAR",
                dataLanguage: Language ? Language : "eng"

            },
            {
                columnId: "copiedPeriod",
                dataType: "EPOCHMONTHANDYEAR",
                dataLanguage: Language ? Language : "eng"
            },
            {
                columnId: "buyerAccountNumber",

            },
            {
                columnId: "vendorAccountNumber",

            },
            {
                columnId: "manualChargeBackDescription",
                updatable: true,
                input:
                {
                    Type: "text", //textarea , select , checkbox
                    PlaceHolder: schemaLanguage.TransactionTableColumns.enterDescripton,
                    Items: [{}]
                }
            },
            {
                columnId: "rebateRateDescription",
                updatable: true,
                input:
                {
                    Type: "text", //textarea , select , checkbox
                    PlaceHolder: schemaLanguage.TransactionTableColumns.enterDescripton,
                    Items: [{}]
                }
            },
        ]
    }
}
const lockCheckIcon = (data) => {
    if (!data) {
        return <LockOpen style={{ fill: "#4caf50" }} />
    } else {
        return <Lock style={{ color: "#be1e2d" }} />
    }
}
const copyCheckIcon = (data) => {
    if (!data) {
        return <Close style={{ color: "#be1e2d" }} />
    } else {
        return <YesTick style={{ color: "#4caf50" }} />
    }
}
const dataCheckIcon = (data) => {
    if (!data) {
        return <FaCircle style={{ fill: "#be1e2d" }} />
    } else {
        return <FaCircle style={{ fill: "#4caf50" }} />
    }
}
// const dataCheckBox = (data) => {
//     if (!data) {
//         return <input type="checkbox" width={32} height={32} />
//     } else {
//         return <input type="checkbox" width={32} height={32} checked />
//     }
// }

