import React, { useEffect } from 'react'
import { Modal } from '@dfds-ui/modal';
import { hideAccountNumbersModal } from '../../../../store/action/customerAction'
import { saveAccountNumbers, getCustomerNumbers, clearAccountNumbers } from './../../../../store/action/accountNumberAction'
import { customerLanguage } from '../../../../config/language'
import { connect } from 'react-redux'
import './AccountModal.scss'
import 'react-accessible-accordion/dist/fancy-example.css';
import { Button } from '@dfds-ui/react-components';
import AccountTable from './AccountTable/AccountTable'
const HistoryModal = (props) => {
    const {
        SelectedRow, ShowAccountNumbersModal, WaitForCustomerNumbers,
        Language, CustomerAccountNumbersHistory,
        saveAccountNumbers, WaitForSaveCustomerNumbers, getCustomerNumbers, hideAccountNumbersModal,
    } = props

    useEffect(() => {
        const customerNumber = SelectedRow ? SelectedRow.number : null
        if (ShowAccountNumbersModal) {
            getCustomerNumbers(customerNumber)
        }
    }, [ShowAccountNumbersModal])
    const _onSaveClick = () => {
        saveAccountNumbers(SelectedRow, CustomerAccountNumbersHistory, hideAccountNumbersModal)
    }
    const _onModalClose = () => {
        hideAccountNumbersModal()
        clearAccountNumbers()
    }
    return (
        <Modal
            isOpen={ShowAccountNumbersModal}
            className="Customer-Account-Numbers-Modal"
            heading={customerLanguage[Language].AccountNumberModalHeader}
            onRequestClose={() => { _onModalClose() }}>
            <AccountTable />
            <Button
                className="Customer-Account-Save-Button"
                size={"small"}
                disabled=
                {WaitForCustomerNumbers ||
                    WaitForSaveCustomerNumbers ||
                    !(CustomerAccountNumbersHistory && CustomerAccountNumbersHistory.length > 0)}
                onClick={() => _onSaveClick()}
            >
                {customerLanguage[Language].CustomerNumberSave}
            </Button>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        WaitForCustomerNumbers: state.accountNumber.WaitForCustomerNumbers,
        CustomerAccountNumbersHistory: state.accountNumber.CustomerAccountNumbersHistory,
        WaitForSaveCustomerNumbers: state.accountNumber.WaitForSaveCustomerNumbers,
        ShowAccountNumbersModal: state.customer.ShowAccountNumbersModal,
        SelectedRow: state.customer.SelectedRow,

        Language: state.user.Language,
    }
}
export default connect(mapStateToProps, {
    saveAccountNumbers, getCustomerNumbers, hideAccountNumbersModal, clearAccountNumbers
})(HistoryModal);