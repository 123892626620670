import React from 'react'
import { MdHistory, MdAccountBalance } from "react-icons/md";
import { AiFillAccountBook } from "react-icons/ai";


export const AccountNumberSchema = (schemaLanguage) => {
    return {
        rowId: "currency", //unique ID
        ColumnDesign: [
            {
                columnId: "currency",
                Text: schemaLanguage.currency,

            },
            {
                columnId: "buyerAccountNumber",
                Text: schemaLanguage.buyerAccountNumber
            },

            {
                columnId: "vendorAccountNumber",
                Text: schemaLanguage.vendorAccountNumber,

            },
        ],
        DataDesign: [
            {
                columnId: "currency",
            },
            {
                columnId: "buyerAccountNumber",
                updatable: true
            },

            {
                columnId: "vendorAccountNumber",
                updatable: true,
            },
        ]
    }
}

