import React from 'react';
import InvoiceControlPage from '../component/InvoiceControl/InvoiceControl'

import { connect } from 'react-redux';
import { pageChanged } from '../store/action/pageActions'

const invoiceControl = (props) => {
    const { pageName, pageChanged, match } = props;
    if (pageName !== match.path) {
        pageChanged(match.path)
    }
    return <InvoiceControlPage />
}
const mapStateToProps = (state) => {
    return {
        pageName: state.page.pageName
    }
}
export default connect(mapStateToProps, { pageChanged })(invoiceControl);
