import React from 'react';
import { connect } from 'react-redux';
import { pageChanged } from '../store/action/pageActions'
import IncomingInvoice from '../component/IncomingInvoice/IncomingInvoice';

const incomingInvoices = (props) => {
    const { pageName, pageChanged, match } = props;
    if (pageName !== match.path) {
        pageChanged(match.path)
    }
    return <IncomingInvoice />
}
const mapStateToProps = (state) => {
    return {
        pageName: state.page.pageName
    }
}
export default connect(mapStateToProps, { pageChanged })(incomingInvoices);
