import httpClient from "../../core/httpclient";
import userType from "../type/user";
import history from '../../core/history';
import { setUserStorage, getUserStorage, clearUserStorage } from '../../core/projectStorage'
import ProjectConfig from '../../config/config'
import XMSErrorHandler from "../../core/xmsErrorHandler";
import userTypes from "../type/user";
export const authenticateUser = (User) => async dispatch => {
    const endPoint = `${ProjectConfig.apiUrl}/auth/authenticate`;
    const UserInformation = {
        Username: User.userName,
        Password: User.userPass
    }
    dispatch({
        type: userType.USER_WAITFORREQUEST
    })
    httpClient.post(endPoint, UserInformation)
        .then(response => {
            const userToken = response.data.result;
            setUserStorage(userToken, UserInformation.Username);
            dispatch({
                type: userType.USER_AUTHENTICATION_SUCCESS,
                token: response.data.result,
                Username: UserInformation.Username
            });
            history.push("/");
        })
        .catch(error => {
            const { ErrorCode, ErrorDescription } = XMSErrorHandler(error)

            dispatch({
                type: userType.USER_ERROR,
                ErrorCode: ErrorCode,
                ErrorMessage: ErrorDescription
            });
        })
        .finally(() => {

        });
}
export const userOnline = () => dispatch => {
    const userStorage = getUserStorage()
    if (userStorage.token.access_token === undefined ||
        userStorage.token.access_token === null ||
        userStorage.token.access_token.trim() === "") {
        return; // No token No user
    }

    dispatch({
        type: userType.USER_ONLINE,
        token: userStorage.token,
        Username: userStorage.Username
    })
}
export const logOutUser = () => dispatch => {
    clearUserStorage();
    dispatch({
        type: userType.USER_LOGOUT
    })
}
export const clearUserError = () => dispatch => {
    dispatch({
        type: userType.USER_ERROR,
        ErrorCode: -1,
        ErrorMessage: ""
    })
}
export const changeLanguage = (Language) => dispatch => {
    dispatch({
        type: userTypes.USER_LANGUAGE,
        Language: Language
    })
}

