import React from 'react'
import PropTypes from 'prop-types';

const FlagNameSelector = (Language) => {
    switch (Language.toUpperCase()) {
        case "TR":
            return (
                "Türkçe"
            )
        case "ENG":
            return (
                "English"
            )
        default:
            return <></>
    }
}
FlagNameSelector.propTypes = {
    Language: PropTypes.string,
};
FlagNameSelector.defaultProps = {
    Language: ""
};
export default FlagNameSelector;
