
import { beforeMonthName } from '../core/dateStr'
export const existLanguages = [
    "tr", "eng"
]
const commonLanguage = {
    tr: {
        ApplicationName: "Rebate Giriş",
        ValidationMessage: "Bu alan doldurulmak zorunda.",
        NoConnection: "Ağ bağlantısı yok.",
        NotFound: "Bulunamadı",
        Description: "Açıklama",
        ButtonYes: "Evet",
        ButtonNo: "Hayır"
    },
    eng: {
        ApplicationName: "Rebate Login",
        ValidationMessage: "This field is required.",
        NoConnection: "No Connection.",
        NotFound: "Not Found",
        Description: "Description",
        ButtonYes: "Yes",
        ButtonNo: "No"
    }
}
export const headerContextNames = {
    tr: {
        Logout: "Çıkış",
        AdminPanel: "Yönetim Paneli",
    },
    eng: {
        Logout: "Logout",
        AdminPanel: "Admin Panel",
    }
}
export const navbarPagenames = {
    tr: {
        CustomerNavText: "Müşteriler",
        RebateNavText: "Rebate",
        InvoiceControlNavText: "Faturalar",
        IncomingInvoices: "Gelen Faturalar",
    },
    eng: {
        CustomerNavText: "Customer",
        RebateNavText: "Rebate",
        InvoiceControlNavText: "Invoices",
        IncomingInvoices: "Incoming Invoices",
    },
}
export const networkErrors = {
    tr: {
        InvalidUser: "Yanlış kullanıcı adı veya şifresi."
    },
    eng: {
        InvalidUser: "Incorrect username or password."
    }
}
export const loginPageLanguage = {
    tr: {
        ValidationMessage: commonLanguage.tr.ValidationMessage,
        LoginHeader: commonLanguage.tr.ApplicationName,
        SignInButtonText: "GİRİŞ",
        ForgotPassword: "Şifremi Unuttum",
        UserNameHolder: "Kullanıcı adı",
        PasswordHolder: "Kullanıcı şifresi"
    },
    eng: {
        ValidationMessage: commonLanguage.eng.ValidationMessage,
        LoginHeader: commonLanguage.eng.ApplicationName,
        SignInButtonText: "SIGN IN",
        ForgotPassword: "Forgot Password?",
        UserNameHolder: "Username",
        PasswordHolder: "Password"

    }
}
export const incomingInvoiceLanguage = {
    tr: {
        NewInvoiceModalHeader: "Yeni Fatura",
        EditInvoiceModalHeader: "Fatura Düzenle",
        TableHeaderName: "Gelen Faturalar",
        NewInvoice: "Yeni Fatura",
        SuccessTitle: "Başarılı",
        IntegratedSuccess: "Muhasebeleştirme başarıyla tamamlandı.",
        IntegratedCheckSuccess: "Muhasebe kontrolünde herhangi bir problem yoktur.",
        InputValidationError: "Kırmızı ile işaretlenmiş alanları boş bırakmayınız.",
        SearchIncomingInvoice: "Gelen Faturaları Ara",
        IsIntegratedMessage: "Muhasebeleştirilmiş bir faturadır. Devam etmek istiyorsanız evete basınız.",
        IsIntegratedTitle: "Muhasebeleştirilmiş Fatura",
        IncomingInvoiceTableLanguage: {
            EditInvoice: "Fatura Düzenle",
            DeleteInvoice: "Fatura Sil",
            Integrate: "Muhasebeleştir",
            CheckIntegrate: "Muhasebeleştirme Kontrolü Yap",
            Integrated: "Entegrasyon",
            EInvoice: "E-Fatura",
            InvoiceDate: "Fatura Tarihi",
            InvoiceId: "Fatura Numarası",
            CustomerNumber: "Müşteri Numarası",
            CustomerName: "Müşteri Adı",
            InvoiceAmount: "Fatura Tutarı",
            InvoiceAmountTL: "Fatura Tutarı (TL)",
            InvoiceCurrency: "Fatura Dövizi",
            ExchangeRate: "Döviz Kuru",
        },
        ModalLanguage: {
            GetTransactionsButton: "Ciro Prim Kayıtlarını Getir",
            SearchCustomerRebate: "Müşteri Primini Arat",
            PaperInvoice: "Kağıt Fatura",
            EInvoice: "E-Fatura",
            GetEInvoiceButton: "GETIR",
            InvoiceDate: "Fatura Tarihi",
            InvoiceId: "Fatura Numarası",
            InvoicePaymentDate: "Fatura Ödeme Tarihi",
            VoucherDate: "Mahsuplaştırma Tarihi",
            ExchangeRate: "Fatura Kuru",
            InvoiceAmount: "Fatura Tutarı (€)",
            VatAmount: "KDV Tutarı (€)",
            InvoiceTotal: "Fatura Toplamı (€)",
            InvoiceAmountTL: "Fatura Tutarı (TL)",
            VatAmountTL: "KDV Tutarı (TL)",
            InvoiceTotalTL: "Fatura Toplamı (TL)",
            Description: "Açıklama",
            ModalTable: {
                Copied: "Kopya",
                Period: "Tarih",
                CustomerNumber: "Müşteri Numarası",
                CustomerName: "Müşteri Adı",
                RebateAmount: "Prim Tutarı",
                RebateRate: "Prim Oranı",
                Currency: "Döviz",
            },
            SaveButton: "KAYDET",
            TLGap: "Fiyat Farkı (TL)"
        }
    },
    eng: {
        NewInvoiceModalHeader: "New Invoice",
        EditInvoiceModalHeader: "Edit Invoice",
        InputValidationError: "Do not leave blank input marked in red",
        TableHeaderName: "Incoming Invoices",
        NewInvoice: "New Invoice",
        SearchIncomingInvoice: "Search Incoming Invoice",
        SuccessTitle: "Success",
        IntegratedSuccess: "Accounting completed successfully.",
        IntegratedCheckSuccess: "There is no problem in accounting control.",
        IsIntegratedMessage: "It is integrated invoice. Click yes to continue.",
        IsIntegratedTitle: "Integrated Invoice",
        IncomingInvoiceTableLanguage: {
            EditInvoice: "Edit Invoice",
            DeleteInvoice: "Delete Invoice",
            Integrate: "Send To Accounting",
            CheckIntegrate: "Check For Accounting",
            Integrated: "Integrated",
            EInvoice: "E-Invoice",
            InvoiceDate: "Invoice Date",
            InvoiceId: "Invoice Id",
            CustomerNumber: "Customer Number",
            CustomerName: "Customer Name",
            InvoiceAmount: "Invoice Amount",
            InvoiceAmountTL: "Invoice Amount (TL)",
            InvoiceCurrency: "Invoice Currency",
            ExchangeRate: "Exchange Rate",
        },
        ModalLanguage: {
            Description: "Description",
            GetTransactionsButton: "Get Transactions",
            SearchCustomerRebate: "Search Customer Rebate",
            PaperInvoice: "Paper Invoice",
            EInvoice: "E-Invoice",
            GetEInvoiceButton: "GET",
            InvoiceDate: "Invoice Date",
            InvoiceId: "Invoice Id",
            InvoicePaymentDate: "Invoice Payment Date",
            VoucherDate: "Voucher Date",
            ExchangeRate: "Exchange Rate",
            InvoiceAmount: "Invoice Amount (€)",
            VatAmount: "VAT Amount (€)",
            InvoiceTotal: "Invoice Total (€)",
            InvoiceAmountTL: "Invoice Amount (TL)",
            VatAmountTL: "VAT Amount (TL)",
            InvoiceTotalTL: "Invoice Total (TL)",
            ModalTable: {
                Copied: "Copied",
                Period: "Period",
                CustomerNumber: "Customer Number",
                CustomerName: "Customer Name",
                RebateAmount: "Rebate Amount",
                RebateRate: "Rebate Rate",
                Currency: "Currency",
            },
            SaveButton: "SAVE",
            TLGap: "Difference Amount (TL)",


        }
    }
}

export const invoiceControlLanguage = {
    tr: {
        phxId: "D365FO Fatura Numara",
        legalId: "Yasal Numara",
        type: "Tipi",
        status: "Statü",
        lastMessage: "Son Mesaj",
        ExportExcelText: "Excel'e Aktar",
        ValidationErrorStartDateLater: "Başlangıç tarihi, bitiş tarihinden büyük olamaz.",
        ValidationErrorJustOneWeeks: "Sadece bir haftalık fatura bilgisini görüntüleyebilirsiniz. Daha fazlasını görmek isterseniz excel'e aktarabilirsiniz.",
        Headername: "Faturalar"
    },
    eng: {
        phxId: "D365FO Invoice Id",
        legalId: "Legal Id",
        type: "Type",
        status: "Status",
        lastMessage: "Last Message",
        ExportExcelText: "Export to Excel",
        ValidationErrorStartDateLater: "Start date cannot be greater than end date",
        ValidationErrorJustOneWeeks: "You can view only one week of invoice information but you can export to excel",
        Headername: "Invoices"
    }
}
export const NoAuthLanguage = {
    tr: {
        NoAuthTitle: "Yetki hatası.",
        NoAuthMessage: "Bu sayfaya erişmeye yetkiniz yoktur.",
        NoAuthButtonLabel: "Geri Dön"
    },
    eng: {
        NoAuthTitle: "Permission Error",
        NoAuthMessage: "It seems you don't have an access to see this page.",
        NoAuthButtonLabel: "Go Back"
    }
}
export const forgotPasswordLanguage = {
    tr: {
        ForgotHeader: "Şifremi Unuttum",
        SuccessMessage: "Şifrenizi resetlemek için mail gönderilmiştir.",
        ErrorMessage: "", // Gelen error'u aldığı gibi basıyor.
        ValidationMessage: commonLanguage.tr.ValidationMessage,
        ButtonText: "GÖNDER",
        UserNameHolder: "Kullanıcı Adı"
    },
    eng: {
        ForgotHeader: "Forgot Password",
        SuccessMessage: "Password reset information sent to your email.",
        ErrorMessage: "", // Gelen error'u aldığı gibi basıyor.
        ValidationMessage: commonLanguage.eng.ValidationMessage,
        ButtonText: "SEND",
        UserNameHolder: "Username"
    }
}
export const resetPasswordLanguage = {
    tr: {
        ValidationMessage: commonLanguage.tr.ValidationMessage,
        ButtonText: "RESET",
        ResetHeader: "Şifre Resetle",
        ErrorMessage: "",
        ConfirmPasswordHolder: "Şifre Doğrulama",
        NewPasswordHolder: "Yeni Şifre",
        InputErrorTitle: "Hata",
        InputErrorMessage: "Doğrulama şifreniz aynı değil. Tekrar kontrol ediniz.",
        NetworkErrorTitle: "Hata",
        NetworkErrorMessage: "Ağa bağlanılamadı. Tekrar deneyiniz.",
        SuccessTitle: "Başarılı",
        SuccessMessage: "Şifreniz değişitirilmiştir.",
        ButtonYes: commonLanguage.tr.ButtonYes,
        ButtonNo: commonLanguage.tr.ButtonNo,
        NotFound: commonLanguage.tr.NotFound,
        NoConnection: commonLanguage.tr.NoConnection,
    },
    eng: {
        ValidationMessage: commonLanguage.eng.ValidationMessage,
        ButtonText: "RESET",
        ResetHeader: "Reset Password",
        ErrorMessage: "",
        ConfirmPasswordHolder: "Confirm Password",
        NewPasswordHolder: "New Password",
        InputErrorTitle: "Error",
        InputErrorMessage: "Password fields aren't same. Check your password.",
        NetworkErrorTitle: "Error",
        NetworkErrorMessage: "Something went wrong. Try again.",
        SuccessTitle: "Success",
        SuccessMessage: "Your password changed.",
        ButtonYes: commonLanguage.eng.ButtonYes,
        ButtonNo: commonLanguage.eng.ButtonNo,
        NotFound: commonLanguage.eng.NotFound,
        NoConnection: commonLanguage.eng.NoConnection,
    }
}
export const customerLanguage = {
    tr: {
        Headername: "Müşteriler",
        TableActionsButtonText: "İşlemler",
        HistoryModalHeader: "Gönderilmiş Dosyalar Tarihçesi",
        showDocumentHistory: "Gönderilmiş dosyaları göster",
        showAccountEdit: "Hesap Numaralarını düzenle",
        customerNumber: "Müşteri Numarası",
        buyerAccountNumber: "Alıcı Hesap Numarası",
        vendorAccountNumber: "Satıcı Hesap Numarası",
        currency: "Döviz",
        name: "Müşteri Adı",
        isOctet: "Octet",
        isRebateCalculationEnabled: "Hesaplama Yap",
        emailAddresses: "Email Adresi",
        phone: "Telefon",
        fax: "Fax",
        county: "İlçe",
        city: "Şehir",
        country: "Ülke",
        Saving: "Kaydediliyor...",
        saveAll: "Tüm Değişiklikleri Kaydet",
        ErrorTitle: "Hata",
        AccountNumberModalHeader: "Hesap Hesap Numarası Düzenle",
        CustomerNumberSave: "KAYDET",
        ButtonYes: commonLanguage.tr.ButtonYes,
        ButtonNo: commonLanguage.tr.ButtonNo,
        NotFound: commonLanguage.tr.NotFound,
        NoConnection: commonLanguage.tr.NoConnection,
    },
    eng: {
        Headername: "Customers",
        TableActionsButtonText: "Actions",
        showDocumentHistory: "Show sent files",
        showAccountEdit: "Account Number Edit",
        HistoryModalHeader: "History of Sent Files",
        customerNumber: "Customer Number",
        buyerAccountNumber: "Buyer Account Number",
        vendorAccountNumber: "Vendor Account Number",
        currency: "Currency",
        name: "Name",
        isOctet: "Octet",
        isRebateCalculationEnabled: "Calculation Enable",
        emailAddresses: "Email Address",
        phone: "Phone",
        fax: "Fax",
        county: "County",
        city: "City",
        country: "Country",
        saveAll: "Save All",
        ErrorTitle: "Error",
        Saving: "Saving...",
        AccountNumberModalHeader: "Edit Customer Account Number",
        CustomerNumberSave: "SAVE",
        ButtonYes: commonLanguage.eng.ButtonYes,
        ButtonNo: commonLanguage.eng.ButtonNo,
        NotFound: commonLanguage.eng.NotFound,
        NoConnection: commonLanguage.eng.NoConnection,
    }
}
export const TariffTableLanguage = {
    tr: {
        EditTariff: "Tarife Düzenle",
        DeleteTariff: "Sil",
        AgreementNumber: "Anlaşma Numarası",
        TariffNumber: "Tarife Numarası",
        StartDate: "Başlangıç Tarihi",
        EndDate: "Bitiş Tarihi",
        Role: "Yetki",
        Status: "Durumu",
    },
    eng: {
        EditTariff: "Edit Tariff",
        DeleteTariff: "Delete Tariff",
        AgreementNumber: "Agreement Number",
        TariffNumber: "Tariff Number",
        StartDate: "Start Date",
        EndDate: "End Date",
        Role: "Role",
        Status: "Status",

    }
}



export const IdentityUserTableLanguage = {
    tr: {
        EditUser: "Kullanıcı Düzenle",
        DeleteUser: "Sil",
        Username: "Kullanıcı Adı",
        FirstName: "Adı",
        LastName: "Soyadı",
        Email: "E-posta",
        Role: "Yetki",
        Status: "Durumu",
    },
    eng: {
        EditUser: "Edit User",
        DeleteUser: "Delete User",
        Username: "Username",
        FirstName: "First Name",
        LastName: "Last Name",
        Email: "Email",
        Role: "Role",
        Status: "Status",

    }
}

export const rebateLanguage = {
    tr: {
        ExportPDF : "PDF'e Aktarılıyor",
        EditTariff: "Tarife Düzenle",
        TariffManagement: "Tarifeler",
        NewTariff: "Yeni Tarife",
        AggrementNumber: "Anlaşma Numarası",
        TariffNumber: "Tarife Numarası",
        EmailStatus: "Email Durum",
        AllEmailStatus: {
            ALL: "HEPSI",
            SEND: "GONDERILMIS",
            NOTSEND: "GONDERILMEMIS",
        },
        Username: "Kullanıcı Adı",
        FirstName: "Adı",
        LastName: "Soyadı",
        Email: "E-posta",
        Password: "Şifre",
        CompanyCode: "Firma Kodu",
        Department: "Departman",
        Roles: "Yetkiler",
        Status: "Durumu",
        Active: "Aktif",
        Passive: "Pasif",
        Save: "Kaydet",
        EditUser: "Kullanıcı Düzenle",
        NewUser: "Yeni Kullanıcı",
        UserManagement: "Kullanıcılar",
        StartDate: "Başlangıç Tarihi",
        EndDate: "Bitiş Tarihi",
        ExcelModalTitle: "Excel'e Aktar",
        ExcelModalMessage: "Müşteri Ciro Prim Hareketlerini excel'e aktarmak için evet'e basınız.",
        NoHistoryFound: "Gönderilmiş dosya bulunamadı.",
        ProcessButtonText: "Hesapla",
        Process: {
            ProcessHeader: "Hesapla",
            ProcessQuestion: `Seçili ayın faturaları hesaplanacaktır. Devam etmek için evet'e basınız.`,
            ProcessingText: "Lütfen bekleyiniz..."
        },
        TableSelectedActionsButtonText: "Seçilmiş Kayıtların İşlemleri",
        TableSelectedContextMenu: {
            lockSelecteds: "Seçili olan kayıtları kilitle",
            unlockSelecteds: "Seçili olan kayıtları aç",
            processSelecteds: "Seçili olan kayıtları yeniden oluştur",
            sendSelecteds: "Seçili olan kayıtları gönder"
        },
        ProcessInfoText: "Not : Fatura iadelerini hesaplamadan, hesapları yeniden oluşturmayınız.",
        Locking: "Kilitleniyor...",
        Saving: "Kaydediliyor...",
        Unlocking: "Kilit açılıyor...",
        Processing: "İşleniyor...",
        Copying: "Kopyalanıyor...",
        Sending: "Gönderiliyor...",
        TableActionsButtonText: "İşlemler",
        ErrorTitle: "Hata",
        GoBackButtonText: "Geri Dön",
        TableContextMenuTexts: {
            saveAll: "Tüm değişiklikleri kaydet",
            lockAll: "Tüm kayıtları kilitle",
            unlockAll: "Tüm kayıtları aç",
            processAll: "Tüm hesapları yeniden oluştur",
            exportExcel: "Excel'e aktar",
            SendAll: "Tüm kayıtlara gönder",
            InvoiceRebateProcess: "Fatura iadelerini hesapla",
            integrationExcel: "Entegrasyon Excel'ini aktar"


        },
        IntegrateModalTitle: "Muhasebeleştir",
        IntegrateModalMessage: "Fatura muhasebeleşecektir. Devam etmek için evet'e basınız.",

        CheckIntegrateModalTitle: "Muhasebeleştirme Kontrolü",
        CheckIntegrateModalMessage: "Faturanın muhasebeleştirme kontrolü yapılacaktır. Devam etmek için evet'e basınız.",
        DeleteTariffModalTitle: "Tariff Kaydını Sil",
        DeleteInvoiceModalTitle: "Fatura Kaydını Sil",
        DeleteIdentityUserModalTitle: "Kullanıcı Kaydını Sil",
        DeleteTariffModalMessage: "Tariff kaydı silinecektir. Devam etmek için evet'e basınız.",
        DeleteInvoiceModalMessage: "Fatura kaydı silinecektir. Devam etmek için evet'e basınız.",
        DeleteIdentityUserModalMessage: "Kullanıcı kaydı silinecektir. Devam etmek için evet'e basınız.",
        IntegrationExcelModalTitle: "Entegrasyon Excel'ini Aktar",
        IntegrationExcelModalMessage: "Entegrasyon Excel'i aktarılacaktır. Devam etmek için evet'e basınız.",
        RebateInvoiceModalTitle: "Fatura Iade Düşümü",
        RebateInvoiceModalMessage: "Fatura iade düşümü başlatılacaktır. Devam etmek için evet'e basınız.",

        ChangesTitle: "Kaydedilmemiş Kayıt",
        ChangesModalMessage: "Kaydedilmemiş kaydınız var. Eğer devam ederseniz yaptığınız değişiklikler kaydedilmeden devam edilecektir. Devam etmek istiyor musunuz?",
        NotFound: commonLanguage.tr.NotFound,
        NoConnection: commonLanguage.tr.NoConnection,

        ButtonYes: commonLanguage.tr.ButtonYes,
        ButtonNo: commonLanguage.tr.ButtonNo,

        FilterValidationError: "Başlangıç ayı, bitiş ayından daha büyük olamaz.",
        Filters: {
            selectYear: "Yıl Seçiniz",
            startMonth: "Başlangıç Ay",
            endMonth: "Bitiş Ay",
            customerName: "Müşteri Adı",
            customerNameHolder: "Müşteri Adını Giriniz",
            customerCode: "Müşteri Numarası",
            customerCodeHolder: "Numarasını Giriniz",
            customerCurrency: "Döviz"
        },

        SearchButtonText: "ARAMA",
        SaveAllButtonText: "Hepsini Kaydet",

        TransactionTableHeaderName: "Müşteri Ciro Prim Hareketleri",
        TransactionTableColumns: {
            period: "Dönem",
            isLocked: "K",
            isLockedInfoText: "Kilitli mi?",
            dontSend: "GY",
            dontSendInfoText: "Gönderme yapma.",
            isCopied: "KC",
            isCopiedInfoText: "Kopyası çıkarıldı mı?",
            isCopiedTransaction: "KA",
            isCopiedTransactionInfoText: "Kopyası alındı mı?",
            isSent: "G",
            isSentInfoText: "Gönderildi mi ?",
            isIntegratedBySoft: "M",
            isIntegratedBySoftInfoText: "Muhasebeleştirildi mi?",
            customerNumber: "Müşteri Numarası",
            customerName: "Müşteri Adı",
            currency: "Döviz",
            totalRevenueAmount: "Ciro Toplam Tutarı",
            calculatedRebateRatio: "Son Prim Oranı",
            calculatedRebateAmount: "Son Prim Tutarı",
            rebateRatio: "Prim Oranı",
            rebateAmount: "Prim Tutarı",
            manualChargeBackAmount: "Manuel Iade Düşüm Tutarı",
            invoiceChargeBackAmount: "Fatura Iade Düşüm Tutarı",
            octet: "Octet",
            email: "E-Posta",
            updatedBy: "Güncelleyen",
            updateDate: "Güncelleme Tarihi",
            copiedPeriod: "Kopyalanma Tarihi",
            manualChargeBackDescription: "Ek Prim Açıklaması",
            rebateRatioDescription: "Prim Oranı Açıklaması",
            enterDescripton: "Açıklama",
            buyerAccountNumber: "Alıcı Hesap Numarası",
            vendorAccountNumber: "Satıcı Hesap Numarası",

        },
        TransactionTableContextMenu: {
            lockTransaction: "Kayıt Kilitle",
            unlockTransaction: "Kayıt Aç",
            reprocessTransaction: "Hesabı Yeniden Oluştur",
            copyTransaction: "Hesabı Kopyala",
            sendTransactionDocument: "Gönderim Yap",
            exportAsExcel: "Excel'e Aktar",
            exportAsPdf: "PDF'e Aktar"

        },

        InvoicesTableHeaderName: "Primi Oluşturan Hareketler",
        InvoiceTableColumns: {
            invoiceDate: "Fatura Tarihi",
            invoiceNumber: "Fatura Numarası",
            voyageNumber: "Sefer Numarası",
            vesselName: "Gemi Adı",
            vesselCode: "Gemi Kodu",
            scheduleDate: "Gemi Hareket Tarihi",
            totalAmount: "Toplam Tutar",
            customerName: "Müşteri Adı",
            country: "Ülke",
            city: "Şehir",
            address: "Adres",
            fax: "Fax"
        },
        
        Integrated: {
            Integrated: "Entegrasyon",
            All: "HEPSI",
            Yes: "EVET",
            No: "HAYIR"
        },
        EInvoice: {
            EInvoice: "E-Fatura",
            All: "HEPSI",
            Yes: "EVET",
            No: "HAYIR"
        }



    },
    eng: {
        ExportPDF : "Exporting PDF",
        EditTariff: "Edit Tariff",
        TariffManagement: "Tariff Management",
        NewTariff: "New Tariff",
        AggrementNumber: "Agreement Number",
        TariffNumber: "Tariff Number",
        EmailStatus: "Select Email Status",
        AllEmailStatus: {
            ALL: "ALL",
            SEND: "SEND",
            NOTSEND: "NOT SEND",
        },
        Username: "Username",
        FirstName: "First Name",
        LastName: "Last Name",
        Email: "Email",
        Password: "Password",
        CompanyCode: "Company Code",
        Department: "Department",
        Roles: "Roles",
        Status: "Status",
        Active: "Active",
        Passive: "Passive",
        Save: "Save",
        EditUser: "Edit User",
        NewUser: "New User",
        UserManagement: "Users",
        StartDate: "Start Date",
        EndDate: "End Date",
        ExcelModalTitle: "Export Excel",
        ExcelModalMessage: "Click yes for export to Transactions.",

        NoHistoryFound: "No History Found",

        ProcessButtonText: "PROCESS",
        Process: {
            ProcessHeader: "Process",
            ProcessQuestion: `Selected months invoices will be process. Click yes to continue.`,
            ProcessingText: "Processing..."
        },
        ProcessInfoText: "Note : Do not re-create accounts without calculating invoice returns.",

        TableSelectedActionsButtonText: "Selected Actions",
        TableSelectedContextMenu: {
            lockSelecteds: "Lock selected transactions",
            unlockSelecteds: "Unlock selected transactions",
            processSelecteds: "Process selected transactions",
            sendSelecteds: "Send selected transactions"
        },

        ChangesTitle: "Unsaved changes",
        ChangesModalMessage: "You have saved changes. Do you want to continue?",

        ErrorTitle: "Error",

        GoBackButtonText: "Go Back",

        IntegrateModalTitle: "Integrate to Accounting",
        IntegrateModalMessage: "Invoice will integrate. Click yes to continue.",

        CheckIntegrateModalTitle: "Integrate Check to Accounting",
        CheckIntegrateModalMessage: "Invoice integration will be check. Click yes to continue.",
        DeleteTariffModalTitle: "Delete Tariff",
        DeleteInvoiceModalTitle: "Delete Customer Invoice",
        DeleteIdentityUserModalTitle: "Delete Identity User",
        DeleteTariffModalMessage: "Tariff will be deleted. Click yes to continue.",
        DeleteInvoiceModalMessage: "Customer Invoice will be deleted. Click yes to continue.",
        DeleteIdentityUserModalMessage: "User will be deleted. Click yes to continue.",
        IntegrationExcelModalTitle: "Integration Excel Export",
        IntegrationExcelModalMessage: "Integration Excel will Export. Click yes to continue.",
        RebateInvoiceModalTitle: "Invoice Rebate Process",
        RebateInvoiceModalMessage: "Invoice rebate will be started. Click yes to continue.",

        Locking: "Locking...",
        Saving: "Saving...",
        Unlocking: "Unlocking...",
        Processing: "Processing...",
        Copying: "Copying...",
        Sending: "Sending...",

        ButtonYes: commonLanguage.eng.ButtonYes,
        ButtonNo: commonLanguage.eng.ButtonNo,

        NotFound: commonLanguage.eng.NotFound,
        NoConnection: commonLanguage.eng.NoConnection,

        TableActionsButtonText: "Actions",
        TableContextMenuTexts: {
            saveAll: "Save Transactions",
            lockAll: "Lock Transactions",
            unlockAll: "Unlock Transactions",
            processAll: "Process Transactions",
            exportExcel: "Export to Excel",
            SendAll: "Send all",
            InvoiceRebateProcess: "Invoice Rebate Process",
            integrationExcel: "Export Integration Excel"
        },

        FilterValidationError: "The BETWEEN condition was provided a range where the lower bound is greater than the upper bound",
        Filters: {
            selectYear: "Select Year",
            startMonth: "Start Month",
            endMonth: "End Month",
            customerName: "Customer Name",
            customerNameHolder: "Enter Customer Name",
            customerCode: "Customer Number",
            customerCodeHolder: "Enter Number",
            customerCurrency: "Currency"

        },

        SearchButtonText: "SEARCH",
        SaveAllButtonText: "SAVE ALL",

        TransactionTableHeaderName: "Transactions",
        TransactionTableColumns: {
            period: "Period",
            isLocked: "iL",
            isLockedInfoText: "Is Locked?",
            dontSend: "d'S",
            dontSendInfoText: "Dont Send.",
            isCopied: "iC",
            isCopiedInfoText: "is Copied?",
            isCopiedTransaction: "iCT",
            isCopiedTransactionInfoText: "is Copied Transaction?",
            isSent: "iS",
            isSentInfoText: "Is Sent?",
            isIntegratedBySoft: "IIS",
            isIntegratedBySoftInfoText: "Is Integrated by Soft?",
            customerNumber: "Customer Number",
            customerName: "Customer Name",
            currency: "Currency",
            totalRevenueAmount: "Total Revenue Amount",
            calculatedRebateRatio: "Calculated Rebate Ratio",
            calculatedRebateAmount: "Calculated Rebate Amount",
            rebateRatio: "Rebate Ratio",
            rebateAmount: "Rebata Amount",
            manualChargeBackAmount: "Manual Charge Back Amount",
            invoiceChargeBackAmount: "Invoice Charge Back Amount",
            octet: "Octet",
            email: "E-Mail",
            updatedBy: "Updated By",
            updateDate: "Update Date",
            copiedPeriod: "Copied Period",
            manualChargeBackDescription: "Manual Charge Back Description",
            rebateRatioDescription: "Rebate Rate Description",
            enterDescripton: "Açıklama",
            buyerAccountNumber: "Buyer Account Number",
            vendorAccountNumber: "Vendor Account Number",
        },
        TransactionTableContextMenu: {
            lockTransaction: "Lock Transaction",
            unlockTransaction: "Unlock Transaction",
            reprocessTransaction: "Reprocess Transaction",
            copyTransaction: "Copy Transaction",
            sendTransactionDocument: "Send Transaction Document",
            exportAsExcel: "Export as Excel",
            exportAsPdf: "Export as PDF"
        },

        InvoicesTableHeaderName: "Invoices",
        InvoiceTableColumns: {
            invoiceDate: "Invoice Date",
            invoiceNumber: "Invoice Number",
            voyageNumber: "Voyage Number",
            vesselName: "Vessel Name",
            vesselCode: "Vessel Code",
            scheduleDate: "Schedule Date",
            totalAmount: "Total Amount",
            customerName: "Customer Name",
            country: "Country",
            city: "City",
            address: "Address",
            fax: "Fax"
        },

        Integrated: {
            Integrated: "Integrated",
            All: "ALL",
            Yes: "YES",
            No: "NO"
        },
        EInvoice: {
            EInvoice: "E-Invoice",
            All: "ALL",
            Yes: "YES",
            No: "NO"
        },
    }
}
export const accountingVoyageLanguage = {
    tr: {
        PageTitle: "Sefer Yönetimi",
        NewAccountingVoyage: "Yeni Sefer Bilgisi",
        DeleteTitle: "Sefer Bilgisini Sil",
        DeleteMessage: "Sefer Bilgisi silenecektir. Devam etmek için evet'e basınız.",
        AccountingVoyageTable: {
            Edit: "Sefer Bilgisini Düzenle",
            Delete: "Sefer Bilgisini Sil",
            Name: "Adı",
            AccountingCode: "Muhasebe Kodu",
            AccountingName: "Muhasebe Adı",
            AccountingRentCode: "Muhasebe Kira Kodu",
            AccountingRentName: "Muhasebe Kira Adı",
            GroupName: "Grup Adı",
        },
        ModalHeaderEdit: "Sefer Bilgisini Düzenle",
        ModalHeaderNew: "Yeni Sefer Bilgisi",
        SaveButton: "Kaydet",
    },
    eng: {
        PageTitle: "Accounting Voyage Management",
        DeleteTitle: "Delete Accounting Voyage",
        NewAccountingVoyage: "New Accounting Voyage",
        DeleteMessage: "Accounting Voyage will be deleted. Click yes to continue",
        AccountingVoyageTable: {
            Edit: "Edit Accounting Voyage",
            Delete: "Delete Accounting Voyage",
            Name: "Name",
            AccountingCode: "Accounting Code",
            AccountingName: "Accounting Name",
            AccountingRentCode: "Accounting Rent Code",
            AccountingRentName: "Accounting Rent Name",
            GroupName: "Group Name",
        },
        ModalHeaderEdit: "Edit Accounting Voyage",
        ModalHeaderNew: "New Accounting Voyage",
        SaveButton: "Save",
    }
}
export const accountingVesselLanguage = {
    tr: {
        PageTitle: "Gemi Bilgisi Yönetimi",
        NewButton: "Yeni Gemi Bilgisi",
        DeleteTitle: "Gemi Bilgisini Sil",
        DeleteMessage: "Gemi Bilgisi silenecektir. Devam etmek için evet'e basınız.",
        Table: {
            Edit: "Gemi Bilgisini Düzenle",
            Delete: "Gemi Bilgisini Sil",
            Rented: "Kiralık",
            Code: "Kod",
            VesselCode: "Gemi Kodu",
            VesselName: "Gemi Adı",
        },
        SaveButton: "Kaydet",
        ModalEdit: "Gemi Bilgisini Düzenle",
        ModalNew: "Yeni Gemi Bilgisi",
    },
    eng: {
        PageTitle: "Accounting Vessel Management",
        NewButton: "New Accounting Vessel",
        DeleteTitle: "Delete Accounting Vessel",
        DeleteMessage: "Accounting Vessel will be deleted. Click yes to continue",
        Table: {
            Edit: "Edit Accounting Vessel",
            Delete: "Delete Accounting Vessel",
            Rented: "Rented",
            Code: "Code",
            VesselCode: "Vessel Code",
            VesselName: "Vessel Name",
        },
        SaveButton: "Save",
        ModalEdit: "Edit Accounting Vessel",
        ModalNew: "New Accounting Vessel",

    }
}
export const unitTypeLanguage = {
    tr: {
        HeaderText: "Unite Tipi Yönetim Paneli",
        NewUnitTypeButtonText: "Yeni Unite Tipi",
        UnitTypeTableLanguage: {
            IdColumnText: "Id",
            NameColumnText: "Ad",
            LinetypeColumnText: "Hat Türü",
            IsEmptyColumnText: "Dolu mu",
            IsValidText: "Geçerli mi",
            DeleteUnitTypeContextText: "Sil",
            EditUnitTypeContextText: "Düzenle",
        },
        ModalLanguage: {
            EditModalHeader: "Unite Tipi Düzenle",
            NewModalHeader: "Yeni Ünite Tipi",
            IdInputText: "Id",
            NameInputText: "Ad",
            LineTypeInputText: "Hat Türü",
            IsEmptyText: "Dolu mu",
            IsValidText: "Geçerli mi",
            SaveButton: "KAYDET"
        },
        DeleteModalTitle: "Unite Tipi Sil",
        DeleteModalMessage: "Unite Tipi silinecektir. Devam etmek için Evet'e basınız."
    },
    eng: {
        HeaderText: "Unit Type Management",
        NewUnitTypeButtonText: "New UnitType",
        UnitTypeTableLanguage: {
            IdColumnText: "Id",
            NameColumnText: "Name",
            LinetypeColumnText: "LineType",
            IsEmptyColumnText: "Is Empty",
            IsValidText: "Is Valid",
            EditUnitTypeContextText: "Edit",
            DeleteUnitTypeContextText: "Delete",

        },
        ModalLanguage: {
            EditModalHeader: "Edit Unit Type",
            NewModalHeader: "New Unit Type",
            IdInputText: "Id",
            NameInputText: "Name",
            LineTypeInputText: "LineType",
            IsEmptyText: "IsEmpty",
            IsValidText: "IsValid",
            SaveButton: "SAVE",
        },
        DeleteModalTitle: "Delete UnitType",
        DeleteModalMessage: "UnitType will be deleted. Click yes to continue."
    }
}
export const adminPagesLanguage = {
    tr: {
        Name: "Adı",
        Ratio: "Oran",
        Save: "Kaydet",
        EditRate: "Oran Güncelle",
        NewRate: "Yeni Oran",
        RebateRateManagement: "İade İndirim Oranı"
    },
    eng: {
        Name: "Name",
        Ratio: "Ratio",
        Save: "Save",
        EditRate: "Edit Rate",
        NewRate: "New Rate",
        RebateRateManagement: "Rebate Rate Management"
    }
}

