import moment from 'moment';
export const epochToShortDate = (epochTime, Utc) => {
    if (Utc) {
        return moment(epochTime * 1000).format('DD-MM-YYYY HH:mm');
    } else {
        return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('DD-MM-YYYY');
    }
}
export const epochToDateTime = (epochTime, Utc) => {
    if (Utc) {
        return moment(epochTime * 1000).format('DD-MM-YYYY HH:mm');
    } else {
        return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('DD-MM-YYYY HH:mm');
    }
}
export const epochToDateTimeNoFormat = (epochTime, Utc) => {
    if (Utc) {
        return moment(epochTime * 1000).format('DDMMYYYYHHmm');
    } else {
        return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('DDMMYYYYHHmm')
    }
}
export const dateToEpoch = (date) => {
    return date.getTime() / 1000
}
export const epochToMonthAndYear = (epochTime, Utc) => {
    if (Utc) {
        return moment(epochTime * 1000).format('MMMM YYYY');

    } else {
        return moment.unix(epochTime).subtract(moment.unix(epochTime).utcOffset() * 60 * 1000).format('MMMM YYYY');

    }
}