import React, { useState, useEffect } from 'react';
import './Login.scss';
import { authenticateUser, clearUserError } from "../../store/action/userActions"
import { connect } from 'react-redux';
import UserInput from '../CommonUI/UserInput/UserInput'
import SendButton from '../CommonUI/SpinButton/SpinButton'
import { Account, Lock } from '@dfds-ui/icons';
import { Modal } from '@dfds-ui/modal'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import { loginPageLanguage } from '../../config/language'
import ErrorHandler from '../../core/ErrorHandler'



const Login = (props) => {
    const { authenticateUser, clearUserError, WaitForRequest, ErrorCode, ErrorMessage, Language } = props;
    const language = Language && Language.toLowerCase()
    const [getUser, setUser] = useState({
        userName: "",
        userPass: "",
        userNameValidation: true,
        userPassValidation: true,
    })
    const [showModal, setModal] = useState(false);
    const [ForgetPassModal, setForgetPassModal] = useState(false)

    const ForgetPass_OnClose = () => {
        setForgetPassModal(false)
    }
    const ForgetPass_Show = () => {
        setForgetPassModal(true)
    }
    const formValidation = () => {
        const uNameValid = getUser.userName.trim() !== "";
        const uPassValid = getUser.userPass.trim() !== "";
        if (uNameValid && uPassValid) return true
        return false
    }
    const onConnect = () => {
        if (WaitForRequest) return 0;

        if (formValidation()) {
            authenticateUser(getUser, setUser)
        }
        else {
            const username = getUser.userName.trim()
            const userpassword = getUser.userPass.trim()
            setUser({ ...getUser, userNameValidation: username, userPassValidation: userpassword })
        }
    }
    const clearUserInput = () => {
        setUser({ userName: "", userPass: "", userNameValidation: true, userPassValidation: true })
    }
    useEffect(() => {
        if (ErrorCode === -1) {
            setModal(false)
            document.getElementById("myInput userName").focus() //button focuslanıyor ?!
        }
        else {
            setModal(true)
        }

    }, [ErrorCode])

    const calcHeight = () => {
        const formOffHeight = (!getUser.userNameValidation ? 25 : 0) + (!getUser.userPassValidation ? 25 : 0);
        const formHeight = 250;
        return formHeight + formOffHeight;
    }
    const inputArea = () => {
        return (
            <div className="inputArea">
                <UserInput
                    className={getUser.userNameValidation ? "Valid" : "Invalid"}
                    isValid={getUser.userNameValidation}
                    name='userName'
                    id='userName'
                    imgComp={<Account />}
                    holder={loginPageLanguage[language].UserNameHolder}
                    type='text'
                    onChange={(event) => setUser({ ...getUser, userName: event.target.value, userNameValidation: true })}
                    value={getUser.userName}
                    ValidationMessage={loginPageLanguage[language].ValidationMessage}
                    onKeyPress={(event) => { if (event.key === "Enter") onConnect() }}
                />
                <UserInput
                    className={getUser.userPassValidation ? "Valid" : "Invalid"}
                    isValid={getUser.userPassValidation}
                    name='userPass'
                    imgComp={<Lock />}
                    holder={loginPageLanguage[language].PasswordHolder}
                    type='password'
                    onChange={(event) => setUser({ ...getUser, userPass: event.target.value, userPassValidation: true })}
                    value={getUser.userPass}
                    ValidationMessage={loginPageLanguage[language].ValidationMessage}
                    onKeyPress={(event) => { if (event.key === "Enter") onConnect() }}
                />
            </div>
        );
    }

    const formHeader = () => {
        return (
            <div className="loginFormHeader">
                {loginPageLanguage[language].LoginHeader}
            </div>

        );
    }
    const formFooter = () => {
        return (
            <div className="loginFormFooter">
                <span id="forgetPass" onClick={ForgetPass_Show}>{loginPageLanguage[language].ForgotPassword}</span>
                <SendButton
                    disable={formValidation()}
                    waitForRequest={WaitForRequest}
                    onClick={() => { onConnect() }}
                    ButtonLabel={loginPageLanguage[language].SignInButtonText}
                    className="signInBtn"
                />
            </div>
        );
    }
    return (
        <>
            <div className="Background"><div className="Background-cover"></div></div>

            <div style={{ height: calcHeight() + "px" }} className="loginForm">
                {formHeader()}
                {inputArea()}
                {formFooter()}
            </div>

            {
                ForgetPassModal &&
                <ForgotPassword
                    ForgetPass_OnClose={ForgetPass_OnClose}
                    Language={Language}
                />
            }

            <Modal heading={<h3>Error</h3>}
                isOpen={showModal}
                onRequestClose={() => { setModal(false); clearUserError(); clearUserInput(); }}>
                <p>{ErrorHandler(ErrorMessage)}</p>

            </Modal>

        </>
    );
}
const mapStateToProps = state => {
    return {
        ErrorCode: state.user.error.ErrorCode,
        ErrorMessage: state.user.error.ErrorMessage,
        WaitForRequest: state.user.WaitForRequest,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { authenticateUser, clearUserError })(Login);