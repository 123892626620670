import React, { useEffect, useState } from 'react'
import CustomerHeader from './CustomerHeader/CustomerHeader'
import CustomerTables from './CustomerTables/CustomerTables'
import { connect } from 'react-redux';
import { getCustomers, showChangesModal, clearCustomerError, hideChangesModal } from '../../store/action/customerAction'
import './Customer.scss'
import { ErrorModal } from '../CommonUI/Modals/ErrorModal';
import { rebateLanguage, customerLanguage } from '../../config/language';
import HistoryModal from './CustomerModels/HistoryModal/HistoryModal';
import AccountModal from './CustomerModels/AccountModal/AccountModal';
import CustomerChangesModal from './CustomerModels/CustomerChangesModal';

const Customer = (props) => {
    const { getCustomers, ErrorHandler, CustomerName, CustomerNumber, Language,
        WaitForSaveCustomers, AccountCustomerNumbersSaved, clearCustomerError,
        showChangesModal, ForwardAction, ChangesModal, DataHistory, hideChangesModal, ShowAccountNumbersModal } = props;

    const [initializePage, setInitPage] = useState(false)

    useEffect(() => {
        setInitPage(true)
        return () => {
            //clearTransactions()
        }
    }, [])
    useEffect(() => {
        if (initializePage) {
            if (!WaitForSaveCustomers)
                setTimeout(() => {
                    getCustomers(CustomerName, CustomerNumber)
                }, 100)
        }
    }, [WaitForSaveCustomers])


    useEffect(() => {
        if (AccountCustomerNumbersSaved && !ShowAccountNumbersModal)
            setTimeout(() => {
                if (DataHistory && DataHistory.length > 0) {
                    showChangesModal(() => getCustomers(CustomerName, CustomerNumber))
                } else {
                    getCustomers(CustomerName, CustomerNumber)
                }
            }, 100)

    }, [AccountCustomerNumbersSaved, ShowAccountNumbersModal])

    useEffect(() => {
        if (ErrorHandler.ErrorCode !== -1) {
            console.log(ErrorHandler.ErrorDescription)
        }
    }, [ErrorHandler])

    const ErrorCode = ErrorHandler.ErrorCode;
    const ErrorDescription = ErrorHandler.ErrorDescription
    return (
        <div className="CustomerPage-Container">
            <CustomerHeader />
            <CustomerTables />
            <AccountModal />
            <HistoryModal NoHistoryFoundMessage={rebateLanguage[Language].NoHistoryFound} />
            <CustomerChangesModal ChangesModal={ChangesModal} Language={Language} ForwardAction={ForwardAction} hideChangesModal={hideChangesModal} />
            {
                (ErrorCode !== -1) &&
                <ErrorModal
                    ButtonLabel={rebateLanguage[Language].GoBackButtonText}
                    ErrorTitle={customerLanguage[Language].ErrorTitle}
                    ErrorMessage={ErrorDescription}
                    OnClose={() => {
                        clearCustomerError()
                    }}
                />
            }

        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        CustomerName: state.customer.CustomerName,
        CustomerNumber: state.customer.CustomerNumber,
        ErrorHandler: state.customer.ErrorHandler,
        WaitForSaveCustomers: state.customer.WaitForSaveCustomers,
        ForwardAction: state.customer.ForwardAction,
        ChangesModal: state.customer.ChangesModal,
        DataHistory: state.customer.DataHistory,
        ShowAccountNumbersModal: state.customer.ShowAccountNumbersModal,
        AccountCustomerNumbersSaved: state.customer.AccountCustomerNumbersSaved,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { getCustomers, clearCustomerError, showChangesModal, hideChangesModal })(Customer);