import {networkErrors} from '../config/language'
const language = "eng";
const ErrorHandler = (ErrorMessage) => {
    switch (ErrorMessage) {
        case "INVALID_CREDENTIALS":
            return networkErrors[language].InvalidUser
        default:
            return ErrorMessage;
    }
}
export default ErrorHandler;
