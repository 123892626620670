import React, { useState, useEffect } from 'react'
import './RebateHeader.scss'
import { SelectField, Input, Radio, Button } from '@dfds-ui/react-components';
import OptionIteration from './OptionIteration'
// import SearchButton from '../../CommonUI/SpinButton/SpinButton'
import {
    getTransactions, setTransactionFilter, setError, setSelectedTransactions,
    setDataHistory, showHistoryCheck, setStartMonth, setEndMonth, setTransactionYear,setEmailStatus
} from '../../../store/action/rebateAction';
import { connect } from 'react-redux';
import { rebateLanguage } from '../../../config/language';
const RebateHeader = (props) => {
    const _todayDate = new Date()
    const { getTransactions, WaitForTransactions, setError,
        setTransactionFilter, WaitForProcessTransaction,
        Language, setSelectedTransactions, setDataHistory, dataHistory,
        showHistoryCheck, StartMonth, EndMonth, TransactionYear, setStartMonth,setEmailStatus,EmailStatus,
        setEndMonth, setTransactionYear } = props;
    const [CustomerName, setCustomerName] = useState("")
    const [CustomerCode, setCustomerCode] = useState("")
    const [rangeValidation, setRangeValidation] = useState(false)

    useEffect(() => {
        getTransactions(StartMonth, EndMonth, TransactionYear, CustomerName, CustomerCode,EmailStatus)
        setTransactionFilter(StartMonth, EndMonth, TransactionYear, CustomerName, CustomerCode,EmailStatus)
    }, [])

    const _customerNameOnChange = (event) => {
        const customerName = event.target.value
        setCustomerName(customerName)
    }
    const _customerCodeOnChange = (event) => {
        const customerCode = event.target.value
        setCustomerCode(customerCode)
    }
    const _startMonthOnChange = (event) => {
        const myStartMonth = event.target.value
        setStartMonth(myStartMonth)
        setRangeValidation(false)

    }
    const _endMonthOnChange = (event) => {
        const myEndMonth = event.target.value
        setEndMonth(myEndMonth)
        setRangeValidation(false)

    }
    const _transactionYearOnChange = (event) => {
        const TYear = event.target.value
        setTransactionYear(TYear)
    }
    const _emailStatus = (event) => {
        var eStatus = event.target.value
        switch (eStatus) {
            case "HEPSI":
            case "ALL":
                eStatus = null
                break;
            case "GONDERILMIS":
            case "SEND":
                eStatus = true
                break;
            case "GONDERILMEMIS":
            case "NOT SEND":
                eStatus = false
                break;
            default:
                eStatus = null;
                break;
        }
     setEmailStatus(eStatus);
    }
    const checkHistory = (Action) => {
        if (typeof Action !== "function") return
        if (dataHistory.length > 0) {
            showHistoryCheck(Action)
        } else {
            setSelectedTransactions([])
            setDataHistory([])
            Action()
        }
    }
    const _onButtonClick = (e) => {
        if (!WaitForTransactions && !WaitForProcessTransaction) {
            if (_rangeValidation()) {
                const Action = () => {
                    getTransactions(StartMonth, EndMonth, TransactionYear, CustomerName, CustomerCode , EmailStatus)
                    setTransactionFilter(StartMonth, EndMonth, TransactionYear, CustomerName, CustomerCode, EmailStatus)
                }
                checkHistory(Action)
            } else {
                setError(500, rebateLanguage[Language].FilterValidationError)
                //error
                //The BETWEEN condition was provided a range where the lower bound is greater than the upper bound
            }
        }
    }
    const _rangeValidation = () => {
        const startMonthNumber = StartMonth ? parseFloat(StartMonth) : 0
        const endMonthNumber = EndMonth ? parseFloat(EndMonth) : 0
        if (startMonthNumber > endMonthNumber) {
            setRangeValidation(true)
            return false
        }
        return true
    }
    return (
        <div onKeyDown={(e) => {
            if (e.key === "Enter")
                _onButtonClick()
        }}
            className="Rebate-Header">
            <div className="Rebate-Header-Date-Container">
                <div className="Date-Container">
                    <SelectField
                        label={rebateLanguage[Language].Filters.selectYear} //Language
                        size={"small"}
                        name="Date-Container-Year"
                        onChange={(event) => _transactionYearOnChange(event)}
                        className="Date-Container-Year">
                        <OptionIteration Start={(_todayDate.getFullYear() - 4)} End={_todayDate.getFullYear()} Reverse={true} />
                    </SelectField>
                </div>
                <div className="Date-Container">
                    <SelectField
                        label={rebateLanguage[Language].Filters.startMonth} //Language
                        size={"small"}
                        onChange={(event) => _startMonthOnChange(event)}
                        name="Date-Container-StartM"
                        className="Date-Container-StartM"
                        error={rangeValidation}
                        defaultValue={StartMonth}>
                        <OptionIteration Start={1} End={12} Selected={StartMonth} />
                    </SelectField>
                </div>
                <div className="Date-Container">
                    <SelectField
                        label={rebateLanguage[Language].Filters.endMonth} //Language
                        size={"small"}
                        onChange={(event) => _endMonthOnChange(event)}
                        name="Date-Container-EndM"
                        className="Date-Container-EndM"
                        error={rangeValidation}
                        defaultValue={EndMonth}>
                        <OptionIteration Start={1} End={12} Selected={EndMonth} />

                    </SelectField>
                </div>
            </div>

            <div className="Rebate-Header-Customer-Container">
                <div className="Customer-Container">
                    <span className="Input-Label" id="Customer-Container-Label">
                        {rebateLanguage[Language].Filters.customerName}
                    </span>
                    <Input size={"small"}
                        className="CustomerName"
                        name="CustomerName"
                        placeholder={rebateLanguage[Language].Filters.customerNameHolder}
                        onChange={(event) => { _customerNameOnChange(event) }}
                    />
                </div>
                <div className="Customer-Container">
                    <span className="Input-Label" id="Customer-Container-Label">
                        {rebateLanguage[Language].Filters.customerCode /*Language*/}
                    </span>
                    <Input
                        size={"small"}
                        className="CustomerCode"
                        name="CustomerCode"
                        placeholder={rebateLanguage[Language].Filters.customerCodeHolder}
                        onChange={(event) => { _customerCodeOnChange(event) }}

                    />
                </div>
                <div className="Email-Container">
                    <SelectField
                        label={rebateLanguage[Language].EmailStatus} //Language rebateLanguage[Language].EmailStatus
                        size={"small"}
                        onChange={(event) => _emailStatus(event)}
                        className="Email-Selectfield"
                        name="Email-Selectfield"
                    >
                        <option>{rebateLanguage[Language].AllEmailStatus.ALL}</option>
                        <option>{rebateLanguage[Language].AllEmailStatus.SEND}</option>
                        <option>{rebateLanguage[Language].AllEmailStatus.NOTSEND}</option>
                    </SelectField>
                </div>
            </div>


            <Button
                // waitForRequest={WaitForTransactions}
                disabled={!WaitForProcessTransaction && WaitForTransactions}
                onClick={() => _onButtonClick()}
                className="SearchButton"
            >
                {rebateLanguage[Language].SearchButtonText}
            </Button>

        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        WaitForTransactions: state.rebate.WaitForTransactions,
        WaitForProcessTransaction: state.rebate.WaitForProcessTransaction,
        dataHistory: state.rebate.DataHistory,
        StartMonth: state.rebate.StartMonth,
        EndMonth: state.rebate.EndMonth,
        TransactionYear: state.rebate.TransactionYear,
        EmailStatus : state.rebate.EmailStatus,
        Language: state.user.Language,

    }
}
export default connect(mapStateToProps, {
    getTransactions, setStartMonth, setEndMonth, setTransactionYear,
    setTransactionFilter, setError, setSelectedTransactions, setDataHistory, showHistoryCheck,setEmailStatus
})(RebateHeader);