import React, { useEffect } from 'react'
import { JustStaticTable } from '../../CommonUI/JustTable/JustTable'
import { InvoiceControlTableSchema } from './InvoiceControlTableSchema'
import { invoiceControlLanguage } from '../../../config/language'
import { connect } from 'react-redux'
import './InvoiceControlTable.scss'
import { InvoiceControlExcelSchema } from './InvoiceControlExcelSchema'
import { JustExcelExport } from '../../../core/JustExcel'
import { getExcelInvoices, deleteExcelInvoices } from '../../../store/action/invoiceControlAction'
import { JustSpinButton } from '../../CommonUI/JustSpinButton/JustSpinButton'
import { currentMonthName } from '../../../core/dateStr'
const InvoiceControlTable = (props) => {
    const { WaitForInvoices, Invoices, Language, WaitForExcelInvoices,
        getExcelInvoices, ExcelInvoices, deleteExcelInvoices, StartDate, EndDate } = props
    const JustAnimations = {
        WaitForRequest: WaitForInvoices
    }
    const _onExportExcel = () => {
        getExcelInvoices(StartDate, EndDate)
    }
    useEffect(() => {
        if (ExcelInvoices && ExcelInvoices.length > 0) {
            const ExcelData = {
                invoicesSheet: ExcelInvoices
            }
            JustExcelExport(`${StartDate}_${EndDate} ${Language === "eng" ? "Invoices" : "Faturaları"}`,
                InvoiceControlExcelSchema, ExcelData, deleteExcelInvoices)
        }
    }, [ExcelInvoices])
    return (
        <div className="InvoiceControl-Container">
            <div className="InvoiceControl-Table-Header">
                <h4 id="InvoiceControl-HeaderName"> {invoiceControlLanguage[Language].Headername}</h4>
                <JustSpinButton
                    disabled={WaitForExcelInvoices}
                    WaitForRequest={WaitForExcelInvoices}
                    className={"exportToExcelButton"}
                    variation={"secondary"}
                    onClick={() => _onExportExcel()}
                    ButtonText={invoiceControlLanguage[Language].ExportExcelText}
                />
            </div>
            <div className="InvoiceControlTable-Container">

                <JustStaticTable
                    JustSchema={InvoiceControlTableSchema(invoiceControlLanguage[Language])}
                    JustData={Invoices}
                    JustAnimations={JustAnimations}
                />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        WaitForInvoices: state.invoiceControl.WaitForInvoices,
        WaitForExcelInvoices: state.invoiceControl.WaitForExcelInvoices,
        Invoices: state.invoiceControl.Invoices,
        ExcelInvoices: state.invoiceControl.ExcelInvoices,
        StartDate: state.invoiceControl.StartDate,
        EndDate: state.invoiceControl.EndDate,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { getExcelInvoices, deleteExcelInvoices })(InvoiceControlTable);