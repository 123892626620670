import React, { useEffect } from 'react'
import './EditInvoiceModal.scss'
import { Modal } from '@dfds-ui/modal'
import { hideEditInvoiceModal, getCustomerTransaction } from '../../../../store/action/incomingInvoiceAction'
import { connect } from 'react-redux'
import EditInvoiceModalHeader from './Header/ModalHeader'
import EditInvoiceModalContent from './Content/ModalContent'
import EditInvoiceModalFooter from './Footer/ModalFooter'
import { incomingInvoiceLanguage } from '../../../../config/language'

const EditInvoiceModal = (props) => {
    const { EditModal, hideEditInvoiceModal, getCustomerTransaction, SelectedInvoice, Language } = props
    useEffect(() => {
        if (EditModal) {
            getCustomerTransaction(SelectedInvoice.transactionId)
        }
    }, [EditModal])
    return (
        <Modal className={"EditInvoiceModal"} isOpen={EditModal} heading={incomingInvoiceLanguage[Language].EditInvoiceModalHeader} onRequestClose={() => hideEditInvoiceModal()}>
            <EditInvoiceModalHeader />
            <EditInvoiceModalContent />
            <EditInvoiceModalFooter />
        </Modal>
    )
}

const mapStateToProps = (state) => {
    return {
        EditModal: state.incomingInvoice.EditModal,
        SelectedInvoice: state.incomingInvoice.SelectedInvoice,
        Language: state.user.Language
    }
}
export default connect(mapStateToProps, { hideEditInvoiceModal, getCustomerTransaction })(EditInvoiceModal);
