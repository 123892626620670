import React from 'react'
import { FlagTr, FlagGb } from '@dfds-ui/icons'
import PropTypes from 'prop-types';

const FlagSelector = (Language) => {
    const flagName = "JustLanguage-FlagIcon"
    switch (Language.toUpperCase()) {
        case "TR":
            return (
                <FlagTr className={flagName} />
            )
        case "ENG":
            return (
                <FlagGb className={flagName} />
            )
        default:
            return <></>
    }
}
FlagSelector.propTypes = {
    Language: PropTypes.string,
};
FlagSelector.defaultProps = {
    Language: ""
};
export default FlagSelector;
