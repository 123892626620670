export const JustNumber = (e, sensity) => {
    const keys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',', '-', "Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"]

    if (keys.indexOf(e.key) > -1 || (e.ctrlKey && (e.key === "A" || e.key === "a"))) {
        var value = e.target.value ? e.target.value : ""
        if (sensity && sensity > 0) {
            if (value.length > 0) {
                const splitedValue = value.split('.')
                if (splitedValue && splitedValue.length > 1) {
                    if (splitedValue[1].length >= sensity) {
                        const cursorPosition = e.target.selectionStart
                        const dotPosition = value.indexOf('.')
                        if (e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight" && !(cursorPosition <= dotPosition))
                            e.preventDefault()
                        return
                    }
                }
            }
        }
        const negativeKey = e.key === "-"

        if (e.key === ',') {
            e.preventDefault()
            if (value.includes(".")) {
                return
            }
            e.target.value = e.target.value + '.'
            return
        }
        const dotKey = e.key === "."
        if (value.includes("-") && negativeKey) {
            e.preventDefault()
            return
        }
        if (negativeKey) {
            e.target.value = "-" + e.target.value
            e.preventDefault()
            return
        }
        if (value.includes(".") && dotKey) {
            e.preventDefault()
            return
        }
    } else {
        e.preventDefault()
        return
    }
}