/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import "./JustLoading.scss";
import { Spinner } from "@dfds-ui/react-components";

export default function JustLoading(props) {
  const { title } = props;
  let height = window.innerHeight - 60 - 65;

  return (
    <div style={{ height: height }} className="JustLoading-Container">
      <div style={{ height: height }} className="JustLoading-Background" />
      <Spinner className="JustLoading-Spinner" size="250px" instant={true} />
      <span id="JustLoading-Title">{title}</span>
    </div>
  );
}
