import React from 'react'
import './JustCanvas.scss'
const JustCanvas = (props) => {
    const { CanvasWidth, CanvasHeight } = props
    return (
        CanvasWidth && CanvasHeight ?
            <div className="JustCanvas-JustCanvas" style={{ width: CanvasWidth, height: CanvasHeight }}>
                {props.children}
            </div>
            :
            <div className="JustCanvas-JustCanvas">
                {props.children}
            </div>


    )
}
export default JustCanvas;