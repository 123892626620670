import React, { useEffect } from 'react'
import './Modal.scss'
import { Modal } from '@dfds-ui/modal'
import { connect } from 'react-redux'
import { IdentityUserModalHide, getUserRoles, saveUser, setInputIdentityCompanyCode, setInputIdentityDepartment, setInputIdentityPassword, setInputIdentityStatus, setInputIdentityUsername, setInputIdentityName, setInputIdentitySurname, setInputIdentityEmail, setInputIdentityRole, getUsers } from '../../../../store/action/adminPanelAction'
import { Input, Button, SelectField } from '@dfds-ui/react-components'
import { rebateLanguage } from '../../../../config/language'

const IdentityModal = (props) => {
    const { IdentityUserModalHide, ShowIdentityUserModal, SelectedUser, Roles, Language,
        getUserRoles, saveUser, IdentityUsername, IdentityName, IdentitySurname, IdentityEmail, IdentityRole, IdentityStatus,
        setInputIdentityCompanyCode, setInputIdentityDepartment, setInputIdentityPassword, IdentityCompanyCode, IdentityDepartment, IdentityPassword,
        setInputIdentityStatus, setInputIdentityUsername, setInputIdentityName, setInputIdentitySurname, setInputIdentityEmail, setInputIdentityRole } = props
    useEffect(() => {
        if (ShowIdentityUserModal) {
            getUserRoles()
        }
    }, [ShowIdentityUserModal])
    return (
        <Modal className="IdentityUser-Modal" heading={SelectedUser ? rebateLanguage[Language].EditUser : rebateLanguage[Language].NewUser} onRequestClose={() => IdentityUserModalHide()} isOpen={ShowIdentityUserModal}>
            <div className="IdentityUser-Modal-Container">
                <div className="IdentityUser-Modal-Header">

                </div>
                <div className="IdentityUser-Modal-Content">
                    <div className="RightContent-Container">
                        <div className="InputWithLabel" id="username">
                            <span className="Input-Label">
                                {rebateLanguage[Language].Username}
                            </span>
                            {
                                <Input
                                    size={"small"}
                                    defaultValue={SelectedUser && SelectedUser.username}
                                    onChange={(event) => { setInputIdentityUsername(event.target.value); }}
                                    disabled={SelectedUser}
                                />
                            }
                        </div>
                        <div className="InputWithLabel" id="firstName">
                            <span className="Input-Label">
                                {rebateLanguage[Language].FirstName}
                            </span>

                            <Input
                                onChange={(event) => { setInputIdentityName(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.name}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="lastName">
                            <span className="Input-Label">
                                {rebateLanguage[Language].LastName}
                            </span>
                            <Input
                                onChange={(event) => { setInputIdentitySurname(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.surname}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="email">
                            <span className="Input-Label">
                                {rebateLanguage[Language].Email}
                            </span>
                            <Input
                                onChange={(event) => { setInputIdentityEmail(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.email}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="password">
                            <span className="Input-Label">
                                {rebateLanguage[Language].Password}
                            </span>
                            <Input
                                onChange={(event) => { setInputIdentityPassword(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.password}
                                size={"small"}
                            />
                        </div>
                    </div>
                    <div className="LeftContent-Container">
                        <div className="InputWithLabel" id="companyCode">
                            <span className="Input-Label">
                                {rebateLanguage[Language].CompanyCode}
                            </span>
                            <Input
                                onChange={(event) => { setInputIdentityCompanyCode(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.companyCode}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="department">
                            <span className="Input-Label">
                                {rebateLanguage[Language].Department}
                            </span>
                            <Input
                                onChange={(event) => { setInputIdentityDepartment(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.department}
                                size={"small"}
                            />
                        </div>
                        <div className="InputWithLabel" id="roles">
                            <span className="Input-Label">
                                {rebateLanguage[Language].Roles}
                            </span>
                            <SelectField
                                onChange={(event) => { setInputIdentityRole(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.roles[0]}
                                size="small"
                            >
                                {Roles.map((Role) => {
                                    return <option value={Role}>{Role}</option>
                                })}
                            </SelectField>
                        </div>
                        <div className="InputWithLabel" id="statuses">
                            <span className="Input-Label">
                                {rebateLanguage[Language].Status}
                            </span>
                            <SelectField
                                onChange={(event) => { setInputIdentityStatus(event.target.value); }}
                                defaultValue={SelectedUser && SelectedUser.status}
                                size="small"
                            >
                                <option value='1'>{rebateLanguage[Language].Active}</option>
                                <option value='2'>{rebateLanguage[Language].Passive}</option>
                            </SelectField>
                        </div>
                    </div>
                </div>
                <div className="IdentityUser-Modal-Footer">
                    <Button
                        className="IdentityUser-Button-Save"
                        disabled={false}
                        onClick={() => {

                            var tmpIdentityPassword = IdentityPassword;
                            var tmpIdentityStatus = IdentityStatus;
                            var tmpIdentityRole = IdentityRole;

                            if (SelectedUser) {
                                if (SelectedUser.password === IdentityPassword) {
                                    setInputIdentityPassword('');
                                    tmpIdentityPassword = '';
                                }
                            }
                            else {
                                tmpIdentityStatus = '1'
                                setInputIdentityStatus('1');

                                tmpIdentityRole = "InternalUser"
                                setInputIdentityRole('InternalUser');
                            }

                            saveUser(IdentityUsername, IdentityName, IdentitySurname, IdentityEmail, tmpIdentityRole, tmpIdentityStatus, IdentityCompanyCode, IdentityDepartment, tmpIdentityPassword)

                        }}
                    >
                        {rebateLanguage[Language].Save}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}



const mapStateToProps = (state) => {
    return {
        Language: state.user.Language,
        ShowIdentityUserModal: state.adminPanel.ShowIdentityUserModal,
        SelectedUser: state.adminPanel.SelectedUser,
        Roles: state.adminPanel.Roles,
        IdentityUsername: state.adminPanel.IdentityUsername,
        IdentityName: state.adminPanel.IdentityName,
        IdentitySurname: state.adminPanel.IdentitySurname,
        IdentityEmail: state.adminPanel.IdentityEmail,
        IdentityRole: state.adminPanel.IdentityRole,
        IdentityStatus: state.adminPanel.IdentityStatus,
        IdentityCompanyCode: state.adminPanel.IdentityCompanyCode,
        IdentityDepartment: state.adminPanel.IdentityDepartment,
        IdentityPassword: state.adminPanel.IdentityPassword,

    }
}
export default connect(mapStateToProps, {
    IdentityUserModalHide, getUserRoles, saveUser, setInputIdentityUsername, setInputIdentityName,
    setInputIdentitySurname, setInputIdentityEmail, setInputIdentityRole, setInputIdentityStatus, setInputIdentityCompanyCode,
    setInputIdentityDepartment, setInputIdentityPassword
})(IdentityModal)