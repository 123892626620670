import React, { useState, useEffect } from 'react'
import './CustomerHeader.scss'
import { Input, Button, Select } from '@dfds-ui/react-components';
import SearchButton from '../../CommonUI/SpinButton/SpinButton'
import { getCustomers, showChangesModal } from '../../../store/action/customerAction';
import { connect } from 'react-redux';
import { rebateLanguage } from '../../../config/language';
const RebateHeader = (props) => {
    const { getCustomers, Language, DataHistory,
        showChangesModal, WaitForCustomers, WaitForSaveCustomers } = props;
    const [CustomerName, setCustomerName] = useState("")
    const [CustomerNumber, setCustomerNumber] = useState("")

    useEffect(() => {
        getCustomers(CustomerName, CustomerNumber)
        //setTransactionFilter(StartMonth, EndMonth, TransactionYear, CustomerName, CustomerCode)
    }, [])

    const checkHistory = (Action) => {
        if (typeof Action !== "function") return
        if (DataHistory.length > 0) {
            showChangesModal(Action)
        } else {
            Action()
        }
    }

    const _customerNameOnChange = (event) => {
        const customerName = event.target.value
        setCustomerName(customerName)
    }
    const _customerCodeOnChange = (event) => {
        const customerNumber = event.target.value
        setCustomerNumber(customerNumber)
    }

    const _onButtonClick = (e) => {
        checkHistory(() => getCustomers(CustomerName, CustomerNumber))
    }
    return (
        <div onKeyDown={(e) => {
            if (e.key === "Enter")
                _onButtonClick()
        }}
            className="Customer-Header">
            <div className="Customer-Header-Customer-Container">
                <div className="Customer-Container">
                    <span className="Input-Label" id="Customer-Container-Label">
                        {rebateLanguage[Language].Filters.customerName}
                    </span>
                    <Input size={"small"}
                        className="CustomerName"
                        name="CustomerName"
                        placeholder={rebateLanguage[Language].Filters.customerNameHolder}
                        onChange={(event) => { _customerNameOnChange(event) }}
                    />
                </div>
                <div className="Customer-Container">
                    <span className="Input-Label" id="Customer-Container-Label">
                        {rebateLanguage[Language].Filters.customerCode /*Language*/}
                    </span>
                    <Input
                        size={"small"}
                        className="CustomerCode"
                        name="CustomerCode"
                        placeholder={rebateLanguage[Language].Filters.customerCodeHolder}
                        onChange={(event) => { _customerCodeOnChange(event) }}

                    />
                </div>

                <div className="Customer-Container">
                    <span className="Input-Label" id="Customer-Container-Label">
                        {rebateLanguage[Language].Filters.customerCurrency /*Language*/}
                    </span>
                    <Select
                        size={"small"}
                        className="CustomerCurrency"
                        name="CustomerCurrency">
                        <option value="EUR">EURO</option>
                        {/* <option value="TL">LIRA</option> */}
                    </Select>

                </div>
            </div>


            <Button
                // waitForRequest={WaitForTransactions}
                disabled={(WaitForCustomers || WaitForSaveCustomers)}
                onClick={() => _onButtonClick()}
                className="SearchButton"
            >{rebateLanguage[Language].SearchButtonText} </Button>

        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        WaitForCustomers: state.customer.WaitForCustomers,
        WaitForSaveCustomers: state.customer.WaitForSaveCustomers,
        DataHistory: state.customer.DataHistory,
        Language: state.user.Language

    }
}
export default connect(mapStateToProps, { getCustomers, showChangesModal })(RebateHeader);