import React, { createRef } from 'react'
import JustDialog from '../JustDialog/JustDialog'
import { Button } from '@dfds-ui/react-components'
import './JustHamburger.scss'
import { GiHamburgerMenu } from "react-icons/gi";
import { More } from '@dfds-ui/icons'
import PropTypes from 'prop-types';

export default class JustHamburger extends React.Component {
    constructor(props) {
        super(props)
        this.state = { Dialog: false }
        this.containerRef = createRef()
    }
    _onMouseLeave = (e) => {
        this.setState({ Dialog: false })
    }
    _onDialogBlur = (e) => {
        this.setState({ Dialog: false })
    }
    _onClick = (e) => {
        this.setState({ Dialog: true })
    }
    render() {
        const dialogOptions = this.props.DialogOptions
        const iconHamburger = this.props.Hamburger
        const withButton = this.props.Button
        const positionOfMenu = this.props.Position
        return (
            <>
                {this.state.Dialog && <JustDialog
                    ParentRef={this.containerRef}
                    onMouseLeave={this._onMouseLeave}
                    onBlur={this._onDialogBlur}
                    DialogOptions={dialogOptions}
                    to={positionOfMenu}
                />}
                {
                    (withButton) ?
                        <div
                            ref={this.containerRef}
                        >
                            <Button onClick={(e) => { this._onClick(e); if (typeof withButton.onClick === "function") withButton.onClick(); }} {...withButton}>{withButton.Text}</Button>
                        </div>
                        :
                        <div
                            className="JustTable-ContextMenuButton-Container"
                            ref={this.containerRef}
                        >
                            {
                                (iconHamburger) ?
                                    <GiHamburgerMenu
                                        onClick={(e) => this._onClick(e)}
                                        id="JustTable-ContextMenuButton" />
                                    :
                                    <More
                                        onClick={(e) => this._onClick(e)}
                                        id="JustTable-ContextMenuButton" />
                            }


                        </div>
                }
            </>
        )
    }
}
JustHamburger.propTypes = {
    DialogOptions: PropTypes.array,
    Hamburger: PropTypes.bool,
    Button: PropTypes.object,
    Position: PropTypes.string
};
JustHamburger.defaultProps = {
    Position: "Right"
}