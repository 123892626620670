import React from 'react'
import { Lock, Delete, Edit, LockOpen, YesTick, Close, LoadingSpinner, Document } from '@dfds-ui/icons'

export const UnitTypeTableSchema = (schemaLanguage) => {
    return {
        rowId: "id",
        RowContextMenu: [
            {
                Text: schemaLanguage.EditUnitTypeContextText,
                Icon: <Edit />,
                Name: "editUnitType",
            },
            {
                Text: schemaLanguage.DeleteUnitTypeContextText,
                Icon: <Delete />,
                Name: "deleteUnitType",
            },
        ],
        ColumnDesign: [
            {
                columnId: "id",
                Text: schemaLanguage.IdColumnText,
            },
            {
                columnId: "name",
                Text: schemaLanguage.NameColumnText,

            },
            {
                columnId: "lineType",
                Text: schemaLanguage.LinetypeColumnText,

            },
            {
                columnId: "isEmpty",
                Text: schemaLanguage.IsEmptyColumnText,
    
            },
            {
                columnId: "isValid",
                Text: schemaLanguage.IsValidText,
                
            }
            ],
        DataDesign: [
            {
                columnId: "id",
            },
            {
                columnId: "name",
            },
            {
                columnId: "lineType",
            },
            {
                columnId: "isEmpty",
                dataType: "icon",
                dataCheck: (data) => isValidIcon(data===1)
            },
            {
                columnId: "isValid",
                dataType: "icon",
                dataCheck: (data) => isValidIcon(data)
            },
        ]
    }
}
const isValidIcon = (data) => {
    if (!data) {
        return <Close style={{ color: "#be1e2d" }} />
    } else {
        return <YesTick style={{ color: "#4caf50" }} />
    }
}
