import React from 'react'
import moment from "moment"
import { Lock, Edit, LockOpen, YesTick, Close, LoadingSpinner, Document } from '@dfds-ui/icons'

export const NewInvoiceTableSchema = (schemaLanguage, Language) => {
    return {
        RadioButton: true,
        ColumnDesign: [
            {
                columnId: "IsCopied",
                Text: schemaLanguage.Copied,
            },
            {
                columnId: "period",
                Text: schemaLanguage.Period,
            },
            {
                columnId: "number",
                Text: schemaLanguage.CustomerNumber,
                parentIds: ["customer"],
            },
            {
                columnId: "name",
                parentIds: ["customer"],
                Text: schemaLanguage.CustomerName,
            },
            {
                columnId: "rebateAmount",
                Text: schemaLanguage.RebateAmount,
            },
            {
                columnId: "rebateRate",
                Text: schemaLanguage.RebateRate,
            },
            {
                columnId: "currency",
                Text: schemaLanguage.Currency,
            },
        ],
        DataDesign: [
            {
                columnId: "IsCopied",
                dataType: "icon",
                dataCheck: (data) => isCopiedIcon(data)
            },
            {
                columnId: "period",
                dataType: "EPOCHMONTHANDYEAR",
            },
            {
                columnId: "number",
            },
            {
                columnId: "name",
            },
            {
                columnId: "rebateAmount",
                dataType: "number",
                numberDigit: 2,

            },
            {
                columnId: "rebateRatio",
                dataType: "number"
            },
            {
                columnId: "currency",
                // dataType: "number"
            },
        ]
    }
}
const isCopiedIcon = (data) => {
    if (!data) {
        return <Close style={{ color: "#be1e2d" }} />
    } else {
        return <YesTick style={{ color: "#4caf50" }} />
    }
}
export const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 200; index++) {
        let myObject = {}
        myObject.IsCopied = Math.floor(Math.random() * 10) > 5
        myObject.period = moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))).unix()
        // .format('MM/DD/YYYY');
        myObject.customerNumber = makeid(8)
        myObject.customerName = makeid(8)
        myObject.rebateAmount = makenumber(4)
        myObject.rebateRate = makeid(2)
        myObject.currency = makeid(2)
        DummyArray.push(myObject)
    }
    return DummyArray
}
function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
function makenumber(length) {
    var result = '';
    var characters = '123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
