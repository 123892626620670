import moment from "moment";

const getDateStr = (date = new Date()) => {
    const dayIndex = date.getDate();
    const monthIndex = (date.getMonth() + 1) // October is 0 has to 09 05 need 0 for DateRangePicker
    const month = (monthIndex < 10) ? "0" + monthIndex : monthIndex;
    const day = (dayIndex < 10) ? "0" + dayIndex : dayIndex;
    const year = date.getFullYear();
    const strDate = year + "-" + month + "-" + day;
    return strDate;
}
const increaseDate = (day, date = new Date()) => {
    date.setDate(date.getDate() + day);
    return getDateStr(date)
}
const decreaseDate = (day, date = new Date()) => {
    date.setDate(date.getDate() - day)
    return getDateStr(date)
}
const strToEpoch = (sDate) => {
    return new Date(sDate).getTime() / 1000
}
const oneWeeksBetween = (nextDate, previousDate) => {
    // const nextDateEpoch = strToEpoch(nextDate)
    const previousDateEpoch = strToEpoch(previousDate)
    var sevenDaysBefore = moment(nextDate).subtract(6, "days").format("YYYY-MM-DD")
    sevenDaysBefore = strToEpoch(sevenDaysBefore)
    return previousDateEpoch >= sevenDaysBefore
}
const beforeThanDate = (nextDate, previousDate) => {
    return strToEpoch(nextDate) > strToEpoch(previousDate)
}
const monthFirstDay = (utc) => {
    var startOfMonth
    if (utc) {
        startOfMonth = moment().startOf('month').unix();
    } else {
        startOfMonth = moment().startOf('month').unix();
        startOfMonth = moment.unix(startOfMonth).add(moment.unix(startOfMonth).utcOffset() * 60 * 3000).unix()
    }
    return startOfMonth
}
const getMonthFirstDay = (year, month) => {
    const cMonth = month.length < 2 ? `0${month}` : month
    var returnDate = moment(`${year}-${cMonth}-01`).unix()
    returnDate = moment.unix(returnDate).add(moment.unix(returnDate).utcOffset() * 60 * 3000).unix()
    return returnDate
}
const monthLastDay = (utc) => {
    //.subtract(1,"day")
    var endOfMonth
    // if (utc) {
    endOfMonth = moment().endOf('month').subtract(1, "day").unix();
    // } else {
    //     endOfMonth = moment().endOf('month').subtract(1, "day").unix();
    //     endOfMonth = moment.unix(endOfMonth).subtract(moment.unix(endOfMonth).utcOffset()).unix()
    // }
    return endOfMonth
}
const monthNames = (Language) => {
    var months = ""
    if (Language === "eng") {
        months = "January;February;March;April;May;June;July;Agust;September;October;November;December";
    } else if (Language === "tr") {
        months = "Ocak;Şubat;Mart;Nisan;Mayıs;Haziran;Temmuz;Ağustos;Eylül;Ekim;Kasım;Aralık";
    }
    return months
}
const currentMonthName = (Language) => {
    var date = moment(Date.now()).toDate();;
    var month = date.getMonth();
    const months = monthNames(Language)
    var monthsArray = months.split(';');
    var monthName = monthsArray[month];
    return monthName
}
const beforeMonthName = (Language) => {
    var date = moment(Date.now()).subtract(1, 'M').toDate();;
    var month = date.getMonth();
    const months = monthNames(Language)
    var monthsArray = months.split(';');
    var monthName = monthsArray[month];
    return monthName
}
const beforeMonthFirstDay = () => {
    const currentBeforeMonth = moment(Date.now()).subtract(1, 'M')
    const firstDayOfBeforeMonth = currentBeforeMonth.startOf('month')
    return firstDayOfBeforeMonth.unix()
}
export { getDateStr, increaseDate, decreaseDate, strToEpoch, beforeThanDate, getMonthFirstDay, oneWeeksBetween, monthFirstDay, monthLastDay, currentMonthName, beforeMonthName, beforeMonthFirstDay };