import React from 'react'
import { MdHistory, MdAccountBalance } from "react-icons/md";
import { AiFillAccountBook } from "react-icons/ai";


export const CustomerTableSchema = (schemaLanguage) => {
    return {
        rowId: "number", //unique ID
        RowContextMenu: [
            {
                Text: schemaLanguage.showDocumentHistory,
                Icon: <MdHistory />,
                Name: "showDocumentHistory",
            },
            {
                Text: schemaLanguage.showAccountEdit,
                Icon: <MdAccountBalance />,
                Name: "showAccountEdit",
            },
        ],
        ColumnDesign: [
            {
                columnId: "customerNumber",
                Text: schemaLanguage.customerNumber,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "125px"
                    },
                }
            },            
            {
                columnId: "name",
                Text: schemaLanguage.name,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "400px"
                    },
                    Filter: {
                        Default: "",
                        Input: {
                            Type: "text", // select
                            Selects: null // (Columns) => {}
                        }
                    }
                },
            },
            {
                columnId: "isRebateCalculationEnabled",
                Text: schemaLanguage.isRebateCalculationEnabled,
                Options: {
                    DesignLock: {
                        Default: true,
                        Width: "100px"
                    },
                }
            },
            {
                columnId: "isOctet",
                Text: schemaLanguage.isOctet,
            },
            {
                columnId: "buyerAccountNumber",
                parentIds: ["accountings"],
                parentArrayPosition: 0,
                Text: schemaLanguage.buyerAccountNumber
            },

            {
                columnId: "vendorAccountNumber",
                parentIds: ["accountings"],
                parentArrayPosition: 0,
                Text: schemaLanguage.vendorAccountNumber,

            },
            {
                columnId: "emailAddresses",
                Text: schemaLanguage.emailAddresses,
            },
            {
                columnId: "phone",
                Text: schemaLanguage.phone,
            },
            {
                columnId: "fax",
                Text: schemaLanguage.fax
            },
            {
                columnId: "county",
                Text: schemaLanguage.county
            },
            {
                columnId: "city",
                Text: schemaLanguage.city
            },
            {
                columnId: "country",
                Text: schemaLanguage.country
            }
        ],
        DataDesign: [
            {
                columnId: "number",
            },
            {
                columnId: "name",
            },
            {
                columnId: "isRebateCalculationEnabled",
                input:
                {
                    Type: "checkbox", //textarea , select , checkbox
                    Items: [{}]
                }

            },
            {
                columnId: "isOctet",
                input:
                {
                    Type: "checkbox", //textarea , select , checkbox
                    Items: [{}]
                }

            },
            {
                columnId: "buyerAccountNumber",
                // updatable: true,
                input:
                {
                    Type: "text", //textarea , select , checkbox
                    Items: [{}]
                }
            },
            {
                columnId: "vendorAccountNumber",
                // updatable: true,
                input:
                {
                    Type: "text", //textarea , select , checkbox
                    Items: [{}]
                }
            },
            {
                columnId: "emailAddresses",
                updatable: true,
                dataType: "Array",

                input:
                {
                    Type: "text", //textarea , select , checkbox
                    PlaceHolder: "Enter Email Address",
                    Items: [{}]
                }
            },
            {
                columnId: "phone"
            },
            {
                columnId: "fax"
            },
            {
                columnId: "county",
            },
            {
                columnId: "city",
            },
            {
                columnId: "country",
            }
        ]
    }
}
// const dataCheckBox = (data) => {
//     if (!data) {
//         return <input type="checkbox" width={32} height={32} />
//     } else {
//         return <input type="checkbox" width={32} height={32} checked />
//     }
// }

const DummyCreator = () => {
    let DummyArray = []
    for (let index = 0; index < 200; index++) {
        let myObject = {}
        myObject.period = 1579507610
        myObject.isLocked = "true"
        myObject.isSent = "true"
        // myObject.isCopied = "false"
        // myObject.isCopiedTransaction = "true"
        // myObject.gonderildi = "true"
        // myObject.mustericode = 666
        // myObject.musteriname = "Furkan Kubilay"
        // myObject.doviz = "Euro"
        // myObject.cirotoplamtut = 3506.56
        // myObject.hesaplananporan = 3.14
        // myObject.hesaplananptutar = 315.36
        // myObject.primoran = 8.45
        // myObject.primtutar = 1.14
        // myObject.manualiadetutar = 605.98
        // myObject.octetmi = "true"
        // myObject.email = "furkankubilay81@hotmail.com"
        // myObject.guncelleyen = "admin@dfds.com"
        // myObject.guncellemetarihi = 1579334810
        // myObject.kopyalanandonem = 1547798810
        // myObject.aciklama1 = "Buraları boş bırakmayalım dimi?"
        // myObject.aciklama2 = "Evet haklısın mükemmel bir idea deneme1 deneme 2 deneme3 deneme4 deneme 5 deneme6 boş bırakma ki görelim ne nerede nasıl duruyor dimi?"
        // myObject.aciklananmanueliade = "Çok data var ama bilemiyorum altan."
        DummyArray.push(myObject)
    }
    return DummyArray
}
export const Dummy = DummyCreator()