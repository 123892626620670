import React, { useState } from 'react'
import UserInput from '../CommonUI/UserInput/UserInput'
import { Lock } from '@dfds-ui/icons';
import ResetButton from '../CommonUI/SpinButton/SpinButton';
import './ResetPassword.scss';
import history from '../../core/history';


import httpClient from "../../core/httpclient";
import Modal from '../CommonUI/Modals/DefaultModal/DefaultModal'

import { resetPasswordLanguage } from '../../config/language'
import ProjectConfig from '../../config/config';
import { connect } from 'react-redux';

const ResetPassword = (props) => {
    const { Guid, Language } = props;
    const [getInputState, setInputState] = useState({
        NewValidation: true,
        ConfirmValidation: true,
        newPassInput: "",
        confirmPassInput: "",
        waitForRequest: false,
        ValidationMessage: "",
    })
    const [ModalProps, setModalProps] = useState({
        Show: false,
        Title: "",
        Message: "",
        onClose: null,
    })
    const modal_onClose = () => {
        setModalProps({ ...ModalProps, Show: false })
    }
    const modal_onSuccessClose = () => {
        setModalProps({ ...ModalProps, Show: false })
        history.push('/login')
    }
    const clearInput = () => {
        setInputState({ ...getInputState, newPassInput: "", confirmPassInput: "" })
    }
    const FormHeader = () => {
        return (
            <div className="RPFormHeader">
                <span className="RPFormTitle">{resetPasswordLanguage[Language].ResetHeader}</span>
            </div>
        )
    }
    const input_onKeyPress = (key) => {
        if (key === "Enter")
            reset_onClick()
    }
    const FormArea = () => {
        return (
            <div className="RPFormArea">
                <UserInput
                    className={getInputState.NewValidation ? "Valid" : "Invalid"}
                    isValid={getInputState.NewValidation}
                    name='userPassNew'
                    imgComp={<Lock />}
                    holder={resetPasswordLanguage[Language].NewPasswordHolder}
                    type='password'
                    onChange={(event) => setInputState({ ...getInputState, newPassInput: event.target.value, NewValidation: true })}
                    ValidationMessage={resetPasswordLanguage[Language].ValidationMessage}
                    onKeyPress={(event) => input_onKeyPress(event.key)}

                />
                <UserInput
                    className={getInputState.ConfirmValidation ? "Valid" : "Invalid"}
                    isValid={getInputState.ConfirmValidation}
                    name='userPassNewConfirm'
                    imgComp={<Lock />}
                    holder={resetPasswordLanguage[Language].ConfirmPasswordHolder}
                    type='password'
                    onChange={(event) => setInputState({ ...getInputState, confirmPassInput: event.target.value, ConfirmValidation: true })}
                    ValidationMessage={resetPasswordLanguage[Language].ValidationMessage}
                    onKeyPress={(event) => input_onKeyPress(event.key)}
                />
            </div>
        )
    }
    const FormFooter = () => {
        return (
            <div className="RPFormFooter">

                <ResetButton
                    disable={true}
                    onClick={reset_onClick}
                    ButtonLabel={resetPasswordLanguage[Language].ButtonText}
                    className="resetBtn"
                    waitForRequest={getInputState.waitForRequest}
                />
            </div>
        )
    }

    const reset_onClick = () => {
        const nInput = getInputState.newPassInput.trim();
        const cInput = getInputState.confirmPassInput.trim()
        if (nInput === "" && cInput === "") {
            setInputState({ ...getInputState, NewValidation: false, ConfirmValidation: false })
            return;
        } else if (nInput === "") {
            setInputState({ ...getInputState, NewValidation: false })
            return;

        } else if (cInput === "") {
            setInputState({ ...getInputState, ConfirmValidation: false })
            return;

        }
        if (cInput !== nInput) {
            setModalProps({
                Show: true,
                Title: resetPasswordLanguage[Language].InputErrorTitle,
                Message: resetPasswordLanguage[Language].InputErrorMessage,
                onClose: modal_onClose
            })
            return;
        }
        setInputState({ ...getInputState, waitForRequest: true })
        changePassword();
    }
    const changePassword = () => {
        const endPoint = `${ProjectConfig.apiUrl}/user/resetpassword`;
        const request = {
            password: getInputState.confirmPassInput,
            passwordLinkId: Guid
        }
        httpClient.post(endPoint, request)
            .then(response => {
                setInputState({ ...getInputState, waitForRequest: false })
                setModalProps({
                    Show: true,
                    Title: resetPasswordLanguage[Language].SuccessTitle,
                    Message: resetPasswordLanguage[Language].SuccessMessage,
                    onClose: modal_onSuccessClose
                })
                clearInput();
            })
            .catch(error => {
                console.log(error)
                setInputState({ ...getInputState, waitForRequest: false })
                setModalProps({
                    Show: true,
                    Title: resetPasswordLanguage[Language].NetworkErrorTitle,
                    Message: resetPasswordLanguage[Language].NetworkErrorMessage,
                    onClose: modal_onClose
                })
                clearInput();
            })
    }
    const calcHeight = () => {
        const offHeight = (getInputState.NewValidation ? 0 : 25) + (getInputState.ConfirmValidation ? 0 : 25);
        const formHeight = 250;
        return formHeight + offHeight;

    }
    return (
        <>
            <div className="Background"><div className="Background-cover"></div></div>
            <div style={{ height: calcHeight() + "px" }} className="ResetPasswordForm">
                {FormHeader()}
                {FormArea()}
                {FormFooter()}
            </div>

            {
                ModalProps.Show &&
                <Modal Title={ModalProps.Title}
                    Message={ModalProps.Message}
                    OnClose={ModalProps.onClose}
                />
            }
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        Language: state.user.Language,
    }
}
export default connect(mapStateToProps, null)(ResetPassword);